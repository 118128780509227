import React, { useState, useEffect, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PlacableObjectList from './PlacableObjectList';
import preparePlaceableData from './preparePlacableData.js';
import ImageCanvas from './ImageCanvas.jsx'
import VisualizerHeader from './VisualizerHeader';
import FloorplanSelector from "./FloorplanSelector"
import floorPlansAPI from '../../api/floorPlans';
import originPointsAPI from '../../api/originpoints';
import samplePointsAPI from '../../api/samplepoints';
import testsAPI from '../../api/tests';
import axios from 'axios';
import dataUtils from './dataUtils'

function Visualizer() {
	const [originPoints, setOriginPoints] = useState([]);
	const [samplePoints, setSamplePoints] = useState([]);
	const [floorPlans, setFloorPlans] = useState([]);
	const [currentFloorPlan, setCurrentFloorPlan] = useState(null);
	const [msg, setMsg] = useState("");
	const [isEditable, setIsEditable] = useState(false);

	const labelsContainerRef = React.createRef();
	/**
	 * runs as on component mounted
	 * gets floorplans, origin and sample point data
	 */
  	useEffect(() => {
		//console.log("Visualizer useEffect");

		//logic to handle highlighting navbar tab
		let segmentsNavButton = document.querySelector('#sBtn')
		if (segmentsNavButton) {
		segmentsNavButton.classList.add('selected');
		}

		async function fetchDataObjects() {
			setMsg('Loading Data...');

			const projectId = JSON.parse(localStorage.getItem('projectId')) || null;
			const testId = JSON.parse(localStorage.getItem('testId')) || null;
			const accessLevel = JSON.parse(localStorage.getItem('accessLevel')) || null;

			if (testId && accessLevel && accessLevel !== 'Viewer') {
				const oneCheckResponse = await testsAPI.getOneTest(testId);
				//todo: make error handling more robust
				if (isResponseData(oneCheckResponse)) {
					//console.log(oneCheckResponse);
					const { testexecuted } = getResponseData(oneCheckResponse);

					setIsEditable(!testexecuted);
				}
			}

			const opResponse = await originPointsAPI.getAllOriginpoints(testId, projectId);

			if (!isResponseData(opResponse)) {
				setMsg('An error occured while attempting to acces the origin points');
				return;
			}

			const opData = getResponseData(opResponse);
			const fpInOPs = opData.map(item => item.floorplan);

			const spResponse = await samplePointsAPI.getAllSamplepoints(testId, projectId);

			if (!isResponseData(spResponse)) {
				setMsg('An error occured while attempting to acces the sample points');
				return;
			}

			//console.log(opResponse);
			//console.log(spResponse);
			const spData = getResponseData(spResponse);

			const fpResponse = await floorPlansAPI.getFloorPlans(projectId);
			//console.log(fpResponse);

			if (!isResponseData(fpResponse)) {
				setMsg('An error occured while attempting to acces the floor plans');
				return;
			}

			const newFloorPlansList = getResponseData(fpResponse).reduce((res, item) => {
				const { filename, floorplanid, heatmapversion } = item;
				if (fpInOPs.includes(filename)) {
					const newItem = {
						filename,
						floorplanid,
						file: null,
						hasHeatmapVersionImage: heatmapversion,
					};
					res.push(newItem);
				}
				return res;
			}, []);
			//console.log(newFloorPlansList);   
			
			setFloorPlans(newFloorPlansList);

			setOriginPoints(preparePlaceableData(opData));
			setSamplePoints(preparePlaceableData(spData));

			setMsg('');

			for (let opDataItem of opData) {
				if (!opDataItem.floorplan) {
					setMsg('Floor plan not defined.');
					break;
				}
			}
		}

    	fetchDataObjects();
  	}, []);

	/**
	 *  Set the initial currentFloorPlan and updates the currentFloorPlan with fresh data
	 */
	useEffect(() => {
		//console.log("floorPlans changed");
		//console.log(floorPlans);

		if (floorPlans.length && !currentFloorPlan) {
			const firstFP = floorPlans[0];

			setCurrentFloorPlan(firstFP);

			if (firstFP && !firstFP.file) {
				const { filename, floorplanid, hasHeatmapVersionImage } = firstFP;

				downloadFloorPlanFile(filename, floorplanid, hasHeatmapVersionImage);
			}
		}

		if (currentFloorPlan) {
			floorPlans.forEach(item => {
				if (item.floorplanid === currentFloorPlan.floorplanid) {
					setCurrentFloorPlan(item);
				}
			});
		}
	}, [floorPlans]);

	const isResponseData = (response) => response && Object.keys(response) && response.data && Object.keys(response.data) && response.data.data;
	const getResponseData = (response) => response.data.data || null;
 
	async function downloadFloorPlanFile(filename, floorPlanId, hasHeatmapVersionImage = false) {
		//console.log("downloadFloorPlanFile " + filename + ", " + floorPlanId);
		if (!filename) {
			setMsg('There is no defined floor plan.');
			return;
		}

		setMsg(`Downloading floor plan: ${filename}`);

		const newAxiosInstance = axios.create();

		const projectId = JSON.parse(localStorage.getItem('projectId')) || null;

		// Step 1: get pre-signed url by sending axios request to server
		
		const result = await floorPlansAPI.getPresignedURL({
			projectId,
			filename,
			action: 'getObject',
			heatmapVersion: +hasHeatmapVersionImage,
		});

		// Step 2: Download file from AWS s3 bucket as a blob
		// Making straight axios call in order to access the ProgressEvent interface which allows up to caputre download progress and pass that data along to spinner component
		const response = await newAxiosInstance.get(result.data.preSignedURL, {
			responseType: 'blob',
			// Progress bar to show the progress of download while user is waiting
			onDownloadProgress: (progressEvent) => {
			const { loaded, total } = progressEvent;
			const percentCompleted = Math.round((loaded * 100) / total);

			setMsg(`Downloading floor plan: ${filename} ${percentCompleted}% completed`);
			//if (percentCompleted === 100){
			//  console.log ("done downloading floorplan");
			//}           
			}
		});
		//console.log(response);

		//  Step 3: Download file in required format - create a blob url and render in img tag
		let blob = new Blob([response.data], { type: response.data.type });
		let createObjectURL = window.URL.createObjectURL || window.webkitURL.createObjectURL;
		const url = createObjectURL(blob);
		updateFloorPlan(floorPlanId, filename, url);
		setMsg('');
	}

  function updateFloorPlan(id, filename, file) {
    //console.log("updateFloorPlan " + id + ", " + filename + ", " + file);
    const updateList = floorPlans.map((item) => {
      if (item.floorplanid == id) {
        const updateItem = {
          floorplanid: id,
          filename: filename,
          file: file,
        };
        //console.log(updateItem);
        return updateItem;
      }
      const anotherItem = {
        ...item,
      };
      //console.log(anotherItem);
      return anotherItem;
    });
    setFloorPlans(updateList);
  }

  function updateOriginPoint(id, name, x, y, iconWidth, iconHeight) {
    //console.log("Visualizer.js - updateOriginPoint " + id + ", " + name + ", " + x + ", " + y);
    //console.log(originPoints);
    const newList = originPoints.map((item) => {
      if (item.opid === id) {
        const updateItem = {
          ...item,
          opname: name,
          opcoordx: x !== null ? x.toString() : null,
          opcoordy: y !== null ? y.toString() : null,
          isDirty: dataUtils.isOriginPointDirty(item, name, x, y),
          iconWidth: iconWidth,
          iconHeight: iconHeight,
        };
        //console.log("-----> updateItem " + updateItem.original.opname);
        return updateItem;
      }
      const anotherItem = {
        ...item,
        isDirty: dataUtils.isOriginPointDirty(item),
        iconWidth: iconWidth,
        iconHeight: iconHeight,
      };
      //console.log("-----> item " + item.original.opname);
      return anotherItem;
    });
    setOriginPoints(newList);
  }

  function cleanOriginPoint(id) {
    //console.log("Visualizer.js - cleanOriginPoint " + id);
    //console.log(originPoints);
    const newList = originPoints.map((item) => {
      if (item.opid === id) {
        const updateItem = {
          ...item,
          original: item,
          isDirty: false,
        };
        //console.log("-----> updateItem " + updateItem.original.opname);
        return updateItem;
      }
      const anotherItem = {
        ...item,
        isDirty: dataUtils.isOriginPointDirty(item),
      };
      //console.log("-----> item " + item.original.opname);
      return anotherItem;
    });
    setOriginPoints(newList);
  }

  function updateSamplePoint(id, name, x, y, iconWidth, iconHeight) {
    //console.log("Visualizer.js - updateSamplePoint " + id + ", " + name + ", " + x + ", " + y);
    //console.log(samplePoints);
    const newList = samplePoints.map((item) => {
      if (item.spid === id) {
        const updateItem = {
          ...item,
          spname: name,
          spcoordx: x !== null ? x.toString() : null,
          spcoordy: y !== null ? y.toString() : null,
          isDirty: dataUtils.isSamplePointDirty(item, name, x, y),
          iconWidth: iconWidth,
          iconHeight: iconHeight,
        };
        //console.log("-----> updateItem " + updateItem.original.spname);
        return updateItem;
      }
      const anotherItem = {
        ...item,
        isDirty: dataUtils.isSamplePointDirty(item),
        iconWidth: iconWidth,
        iconHeight: iconHeight,
      };
      //console.log("-----> item " + item.original.opname);
      return anotherItem;
    });
    setSamplePoints(newList);
  }
  function cleanSamplePoint(id) {
    //console.log("Visualizer.js - cleanSamplePoint " + id);
    //console.log(samplePoints);
    const newList = samplePoints.map((item) => {
      if (item.spid === id) {
        const updateItem = {
          ...item,
          original: item,
          isDirty: false,
        };
        //console.log("-----> updateItem " + updateItem.original.opname);
        return updateItem;
      }
      const anotherItem = {
        ...item,
        isDirty: dataUtils.isSamplePointDirty(item),
      };
      //console.log("-----> item " + item.original.opname);
      return anotherItem;
    });
    setSamplePoints(newList);
  }


  function handleChange(e) {

    //console.log("Visualizer.js - onChangeHandler");

    switch (e.target.name) {
      case "xname":
        //console.log("Visualizer.js - name changed for item id:" + e.id + ", " + e.target.value)
        if (e.objectType === "OriginPoint") {
          updateOriginPoint(e.id, e.target.value, e.x, e.y, e.iconWidth, e.iconHeight);
        } else if (e.objectType === "SamplePoint") {
          updateSamplePoint(e.id, e.target.value, e.x, e.y, e.iconWidth, e.iconHeight);
        } else {
          console.log("Visualizer.js - unknown objectType: " + e.objectType);
        }
        break;
      default:
        console.log("Visualizer.js - unsupported value: " + e.target.name);
    }

  }
  function handleChanged(e) {
    // Initiate API update here - using e.id


    //console.log("App.js - onChangedHandler");
    //console.log(e);
    if (e.objectType === "OriginPoint") {
      originPoints.forEach(item => {
        //console.log(item);
        if (item.opid === e.id) {
          dataUtils.syncOriginPointToServer(item,
            (item) => {
              // successHandler
              //console.log("successHandler");
              //console.log(item);
              cleanOriginPoint(item.opid);
            },
            (item, response) => {
              // errorHandler
              console.log("errorHandler");
              console.log(item);
              console.log(response);
            }
          );
        }
      });
    } else if (e.objectType === "SamplePoint") {
      samplePoints.forEach(item => {
        //console.log(item);
        if (item.spid === e.id) {
          dataUtils.syncSamplePointToServer(item,
            (item) => {
              // successHandler
              //console.log("successHandler");
              //console.log(item);
              cleanSamplePoint(item.spid);
            },
            (item, response) => {
              // errorHandler
              console.log("errorHandler");
              console.log(item);
              console.log(response);
            }
          );
        }
      });
    }

  }

  return (
    <div className="Visualizer">
      <DndProvider backend={HTML5Backend}>

        <div className="viz-content" >

          <VisualizerHeader
            msg={msg} />
          <div className="main">
            <div className="left-panel">

              <FloorplanSelector
                floorPlans={floorPlans}
                currentSelection={currentFloorPlan}
                onSelectionChange={async (e) => {
                  //console.log("onSelectionChange");
                  //console.log(e.target.value);
                  let newSelectedFloorPlan = null;
                  for (var selected of floorPlans) {
                    if (selected.floorplanid === Number(e.target.value)) {
                      newSelectedFloorPlan = selected;

                      break;
                    }
                  }
                  setCurrentFloorPlan(newSelectedFloorPlan);
                  if (newSelectedFloorPlan.file === null) {
                    //todo: verify the need for the await
                    downloadFloorPlanFile(newSelectedFloorPlan.filename, newSelectedFloorPlan.floorplanid);
                  }
                }}
              />
              <PlacableObjectList
                isEditable={isEditable}
                originPoints={originPoints}
                samplePoints={samplePoints}
                isOriginPointDirty={dataUtils.isOriginPointDirty}
                isSamplePointDirty={dataUtils.isSamplePointDirty}
                selectedFloorplan={currentFloorPlan !== undefined && currentFloorPlan !== null ? currentFloorPlan.filename : null}
                onChange={handleChange}
                onChanged={handleChanged}

              />
            </div>
            <div className="image-canvas" ref={labelsContainerRef}>
              <ImageCanvas
                isEditable={isEditable}
                originPoints={originPoints}
                samplePoints={samplePoints}
                floorPlan={currentFloorPlan}
                movePlaceable={(id, name, x, y) => {
                  //console.log("movePlaceable: " + id + ", " + x + ", " + y);
                  // todo: clean up to be less brute force
                  //moveOriginPoint(id, x, y);
                  updateOriginPoint(id, name, x, y)
                  //moveSamplePoint(id, x, y);

                }}
                onChange={handleChange}
                onChanged={handleChanged}
              />
            </div>
          </div>
        </div>
      </DndProvider>
    </div>
  );
}

export default Visualizer;
