import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import WarnIcon from "../../assets/warnicon.png";
import { CSVLink } from "react-csv";
import { VERIFICATION_LIKE_TESTS } from "../../config";
import { te } from "date-fns/locale";
import ProjectStatusData from "../../data/projectStatusData";

const styles = (theme) => ({
  columnHeader: {
    minHeight: "200px",
    //maxHeight: '20px',
    //border: '3px solid #73AD21',
    background: "rgb(0, 137, 150)", //'lightgray',
    padding: "10px",
    //align: 'left',
    //transform: 'rotate(270deg)',
  },

  columnHeaderText: {
    //transform: 'rotate(270deg)', /* Equal to rotateZ(45deg) */
    //textAlign: 'left',
    //margin: '10px',
    //minWidth: '200px',
    //position: 'absolute',
    //right: '0px',
  },
  table: {
    border: "1px solid gray !important", //1px solid rgba(224, 224, 224, 1);
    //borderWidth: '1px !important',
    //borderSpacing: '2px !important',
    minWidth: 400,
  },
  cell: {
    padding: "4px !important",
    //margin: '10px',
    fontSize: "smaller",
    border: "1px solid gray !important",
  },
  row: {
    //border: '1px solid #73AD21',
    padding: "10px",
  },
  idColumn: {
    width: "15px",
  },
});

class ProjectScopeView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projectId: props.projectId,
      projectName: props.projectName,
      address: props.address,
      customer: props.customer,
      testDate: props.testDates,
      floors: props.floors,
      contacts: props.contacts,
      details: props.details,
      dataIssues: props.dataIssues,
      filteredTags: [],
    };
    this.labelsContainerRef = React.createRef();

    this.getTheData = this.getTheData.bind(this);
    //this.getTheProjectData = this.getTheProjectData.bind(this);
    this.renderScenarioInfo = this.renderScenarioInfo.bind(this);
    this.handleBack = this.handleBack.bind(this);

    this.buildExportStructure = this.buildExportStructure.bind(this);
    this.buildExecutiveExportStructure =
      this.buildExecutiveExportStructure.bind(this);
    this.escapeString = this.escapeString.bind(this);
  }

  async componentDidMount() {
    this.updateFilteredTags();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tagInfo !== this.props.tagInfo) {
      this.updateFilteredTags();
    }
  }

  updateFilteredTags() {
    const { tagInfo } = this.props;

    if (!tagInfo || !Array.isArray(tagInfo)) {
      this.setState({
        filteredTags: [],
      })
    }

    const cache = [];

    const result = [];

    for (let singleTag of tagInfo) {
      const { tag, lotNumber, expirationDate } = singleTag;

      const strData = `${tag}${lotNumber}${expirationDate}`;

      if (!~cache.indexOf(strData)) {
        result.push(singleTag);
        cache.push(strData);
      }
    }
    
    this.setState({
      filteredTags: result,
    })

    return;
  };

  handleBack() {
    this.props.history.push("/projectview");
  }

  async getTheData() {
    //console.log('getTheData() ' + this.state.projectId);
    await this.getTheProjectData();
  }

  buildExportStructure() {
    let rows = [];
    //column headers
    rows.push([
      "TestNum",
      "Test_id",
      "TestName",
      "Scenario",
      "ScenarioDescription",
      "OPCount",
      "OPTags",
      "SampleType",
      "SPCount",
      "IntervalCount",
      "IntervalDuration",
      "SampleCount",
      "SNStart",
      "SNEnd",
    ]);
    this.props.details.map((test) => {
      const intervalDuration =
        test.intervalDuration ||
        (!!~VERIFICATION_LIKE_TESTS.indexOf(test.type) && "Custom (5)");
      let columns = [
        test.testSequenceNumber,
        test.testId,
        this.escapeString(test.testName), // escape the commas
        test.scenarioNames[0].split("scenario")[1],
        this.escapeString(test.scenarioDescriptions), // escape the commas
        test.opCount,
        this.escapeString(
          "Tag-" + test.opTags.replace(/\s/g, "").replace(/,/g, ", Tag-")
        ), // escape the commas
        this.escapeString(test.sampleType), // escape the commas
        test.spCount,
        test.intervalCount,
        intervalDuration,
        test.sampleCount,
        test.startingSampleNumber,
        test.endingSampleNumber,
      ];

      //console.log(columns);
      rows.push(columns);
    });

    return rows;
  }

  buildExecutiveExportStructure() {
    let rows = [];
    //column headers
    rows.push([
      "Test Num",
      "Test_id",
      "Test Name",
      "Scenario",
      "Scenario Description",
      "OP Count",
      "Sample Type",
      "SP Count",
      "Interval Count",
      "Interval Duration",
    ]);
    this.props.details.map((test) => {
      const intervalDuration =
        test.intervalDuration ||
        (!!~VERIFICATION_LIKE_TESTS.indexOf(test.type) && "Custom (5)");
      let columns = [
        test.testSequenceNumber,
        test.testId,
        this.escapeString(test.testName), // escape the commas
        test.scenarioNames[0].split("scenario")[1],
        this.escapeString(test.scenarioDescriptions), // escape the commas
        test.opCount,
        this.escapeString(test.sampleType), // escape the commas
        test.spCount,
        test.intervalCount,
        intervalDuration,
      ];

      //console.log(columns);
      rows.push(columns);
    });

    return rows;
  }

  escapeString(desc) {
    let item;
    if (desc !== null) {
      desc = "" + desc;
      //console.log(desc + ', ' + typeof (desc));
      item = desc.replace(/(\r\n|\n|\r|\s+|\t|&nbsp;)/gm, " ");
      item = item.replace(/,/g, ",");
      //item = item.replace(/"/g, '\"');
      item = item.replace(/"/g, '""');
      //itemDesc = itemDesc.replace(/'/g, '\'');
      item = item.replace(/ +(?= )/g, "");
    } else {
      //console.log(desc);
      item = desc;
    }
    return item;
  }

  renderScenarioInfo(detail) {
    let info = [];
    for (let i = 0; i < detail.scenarioNames.length; i++) {
      info.push(
        detail.scenarioNames[i] +
          (detail.scenarioDescriptions[i] !== null
            ? ": " + detail.scenarioDescriptions[i]
            : "")
      );
    }
    if (info.length < 1) {
      info.push("n/a");
    }
    //console.log(detail);
    //console.log(info);

    return (
      <div>
        {info.map((item) => (
          <p style={{ paddingBottom: "8px" }}>{item}</p>
        ))}
      </div>
    );
  }

  render() {
    const csvData = this.buildExportStructure();
    //console.log(csvData);
    const csvExecutiveSummaryData = this.buildExecutiveExportStructure();
    //console.log(csvExecutiveSummaryData);
    //console.log(this.props);
    const { classes } = this.props;
    const { filteredTags } = this.state;
    let today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      today.getDate().toString().padStart(2, "0") +
      " " +
      today.getHours().toString().padStart(2, "0") +
      ":" +
      today.getMinutes().toString().padStart(2, "0") +
      ":" +
      today.getSeconds().toString().padStart(2, "0");

    return (
      <div>
        <TableContainer
          ref={this.labelsContainerRef}
          style={{ width: "99.5%" }}
        >
          <div style={{ paddingBottom: "10px" }}>
            <Typography variant="subtitle1">Prepared on {date}</Typography>
          </div>
          <Table
            className={classes.table}
            size="small"
            aria-label="Project Info"
          >
            <TableBody>
              <TableRow>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                >
                  Project ID
                </TableCell>
                <TableCell className={classes.cell} colSpan={3}>
                  {this.props.projectId}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                >
                  Project Name
                </TableCell>
                <TableCell className={classes.cell}>
                  {this.props.projectName}
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                >
                  Status
                </TableCell>
                <TableCell className={classes.cell}>
                  {ProjectStatusData[this.props.status]}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                >
                  Address
                </TableCell>
                <TableCell className={classes.cell}>
                  {this.props.address}
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                >
                  Customer
                </TableCell>
                <TableCell className={classes.cell}>
                  {this.props.customer}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                >
                  Description
                </TableCell>
                <TableCell className={classes.cell}>
                  {this.props.description}
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                >
                  Partner
                </TableCell>
                <TableCell className={classes.cell}>
                  {this.props.partner}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Typography variant="h6">Project Scope</Typography>

            <CSVLink
              data={csvData}
              filename={`PID ${this.props.projectId} Project Scope.csv`}
              style={{ margin: "0 10px 0 10px", textDecoration: "none" }}
            >
              <Typography variant="subtitle1">
                Download Project Scope
              </Typography>
            </CSVLink>

            <CSVLink
              data={csvExecutiveSummaryData}
              filename={`PID ${this.props.projectId} Executive Summary.csv`}
              style={{ margin: "0 10px 0 10px", textDecoration: "none" }}
            >
              <Typography variant="subtitle1">
                Download Executive Summary
              </Typography>
            </CSVLink>
          </div>

          {!!filteredTags.length && <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="left">
                  Tag
                </TableCell>
                <TableCell className={classes.cell}
                           style={{ background: "rgb(0, 137, 150)", color: "white" }}
                           align="left">
                  Lot Number
                </TableCell>
                <TableCell className={classes.cell}
                           style={{ background: "rgb(0, 137, 150)", color: "white" }}
                           align="left">
                  Exp. Date
                </TableCell>
              </TableRow>
            </TableHead>
            {filteredTags.map((item, i) => <TableRow key={i}>
              <TableCell className={classes.cell} align="left">{item.tag}</TableCell>
              <TableCell className={classes.cell} align="left">{item.lotNumber}</TableCell>
              <TableCell className={classes.cell} align="left">{item.expirationDate}</TableCell>
            </TableRow>)}
          </Table>}


          <br />
          <br />
          <Table
            className={classes.table}
            size="small"
            aria-label="test data rows"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="left"
                >
                  Done
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="right"
                >
                  Issues
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="right"
                >
                  Test Num
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="right"
                >
                  Test Id
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="left"
                >
                  Test Date
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="left"
                >
                  Test Name
                </TableCell>

                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="right"
                >
                  Scenario
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="left"
                >
                  Scenario Desc
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="right"
                >
                  OP Count
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="left"
                >
                  OP Tags
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="left"
                >
                  Sample Type
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="right"
                >
                  SP Count
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="right"
                >
                  Interval Count
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="right"
                >
                  Interval Duration
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="right"
                >
                  Sample Count
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="left"
                >
                  SN Start
                </TableCell>
                <TableCell
                  className={classes.cell}
                  style={{ background: "rgb(0, 137, 150)", color: "white" }}
                  align="left"
                >
                  SN End
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.details !== undefined &&
                this.props.details.map((test) => (
                  <TableRow key={test.testId}>
                    <TableCell className={classes.cell} align="left">
                      {test.isCompleted === true ? "yes" : "no"}
                    </TableCell>
                    <TableCell className={classes.cell} align="right">
                      {this.props.dataIssues.filter(
                        (i) => i.testId === test.testId
                      ).length > 0 && (
                        <img src={WarnIcon} width="20px" height="auto" />
                      )}
                    </TableCell>
                    <TableCell className={classes.cell} align="right">
                      {test.testSequenceNumber}
                    </TableCell>
                    <TableCell className={classes.cell} align="right">
                      {test.testId}
                    </TableCell>
                    <TableCell className={classes.cell} align="right">
                      {test.testDate !== null
                        ? test.testDate.split("T")[0]
                        : "n/a"}
                    </TableCell>
                    <TableCell className={classes.cell} align="left">
                      {test.testName}
                    </TableCell>
                    <TableCell className={classes.cell} align="right">
                      {test.scenarioNames[0].split("scenario")[1]}
                    </TableCell>
                    <TableCell className={classes.cell} align="left">
                      {test.scenarioDescriptions}
                    </TableCell>
                    <TableCell className={classes.cell} align="right">
                      {test.opCount}
                    </TableCell>
                    <TableCell className={classes.cell} align="left">
                      {test.opTags}
                    </TableCell>
                    <TableCell className={classes.cell} align="left">
                      {test.sampleType}
                    </TableCell>
                    <TableCell className={classes.cell} align="right">
                      {test.spCount}
                    </TableCell>
                    <TableCell className={classes.cell} align="right">
                      {test.intervalCount}
                    </TableCell>
                    <TableCell className={classes.cell} align="right">
                      {test.intervalDuration ||
                        (!!~VERIFICATION_LIKE_TESTS.indexOf(test.type) &&
                          "Custom (5)")}
                    </TableCell>
                    <TableCell className={classes.cell} align="right">
                      {test.sampleCount}
                    </TableCell>
                    <TableCell className={classes.cell} align="left">
                      {test.startingSampleNumber}
                    </TableCell>
                    <TableCell
                      className={classes.cell}
                      align="left"
                      style={{ width: "65px" }}
                    >
                      {test.endingSampleNumber}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default withStyles(styles)(ProjectScopeView);
