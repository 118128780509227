import React from 'react';
class EditField extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: this.props.value }

        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);

    }
    onFocus(e) {
        console.log("onFocus");
        if (this.props.onFocus !== undefined && typeof this.props.onFocus === 'function') {
            this.props.onFocus(e);
        }
    }
    onChange(e) {
        console.log("onChange");
        if (this.props.onChange !== undefined && typeof this.props.onChange === 'function') {
            this.setState({ value: e.target.value });
            this.props.onChange(e);
        }
    }
    onBlur(e) {
        console.log("onBlur");
        if (this.props.onBlur !== undefined && typeof this.props.onBlur === 'function') {
            this.props.onBlur(e);
        }
    }
    onKeyUp(e) {
        if (e.charCode === 13) {
            console.log("return pressed");
            //this.onBlur(e);
        }
    }
    render() {
        return (
            <input className="name-field"
                autoComplete="off"
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                onChange={this.onChange}
                onKeyPress={this.onKeyUp}
                name={this.props.name}
                value={(this.state.value !== undefined && this.state.value != null) ? this.state.value : ""}
            />
        )
    }

}


export default EditField;