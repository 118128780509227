import React, { Component } from 'react';
import Login from '../../components/Login/Login';
import { Context } from '../../context/Context';
import authentication from '../../api/authentication';

class LoginView extends Component {

    state = {
        email: '',
        password: '',
        auth: null
    };

    handleOnchange = e => {
        this.setState({
            [e.target.name] : e.target.value
        }, () => {
            //console.log('email: ', this.state.email);
            //console.log('password: ', this.state.password);
        })
    }

    validateCredentials = async (creds) => {
        let email = creds.email;
        let password =  creds.password;
        let data = { email, password };
        const response = await authentication.login(data);
        return response;
    }

    onSubmit = values => {
        let token;
        let partnerType;
        //Values is returned by react-hook-form handleSubmit()
        //console.log('VALUES', JSON.stringify(values));

        //onSubmit only receives values if form passed all validation
        if(values) {
            this.validateCredentials(values)
                .then(response => {
                    if(response.data.success) {
                        if  (response.data.data.user.roleName !=='Safetracer' || response.data.data.user.accessLevel !=='Admin') {
                            localStorage.clear();
                            window.location.replace(window.location.origin + '/login');
                        }
                        //Set email for Navbar display
                        this.context.setUserEmail(response.data.data.user.email);
                        this.context.setUserId(response.data.data.user.userId);

                        //Auth bool to diplay/not display invalid creds prompt
                        this.setState({ auth: true }, () => {
                            //console.log('auth: ', this.state.auth)
                        });
                        //Extract token and store in localStorage
                        token = response.data.data.token;
                        token = JSON.stringify(token)
                        //console.log('token: ', token);

                        //Extract companyName and store in localStorage/Context
                        partnerType = response.data.data.user.companyName;
                        this.context.setCompanyName(partnerType);
                        
                        JSON.stringify(localStorage.setItem('token', token));
                        //Set accessLevel in global state
                        this.context.setAccessLevel(response.data.data.user.accessLevel);
                        //Set roleName in global state
                        this.context.setRoleName(response.data.data.user.roleName);
                        //Set authenticated to true in global state
                        this.context.setLoggedIn();
                        //Proceed to Projects page
                        this.props.history.push('/projects');
                    } else {
                        this.setState({ auth: false }, () => {
                            console.log('auth: ', this.state.auth)
                        });
                    }
                }
            ) 
        }
    }

    componentDidMount() {
        document.getElementsByClassName('navbar-wrapper')[0].style.display = "none";
        if(document.getElementsByClassName('drawer-wrapper')) {
            document.getElementsByClassName('drawer-wrapper')[0].style.display = "none";
        }
    }

    componentWillUnmount() {
        document.getElementsByClassName('navbar-wrapper')[0].style.display = "block";
        document.getElementsByClassName('drawer-wrapper')[0].style.display = "block";
        this.setState({ auth: null });
    }

    render() {
        const { auth } = this.state;
        return (
            <Context.Consumer>
                {context => (
                    <Login handleOnchange={this.handleOnchange} onSubmit={this.onSubmit} auth={auth} props={this.props} />
                )}
            </Context.Consumer>
        )
    }
}
LoginView.contextType = Context;
export default LoginView;