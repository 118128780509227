import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import {withRouter} from 'react-router'

class ButtonChecklist extends Component {

    constructor(props) {
        super(props);
        this.state = { route: null };
    }

    handleButtonRouting = route => {
        this.props.history.push(route);
    }

    componentDidMount() {
        if(this.props.checklist) {
            if(this.props.checklist === "QC Check List") {
                this.setState({ route: '/qcchecklistview' });
            } else if(this.props.checklist === "Materials and Equipment Checklist") {
                this.setState({ route: '/materialschecklistview' });
            } else if(this.props.checklist === "SafeTraces Chain of Custody") {
                this.setState({ route: '/cocchecklistview' });
            } else if(this.props.checklist === "Tags Check List") {
                this.setState({ route: '/tagschecklistview' });
            } else if(this.props.checklist === "Lab Requirements Check List") {
                this.setState({ route: '/labrequirementchecklistview' });
            }
        }
    }
    
    render() {
        const { route } = this.state;
        return (
            <Button 
                size="small" 
                variant="outlined" 
                style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", marginLeft: "5px", fontFamily: "Gotham", margin: '15px'}} 
                onClick={() => this.handleButtonRouting(route)}>
                View Checklist
            </Button>
        );
    }
}

export default withRouter(ButtonChecklist)