import React from 'react';
import samplePlans from '../../api/sampleplans';
import SamplePlanList from '../../components/SamplePlanList/SamplePlanList.js';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import { Context } from '../../context/Context';
import './SamplePlanForProject.css';

import { CSVLink } from 'react-csv';

class SamplePlanForproject extends React.Component {
    constructor(props) {
        super(props);

        this.state = { projectId: null, samplePlanData: [], downloadData: [] };

        this.handleTestPlanClick = this.handleTestPlanClick.bind(this);
        this.testplanNavButton = document.querySelector('#sBtn');
    }
       
    componentDidMount() {
        //let testplanNavButton = document.querySelector('#tpBtn');
        if(this.testplanNavButton) {
            this.testplanNavButton.classList.add('selected');
        }

        let projectId = JSON.parse(localStorage.getItem("projectId"));

        this.setState({
            projectId,
        }, async () => {
            const responseUpdate = await samplePlans.updateSamplePlan(projectId);
            if (!responseUpdate?.data?.success) {
                return null;
            }

            const response = await samplePlans.getSamplePlanForProject(null, projectId);
            const { samplePlan } = response.data.data;

            const samplePlanData = [];
            const downloadData = [];

            let headersObj;

            for (let plateNum = 0; plateNum < samplePlan.length; plateNum++) {
                const plateData = samplePlan[plateNum];
                const { rows, unique, uniqueNum, typeC } = plateData;

                let rowsForDownload = rows;

                /**
                 * If the plate is unique, for each data row change plate id ONLY for downloaded file.
                 * Make copy of each data row so the table data stay unaffected.
                 */
                if (unique && typeC) {
                    rowsForDownload = rows.map((row) => {
                        const copyRow = { ...row };
                        copyRow.plateid = copyRow.plateid + `U${uniqueNum}`;

                        return copyRow;
                    });
                }

                samplePlanData.push(...rows);
                downloadData.push(...rowsForDownload);

                /** 
                 * Add header row after each plate.
                 * Header row is being formed using the keys of initial data object.
                 */
                if (plateNum !== samplePlan.length - 1) {
                    if (!headersObj) {
                        headersObj = Object.keys(rows[0]).reduce((res, key) => { 
                            res[key] = key; 
                            return res; 
                        }, {});
                    }

                    downloadData.push(headersObj);
                }
            }

            this.setState ({ samplePlanData, downloadData });
        });
    };
    

    componentWillUnmount() {
        if(this.testplanNavButton) {
            this.testplanNavButton.classList.remove('selected');
        }
    }

    handleTestPlanClick(event) {
        this.props.history.push('/testplan');
    }
    
    render() {
        const { projectId, samplePlanData, downloadData } = this.state;
        const btnStyle = { 
            backgroundColor: "#82BC00", 
            color: "#ffffff", 
            borderColor: "#82BC00", 
            fontFamily: "Gotham" 
        };

        return (
            <Context.Consumer>
                {context => (
                    <div className="sample-plan-for-project-container">
                        <div>
                        <Typography variant="h5" style={{fontFamily: "Gotham", marginTop: "30px"}} color="textPrimary" align="center">{`(Project Id ${projectId}) ${context.state.projectName} - Sample Plan For Project`}</Typography>
                        </div>
                        <div className="sample-plan-for-project-table-container">
                            <div className="sample-plan-for-project-button-container">
                                <CSVLink
                                    data={downloadData}
                                    style={{margin: '0 10px 0 10px', textDecoration: "none"}}
                                    filename={`PID ${projectId}-${context.state.projectName}-Wide.csv`}>
                                    <Button size="small" variant="outlined" style={btnStyle} >Download</Button>
                                </CSVLink>
                                <Button size="small" variant="outlined" style={btnStyle} onClick={() => samplePlans.getZipUrl(projectId, context.state.projectName)}>
                                    Download QS3
                                </Button>
                            </div>
                            <div className="sample-plan-for-project-plan-list-container">
                                <SamplePlanList SamplePlan={samplePlanData} history={this.props.history}/>
                            </div>
                        </div>
                    </div>
                )}
            </Context.Consumer>
        )
    }
}

export default SamplePlanForproject;