const fileNameUtil = {


    getExtension: (fullFileName) => {
        //console.log('getExtension() ' + fullFileName);
        if (fullFileName === undefined || fullFileName === null) {
            return null;
        }
        const lcFN = fullFileName.toLowerCase();

        if (lcFN.endsWith('.jpg') || lcFN.endsWith('.jpeg') || lcFN.endsWith('.png')) {
            const parts = fullFileName.split('.');
            return parts[parts.length - 1];
        }

        return null;
    },

    removeExtension: (fullFileName) => {
        const extension = fileNameUtil.getExtension(fullFileName)

        //console.log('removeExtension() ' + fullFileName + ', ' + extension);

        if (extension !== null) {
            const fileName = fullFileName.split('.' + extension)[0];
            return fileName;
        }
        return fullFileName;
    },

    getFileNamesWithOutExtensions: (fileList) => {
        return fileList.map((item) => {
            return fileNameUtil.removeExtension(item.filename);
        });
    },

    appendSuffixToFileName: (fileName, fileList) => {
        let suffixInt = 1;
        let fileNames = fileNameUtil.getFileNamesWithOutExtensions(fileList);
        if (fileNames.includes(fileName)) {
            while (fileNames.includes(fileName + ' (' + suffixInt + ')')) {
                suffixInt++;
            }
            return fileName + ' (' + suffixInt + ')';
        }


        return fileName;
    },

    appendExtensionToFileName: (fileName) => {
        return fileName + '.png';
    },

    isFileNameAlreadyUsed: (fileName, fileList) => {

        return fileNameUtil.getFileNamesWithOutExtensions(fileList).map((item) => {
            return item.toLowerCase();
        }).includes(fileName.toLowerCase());

    },

    isFileNameInvalid: (fileName) => {
        if (fileName === undefined || fileName === null || fileName.trim().length < 1) {
            return true;
        }
        let re = /^(con|prn|aux|nul|((com|lpt)[0-9]))$|([<>:"\/\\|?*])|(\.|\s)$/ig
        return re.test(fileName);
    },


}
export default fileNameUtil;