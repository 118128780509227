import projectsAPI from '../api/projects';
import testsAPI from '../api/tests';
import segmentsAPI from '../api/segments';
import scenariosAPI from '../api/scenarios';
import originPointsAPI from '../api/originpoints';
import samplePointsAPI from '../api/samplepoints';
import samplePlansAPI from '../api/sampleplans';
import floorPlansAPI from '../api/floorPlans';
import axios from 'axios';


const dataUtil = {

    getProjectData: (projectId, successHandler, errorHandler) => {
        if (projectId === null) {
            errorHandler(projectId);
            return;
        }

        projectsAPI.getOneProject(projectId)
            .then(response => {
                //console.log(response);
                if (response.status === 200 &&
                    response.data !== null &&
                    response.data.success === true &&
                    response.data.data !== undefined) {
                    if (successHandler !== undefined && typeof successHandler === 'function') {
                        successHandler(projectId, response.data.data);
                    }
                } else {
                    if (errorHandler !== undefined && typeof errorHandler === 'function') {
                        errorHandler(projectId, response);
                    }
                }
            }).catch(error => {
                console.log(error);
                if (errorHandler !== undefined && typeof errorHandler === 'function') {
                    errorHandler(projectId, error);
                }

            });
    },
    getProjectTestsData: (projectId, successHandler, errorHandler) => {
        if (projectId === null) {
            errorHandler(projectId);
            return;
        }

        testsAPI.getAllTests(projectId)
            .then(async (response) => {
                //console.log(response);
                if (response.status === 200 &&
                    response.data !== null &&
                    response.data.success === true &&
                    response.data.data !== undefined) {
                    
                    const _tests = response.data.data;
                    const displayNumRes = await testsAPI.getDisplayNumbersProject(projectId);
                    if (displayNumRes && displayNumRes.data && displayNumRes.data.success) {
                        const _numbers = displayNumRes.data.data;
                        for (let test of _tests) {
                            test.displayNumber = _numbers[test.testid] || '';
                        }
                    }
                    if (successHandler !== undefined && typeof successHandler === 'function') {
                        successHandler(projectId, _tests);
                    }
                } else {
                    if (errorHandler !== undefined && typeof errorHandler === 'function') {
                        errorHandler(projectId, response);
                    }
                }
            }).catch(error => {
                console.log(error);
                if (errorHandler !== undefined && typeof errorHandler === 'function') {
                    errorHandler(projectId, error);
                }

            });
    },
    getProjectSegmentData: (projectId, testId, successHandler, errorHandler) => {
        if (projectId === null) {
            errorHandler(projectId);
            return;
        }

        segmentsAPI.getSegments(testId, projectId)
            .then(response => {
                //console.log(response);
                if (response.status === 200 &&
                    response.data !== null &&
                    response.data.success === true &&
                    response.data.data !== undefined) {
                    if (successHandler !== undefined && typeof successHandler === 'function') {
                        successHandler(projectId, testId, response.data.data);
                    }
                } else {
                    if (errorHandler !== undefined && typeof errorHandler === 'function') {
                        errorHandler(projectId, testId, response);
                    }
                }
            }).catch(error => {
                console.log(error);
                if (errorHandler !== undefined && typeof errorHandler === 'function') {
                    errorHandler(projectId, testId, error);
                }

            });
    },
    getScenarioData: (segmentId, testId, successHandler, errorHandler) => {
        if (segmentId === null) {
            errorHandler(segmentId, testId);
            return;
        }

        scenariosAPI.getScenarios(segmentId)
            .then(response => {
                //console.log(response);
                if (response.status === 200 &&
                    response.data !== null &&
                    response.data.success === true &&
                    response.data.data !== undefined) {
                    if (successHandler !== undefined && typeof successHandler === 'function') {
                        successHandler(segmentId, testId, response.data.data);
                    }
                } else {
                    if (errorHandler !== undefined && typeof errorHandler === 'function') {
                        errorHandler(segmentId, testId, response);
                    }
                }
            }).catch(error => {
                console.log(error);
                if (errorHandler !== undefined && typeof errorHandler === 'function') {
                    errorHandler(segmentId, testId, error);
                }

            });
    },
    getOriginPointData: (projectId, testId, successHandler, errorHandler) => {
        if (projectId === null || testId === null) {
            errorHandler(projectId, testId);
            return;
        }

        originPointsAPI.getAllOriginpoints(testId, projectId)
            .then(response => {
                //console.log(response);
                if (response.status === 200 &&
                    response.data !== null &&
                    response.data.success === true &&
                    response.data.data !== undefined) {
                    if (successHandler !== undefined && typeof successHandler === 'function') {
                        successHandler(projectId, testId, response.data.data);
                    }
                } else {
                    if (errorHandler !== undefined && typeof errorHandler === 'function') {
                        errorHandler(projectId, testId, response);
                    }
                }
            }).catch(error => {
                console.log(error);
                if (errorHandler !== undefined && typeof errorHandler === 'function') {
                    errorHandler(projectId, testId, error);
                }

            });
    },
    getSamplePointData: (projectId, testId, successHandler, errorHandler) => {
        if (projectId === null || testId == null) {
            errorHandler(testId);
            return;
        }
        //console.log('getSamplePointsData() ' + projectId + ', ' + testId);

        samplePointsAPI.getAllSamplepoints(testId, projectId)
            .then(response => {
                //console.log(response);
                if (response.status === 200 &&
                    response.data !== null &&
                    response.data.success === true &&
                    response.data.data !== undefined) {
                    if (successHandler !== undefined && typeof successHandler === 'function') {
                        //console.log(response);
                        successHandler(projectId, testId, response.data.data);
                    }
                } else {
                    if (errorHandler !== undefined && typeof errorHandler === 'function') {
                        errorHandler(projectId, testId, response);
                    }
                }
            }).catch(error => {
                console.log(error);
                if (errorHandler !== undefined && typeof errorHandler === 'function') {
                    errorHandler(projectId, testId, error);
                }

            });
    },
    getSamplePointsDetails: (projectId, testId, successHandler, errorHandler) => {
        if (projectId === null || testId == null) {
            errorHandler(projectId, testId);
            return;
        }
        samplePointsAPI.getDetails(testId)
            .then(response => {
                //console.log(response);
                if (response.status === 200 &&
                    response.data !== null &&
                    response.data.success === true &&
                    response.data.data !== undefined) {
                    if (successHandler !== undefined && typeof successHandler === 'function') {
                        //console.log(response);
                        successHandler(projectId, testId, response.data.data);
                    }
                } else {
                    if (errorHandler !== undefined && typeof errorHandler === 'function') {
                        errorHandler(projectId, testId, response);
                    }
                }
            }).catch(error => {
                console.log(error);
                if (errorHandler !== undefined && typeof errorHandler === 'function') {
                    errorHandler(projectId, testId, error);
                }

            });

    },
    getSampleData: (projectId, testId, successHandler, errorHandler) => {
        if (projectId === null || testId == null) {
            errorHandler(testId);
            return;
        }
        //console.log('getSampleData() ' + projectId + ', ' + testId);

        samplePointsAPI.getAllSamples(testId, projectId)
            .then(response => {
                //console.log(response);
                if (response.status === 200 &&
                    response.data !== null &&
                    response.data.success === true &&
                    response.data.data !== undefined) {
                    if (successHandler !== undefined && typeof successHandler === 'function') {
                        //console.log(response);
                        successHandler(projectId, testId, response.data.data);
                    }
                } else {
                    if (errorHandler !== undefined && typeof errorHandler === 'function') {
                        errorHandler(projectId, testId, response);
                    }
                }
            }).catch(error => {
                console.log(error);
                if (errorHandler !== undefined && typeof errorHandler === 'function') {
                    errorHandler(projectId, testId, error);
                }

            });
    },
    getSampleCollectorLabels: (projectId, successHandler, errorHandler) => {
        if (projectId === null) {
            errorHandler(projectId);
            return;
        }
        //console.log('getSampleCollectorLabels() ' + projectId);
        samplePlansAPI.getLabels(null, projectId)
            .then(response => {
                //console.log(response);
                if (response.status === 200 &&
                    response.data !== null &&
                    response.data.success === true &&
                    response.data.data !== undefined) {
                    if (successHandler !== undefined && typeof successHandler === 'function') {
                        //console.log(response);
                        successHandler(projectId, response.data.data);
                    }
                } else {
                    if (errorHandler !== undefined && typeof errorHandler === 'function') {
                        errorHandler(projectId, response);
                    }
                }
            }).catch(error => {
                console.log(error);
                if (errorHandler !== undefined && typeof errorHandler === 'function') {
                    errorHandler(projectId, error);
                }

            });
    },
    downloadFloorPlan: (projectId, fileName, successHandler, errorHandler, progressHandler) => {
        //console.log("downloadFloorPlan " + projectId + ", " + fileName);

        const newAxiosInstance = axios.create();
        // Step 1: get pre-signed url by sending axios request to server
        floorPlansAPI.getPresignedURL({
            projectId: projectId,
            filename: fileName,
            action: 'getObject',
            heatmapVersion: true,
        }).then(response => {
            //console.log("presignedURL Response");
            //console.log(response);
            if (response.status === 200 &&
                response.data !== undefined &&
                response.data !== null &&
                response.data.preSignedURL !== undefined &&
                response.data.preSignedURL !== null) {
                //step 2. download data
                newAxiosInstance.get(response.data.preSignedURL, {
                    responseType: 'blob',
                    // Progress bar to show the progress of download while user is waiting
                    onDownloadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        if (progressHandler !== undefined && typeof progressHandler === 'function') {
                            progressHandler(percentCompleted);
                        }
                    }
                }).then(response => {
                    //console.log("data response");
                    //console.log(response);
                    if (response.status === 200 &&
                        response.data !== undefined &&
                        response.data !== null &&
                        response.data.type !== undefined &&
                        response.data.type !== null) {

                        //  Step 3: Download file in required format - create a blob url
                        let blob = new Blob([response.data], { type: response.data.type });
                        let createObjectURL = window.URL.createObjectURL || window.webkitURL.createObjectURL;
                        const url = createObjectURL(blob);


                        if (successHandler !== undefined && typeof successHandler === 'function') {
                            //console.log(response);
                            successHandler(projectId, fileName, url);
                        }
                    } else {
                        if (errorHandler !== undefined && typeof errorHandler === 'function') {
                            errorHandler(projectId, fileName, response);
                        }
                    }
                }).catch(error => {
                    console.log(error);
                    if (errorHandler !== undefined && typeof errorHandler === 'function') {
                        errorHandler(projectId, fileName, error);
                    }

                });

            } else {
                if (errorHandler !== undefined && typeof errorHandler === 'function') {
                    errorHandler(projectId, fileName, response);
                }
            }
        }).catch(error => {
            console.log(error);
            if (errorHandler !== undefined && typeof errorHandler === 'function') {
                errorHandler(projectId, fileName, error);
            }
        });
    }
}
export default dataUtil;
