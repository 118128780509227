import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const MuiAlertComponent = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

export const AlertLotNumber = ({ launchLotAlert, lotNumAlertMsg, handleClosingAlert }) => {
  const green = '#008996'
  const red = '#ff0000';
  const info = 'info';
  const warning = 'warning';
  const vertical = "top";
  const horizontal = "center";
  //const classes = useStyles();  
  const [ open, setOpen ] = useState(false);
  const [ color, setColor ] = useState(null);
  const [ severity, setSeverity ] = useState(null);

  useEffect(() => {
      if(launchLotAlert) {
          setOpen(!open);
      }
      if(lotNumAlertMsg === 'Lot number successfully updated') {
          setColor(green);
          setSeverity(info);
      }
      if(lotNumAlertMsg === 'Air sample rate successfully updated') {
        setColor(green);
        setSeverity(info);
      }
      if(lotNumAlertMsg === 'Please provide a valid Air sampler rate') {
        setColor(red);
        setSeverity(warning);
      }
      if(lotNumAlertMsg === 'Comments successfully updated') {
        setColor(green);
        setSeverity(info);
      }
      if(lotNumAlertMsg === 'Description successfully updated') {
        setColor(green);
        setSeverity(info);
      }
      if(lotNumAlertMsg === 'Please assign a plate kind and provide a valid lot number') {
          setColor(red);
          setSeverity(warning);
      }
      if(lotNumAlertMsg === 'Please provide a tag lot number first') {
          setColor(red);
          setSeverity(warning);
      }
      if(lotNumAlertMsg === 'Please provide a tag lot number') {
        setColor(red);
        setSeverity(warning);
      }
      if(lotNumAlertMsg === 'Expiration date successfully updated') {
          setColor(green);
          setSeverity(info);
      }
      if(lotNumAlertMsg === 'Survey data successfully updated') {
        setColor(green);
        setSeverity(info);
    }
      if(lotNumAlertMsg === 'Please provide a valid expiration date') {
          setColor(red);
          setSeverity(warning);
      } 
      if(lotNumAlertMsg === 'Please provide a valid lot number') {
        setColor(red);
        setSeverity(warning);
      } 
      if(lotNumAlertMsg === 'Please assign tag lot number first before submitting expiration date') {
          setColor(red);
          setSeverity(warning);
      } 
  },[]);

  return (
    //<div className={classes.root}
    <div>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={6000}>
          <MuiAlertComponent 
            style={{backgroundColor: `${color}`, color: "#ffffff", borderColor: `${color}`, fontFamily: "Gotham"}} 
            onClose={handleClosingAlert} 
            severity={severity}>
            {lotNumAlertMsg}
          </MuiAlertComponent>
      </Snackbar>
    </div>
  );
}