import React from 'react';
import { Context } from '../../context/Context';
import Typography from "@material-ui/core/Typography";
import { LaboratoryChecklistsList } from '../../components/LaboratoryChecklistsList/LaboratoryChecklistsList';
import './LaboratoryView.css';

class LaboratoryView extends React.Component {

    constructor(props) {
        super(props);
        this.state = { projectId: null };
    }
    
    componentDidMount() {
        //logic to handle highlighting navbar tab
        let labNavButton = document.querySelector('#labBtn');
        if(labNavButton) {
            labNavButton.classList.add('lab-selected');
        }
        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        this.setState({ projectId });
    }

    componentWillUnmount() {
        let labNavButton = document.querySelector('#labBtn');
        if(labNavButton) {
            labNavButton.classList.remove('lab-selected');
        }
    }

    render() {
        const { projectId } = this.state;
        return (
            <Context.Consumer>
                {context => (
                    <div className="lab-view-container">
                        <div>
                            <Typography variant="h5" color="textPrimary" align="center" style={{fontFamily: "Gotham", marginTop: "30px"}}>{`(Project Id ${projectId}) ${context.state.projectName}: ${context.state.testName}`}</Typography>
                        </div>     
                        <div className="lab-view-table-container">
                            <LaboratoryChecklistsList />
                        </div>
                    </div>
                )}
            </Context.Consumer>
        )
    }
}
LaboratoryView.contextType = Context;
export default LaboratoryView;



