import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import sizeScalingUtil from "./sizeScalingUtil";

import "./Visualizer.css";

const styles = () => ({
  isHidden: {
    visibility: "hidden",
    opacity: 0,
    width: "1px",
    height: "auto",
  },
});
class AnnotatedFloorPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageFile: props.imageFile,
      imageDimensions: null,
      blobUrl: null,
    };

    this.buildSVG = this.buildSVG.bind(this);
    this.floorplanDimensions = this.floorplanDimensions.bind(this);
  }
  floorplanDimensions() {
    const d = {
      width: this.state.imageDimensions?.width,
      height: this.state.imageDimensions?.height,
    };
    return d;
  }

  buildSVG() {
    if (this.state.imageDimensions === null || this.state.imageFile === null) {
      return <div></div>;
    }

    let originPointItems = [];
    let samplePointItems = [];
    const toRect = {
      width: this.props.size.width, //this.state.width,
      height:
        this.floorplanDimensions().height *
        (this.props.size.width / this.floorplanDimensions().width),
    };

    const iconWidth = sizeScalingUtil.iconSize(
      this.floorplanDimensions(),
      toRect
    ).width;
    const fontSize = iconWidth * 0.2;

    originPointItems = this.props.originPoints
      .map((item) => {
        //console.log(item);
        if (
          item.floorplan === this.props.fileName &&
          item.x !== null &&
          item.y !== null
        ) {
          const centeredCoordinates = sizeScalingUtil.toCoordiates(
            this.floorplanDimensions(),
            item.x !== null ? Number(item.x) : null,
            item.y !== null ? Number(item.y) : null,
            toRect
          );
          const strokeWidth = iconWidth * 0.1;
          const scaledX = centeredCoordinates.x + strokeWidth / 4;
          const scaledY = centeredCoordinates.y + strokeWidth / 2;

          return (
            <g key={item.opnumber}>
              <circle
                cx={scaledX}
                cy={scaledY}
                r={iconWidth * 0.4 + "px"}
                stroke={"saddlebrown"}
                strokeWidth={strokeWidth + "px"}
                fill="linen"
              ></circle>
              <text
                x={scaledX}
                y={scaledY}
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize={fontSize}
              >
                {item.number}
              </text>
            </g>
          );
        }
      })
      .filter((item) => item !== undefined);

    samplePointItems = this.props.samplePoints
      .map((item) => {
        //console.log(item);
        if (
          item.floorplan === this.props.fileName &&
          item.x !== null &&
          item.y !== null
        ) {
          const centeredCoordinates = sizeScalingUtil.toCoordiates(
            this.floorplanDimensions(),
            item.x !== null ? Number(item.x) : null,
            item.y !== null ? Number(item.y) : null,
            toRect
          );

          const strokeWidth = iconWidth * 0.1;
          const scaledX = centeredCoordinates.x + strokeWidth / 4;
          const scaledY = centeredCoordinates.y + strokeWidth / 2;
          return (
            <g key={item.opnumber}>
              <circle
                cx={scaledX}
                cy={scaledY}
                r={iconWidth * 0.4 + "px"}
                stroke={"blue"}
                strokeWidth={iconWidth * 0.1 + "px"}
                fill="linen"
              ></circle>
              <text
                x={scaledX}
                y={scaledY}
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize={fontSize}
              >
                {item.number}
              </text>
            </g>
          );
        }
      })
      .filter((item) => item !== undefined);

    //console.log(samplePointItems);

    if (this.state.imageFile !== null) {
      return (
        <svg
          id="svgImage"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          height={toRect.height}
          width={toRect.width}
          preserveAspectRatio="xMidYMid meet"
          onLoad={() => {
            //console.log('svg onLoad');

            let svgnode = document.getElementById("svgImage");
            let clonedSvgElement = svgnode.cloneNode(true);

            //let outerHTML = clonedSvgElement.outerHTML,
            //    blob = new Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' });

            const serializer = new XMLSerializer();
            const svgStr = serializer.serializeToString(clonedSvgElement);

            //console.log(svgStr);

            const blob = new Blob([svgStr], {
              type: "image/svg+xml;charset=utf-8",
            });
            //console.log(blob);
            let URL = window.URL || window.webkitURL || window;
            let blobUrl = URL.createObjectURL(blob);

            let image = new Image();
            image.onload = (e) => {
              //console.log('image onload');
              let canvas = document.createElement("canvas");
              let ctx = canvas.getContext("2d");

              canvas.width = this.state.imageDimensions.width;
              canvas.height = this.state.imageDimensions.height;
              //document.body.appendChild(canvas);

              ctx.drawImage(image, 0, 0);

              this.setState({ blobUrl: canvas.toDataURL(`image/${"png"}`, 1) });
            };

            image.src = clonedSvgElement;
          }}
        >
          <g>
            <g>
              <image
                href={this.state.imageFile}
                width={this.props.size.width}
                preserveAspectRatio="xMidYMid meet"
              />
            </g>

            {originPointItems}
            {samplePointItems}
          </g>
        </svg>
      );
    }
  }

  render() {
    const { classes } = this.props;
    const svgImage = this.buildSVG();

    //console.log(this.props);
    //console.log(this.state);

    return (
      <div>
        <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <Typography variant="h6">File Name: {this.props.fileName}</Typography>
        </div>
        {this.state.imageFile !== undefined && this.state.imageFile !== null && (
          <img
            src={this.state.imageFile}
            alt="Working Image"
            className={classes.isHidden}
            onLoad={(e) => {
              this.setState(
                {
                  imageDimensions: {
                    width: e.target.naturalWidth,
                    height: e.target.naturalHeight,
                  },
                },
                () => {
                  //console.log(this.state.imageDimensions);
                }
              );
            }}
          />
        )}

        {this.props.heatmapVersionFP ? (
          <div id="annotated-floorplan-container">
            <div id="annotated-floorplan-container--logo" />
            <div id="annotated-floorplan-container--img">{svgImage}</div>
          </div>
        ) : (
          svgImage
        )}
      </div>
    );
  }
}

export default withStyles(styles)(AnnotatedFloorPlan);
