import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const useRowStyles = makeStyles((theme) => ({
  dialogActions: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    '& .MuiButton-label': {
      fontFamily: "Gotham",
    }
    
  },
  dialog: {
    '& .MuiPaper-root': {
      padding: theme.spacing(1),
    },
    '& .MuiTypography-root': {
      fontFamily: "Gotham",
    },
  },
}));

export function TestAreaUpdateConfirmation({ 
  onPrimaryAction,
  onSecondaryAction,
  open, 
  onCancel,
}) {
  const classes = useRowStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to Update the new test area along with updating the existing OP&SP count or 
            Just update the new test area without changing the existing OP&SP count?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onSecondaryAction}>Update only test area</Button>
          <Button onClick={onPrimaryAction}>Update all</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}