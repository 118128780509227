import axios from "axios";
import config from "../config.js";

const BASEURL = config.baseURL + "/contracts";

const contracts = {
  createContract: async (data) => {
    const response = await axios.post(BASEURL, data);
    return response;
  },
  createSiteContract: async (data) => {
    const response = await axios.post(BASEURL + "/siteContracts", data);
    return response;
  },
  createTestPackage: async (data) => {
    const response = await axios.post(BASEURL + "/testPackages", data);
    return response;
  },
};

export default contracts;