import React, { useEffect, useRef, useState } from 'react';
import { makeStyles }                         from '@material-ui/core/styles';
import Box                                    from '@material-ui/core/Box';
import Table                                  from '@material-ui/core/Table';
import TableCell                              from '@material-ui/core/TableCell';
import TableHead                              from '@material-ui/core/TableHead';
import TableRow                               from '@material-ui/core/TableRow';
import IconButton                             from '@material-ui/core/IconButton';
import Collapse                               from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon                  from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon                    from '@material-ui/icons/KeyboardArrowUp';
import AddBoxIcon                             from '@material-ui/icons/AddBox';
import Tooltip                                from '@material-ui/core/Tooltip';
import { Context }                            from '../../context/Context';
import { TableBody }                          from "@material-ui/core";
import Modal                                  from "@material-ui/core/Modal";
import Backdrop                               from "@material-ui/core/Backdrop";
import Fade                                   from "@material-ui/core/Fade";
import TextField                              from "@material-ui/core/TextField";
import Button                                 from "@material-ui/core/Button";
import DeleteForeverIcon                      from "@material-ui/icons/DeleteForever";
import tagsApi                                from '../../api/tags';
import { DatePicker }                         from "@material-ui/pickers";
import { useForm }                            from "react-hook-form";
import { format, parseISO } from "date-fns";

const useRowStyles = makeStyles((theme) => ({
    cell:        {
        fontFamily:  'Gotham',
        fontSize:    '13px',
        textAlign:   'center',
        borderRight: 'none',
        borderLeft:  'none',
        height:      '40px',
    },
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell:  {
        fontFamily: 'Gotham',
        fontSize:   '13px',
        fontWeight: '700',
        textAlign:  'center',
        padding:    '10px !important'
    },
    tableRow:    {
        cursor: 'pointer',
    },
    modal:       {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center'
    },
    paper:       {
        backgroundColor: theme.palette.background.paper,
        boxShadow:       theme.shadows[5],
        padding:         theme.spacing(7, 5, 7),
        textAlign:       'center',
        fontFamily:      'Gotham',
        minWidth:        '350px'
    },
}));

export const NestedTableTag = ({ tag, users, fetchTags, fetchTagProperties }) => {

    const classes              = useRowStyles();
    const { register, errors } = useForm();

    const [ open, setOpen ]                                     = useState(false);
    const [ tagEditModal, setTagEditModal ]                     = useState(false);
    const [ tagBaselineEditModal, setTagBaselineEditModal ]     = useState(false);
    const [ tagBaselineDeleteModal, setTagBaselineDeleteModal ] = useState(false);
    const [ tagDeleteModal, setTagDeleteModal ]                 = useState(false);
    const [ user, setUser ]                                     = useState({});

    const [ tagName, setTagName ]                       = useState(null);
    const [ tagPlateKind, setTagPlateKind ]             = useState(null);
    const [ tagSlope, setTagSlope ]                     = useState(null);
    const [ tagIntercept, setTagIntercept ]             = useState(null);
    const [ tagLotNumber, setTagLotNumber ]             = useState(null);
    const [ tagManufactureDate, setTagManufactureDate ] = useState(null);
    const [ tagExpirationDate, setTagExpirationDate ]   = useState(null);
    const [ tagManufacturedBy, setTagManufacturedBy ]   = useState(null);
    const [ tagNotes, setTagNotes ]                     = useState(null);

    const [ tagBaselineId, setTagBaselineId ]       = useState(null);
    const [ tagBaseline, setTagBaseline ]           = useState(null);
    const [ tagQcdate, setTagQcdate ]               = useState(null);
    const [ tagQcdateEnabled, setTagQcdateEnabled ] = useState(true);

    /**
     * Close or open a nested content
     */
    const handleCollapse = () => {
        setOpen(!open);
    }

    /**
     * Open modal for editing a tag's details
     */
    const handleTagEditModal = () => {
        // Set defaults from exist tag
        setTagName(tag.tag);
        setTagPlateKind(tag.platekind);
        setTagSlope(tag.tagslope);
        setTagIntercept(tag.tagintercept);
        setTagLotNumber(tag.taglotnumber);
        setTagManufactureDate(tag.manufacturedate);
        setTagExpirationDate(tag.expirationdate);
        setTagManufacturedBy(tag.manufacturedby);
        setTagNotes(tag.tagnotes);
        // Open modal
        setTagEditModal(!tagEditModal);
    }

    /**
     * Open modal for editing selected baseline
     *
     * @param qcdateEditEnabled
     * @param baselineId
     * @param QCDate
     * @param baseline
     */
    const handleTagBaselineEditModal = (qcdateEditEnabled = true, baselineId = null, QCDate = null, baseline = null) => {
        // Set values for edit
        setTagBaselineId(baselineId);
        setTagQcdate(QCDate);
        setTagBaseline(baseline);

        // Turn on/off input field with qcdate
        setTagQcdateEnabled(qcdateEditEnabled);

        // Open modal
        setTagBaselineEditModal(!tagBaselineEditModal);
    }

    /**
     * Open modal for deleting selected tag
     */
    const handleTagDeleteModal = () => {
        setTagDeleteModal(!tagDeleteModal);
    }

    /**
     * Open modal for deleting selected baseline of tag
     */
    const handleTagBaselineDeleteModal = (baselineId) => {
        setTagBaselineId(baselineId);
        setTagBaselineDeleteModal(!tagBaselineDeleteModal);
    }

    /**
     * Editing details about tag
     *
     * @param field
     * @param value
     */
    const handleChange = (field, value) => {
        switch (field) {
            case "tag":
                setTagName(value.target.value);
                break;
            case "platekind":
                setTagPlateKind(value.target.value);
                break;
            case "tagslope":
                setTagSlope(value.target.value);
                break;
            case "tagintercept":
                setTagIntercept(value.target.value);
                break;
            case "taglotnumber":
                setTagLotNumber(value.target.value);
                break;
            case "manufacturedate":

                if (value === null) {
                    setTagManufactureDate(null);
                } else {
                    setTagManufactureDate(format(value, 'yyyy-MM-dd'));
                }

                break;
            case "expirationdate":

                if (value === null) {
                    setTagExpirationDate(null);
                } else {
                    setTagExpirationDate(format(value, 'yyyy-MM-dd'));
                }

                break;
            case "manufacturedby":
                setTagManufacturedBy(value.target.value);
                break;
            case "tagnotes":
                setTagNotes(value.target.value);
                break;
            case "tagbaseline":
                setTagBaseline(value.target.value);
                break;
            case "tagqcdate":

                if (value === null) {
                    setTagQcdate(null);
                } else {
                    setTagQcdate(format(value, 'yyyy-MM-dd'));
                }
                break;
            default:
                break;
        }
    }

    /**
     * Update exists tag
     *
     * @returns {Promise<void>}
     */
    const handleTagEditSubmit = async () => {
        await tagsApi.updateOneTag(
            tagPlateKind,
            tagName,
            tagManufactureDate,
            tagExpirationDate,
            tagSlope,
            tagIntercept,
            tagNotes,
            tagLotNumber,
            tagManufacturedBy,
            tag.tagid
        );
        await fetchTags();
        await fetchTagProperties();
        setTagEditModal(false);
    }

    /**
     * Remove tag by ID, then close modal
     *
     * @returns {Promise<void>}
     */
    const handleTagDeleteSubmit = async () => {
        await tagsApi.removeOneTag(tag.tagid);
        await fetchTags();
        await fetchTagProperties();
        setTagDeleteModal(false);
    }

    /**
     * Create new tag's qcdate:baseline or update exists
     * then refresh list of tags and close modal.
     */
    const handleTagBaselineSubmit = async () => {
        if (null === tagBaselineId) {
            await tagsApi.addTagBaseline(tag.tagid, tagQcdate, tagBaseline);
        } else {
            await tagsApi.updateTagBaseline(tag.tagid, tagBaselineId, tagQcdate, tagBaseline);
        }
        fetchTags();
        fetchTagProperties();
        setTagBaselineEditModal(false);
    }

    /**
     * Remove baseline by Tag ID and Baseline ID
     *
     * @returns {Promise<void>}
     */
    const handleTagBaselineDeleteSubmit = async () => {
        await tagsApi.removeTagBaseline(tag.tagid, tagBaselineId);
        await fetchTags();
        await fetchTagProperties();
        setTagBaselineDeleteModal(false);
    }

    const firstUpdate = useRef(true);
    useEffect(() => {
        setUser(users.find(obj => {return obj.userid === tag.updatedby}));
        firstUpdate.current = false;
    });

    return (
        <Context.Consumer>
            {context => (
                <>
                    <TableRow className={!!open ? null : classes.tableRow} hover={!open}>
                        <TableCell colSpan={3} className={classes.cell}>
                            {!!tag.tagbaselines && (
                                <Tooltip title="Tag Baselines" placement="top" aria-label="tag-baseline">
                                    <IconButton aria-label="expand row" size="small" onClick={() => handleCollapse()}>
                                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </Tooltip>
                            )}
                        </TableCell>
                        <TableCell size="small" className={classes.cell}>
                            <IconButton onClick={() => handleTagDeleteModal()} aria-label="delete">
                                <DeleteForeverIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell size="small" className={classes.cell} onClick={handleTagEditModal}>
                            {tag.tag}
                        </TableCell>
                        <TableCell size="small" className={classes.cell} onClick={handleTagEditModal}>
                            {tag.platekind}
                        </TableCell>
                        <TableCell size="small" className={classes.cell} onClick={handleTagEditModal}>
                            {tag.tagslope}
                        </TableCell>
                        <TableCell size="small" className={classes.cell} onClick={handleTagEditModal}>
                            {tag.tagintercept}
                        </TableCell>
                        <TableCell size="small" className={classes.cell} onClick={handleTagEditModal}>
                            {tag.taglotnumber}
                        </TableCell>
                        <TableCell size="small" className={classes.cell} onClick={handleTagEditModal}>
                            {tag.manufacturedate}
                        </TableCell>
                        <TableCell size="small" className={classes.cell} onClick={handleTagEditModal}>
                            {tag.expirationdate}
                        </TableCell>
                        <TableCell className={classes.cell}>
                            <IconButton onClick={() => handleTagBaselineEditModal(true, null, null, null)} aria-label="tag-baseline-add">
                                <AddBoxIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>

                    {/* NestedContent START */}
                    {!!tag.tagbaselines && (
                        <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={8}>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <Box margin={1}>
                                        <Table aria-label="tag-baseline">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell} />
                                                    <TableCell className={classes.headerCell} />
                                                    <TableCell className={classes.headerCell}>Delete Baseline</TableCell>
                                                    <TableCell className={classes.headerCell}>QC Date</TableCell>
                                                    <TableCell className={classes.headerCell}>Baseline</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {tag.tagbaselines.sort((a, b) => (a.qcdate > b.qcdate) ? 1 : -1).reverse().map((baseline, i) => (
                                                <TableBody key={`tag-baseline-${i}`}>
                                                    <TableRow hover={true} className={classes.tableRow}>
                                                        <TableCell size="small" className={classes.cell} style={{ border: "none" }} />
                                                        <TableCell size="small" className={classes.cell} style={{ border: "none" }} />
                                                        <TableCell size="small" className={classes.cell}>
                                                            <IconButton onClick={() => handleTagBaselineDeleteModal(baseline.baselineid)} aria-label="delete">
                                                                <DeleteForeverIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell size="small" className={classes.cell} onClick={() => handleTagBaselineEditModal(false, baseline.baselineid, baseline.qcdate, baseline.baseline)}>
                                                            {baseline.qcdate}
                                                        </TableCell>
                                                        <TableCell size="small" className={classes.cell} onClick={() => handleTagBaselineEditModal(false, baseline.baselineid, baseline.qcdate, baseline.baseline)}>
                                                            {baseline.baseline}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ))}
                                        </Table>
                                    </Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    )}
                    {/* NestedContent END */}

                    {/* Modal Tag Delete START */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={tagDeleteModal}
                        onClose={() => handleTagDeleteModal()}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}>
                        <div>
                            <Fade in={tagDeleteModal}>
                                <div className={classes.paper}>
                                    <h2 id="transition-modal-title">Are you sure you want to delete "{tag.tag}" tag?</h2>
                                    <div>
                                        <Button
                                            onClick={() => handleTagDeleteSubmit()}
                                            size="small"
                                            style={{ backgroundColor: "rgb(0, 137, 150)", color: "#ffffff", fontFamily: "Gotham", margin: "5px" }}>Yes
                                        </Button>
                                        <Button
                                            onClick={() => handleTagDeleteModal('test')}
                                            size="small"
                                            style={{ backgroundColor: "rgb(0, 137, 150)", color: "#ffffff", fontFamily: "Gotham", margin: "5px" }}>No
                                        </Button>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </Modal>
                    {/* Modal Tag Delete END */}

                    {/* Modal Tag Baseline Create/Update START */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={tagBaselineEditModal}
                        onClose={handleTagBaselineEditModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}>
                        <div>
                            <Fade in={tagBaselineEditModal}>
                                <div className={classes.paper}>
                                    <div className="grid-wrapper">
                                        <div>
                                            <h2 id="transition-modal-title">
                                                Baseline of "{tag.tag}"
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="grid-wrapper">
                                        <div>
                                            <DatePicker
                                                fullWidth={true}
                                                disabled={!tagQcdateEnabled}
                                                inputRef={register({ required: true })}
                                                label="QC Date"
                                                name="tag-baseline-qcdate"
                                                autoComplete="tag-qcdate"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("tagqcdate", newValue)}
                                                error={errors.expirationDate}
                                                format="yyyy-MM-dd"
                                                mask="____-__-__"
                                                value={parseISO(tagQcdate) ?? null}
                                                clearable
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth={true}
                                                label="Baseline"
                                                name="tag-baseline-number"
                                                autoComplete="tag-baseline-number"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("tagbaseline", newValue)}
                                                value={tagBaseline ?? ''}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid-wrapper">
                                        <div>
                                            <Button
                                                onClick={e => handleTagBaselineSubmit(e)}
                                                size="small"
                                                className="modal-button"
                                                disabled={!tagQcdate}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                onClick={handleTagBaselineEditModal}
                                                size="small"
                                                className="modal-button"
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </Modal>
                    {/* Modal Tag Baseline Create/Edit END */}

                    {/* Modal Tag Baseline Delete START */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={tagBaselineDeleteModal}
                        onClose={() => handleTagBaselineDeleteModal()}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}>
                        <div>
                            <Fade in={tagBaselineDeleteModal}>
                                <div className={classes.paper}>
                                    <h2 id="transition-modal-title">Are you sure you want to delete "{tag.tag}" baseline?</h2>
                                    <div>
                                        <Button
                                            onClick={() => handleTagBaselineDeleteSubmit()}
                                            size="small"
                                            style={{ backgroundColor: "rgb(0, 137, 150)", color: "#ffffff", fontFamily: "Gotham", margin: "5px" }}>Yes
                                        </Button>
                                        <Button
                                            onClick={() => handleTagBaselineDeleteModal()}
                                            size="small"
                                            style={{ backgroundColor: "rgb(0, 137, 150)", color: "#ffffff", fontFamily: "Gotham", margin: "5px" }}>No
                                        </Button>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </Modal>
                    {/* Modal Tag Delete END */}

                    {/* Modal Tag Edit START */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={tagEditModal}
                        onClose={handleTagEditModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}>
                        <div>
                            <Fade in={tagEditModal}>
                                <div className={classes.paper}>
                                    <div className="grid-wrapper">
                                        <div>
                                            <h2 id="transition-modal-title">
                                                Update tag details
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="grid-wrapper">
                                        <div>
                                            <TextField
                                                fullWidth={true}
                                                label="Name"
                                                name="tag-name"
                                                autoComplete="tag-name"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("tag", newValue)}
                                                value={tagName ?? ''}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth={true}
                                                label="Platekind"
                                                name="tag-platekind"
                                                autoComplete="tag-platekind"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("platekind", newValue)}
                                                value={tagPlateKind ?? ''}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth={true}
                                                label="Slope"
                                                name="tag-slope"
                                                autoComplete="tag-slope"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("tagslope", newValue)}
                                                value={tagSlope ?? ''}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth={true}
                                                label="Intercept"
                                                name="tag-intercept"
                                                autoComplete="tag-intercept"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("tagintercept", newValue)}
                                                value={tagIntercept ?? ''}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth={true}
                                                label="Lot Number"
                                                name="tag-lotnumber"
                                                autoComplete="tag-lotnumber"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("taglotnumber", newValue)}
                                                value={tagLotNumber ?? ''}
                                            />
                                        </div>
                                        <div>
                                            <DatePicker
                                                fullWidth={true}
                                                inputRef={register({ required: true })}
                                                label="Manufacture Date (Bulk saliva)"
                                                name="tag-manufacturedate"
                                                autoComplete="tag-manufacturedate"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("manufacturedate", newValue)}
                                                error={errors.manufacturedate}
                                                format="yyyy-MM-dd"
                                                mask="____-__-__"
                                                value={parseISO(tagManufactureDate) ?? null}
                                                clearable
                                            />
                                        </div>
                                        <div>
                                            <DatePicker
                                                fullWidth={true}
                                                inputRef={register({ required: true })}
                                                label="Expiration Date (Bulk saliva)"
                                                name="tag-expirationdate"
                                                autoComplete="tag-expirationdate"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("expirationdate", newValue)}
                                                error={errors.expirationdate}
                                                format="yyyy-MM-dd"
                                                mask="____-__-__"
                                                value={parseISO(tagExpirationDate) ?? null}
                                                clearable
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth={true}
                                                label="Manufactured By"
                                                name="tag-manufacturedby"
                                                autoComplete="tag-manufacturedby"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("manufacturedby", newValue)}
                                                value={tagManufacturedBy ?? ''}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth={true}
                                                label="Notes"
                                                name="tag-notes"
                                                autoComplete="tag-notes"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("tagnotes", newValue)}
                                                value={tagNotes ?? ''}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid-wrapper">
                                        <div>
                                            <Button
                                                onClick={handleTagEditSubmit}
                                                disabled={!(tagPlateKind && tagName && tagManufactureDate && tagSlope && tagIntercept && tagLotNumber)}
                                                size="small"
                                                className="modal-button"
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                onClick={handleTagEditModal}
                                                size="small"
                                                className="modal-button"
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </Modal>
                    {/* Modal Tag Edit END */}
                </>
            )}
        </Context.Consumer>
    );
}
