import React from 'react';
import sampleplans from '../../api/sampleplans';
import Labels from '../../components/Labels/Labels.js';
import Button from '@material-ui/core/Button';
import ReactToPrint from 'react-to-print';
import Typography from "@material-ui/core/Typography";
import NavBarUtil from "../../components/Navbar/NavBarUtil";
import PrintAll from "../ProjectsView/PrintAll";
import { withStyles } from "@material-ui/core";
import { PrintStyles } from "../ProjectsView/PrintStyles";

class SampleCollectorLabels extends React.Component {
    state = {
        segmentId: null, 
        labels: [],
        labelNumber: 'OL28',
        header: 'SampleCollector Labels'
    };

    async componentDidMount() {
        NavBarUtil.setNavButtonActive('#lBtn')

        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        let testId = JSON.parse(localStorage.getItem("testId")) ? JSON.parse(localStorage.getItem("testId")) : null;

        this.setState({
            projectId: projectId,
            testId: testId
        }, async () => {
            //  Get sample plans for the segmentId
            const response = await sampleplans.getLabels(null, this.state.projectId);
            let labels = response.data.data;
            labels.forEach(label => {
                label.number = label.samplePoint;
            });
            this.setState ({labels: labels});
        });
    };

    handleBack = () => this.props.history.push('/projectview');

    render() {
        const { labelNumber, header } = this.state;
        const { classes } = this.props;
        return (
            <main style={{ position: 'relative', top: '20px' }}>
                <div className="labels-flex-container">
                    <Typography variant="h5" color="textPrimary" align="left" style={{fontFamily: "Gotham"}}>{header}</Typography>
                    <ReactToPrint
                        content={() => this.componentRef}
                        trigger={() => {
                            return (
                                <div className={'labels-flex-child'}>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        className={classes.greenButton}
                                    >
                                        Print
                                    </Button>
                                </div>
                            )
                        }}

                    />
                    <Button onClick={this.handleBack} size="small" variant="outlined" className={classes.greenButton}>Back</Button>
                </div>
                <Typography color="textPrimary" align="center" style={{fontFamily: "Gotham"}}>
                    <div className="labels-flex-child">{`Please use label Online Label ${labelNumber} which you can find`}<a href="https://www.onlinelabels.com/products/ol28?src=dlc-410" target="_blank"> here</a></div>
                </Typography>
                <div className="labels-container">
                    <Labels labels={this.state.labels} history={this.props.history}/> 
                </div>
                <div className="print-source" ref={el => (this.componentRef = el)}>
                    <PrintAll type="SCLabels"/>
                </div>
            </main>
        )
    }
}

export default withStyles(PrintStyles, { withTheme: true })(SampleCollectorLabels);