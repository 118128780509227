import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useRowStyles = makeStyles((theme) => ({
  dialogActions: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    '& .MuiButton-label': {
      fontFamily: "Gotham",
    }
    
  },
  dialog: {
    '& .MuiPaper-root': {
      padding: theme.spacing(1),
    },
    '& .MuiTypography-root': {
      fontFamily: "Gotham",
    },
  },
}));

export function GeneralConfirmationDialog({ 
  onOk, 
  open, 
  onCancel, 
  title, 
  cancelBtnText,
  okBtnText,
  contentText
}) {
  const classes = useRowStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        { contentText &&
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {contentText}
          </DialogContentText>
        </DialogContent> }
        <DialogActions className={classes.dialogActions}>
          <Button onClick={onCancel}>{cancelBtnText}</Button>
          <Button onClick={onOk}>{okBtnText}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}