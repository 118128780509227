import React from '../../../node_modules/react';
import testPlan from '../../api/testPlan';
import projects from '../../api/projects';
import tests from '../../api/tests';
import sampleplans from '../../api/sampleplans';
import Button from '@material-ui/core/Button';
import { Context } from '../../context/Context';
import Typography from "@material-ui/core/Typography";
import { Alert } from '../../components/Alert/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Tooltip from '@material-ui/core/Tooltip';
import './TestPlan.css';

const styles = theme => ({
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'center',
        padding: '10px !important'
    },
    cell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        textAlign: 'center',
        borderRight: 'none',
        borderLeft: 'none'
    },
    loneCell: {
        fontFamily: 'Gotham',
        fontSize: '20px',
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    }
});

class TestPlan extends React.Component {
    constructor(props) {
        super(props);
        this.multiselectOptions = {};
        this.numberOfPages = 0;
        this.state = {
            projectId: null,
            testPlan: [],
            segments: [],
            segmentIds: [],
            segmentSurveyDates: {},
            selectedSegments: {},
            segmentComments: {},
            open: false,
            alertMsg: '',
            updateSuccessful: false,
            workFlow: false,
            itemsPerPage: 10,
            page: 1,
            disable: false,
            testCompleted: null
        };
    }

    handleOpeningAlert = (type, success, createSamplePlanRes = 'Sample plan created!') => {
        //createSamplePlanRes default param to handle 3 senarios possible when attempting to create SP
        if(type === 'testPlan') {
            this.setState({ 
                open: !this.state.open,
                alertMsg: !!success ? 'Test plan updated!' : 'Error updating test plan!',
                updateSuccessful: !!success ? true : false,
                workFlow: false 
            }); 
        } else if(type === 'samplePlan') {
            this.setState({ 
                open: !this.state.open,
                alertMsg: createSamplePlanRes,
                updateSuccessful: !!success ? true : false,
                workFlow: false 
            }); 
        }
    }

    handleClosingAlert = () => {
        this.setState({ 
            open: false,
            alertMsg: '',
            updateSuccessful: false
        });  
    }
   
    componentDidMount() {
        let response;
        let oneCheckResponse;
        let completed = null;

        //logic to handle highlighting navbar tab
        let testplanNavButton = document.querySelector('#tpBtn');
        if(testplanNavButton) {
            testplanNavButton.classList.add('selected');
        }
        
        let projectId = JSON.parse(localStorage.getItem("projectId"));
        let testId = JSON.parse(localStorage.getItem("testId"));

        this.setState({
            projectId: projectId,
            testId: testId
        }, async () => {

            response = await testPlan.getTestPlan(this.state.testId, this.state.projectId);

            if(testId !== null) {
                oneCheckResponse = await tests.getOneTest(this.state.testId);
                if(oneCheckResponse) {
                    completed = oneCheckResponse.data.data.testexecuted;
                }
            } else {
                oneCheckResponse = await projects.getOneProject(this.state.projectId);
                if(oneCheckResponse) {
                    completed = oneCheckResponse.data.data.testCompleted;
                }
            }
            //Check if Project is testCompleted
            if(oneCheckResponse && completed !== null) {
                this.setState({ testCompleted: completed }, () => {
                    if(this.context.state.accessLevel === 'Viewer' || this.state.testCompleted) {
                        this.setState({disable: true});
                    }
                });
            }

            const plan = response.data.data;
            // Get segment names from segment ids for segments in 'segmentstested' column
            if(plan) {
                if(plan.length > 0) {
                    let segmentMap = {};
                    plan.forEach(d => {
                        segmentMap[d.segmentid] = d.segmentname;
                    });
                    plan.forEach(p => {
                        p.segmentstestedName = [];
                        let segments = p.segmentstested;
                        if(segments && segments.length > 0) {
                            segments.forEach(s => {
                                p.segmentstestedName.push(segmentMap[s]);
                            });
                        }
                        // Set survey date to current date if not specified yet
                        if(p.surveydate === "Invalid date") {
                            p.surveydate = new Date().toISOString().slice(0,10);
                        } else {
                            p.surveydate = new Date(p.surveydate).toISOString().slice(0,10);
                        }
                    });
                }
            }
            this.setState ({testPlan: plan}, () => {
                //Create array of segments for Select dropdown
                this.state.testPlan.forEach((plan, i) => {
                    this.setState(prevState => ({
                        segmentSurveyDates: {...prevState.segmentSurveyDates, [i]: plan.surveydate},
                        segmentComments: {...prevState.segmentComments, [i]: plan.comments},
                        segments: [...prevState.segments, plan.segmentname],
                        segmentIds: [...prevState.segmentIds, plan.segmentid],
                        selectedSegments: {...prevState.selectedSegments, [i]: plan.segmentstestedName}
                    }));
                });
            });
        });
    };

    fetchUpdatedTestPlan = async () => {
        let projectId = JSON.parse(localStorage.getItem("projectId"));
        let testId = JSON.parse(localStorage.getItem("testId"));
        let response;
        this.setState({
            projectId: projectId,
            testId: testId
        }, async () => {
            response = await testPlan.getTestPlan(this.state.testId, this.state.projectId);
            const plan = response.data.data;
            // Get segment names from segment ids for segments in 'segmentstested' column
            if(plan.length > 0) {
                let segmentMap = {};
                plan.forEach(d => {
                    segmentMap[d.segmentid] = d.segmentname;
                });
                plan.forEach(p => {
                    p.segmentstestedName = [];
                    let segments = p.segmentstested;
                    if(segments && segments.length > 0) {
                        segments.forEach(s => {
                            p.segmentstestedName.push(segmentMap[s]);
                        });
                    }
                    // Set survey date to current date if not specified yet
                    if(p.surveydate === "Invalid date") {
                        p.surveydate = new Date().toISOString().slice(0,10);
                    } else {
                        p.surveydate = new Date(p.surveydate).toISOString().slice(0,10);
                    }
                });
            }
            this.setState ({testPlan: plan}, () => {
                //Create array of segments for Select dropdown
                this.state.testPlan.forEach((plan, i) => {
                    this.setState(prevState => ({
                        segmentSurveyDates: {...prevState.segmentSurveyDates, [i]: plan.surveydate},
                        segmentComments: {...prevState.segmentComments, [i]: plan.comments},
                        segmentIds: [...prevState.segmentIds, plan.segmentid],
                        selectedSegments: {...prevState.selectedSegments, [i]: plan.segmentstestedName}
                    }));
                });
            });
        });
    }
   
    getRowCellValues = (ref, index) => {
        const { selectedSegments } = this.state;
        let rowVals = {};
        let segmentId;
        let surveyDate;
        let segmentsTested;
        let comments;

        //This returns an HTMLCollection
        let rowCellChildren = ref.children;
        //Convert HTMLCollection into Array
        rowCellChildren = [...rowCellChildren];
        //Remove last 3 columns which contain buttons
        rowCellChildren = rowCellChildren.slice(0, 4);

        segmentId = this.state.segmentIds[index];
        surveyDate = rowCellChildren[1].children[0].children[0].children[0].children[0].children[0].value;
        segmentsTested = selectedSegments[index];
        comments = rowCellChildren[3].children[0].children[0].children[0].children[0].children[0].value;

        rowVals = {
            "segmentId" : segmentId,
            "surveyDate" : surveyDate,
            "segmentsTested" : segmentsTested,
            "comments" : comments
        }
        return rowVals;
    }

    onKeyDownUpdate = (e, ref, index, type) => {
        e.preventDefault();
        const data = this.getRowCellValues(ref, index);
        const segmentId = data.segmentId;
        this.updateTestPlan(segmentId, data, index, type);
    }

    updateTestPlan = (segmentid, data, index, type) => {
        let segmentsTestedNames;
        let updated = null;

        if(data) {
            segmentsTestedNames = data.segmentsTested;
        }
        
        let segmentsTestedId = [];
        segmentsTestedNames.forEach((segmentName, i) => {
            this.state.testPlan.forEach(segment => {
                if(segment.segmentname === segmentName) {
                    segmentsTestedId.push((segment.segmentid).toString());
                }
            });
        });

        const testPlanToUpdate = {
            "testId": this.state.testId,
            "segmentId": data.segmentId,
            "surveyDate": data.surveyDate,
            "segmentsTested": segmentsTestedId,
            "comments": data.comments!==null?data.comments:'No comments'
        };
        
        testPlan.updateTestPlan(testPlanToUpdate).then((result) => {
            if(result.data.success) {
                updated = result.data.success;
                if(type !== 'selectSegments') {
                    this.setState({ 
                        open: !this.state.open,
                        alertMsg: type === 'comment' ? 'Comments successfully updated' : 'Survey date successfully updated',
                        updateSuccessful: result.data.success ? true : false
                    }); 
                }
                //Update plan and repaint UI
                this.fetchUpdatedTestPlan();
            } else {
                updated = result.data.success;
            }
        })
    }

    onCreateSamplePlanClick(ref, index) {
        this.allSegmentPlansCreated(this.state.testPlan);
        let updated = null;
        let createSamplePlanRes = null;
        const data = this.getRowCellValues(ref, index);
        const segmentId = data.segmentId;
        sampleplans.createSamplePlan(segmentId).then(result => {
            // TOGGLE THIS TO TEST
            // result.data.success = true; 
            //console.log('TESTPLAN result.data.success: ', result.data.success);
            if(result.data.success) {
                updated = result.data.success;
                createSamplePlanRes = 'sample plan created!';
                this.setState({createPlan:{text: 'Sample plan created', disabled: true} });
            } else if (result.data.success === false && result.data.error.error === "Sample Plan already created for segment") {
                updated = result.data.success;
                createSamplePlanRes = 'Sample plan already created!';
            } else {
                updated = result.data.success;
                createSamplePlanRes = 'Error creating sample plan!';
            }
        })
    }

    onDisplaySamplePlanClick(ref, index) {
        const data = this.getRowCellValues(ref, index);
        const segmentId = data.segmentId;
        this.props.history.push('/' + segmentId + '/sampleplan');
    }

    handleSegmentsClick = (event) => {
        this.props.history.push('/segments');  
    }

    handleViewPlanForProjectClick = (event) => {
        this.props.history.push('/sampleplan');  
    }

    setMultiselectOptions = (value, ref, index) => {
        let selectedSegmentsCopyVals; //declare empty variable
        const { selectedSegments } = this.state; // destructure current value of selectedSegments from state
        const selectedSegmentsCopy = {...selectedSegments}; // make copy

        //if the segments obj at this index exists, extract the values
        if(selectedSegmentsCopy[index]) {
            selectedSegmentsCopyVals = Object.values(selectedSegmentsCopy[index])
        }

        //if values were identified and stored into selectedSegmentsCopyVals variable update with new value
        if(selectedSegmentsCopyVals) {
            selectedSegmentsCopy[index] = [value];
        //else just store the value into obj at that index
        } else {
            selectedSegmentsCopy[index] = [value];
        }

        //convert to array of segment string values
        selectedSegmentsCopy[index] = selectedSegmentsCopy[index].toString().split(',');

        this.setState(prevState => ({
            selectedSegments: {                   // object that we want to update
                //...prevState.selectedSegments,    // keep all other key-value pairs
                [index]: [...new Set(selectedSegmentsCopy[index])] // update the value of specific key and remove any duplicates
            }
        }), () => {
            const data = this.getRowCellValues(ref, index);
            const segmentId = data.segmentId;
            this.updateTestPlan(segmentId, data, index, 'selectSegments');
        });
    }

    handleDropDownChange = (e, ref, index) => {
        let rowIndex = index;
        let value = e.target.value.toString();
        this.setMultiselectOptions(value, ref, rowIndex);
    }

    handleTextFieldChange = (event, i, type) => {
        let value = event.target.value;
        if(type === 'surveyDate') {
            this.setState(prevState => ({
                segmentSurveyDates: {...prevState.segmentSurveyDates, [i]: value}
            }));
        }
        if(type === 'comment') {
            this.setState(prevState => ({
                segmentComments: {...prevState.segmentComments, [i]: value}
            }));
        }
    }

    handlePageChange = (e, value) => {
        this.setState({ page: value });
    };

    setNumberOfPages = (testPlan, itemsPerPg) => {
        this.numberOfPages = Math.ceil(testPlan.length / itemsPerPg);
    }

    render() {
        const { open, alertMsg, updateSuccessful, workFlow, testPlan, segments, selectedSegments, segmentComments, segmentSurveyDates, page, itemsPerPage, disable, projectId } = this.state;
        const { classes } = this.props;

        if(testPlan) {
            this.setNumberOfPages(testPlan, itemsPerPage);
        } 
        
        return (
            <Context.Consumer>
                {context => (
                    <div style={{ marginTop: '100px' }} className="test-plan-container">
                        <div>
                            <Typography variant="h5" style={{fontFamily: "Gotham", marginTop: "30px"}} color="textPrimary" align="center">{`(Project Id ${projectId}) ${context.state.projectName}: ${context.state.testName}`}</Typography>
                        </div>
                        <div className="test-plan-table-container">
                            <div className="test-plan-button-container">
                                <Button size="small" variant="outlined" onClick={this.handleViewPlanForProjectClick} style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", margin: "5px", fontFamily: "Gotham"}}>SAMPLE PLAN</Button>
                            </div>
                            <div>
                                <TableContainer style={{ marginTop: '15px', marginLeft: '170px', width: '100%' }} component={Paper}>
                                    <div className="">
                                        <Table aria-label="">
                                            <TableHead className={classes.tableHeader}>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell}>Samples from Segment</TableCell>
                                                    <TableCell className={classes.headerCell}>Survey Date [YYYY-MM-DD]</TableCell>
                                                    <TableCell className={classes.headerCell}>Tested against OP from Segments</TableCell>
                                                    <TableCell className={classes.headerCell}>Comments</TableCell>
                                                    <TableCell className={classes.headerCell}>Display Sample Plan</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {testPlan
                                                    .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                                    .map((plan, i) => (
                                                    <TableRow key={`key=${i}`} ref={el => this[`row-${i}-ref`] = el}>
                                                        <TableCell segmentid={plan.segmentname} className={classes.cell}>
                                                            {plan.segmentname}
                                                        </TableCell>
                                                        <TableCell className={classes.cell}>
                                                            <form onSubmit={e => this.onKeyDownUpdate(e, this[`row-${i}-ref`], i, 'surveyDate')} noValidate autoComplete="off">
                                                                    <div style={{ marginLeft: '50px' }}>
                                                                        <TextField 
                                                                            type="text"
                                                                            inputProps={{
                                                                                style: { textAlign: 'center', fontFamily: 'Gotham', fontSize: '13px', marginTop: '10px' }
                                                                            }}
                                                                            InputProps={{ disableUnderline: true }}
                                                                            value={segmentSurveyDates[i] || ''}
                                                                            disabled={disable}
                                                                            onChange={e => this.handleTextFieldChange(e, i, 'surveyDate')} 
                                                                        />
                                                                        <Tooltip title="Submit survey date" placement="top" aria-label="origin-points">
                                                                            <IconButton  
                                                                                type="submit"
                                                                                style={{ position: 'relative', right: '30px' }}
                                                                                disabled={disable}
                                                                            >
                                                                                <CloudUploadIcon style={{color: "rgba(0, 0, 0, 0.54)"}} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                </form>

                                                            {/** 
                                                            <TextField 
                                                                type="text"
                                                                inputProps={{
                                                                    style: { textAlign: 'center', fontFamily: 'Gotham', fontSize: '13px' }
                                                                }}
                                                                InputProps={{ disableUnderline: true }}
                                                                value={segmentSurveyDates[i] || ''}
                                                                disabled={disable}
                                                                onChange={e => this.handleTextFieldChange(e, i, 'surveyDate')}
                                                                onKeyDown={e => this.onKeyDownUpdate(e, this[`row-${i}-ref`], i)} />
                                                            */}
                                                        </TableCell>
                                                        <TableCell className={classes.cell}>
                                                            <FormControl className={classes.formControl}>
                                                                <Select
                                                                    disableUnderline
                                                                    style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }}
                                                                    labelId="segments"
                                                                    id={plan.segmentname}
                                                                    name={plan.segmentname}
                                                                    multiple
                                                                    value={selectedSegments[i] ? selectedSegments[i] : []}
                                                                    disabled={disable}
                                                                    onChange={e => this.handleDropDownChange(e, this[`row-${i}-ref`], i)}
                                                                    renderValue={(value) => value}
                                                                >
                                                                {segments.map((segment, i) => (
                                                                    <MenuItem style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} key={`${segment}-${i}`} value={segment}>
                                                                        {segment}
                                                                    </MenuItem>
                                                                ))}
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell className={classes.cell}>
                                                            <form onSubmit={e => this.onKeyDownUpdate(e, this[`row-${i}-ref`], i, 'comment')} noValidate autoComplete="off">
                                                                <div style={{ marginLeft: '50px' }}>
                                                                    <TextField 
                                                                        type="text"
                                                                        multiline
                                                                        rows={3}
                                                                        inputProps={{
                                                                            style: { 
                                                                                textAlign: 'center', 
                                                                                fontFamily: 'Gotham', 
                                                                                fontSize: '13px', 
                                                                                marginTop: '10px', 
                                                                                width: '85%',
                                                                                height: 'auto'
                                                                            }
                                                                        }}
                                                                        InputProps={{ disableUnderline: true }}
                                                                        value={segmentComments[i] || ''}
                                                                        disabled={disable}
                                                                        onChange={e => this.handleTextFieldChange(e, i, 'comment')}
                                                                    />
                                                                    <Tooltip title="Submit Comments" placement="top" aria-label="origin-points">
                                                                        <IconButton  
                                                                            type="submit"
                                                                            style={{ position: 'relative', right: '30px' }}
                                                                            disabled={disable}
                                                                        >
                                                                            <CloudUploadIcon style={{color: "rgba(0, 0, 0, 0.54)"}} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            </form>
                                                        </TableCell>
                                                        <TableCell className={classes.cell}>
                                                            <Button onClick={() => this.onDisplaySamplePlanClick(this[`row-${i}-ref`], i)} size="small" variant="outlined" style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", margin: "5px", fontFamily: "Gotham"}}>View Plan</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </TableContainer>
                                {!!open && (<Alert flag={open} msg={alertMsg} closeFunc={this.handleClosingAlert} updateSuccessful={updateSuccessful} workFlow={workFlow} />)}
                            </div>
                        </div>
                        <section className="labels-pagination-wrapper">
                            <Pagination
                                count={this.numberOfPages}
                                page={page}
                                defaultPage={1}
                                onChange={this.handlePageChange}
                                color="standard"
                                size="medium"
                                showFirstButton
                                showLastButton
                            />
                        </section>
                    </div>
                )}
            </Context.Consumer>
        )
    }
}
TestPlan.contextType = Context;
export default withStyles(styles, { withTheme: true })(TestPlan);