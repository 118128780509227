import React from '../../../node_modules/react';
import { AgGridReact } from '../../../node_modules/ag-grid-react/main';
import '../../../node_modules/ag-grid-community/dist/styles/ag-grid.css';
import '../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css';

class SamplePlanList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columnDefs: [{
                headerName: "OPNumber", field: "opnumber", width: 100 
                }, {
                headerName: "plateKind", field: "platekind", width: 50
                }, {
                headerName: "plateId", field: "plateid", width: 70
                }, {
                headerName: "plateIdNumber", field: "plateidnumber", width: 50
                }, {
                headerName: "Tag", field: "tag", width: 100
                },{
                    headerName: "Row", field: "row", width: 50
                }, {
                    headerName: "c1", field: "c1", width: 100
                },{
                    headerName: "c2", field: "c2", width: 100
                }, {
                    headerName: "c3", field: "c3", width: 100
                }, {
                    headerName: "c4", field: "c4", width: 100
                }, {
                    headerName: "c5", field: "c5", width: 100
                }, {
                    headerName: "c6", field: "c6", width: 100
                }, {
                    headerName: "c7", field: "c7", width: 100
                }, {
                    headerName: "c8", field: "c8", width: 100
                }, {
                    headerName: "c9", field: "c9", width: 100
                }, {
                    headerName: "c10", field: "c10", width: 100
                }, {
                    headerName: "c11", field: "c11", width: 100
                }, {
                    headerName: "c12", field: "c12", width: 100
                }],
                defaultColDef: {
                    editable: true,
                    sortable: true,
                    flex: 1,
                    minWidth: 100,
                    filter: true,
                    resizable: true,
                }
        }
    }   

    render() {
        return (
            <div
            className="ag-theme-alpine"
            style={{
            height: '500px',
            width: '95%',
            textAlign: "left",
            fontFamily: "gotham",
            margin:'10px' }}>
                <AgGridReact
                    pagination={true}
                    columnDefs={this.state.columnDefs}
                    rowData={this.props.SamplePlan}
                    defaultColDef={this.state.defaultColDef}
                    rowSelection="single">
                </AgGridReact>
            </div>
        );
    }
}

export default SamplePlanList;