import React from 'react';

class VisualizerHeader extends React.Component{
    


    render(){
        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        let projectName = localStorage.getItem("projectName") ? localStorage.getItem("projectName") : null;
        let testName = localStorage.getItem("testName") ? localStorage.getItem("testName") : null;
        return (
        <div className="visualizer-header">
            <div id="info-panel">
                <h5>(Project Id {projectId}) {projectName}: {testName}</h5>
                <p>{this.props.msg !== undefined && this.props.msg !== null? this.props.msg : ""}</p>
            </div>         
        </div>
        )
    }
}

export default VisualizerHeader;