import React                       from 'react';
import { Context }                 from '../../context/Context';
import { withStyles }              from "@material-ui/core/styles";
import { ProjectTests }            from "./ProjectTests";
import { ProjectDataView }         from "./ProjectDataView";
import { ProjectDataEdit }         from "./ProjectDataEdit";
import { ProjectDetailsFloorPlan } from "./ProjectDetailsFloorPlan";
import { ProjectTags }             from "./ProjectTags";
import './ProjectDetails.css';
import {ProjectResults} from "./ProjectResults";
import projectsAPI                 from "../../api/projects";
import testsAPI                    from "../../api/tests";
import floorPlans                  from "../../api/floorPlans";
import authentication              from "../../api/authentication";
import originpoints                from "../../api/originpoints";
import NavBarUtil from "../../components/Navbar/NavBarUtil";
import results from "../../api/results";
import { VERIFICATION_LIKE_TESTS } from '../../config';
import {ProjectResultsTable} from "./ProjectResultsTable";
import PublishProjectModal from "../../components/PublishProjectModal/PublishProjectModal";
import { GeneralConfirmationDialog } from '../../components/ConfirmationDialog';
import { AlertGeneral } from '../../components/Alert/AlertGeneral';

const styles = theme => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow:       theme.shadows[5],
        padding:         theme.spacing(7, 5, 7),
        textAlign:       'center',
        fontFamily:      "Gotham"
    }
});

class ProjectDetails extends React.Component {

    state = {
        projectId:              null,
        projectData:            {
            name:          null,
            address:       null,
            description:   null,
            endCustomer:   null,
            partners:      null,
            companyid:     null,
            testCompleted: null,
            tests:         [],
            status: '',
            origin: '',
        },
        openTestModal:          false,
        errorMsg:               "",
        openTestCompletedAlert: false,
        editProject:            false,
        customerCompanies:      [],
        floorPlansData:         [],
        partners:               [],
        projectTags:            [],
        resultsTestList:        [],
        gradientReader:         null,
        isResultsUploaded:      false,
        resultsFilesList:       [],
        testsCoverage:          {},
        verificationProject: false,
        verificationLikeProject: false,
        atLeastOneNonVerificationLike: false,
        isPublicationAllowedUser: false,
        isPublicationAllowedProject: false,
        isPublicationAllowed: false,
        publicationModalVisible: false,
        shouldConfirmRemoval: false,
        alertMsg: '',
        alertOpen: false
    };

    async componentDidMount() {
        let projectId = this.getProjectId();


        const role = localStorage.getItem(`roleName`);
        const accessLevel = localStorage.getItem(`accessLevel`);

        let publicationModalVisible = !!localStorage.getItem(`publicationInProgress_${projectId}`);
        let isPublicationAllowedUser = false;
        try {
            isPublicationAllowedUser = JSON.parse(role) === "Safetracer" && JSON.parse(accessLevel) === "Admin";
        } catch (err) {}
        const isPublicationAllowed = this.state.isPublicationAllowed || (isPublicationAllowedUser && this.state.isPublicationAllowedProject);
        this.setState({ projectId: projectId, publicationModalVisible, isPublicationAllowedUser, isPublicationAllowed });
        let gr = await gradientReader.createGradientReader([
            {stop: 0.0, color: 'red'},
            {stop: 0.143, color: 'red'},
            {stop: 0.286, color: 'yellow'},
            {stop: 0.428, color: 'lightgreen'},
            {stop: 0.571, color: 'green'},
            {stop: 0.714, color: 'green'},
            {stop: 0.847, color: 'green'},
            {stop: 1, color: 'green'}
        ]);
        this.setState({gradientReader: gr});
        await this.getTheProjectData();
        await this.getResultsFilesListAndIsResultsUploaded();
        await this.getTestsCoverage();
        this.context.setNotOnProject();
        this.context.setOnProjectDetails();

        NavBarUtil.setNavButtonInactive('#lBtn')
        NavBarUtil.setNavButtonActive('#pBtn')

        const customerResponse = await authentication.getAllCustomers();
        if (customerResponse && customerResponse.data && customerResponse.data.success) {
            customerResponse.data.data.forEach(obj => {
                this.setState(prevState => ({
                    customerCompanies: [...prevState.customerCompanies, obj.companyname]
                }));
            })
        } else {
            console.log('Error', customerResponse);
        }

    }
    componentWillUnmount() {
        if (this.state.projectId) {
            localStorage.removeItem(`publicationInProgress_${this.state.projectId}`);
        }
    }
    getProjectId      = () => {
        return JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
    }
    getResultsFilesListAndIsResultsUploaded = async () => {
        let id = this.getProjectId();

        await dataUtil.getResultsFilesList(id,
            (projectId, testList) => {
                // success
                this.setState({
                    resultsFilesList: testList
                });
            },
            (projectId, response) => {
                // error
                this.onError('failed to get project results files data. projectId: ' + projectId);
            });

        dataUtil.isResultsUploaded(id,
            (projectId, isResultsUploaded) => {
                // success
                this.setState({
                    isResultsUploaded: isResultsUploaded
                });
            },
            (projectId, response) => {
                // error
                this.onError('failed to get project tests data. projectId: ' + projectId);
            });

        await this.getTestsCoverage();
    }
    getTestsCoverage = async () => {
        let id = this.getProjectId();

        dataUtil.getTestsCoverage(id,
            (projectId, testCoverage) => {
                this.setState({ testsCoverage: testCoverage });
            },
            (projectId, response) => {
                this.onError('failed to get tests coverage. projectId: ' + projectId);
            });
    }
    getTheProjectData = async () => {
        let id = this.getProjectId();

        await dataUtil.getTestsList(id,
            (projectId, testList) => {
                // success
                this.setState({
                    resultsTestList: testList
                });
            },
            (projectId, response) => {
                // error
                this.onError('failed to get project tests data. projectId: ' + projectId);
            });

        dataUtil.getProjectTagsData(id,
            (projectId, projectTags) => {
                // success
                this.setState({
                    projectTags: projectTags
                });
            },
            (projectId, response) => {
                // error
                this.onError('failed to get project tests data. projectId: ' + projectId);
            });

        dataUtil.getProjectData(id,
            (projectId, projectData) => {
                // success
                const isPublicationAllowedProject = projectData.origin === 'project_portal';
                const { isPublicationAllowedUser } = this.state;
                const isPublicationAllowed = this.state.isPublicationAllowed || (isPublicationAllowedProject && isPublicationAllowedUser);

                const hasTests = projectData && Array.isArray(projectData.tests) && projectData.tests.length;

                let atLeastOneNonVerificationLike = false;
                const verificationProject = hasTests && projectData.tests[0].testtype === 'verification';
                const verificationLikeProject = hasTests && projectData.tests.reduce((res, test) => {
                    let isVerificationLike = false;
                    if (test && test.testtype) {
                        isVerificationLike = !!~VERIFICATION_LIKE_TESTS.indexOf(test.testtype);
                    }
                    atLeastOneNonVerificationLike = atLeastOneNonVerificationLike || !isVerificationLike;
                    return res || isVerificationLike;
                }, false);

                this.setState({
                    projectData: projectData,
                    isPublicationAllowed,
                    verificationProject,
                    verificationLikeProject,
                    atLeastOneNonVerificationLike,
                });
            }, (projectId, response) => {
                // error
                this.onError('failed to get project data. projectId: ' + projectId);
            });

        dataUtil.getFloorPlansData(id,
            (projectId, floorPlansData) => {
                // success
                this.setState({
                    floorPlansData: floorPlansData
                });
            }, (projectId, response) => {
                // error
                this.onError('failed to get project florr plans data. projectId: ' + projectId);
            });

        dataUtil.getPartnersData((partnersData) => {
            // success
            this.setState({
                partners: partnersData
            });
        }, () => {
            // error
            this.onError('failed to get project partners data.');
        });
    }

    handleProjectEdit = () => {
        this.setState({editProject: !this.state.editProject});
    }

    handleProjectPublish = (action = "open") => {
        const key = `publicationInProgress_${this.state.projectId}`;
        if (action === "open") {
            localStorage.setItem(key, 1);
        } else {
            localStorage.removeItem(key);
        }
        this.setState({
            publicationModalVisible: action === "open",
        });
    };

    onError(message, response) {
        if (this.errorHandler !== undefined) {
            this.errorHandler(this.newErrorEvent(message, response));
        }
    }

    newErrorEvent(message, response) {
        return {
            message:  message,
            response: response,
        };
    }
    updatePublishStatusLocally = (status) => {
        const { projectData } = this.state;
        projectData.status = status;
        this.setState({
            projectData: {
                ...projectData,
            }
        })
    }
    handleProjectRemoval = async () => {
        const { projectId } = this.state;
        let errMsg;
        try {
            const result = await projectsAPI.withdrawPublication(projectId);
            if (result && result.data && result.data.success) {
                this.setState({
                    shouldConfirmRemoval: false,
                }, () => this.updatePublishStatusLocally('res_generated'));         
            } else {
                if (result.data.error && result.data.error.message) {
                    errMsg = result.data.error.message;
                    throw new Error();
                }
            }

        } catch (err) {
            this.setState({
                shouldConfirmRemoval: false,
                alertOpen: true,
                alertMsg: errMsg || 'Something went wrong'
            })
        }
    }
    updateProjectStatus = async (status, i = 0) => {
        try {
            const result = await projectsAPI.updateStatus(this.state.projectId, status);
            if (!result?.data?.success) throw new Error();
            return true;
        } catch (err) {
            if (i < 2) {
                return this.updateProjectStatus(status, i + 1);
            } else {
                console.log('Project status update error: ', err);
                return null;
            }
        }
    }
    render() {
        const { projectId, customerCompanies } = this.state;

        if (this.state.editProject) {
            return (
                <Context.Consumer>
                    {context => (
                        <div className="project-details-container">
                            <ProjectDataEdit
                                projectId={projectId}
                                projectData={this.state.projectData}
                                partnersList={this.state.partners}
                                handleProjectEdit={this.handleProjectEdit}
                                fetchUpdatedProject={this.getTheProjectData}
                                customerCompanies={customerCompanies}
                                updateProjectStatus={(status) => this.updateProjectStatus(status)}
                            />
                        </div>
                    )}
                </Context.Consumer>
            )
        }

        return (
            <Context.Consumer>
                {context => (
                    <div className="project-details-container">
                        {!!this.state.alertOpen && (<AlertGeneral open={this.state.alertOpen} alertMsg={this.state.alertMsg} closeFunc={() => this.setState({ alertOpen: false})} severity='error' />)}
                        <ProjectDataView
                            style={{marginBottom: '15px'}}
                            projectId={projectId}
                            projectData={this.state.projectData}
                            handleProjectEdit={this.handleProjectEdit}
                            handleProjectPublish={this.handleProjectPublish}
                            publicationAccess={this.state.isPublicationAllowed}
                            publishDisabled={this.state.publicationModalVisible} 
                            handleProjectRemoval={() => this.setState({ shouldConfirmRemoval: true })}
                        />
                        <ProjectTests
                            projectId={this.state.projectData.id}
                            projectName={this.state.projectData.name}
                            projectTests={this.state.projectData.tests}
                            projectStatus={this.state.projectData.status}
                            projectCompleted={this.state.projectData.testCompleted}
                            projectFromProjectPortal={!!(this.state.projectData.origin && this.state.projectData.origin === 'project_portal')}
                            isResultsUploaded={this.state.isResultsUploaded}
                            testsCoverage={this.state.testsCoverage}
                            fetchUpdatedProject={this.getTheProjectData}
                            updateProjectStatus={(status) => this.updateProjectStatus(status)}
                        />
                        <ProjectTags
                            projectId={this.state.projectData.id}
                            projectTags={this.state.projectTags}
                            fetchUpdatedProject={this.getTheProjectData}
                        />
                        <ProjectDetailsFloorPlan
                            projectId={this.state.projectData.id}
                            floorPlansData={this.state.floorPlansData}
                            projectCompleted={this.state.projectData.testCompleted}
                            fetchUpdatedProject={this.getTheProjectData}
                        />
                        <ProjectResults
                            projectId={projectId}
                            resultsFilesList={this.state.resultsFilesList}
                            fetchUpdatedData={this.getResultsFilesListAndIsResultsUploaded}
                            updateProjectStatus={(status) => this.updateProjectStatus(status)}
                            fetchUpdatedProject={this.getTheProjectData}
                        />
                        {this.state.isResultsUploaded && (
                            <hr className="project-details-hr" />
                        )}
                        <ProjectResultsTable
                            projectId={this.state.projectId}
                            resultsTestList={this.state.resultsTestList}
                            gradientReader={this.state.gradientReader}
                            isResultsUploaded={this.state.isResultsUploaded}
                            verificationProject={this.state.verificationProject}
                            verificationLikeProject={this.state.verificationLikeProject}
                            atLeastOneNonVerificationLike={this.state.atLeastOneNonVerificationLike}
                        />
                        <hr className="project-details-hr" />
                        {this.state.isPublicationAllowed && !!this.state.projectData.id && (
                            <PublishProjectModal
                                visible={this.state.publicationModalVisible}
                                projectData={this.state.projectData}
                                closeModal={() => this.handleProjectPublish("close")}
                                changeProjectStatus={() => this.updatePublishStatusLocally('published')}
                            />
                        )}
                        {this.state.isPublicationAllowed && (
                            <GeneralConfirmationDialog
                                open={this.state.shouldConfirmRemoval}
                                title={"Are you sure you want to remove the project from the Customer Portal?"}
                                onOk={this.handleProjectRemoval}
                                onCancel={() => this.setState({ shouldConfirmRemoval: false })}
                                cancelBtnText={"Cancel"}
                                okBtnText={"Remove"}
                            />
                        )}
                    </div>
                )}
            </Context.Consumer>
        )
    }
}

const gradientReader = {
    createGradientReader: (colorStops) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const gr = ctx.createLinearGradient(0, 0, 101, 0);
        canvas.width = 101;
        canvas.height = 1;

        for (const { stop, color } of colorStops) {
            gr.addColorStop(stop, color);
        }

        ctx.fillStyle = gr;
        ctx.fillRect(0, 0, 101, 1);

        return {
            getColor: (pst) => ctx.getImageData(pst|0, 0, 1, 1).data
        };
    }
}

const dataUtil = {
    getTestsCoverage: (projectId, successHandler, errorHandler) => {
        if (projectId === null) {
            if (dataUtil.isValidHandler(errorHandler)) {
                errorHandler(projectId);
            }

            return;
        }
        results.getTestsCoverage(projectId)
            .then(response => {
                if (dataUtil.isValidResponse(response)) {
                    if (dataUtil.isValidHandler(successHandler)) {
                        successHandler(projectId, response.data.data);
                    }
                } else {
                    if (dataUtil.isValidHandler(errorHandler)) {
                        errorHandler(projectId, response.data.data);
                    }
                }
            });
    },
    getResultsFilesList: (projectId, successHandler, errorHandler) => {
        if (projectId === null) {
            if (dataUtil.isValidHandler(errorHandler)) {
                errorHandler(projectId);
            }

            return;
        }
        results.getResultsFilesList(projectId)
            .then(response => {
                if (dataUtil.isValidResponse(response)) {
                    if (dataUtil.isValidHandler(successHandler)) {
                        successHandler(projectId, response.data.data);
                    }
                } else {
                    if (dataUtil.isValidHandler(errorHandler)) {
                        errorHandler(projectId, response.data.data);
                    }
                }
            });
    },
    isResultsUploaded: (projectId, successHandler, errorHandler) => {
        if (projectId === null) {
            if (dataUtil.isValidHandler(errorHandler)) {
                errorHandler(projectId);
            }

            return;
        }
        results.isResultsUploaded(projectId)
            .then(response => {
                if (dataUtil.isValidResponse(response)) {
                    if (dataUtil.isValidHandler(successHandler)) {
                        successHandler(projectId, response.data.data);
                    }
                } else {
                    if (dataUtil.isValidHandler(errorHandler)) {
                        errorHandler(projectId, response.data.data);
                    }
                }
            });
    },
    getTestsList: (projectId, successHandler, errorHandler) => {
        if (projectId === null) {
            if (dataUtil.isValidHandler(errorHandler)) {
                errorHandler(projectId);
            }

            return;
        }
        results.getTestsList(projectId)
            .then(response => {
                if (dataUtil.isValidResponse(response)) {
                    if (dataUtil.isValidHandler(successHandler)) {
                        successHandler(projectId, response.data.data);
                    }
                } else {
                    if (dataUtil.isValidHandler(errorHandler)) {
                        errorHandler(projectId, response.data.data);
                    }
                }
            });
    },
    getProjectTagsData: (projectId, successHandler, errorHandler) => {
        if (projectId === null) {
            if (dataUtil.isValidHandler(errorHandler)) {
                errorHandler(projectId);
            }

            return;
        }

        originpoints.getAllOriginpoints(null, projectId)
            .then(response => {
                if (dataUtil.isValidResponse(response)) {
                    if (dataUtil.isValidHandler(successHandler)) {
                        successHandler(projectId, response.data.data);
                    }
                } else {
                    if (dataUtil.isValidHandler(errorHandler)) {
                        errorHandler(projectId, response.data.data);
                    }
                }
            });
    },
    getProjectData:     (projectId, successHandler, errorHandler) => {
        if (projectId === null) {
            if (dataUtil.isValidHandler(errorHandler)) {
                errorHandler(projectId);
            }

            return;
        }

        projectsAPI.getOneProject(projectId)
            .then(async (response) => {
                const { tests: _tests } = response.data.data;
                const displayNumRes = await testsAPI.getDisplayNumbersProject(projectId);
                if (displayNumRes && displayNumRes.data && displayNumRes.data.success) {
                    const _numbers = displayNumRes.data.data;
                    for (let test of _tests) {
                        test.displayNumber = _numbers[test.testid] || '';
                    }
                }

                if (dataUtil.isValidResponse(response)) {
                    if (dataUtil.isValidHandler(successHandler)) {
                        successHandler(projectId, response.data.data);
                    }
                } else {
                    if (dataUtil.isValidHandler(errorHandler)) {
                        errorHandler(projectId, response.data.data);
                    }
                }
            });
    },
    getFloorPlansData:  (projectId, successHandler, errorHandler) => {
        if (projectId === null) {
            if (dataUtil.isValidHandler(errorHandler)) {
                errorHandler(projectId);
            }

            return;
        }

        floorPlans.getFloorPlans(projectId)
            .then(response => {
                if (dataUtil.isValidResponse(response)) {
                    if (dataUtil.isValidHandler(successHandler)) {
                        successHandler(projectId, response.data.data);
                    }
                } else {
                    if (dataUtil.isValidHandler(errorHandler)) {
                        errorHandler(projectId, response.data.data);
                    }
                }
            });
    },
    getPartnersData:    (successHandler, errorHandler) => {
        authentication.getPartners()
            .then(response => {
                if (dataUtil.isValidResponse(response)) {
                    if (dataUtil.isValidHandler(successHandler)) {
                        let partnersList = ['Safetraces'];
                        response.data.data.forEach(obj => {
                            partnersList.push(obj.companyname);
                        });
                        successHandler(partnersList);
                    }
                } else {
                    if (dataUtil.isValidHandler(errorHandler)) {
                        errorHandler(response);
                    }
                }
            });
    },
    isValidResponse:    (response) => {
        if (response && response.status === 200 &&
            response.data !== null &&
            response.data.success === true &&
            response.data.data !== undefined) {
            return true;
        }

        return false;
    },
    isValidHandler:     (handler) => {
        if (handler !== undefined && typeof handler === 'function') {
            return true;
        }

        return false;
    }
}

ProjectDetails.contextType = Context;
export default withStyles(styles, {withTheme: true})(ProjectDetails);
