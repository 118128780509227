import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Context } from '../../context/Context';

class SelectTestCompleted extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: null
        };
    }

    componentDidMount() {
        const { testExecuted } = this.props;
        if(this.context.state.companyName === 'Safetraces' && this.context.state.accessLevel === 'Admin') {
            this.setState({ disabled: false });
        } else if(testExecuted === true || this.context.state.accessLevel === 'Viewer') {
            this.setState({ disabled: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps !== this.props) {
            const { testExecuted } = this.props;
            if(this.context.state.companyName === 'Safetraces' && this.context.state.accessLevel === 'Admin') {
                this.setState({ disabled: false });
            } else if(testExecuted === true || this.context.state.accessLevel === 'Viewer') {
                this.setState({ disabled: true });
            }
        }
    }

    handleSelectChange = e => {
        const { onSelectChange, test } = this.props;
        const { value } = e.target;
        const params = {
            event: e,
            test: test,
            value: value
        };

        onSelectChange(params);
    }
    render() {
        const { testExecuted, test } = this.props;
        const { disabled } = this.state;
        return (
            <FormControl>
                <Select
                    disableUnderline
                    style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }}
                    name={test.testname}
                    value={testExecuted}
                    onChange={e => this.handleSelectChange(e)}
                    disabled={disabled}
                >
                    <MenuItem style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={true}>Yes</MenuItem>
                    <MenuItem style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={false}>No</MenuItem>
                </Select>
            </FormControl>
        )
    }
  }
SelectTestCompleted.contextType = Context;
export default SelectTestCompleted;