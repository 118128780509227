import axios from 'axios';
import config from '../config.js';

const BASEURL = config.baseURL + '/floorplans';

const floorPlans = {
  addFloorPlan : async(projectId, data) => {
    const response = await axios.post (BASEURL + '?projectId=' + projectId, data);
    return response;
  },
  getFloorPlans: async(projectId) => {
    const response = await axios.get (BASEURL + '?projectId=' + projectId);
    return response;
  },
  getFloorPlansForTest: async(testId) => {
    const response = await axios.get (BASEURL + '?testId=' + testId);
    return response;
  },
  getPresignedURL: async(data) => {
    const heatmapVersionAddition = data.heatmapVersion ? '&heatmapVersion=1' : '';
    const response = await axios.get (BASEURL + '/getPresignedURL?projectId=' + data.projectId + '&' +'filename=' + data.filename + '&' +'filetype=' + data.filetype + '&' + 'action=' + data.action + heatmapVersionAddition, data);
    return response;
  },
  uploadFloorPlan: async(url, file, options) => {
    const response = await axios.put (url , file, options);
    return response;
  },
  updateFloorPlan: async (floorPlanId, data) => {
    return await axios.put (BASEURL + '/' +  floorPlanId, data);
  },
  downloadFloorPlan: async(url) => {
    const response = await axios.get (url , {responseType: 'blob'});
    return response;
  },
  deleteFloorPlan: async(floorPlanId) => {
    const response = await axios.delete (BASEURL + '/' + floorPlanId);
    return response;
  }
};


export default floorPlans;