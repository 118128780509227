import React from '../../../node_modules/react';
import samplePlans from '../../api/sampleplans';
import SamplePlanList from '../../components/SamplePlanList/SamplePlanList.js';
import { CSVLink } from 'react-csv';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import { Context } from '../../context/Context';
import './SamplePlan.css';

class SamplePlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {segmentId: null, SamplePlan: []};
        this.handleTestPlanClick = this.handleTestPlanClick.bind(this);
        this.testplanNavButton = document.querySelector('#tpBtn');
    }

    async componentDidMount() {
        //let testplanNavButton = document.querySelector('#tpBtn');
        if(this.testplanNavButton) {
            this.testplanNavButton.classList.add('selected');
        }

        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        const { match: { params } } = this.props;
        let segmentId = params.segmentId;

        this.setState({
            projectId: projectId,
            segmentId: segmentId
        }, async () => {
            const responseUpdate = await samplePlans.updateSamplePlan(projectId);
            if (!responseUpdate?.data?.success) {
                return null;
            }
            const response = await samplePlans.getSamplePlan(segmentId);
            this.setState ({SamplePlan: response.data.data});
        });
    };
    
    componentWillUnmount() {
        if(this.testplanNavButton) {
            this.testplanNavButton.classList.remove('selected');
        }
    }

    handleTestPlanClick(event) {
        this.props.history.push('/testplan');
    }
    
    render() {
        const { projectId } = this.state;
        return (
            <Context.Consumer>
                {context => (
                    <div style={{ marginTop: '100px' }} className="sample-plan-container">
                        <div>
                            <Typography variant="h5" style={{fontFamily: "Gotham", marginTop: "30px"}} color="textPrimary" align="center">{`(Project Id ${projectId}) ${context.state.projectName}: ${context.state.testName} - Sample Plan`}</Typography>
                        </div>
                        <div className="sample-plan-btn-container">
                            <CSVLink 
                                data={this.state.SamplePlan} 
                                filename={`${context.state.projectName}-${projectId}-${context.state.testName}-sample-plan.csv`}
                                style={{margin: '0 10px 0 10px', textDecoration: "none"}}>
                                <Button size="small" variant="outlined" style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", fontFamily: "Gotham"}} >Download</Button>
                            </CSVLink>
                        </div>
                        <div>
                            <SamplePlanList SamplePlan={this.state.SamplePlan} history={this.props.history}/>
                        </div>
                    </div>
                )}
            </Context.Consumer>
        )
    }
}

export default SamplePlan;