import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import { useForm } from "react-hook-form";

const MainInfoForm = React.forwardRef((props, ref) => {
  const { portfolioField, submitHandler, data, fieldsRules } = props;
  const { register, handleSubmit, errors } = useForm();

  const inputStyle = { fontFamily: "Gotham" };

  const isPositiveNumber = (n) => parseInt(n, 10) > 0 || !n;

  return (
    <React.Fragment>
      <Fade in={true}>
        <form ref={ref} onSubmit={handleSubmit(submitHandler)} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {portfolioField && (
                <TextField
                  fullWidth
                  required={fieldsRules.portfolio_name}
                  inputRef={register({ required: fieldsRules.portfolio_name })}
                  id="portfolio_name"
                  label="Portfolio name"
                  name="portfolio_name"
                  autoComplete="portfolio_name"
                  defaultValue={data.portfolio_name}
                  style={inputStyle}
                  error={!!errors.portfolio_name}
                  helperText={
                    errors.portfolio_name && "Portfolio name required"
                  }
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required={fieldsRules.building_name}
                inputRef={register({ required: fieldsRules.building_name })}
                id="building_name"
                label="Building name"
                name="building_name"
                autoComplete="building_name"
                defaultValue={data.building_name}
                style={inputStyle}
                error={!!errors.building_name}
                helperText={errors.building_name && "Building name required"}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required={fieldsRules.floor_amount}
                inputRef={register({
                  required: fieldsRules.floor_amount,
                  valueAsNumber: true,
                  validate: { positive: isPositiveNumber },
                })}
                id="floor_amount"
                label="Floors amount"
                name="floor_amount"
                autoComplete="floor_amount"
                defaultValue={data.floor_amount}
                style={inputStyle}
                error={!!errors.floor_amount}
                helperText={
                  errors.floor_amount
                    ? errors.floor_amount.type === "positive"
                      ? "Enter a number"
                      : "Floors amount required"
                    : null
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required={fieldsRules.square_fts}
                inputRef={register({
                  required: fieldsRules.square_fts,
                  valueAsNumber: true,
                  validate: { positive: isPositiveNumber },
                })}
                id="square_fts"
                label="Square (ft)"
                name="square_fts"
                autoComplete="square_fts"
                style={inputStyle}
                defaultValue={data.square_fts}
                error={!!errors.square_fts}
                helperText={
                  errors.square_fts
                    ? errors.square_fts.type === "positive"
                      ? "Enter a number"
                      : "Square required"
                    : null
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required={fieldsRules.floor_square_fts}
                inputRef={register({
                  required: fieldsRules.floor_square_fts,
                  valueAsNumber: true,
                  validate: { positive: isPositiveNumber },
                })}
                id="floor_square_fts"
                label="Floor square (ft)"
                name="floor_square_fts"
                autoComplete="floor_square_fts"
                style={inputStyle}
                defaultValue={data.floor_square_fts}
                error={!!errors.floor_square_fts}
                helperText={
                  errors.floor_square_fts
                    ? errors.floor_square_fts.type === "positive"
                      ? "Enter a number"
                      : "Floor square required"
                    : null
                }
              />
            </Grid>
          </Grid>
        </form>
      </Fade>
    </React.Fragment>
  );
});

export default MainInfoForm;