import React, { Component } from 'react';
import { Context } from '../../context/Context';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { CustomCheckbox } from '../../components/CustomCheckbox/CustomCheckbox';
import COCChecklistTable from '../../components/COCChecklistTable/COCChecklistTable';
import { withStyles } from "@material-ui/core/styles";
import logo from '../../assets/SafeTraces_Logo.Horiz.png';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReactToPrint from 'react-to-print';
import { cocHeader } from '../../data/COCChecklistData';
import samplepoints from '../../api/samplepoints';
import projects from '../../api/projects';
import './COCChecklistView.css';

const styles = theme => ({
    cell: {
        fontFamily: 'Gotham',
        fontSize: '16px'
    }
});

class COCChecklistView extends Component {

    state = {
        projectId: null, 
        totalNumSamples: null,
        projectName: null,
        address: null
    };

    cocChecklistContainerRef = React.createRef();

    async componentDidMount() {
        let checklistsNavButton = document.querySelector('#clBtn');
        if(checklistsNavButton) {
            checklistsNavButton.classList.add('selected');
        }
        let projectId = localStorage.getItem("projectId");
        this.setState({ projectId: projectId });
        const oneProjectResponse = await projects.getOneProject(projectId);

        this.setState({ 
            projectName: oneProjectResponse.data.data.name, 
            address: oneProjectResponse.data.data.address,
        }, () => {
            //console.log('QCChecklistView projectName: ', this.state.projectName)
            //console.log('QCChecklistView address: ', this.state.address)
        });
        const spResponse = await samplepoints.getAllSamplepoints(projectId);
        if(spResponse.data.data && spResponse.data.data.length > 0) {
            this.setState({ totalNumSamples: spResponse.data.data.length });
        }
    };

    componentDidUpdate() {
        let checklistsNavButton = document.querySelector('#clBtn');
        if(checklistsNavButton) {
            checklistsNavButton.classList.add('selected');
        }
    }

    componentWillUnmount() {
        let checklistsNavButton = document.querySelector('#clBtn');
        if(checklistsNavButton) {
            checklistsNavButton.classList.remove('selected');
        }
    }

    render() {
        const { address, projectId } = this.state;
        const { classes } = this.props;
        return (
            <Context.Consumer>
                {context => (
                    <main style={{ marginTop: '100px', width: '75%' }} className="coc-checklist-container">
                        <section className="coc-checklist-container-header-section">
                            <div style={{ marginLeft: '30%' }}>
                                <Typography variant="h5" color="textPrimary" align="center" style={{fontFamily: "Gotham", marginTop: "30px"}}>{`(Project Id ${projectId}) ${context.state.projectName}: ${context.state.testName} - Chain of Custody Checklist`}</Typography>
                            </div>
                            <div className="coc-checklist-container-header-section-btn">
                                <ReactToPrint
                                    trigger={() => {
                                        return (
                                            <div className="labels-flex-child">
                                                <Button size="small" variant="outlined" style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", marginLeft: "5px", fontFamily: "Gotham"}}>Print</Button>  
                                            </div>
                                        )                     
                                    }}
                                    content={() => this.cocChecklistContainerRef.current}
                                />
                            </div>
                        </section>
                        <section style={{ marginLeft: '250px', width: '100%' }} ref={this.cocChecklistContainerRef} className="coc-body-container">
                            <section style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ marginBottom: '20px' }}>
                                    <img src={logo} alt="logo" className="coc-checklist-header-logo" />
                                </div>
                                <div className="coc-header"><b>{`Chain of Custody Check List`}</b></div>
                            </section>
                            <TableContainer component={Paper}>
                                <div>
                                    <Table aria-label="coc-table-header">
                                        <TableHead>
                                            <TableRow style={{ display: 'flex', flexDirection: 'row' }} >
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }} className={classes.cell}>Prepared By:</TableCell>
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }} className={classes.cell}>Program: veriDART</TableCell>
                                            </TableRow>
                                            <TableRow style={{ display: 'flex', flexDirection: 'row' }}>
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }} className={classes.cell}>Document Number:</TableCell>
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }} className={classes.cell}>Number of Pages:</TableCell>
                                            </TableRow >
                                            <TableRow style={{ display: 'flex', flexDirection: 'row' }}>
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }} className={classes.cell}>Effective Date:</TableCell>
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }} className={classes.cell}>Version: 1</TableCell>
                                            </TableRow>
                                            <TableRow style={{ display: 'flex', flexDirection: 'row' }}>
                                            <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }} className={classes.cell}>{`Ship To: ${address}`}</TableCell>
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }}  className={classes.cell}>Shipped By:
UPS, Fed-Ex, USPS, Other:</TableCell>
                                            </TableRow>
                                            <TableRow style={{ display: 'flex', flexDirection: 'row' }}>
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }} className={classes.cell}>Phone:
925-326-1200</TableCell>
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }} className={classes.cell}>Shipping/Tracking Number:</TableCell>
                                            </TableRow>
                                            <TableRow style={{ display: 'flex', flexDirection: 'row' }} >
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }} className={classes.cell}>Lab Contact:</TableCell>
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }} className={classes.cell}>Email:</TableCell>
                                            </TableRow>
                                            <TableRow style={{ display: 'flex', flexDirection: 'row' }}>
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px', display: 'flex', flexDirection: 'row' }} className={classes.cell}>
                                                    <div style={{ marginTop: '10px' }}>{`QC Checklist Attached:`}</div>
                                                    <div className='coc-checkbox-container-cell'>
                                                        <div className="text">Yes</div>
                                                        <div>
                                                            <CustomCheckbox />
                                                        </div>
                                                    </div>
                                                    <div className='coc-checkbox-container-cell'>
                                                        <div className="text">No</div>
                                                        <div>
                                                            <CustomCheckbox />
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }} className={classes.cell}>
                                                    <div style={{ marginTop: '10px' }} className="cell-last">{`Sample Information: Attach Completed QC Checklist`}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow style={{ display: 'flex', flexDirection: 'row' }} >
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }} className={classes.cell}>{`Project Id: ${projectId}`}</TableCell>
                                                <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50%', padding: '3px' }} className={classes.cell}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </div>
                            </TableContainer>
                            <section>
                                <COCChecklistTable />
                            </section>
                        </section>
                    </main>
                )}
            </Context.Consumer>
        );
    }
}
export default withStyles(styles, { withTheme: true })(COCChecklistView);