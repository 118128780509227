import React, { useState, useContext } from 'react';
import {Context} from "../../context/Context";
import {AlertTestCompleted} from "../../components/Alert/AlertTestCompleted";
import Typography from "@material-ui/core/Typography";
import './ProjectDetails.css';
import Dropzone from "react-dropzone";
import results from "../../api/results";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import TableContainer from "@material-ui/core/TableContainer";

const useRowStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(7, 5, 7),
        textAlign: 'center',
        fontFamily: "Gotham"
    },
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'left',
        padding: '10px !important'
    },
    bodyCell: {
        textAlign: 'left',
        padding: '10px !important'
    },
}));

export const ProjectResults = ({ projectId, resultsFilesList, fetchUpdatedData, updateProjectStatus, fetchUpdatedProject }) => {
    const { state } = useContext(Context);
    const classes = useRowStyles();
    const [ errorMsg, setErrorMsg ] = useState('');
    const [ success, setSuccess ] = useState(false);
    const [ confirm, setConfirm ] = useState(false);
    const [ confirmfiles, setConfirmfiles] = useState(false);
    const [ resultsCompletedAlert, setResultsCompletedAlert ] = useState(false);
    const [ resultsFiles, setResultsFiles ] = useState([]);
    const [ openUploadResultsConfirmationModal, setOpenUploadResultsConfirmationModal ] = useState(false);
    const [ openDeleteResultsFileConfirmationModal, setOpenDeleteResultsFileConfirmationModal ] = useState(false);
    const [ deleteFileName, setDeleteFileName ] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    const handleModalOpen = () => setOpenUploadResultsConfirmationModal(!openUploadResultsConfirmationModal);
    const handleModalClose = () => {
        setOpenUploadResultsConfirmationModal(!openUploadResultsConfirmationModal);
        setConfirm(false);
        setConfirmfiles(false);
        setResultsFiles([]);
    }
    const handleConfirm = async () => {
        setIsUploading(true);
        try {
            const result = await results.uploadResults(projectId, { confirm: confirm, confirmfiles: confirmfiles, files: resultsFiles });
            let projectUpdated;
            if (result.data.success) {
                if (Array.isArray(resultsFilesList) && !resultsFilesList.length) {
                    projectUpdated = await updateProjectStatus('res_generated');
                }
                handleModalClose();
                setSuccess(true);
                setErrorMsg('Successfully saved');
                setResultsCompletedAlert(true);
                fetchUpdatedData();
                setIsUploading(false);
                if (projectUpdated) await fetchUpdatedProject();
            } else {
                if (result.data.error.message) {
                    if (result.data.error.message === 'confirm') {
                        setConfirm(true);
                    } else if (result.data.error.message === 'confirmfiles') {
                        setConfirmfiles(true);
                    } else {
                        handleModalClose();
                        setSuccess(false);
                        setErrorMsg(result.data.error.message);
                        setResultsCompletedAlert(true);
                    }
                }
                setIsUploading(false);
            }
        } catch (e) {
            setIsUploading(false);
        }
    }

    const handleModalDeleteOpen = async (fileName) => {
        setDeleteFileName(fileName);
        setOpenDeleteResultsFileConfirmationModal(true);
    }
    const handleDeleteModalClose = async () => {
        setDeleteFileName('');
        setOpenDeleteResultsFileConfirmationModal(false);
    }
    const handleDeleteConfirm = async () => {
        const result = await results.deleteResultsFile({
            projectId: projectId,
            filename: deleteFileName,
        });
        let projectUpdated;
        if (result.data.success) {
            if (Array.isArray(resultsFilesList) && resultsFilesList.length === 1) {
                const isExecuted = await updateProjectStatus('executed');
                if (isExecuted) {
                    projectUpdated = isExecuted;
                } else {
                    projectUpdated = await updateProjectStatus('in_progress')
                }
            }
            await handleDeleteModalClose();
            setSuccess(true);
            setErrorMsg('Successfully deleted');
            setResultsCompletedAlert(true);
            fetchUpdatedData();
            if (projectUpdated) await fetchUpdatedProject();
        } else {
            if (result.data.error.message) {
                await handleDeleteModalClose();
                setSuccess(false);
                setErrorMsg(result.data.error.message);
                setResultsCompletedAlert(true);
            }
        }
    }

    const handleDownload = async (filename) => {
        const result = await results.getPresignedURL({
            projectId: projectId,
            filename: filename,
        });
        
        let download = document.createElement('a');
        download.setAttribute('download', 'download');
        download.href = result.data.preSignedURL;
        document.body.appendChild(download);
        download.click();
        download.remove();
    }

    const handleClosingAlert = () => setResultsCompletedAlert(!resultsCompletedAlert);

    const uploadFile = async (files) => {
        for (let file of files) {
            let fileReader = new FileReader();
            fileReader.onload = (e) => {
                resultsFiles.push({filename: file.name, content: e.target.result});
            };
            fileReader.readAsDataURL(file);
        }

        handleModalOpen();
    }

    const getUploadModalTitle = () => {
        let title = 'Are you sure you want to upload PCR test results?';
        if (confirm && !confirmfiles) {
            title = 'Are you sure that old values will be replaced?';
        }
        if (confirmfiles) {
            title = 'Are you sure that old files will be replaced?';
        }

        return title;
    };

    return (
        <div>
            {state.accessLevel === 'Admin' && state.roleName === 'Safetracer' && (
                <div>
                    <div className="grid-wrapper">
                        <div>
                            <Typography variant="h5" color="textPrimary">
                                {/* Upload PCR results */}
                                Lab Results
                                {state.accessLevel === 'Admin' && (
                                    <Dropzone onDrop={(acceptedFiles) => uploadFile(acceptedFiles)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section style={{
                                                border: '1px solid #82BC00',
                                                textAlign: 'center',
                                                height: '35px',
                                                width: '360px',
                                                fontWeight: 'bold',
                                                lineHeight: '35px',
                                                fontSize: '16px',
                                                fontFamily: 'Gotham',
                                                float: 'right',
                                            }}>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <p>Drag and drop file here, or click to select a file</p>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                )}
                            </Typography>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className="modal"
                                open={openUploadResultsConfirmationModal}
                                onClose={() => handleModalClose()}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}>
                                <div>
                                    <Fade in={openUploadResultsConfirmationModal}>
                                        <div className={classes.paper}>
                                            <h2 id="transition-modal-title">{getUploadModalTitle()}</h2>
                                            <div>
                                                <Button
                                                    onClick={() => handleConfirm()}
                                                    size="small"
                                                    className="modal-button"
                                                    disabled={isUploading}>
                                                    Yes
                                                </Button>
                                                <Button
                                                    onClick={() => handleModalClose()}
                                                    size="small"
                                                    disabled={isUploading}
                                                    className="modal-button">
                                                    No
                                                </Button>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <div className="grid-wrapper">
                        {resultsFilesList.length > 0 && (
                            <div style={{ marginTop: '15px' }}>
                                {/* <Typography variant="h5" color="textPrimary">
                                    PCR results files
                                </Typography> */}
                                <TableContainer style={{ marginTop: '15px', boxShadow: 'none' }} component={Paper}>
                                    <Table aria-label="project-list-table">
                                        <TableHead className={classes.tableHeader}>
                                            <TableRow>
                                                <TableCell className={classes.headerCell}></TableCell>
                                                <TableCell className={classes.headerCell}>File name</TableCell>
                                                <TableCell className={classes.headerCell}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {resultsFilesList.map((file, i) => (
                                            <TableBody key={`key-${i}`}>
                                                <TableRow hover={true}>
                                                    <TableCell className={classes.bodyCell}>
                                                        <IconButton onClick={() => handleModalDeleteOpen(file.filename)} aria-label="delete" className="delete-button">
                                                            <DeleteForeverIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell className={classes.bodyCell}>
                                                        {file.filename}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            size="small"
                                                            variant="outlined"
                                                            className="add-edit-button"
                                                            onClick={() => handleDownload(file.filename)}
                                                            style={{margin: '0'}}
                                                        >
                                                            Download
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ))}
                                    </Table>
                                </TableContainer>
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    className="modal"
                                    open={openDeleteResultsFileConfirmationModal}
                                    onClose={() => handleDeleteModalClose()}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}>
                                    <div>
                                        <Fade in={openDeleteResultsFileConfirmationModal}>
                                            <div className={classes.paper}>
                                                <h2 id="transition-modal-title">Are you sure you want to delete PCR test results?</h2>
                                                <div>
                                                    <Button
                                                        onClick={() => handleDeleteConfirm()}
                                                        size="small"
                                                        className="modal-button">
                                                        Yes
                                                    </Button>
                                                    <Button
                                                        onClick={() => handleDeleteModalClose()}
                                                        size="small"
                                                        className="modal-button">
                                                        No
                                                    </Button>
                                                </div>
                                            </div>
                                        </Fade>
                                    </div>
                                </Modal>
                            </div>
                        )}
                    </div>
                </div>)}
            {!!resultsCompletedAlert && (<AlertTestCompleted flag={resultsCompletedAlert} msg={errorMsg} closeFunc={handleClosingAlert} updateSuccessful={success} />)}
        </div>
    );
};