
import axios from 'axios';
import config from '../config.js';

const BASEURL = config.baseURL + '/scenarios';

const scenarios = {
  createScenarios : async(data) => {
    const response = await axios.post (BASEURL + '/create', data);
    return response;
  },
  updateScenarios : async(scenarioId, data) => {
    const response = await axios.put (BASEURL + '/' + scenarioId, data);
    return response; 
  },
  getScenarios : async(segmentId) => {
    const response = await axios.get (BASEURL + '?segmentId=' + segmentId);
    return response;
  }
};


export default scenarios;