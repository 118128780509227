import React from '../../../node_modules/react';
import samplepoints from '../../api/samplepoints';
import SamplePointsList from '../../components/SamplePointsList/SamplePointsList.js';
import { CSVLink } from 'react-csv';
import { Context } from '../../context/Context';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import './SamplePoints.css';

class SamplePoints extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            segmentId: null, 
            samplePoints: []
        };
    }

    componentDidMount() {
        //logic to handle highlighting navbar tab
        let segmentsNavButton = document.querySelector('#sBtn')
        if(segmentsNavButton) {
            segmentsNavButton.classList.add('selected');
        }

        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        let testId = JSON.parse(localStorage.getItem("testId")) ? JSON.parse(localStorage.getItem("testId")) : null;
        
        this.setState({
            projectId: projectId,
            testId: testId
        }, async () => {
            const response = await samplepoints.getAllSamplepoints(this.state.testId, this.state.projectId);
            this.setState ({ samplePoints: response.data.data });
        });
    };

    handleSegmentsClick = (event) => {
        this.props.history.push('/' + this.state.projectId + '/segments');  
    }

    componentWillUnmount() {
        let segmentsNavButton = document.querySelector('#sBtn')
        if(segmentsNavButton) {
            segmentsNavButton.classList.remove('selected');
        }
    }

    render() {
        const { projectId, testId } = this.state;
        return (
            <Context.Consumer>
                {context => (
                    <div className="sample-points-container">
                        <div>
                            <Typography variant="h5" color="textPrimary" style={{fontFamily: "Gotham", marginTop: "30px"}} align="center">{`(Project Id ${projectId}) ${context.state.projectName}: ${context.state.testName} - Sample Points`}</Typography>
                        </div>
                        <div className="sample-points-btn-container">
                            <CSVLink 
                                data={this.state.samplePoints} 
                                filename={`Test ${testId} PID ${projectId} Sample Points Coordinates.csv`}
                                style={{margin: '0 10px 0 10px', textDecoration: "none"}}>
                                    <Button size="small" variant="outlined" style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", fontFamily: "Gotham"}} >Download</Button>
                            </CSVLink>
                        </div>
                        <div>
                            <SamplePointsList samplePoints={this.state.samplePoints} history={this.props.history}/>
                        </div>
                    </div>
                )}
            </Context.Consumer>
        )
    }
}

export default SamplePoints;





















