import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Context } from '../../context/Context';
import { NestedTableOP } from './NestedTableOP';

const useRowStyles = makeStyles({
    cell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        textAlign: 'center',
        borderRight: 'none',
        borderLeft: 'none',
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'center',
        padding: '10px !important'
    },
    tableRow: {
        cursor: 'pointer',
    }
});

export const NestedTableScenario = ({
        scenario,
        ops,
        index,
        testCompleted,
        onTextChangeFunc,
        onKeyDownTextUpdate,
        handleTagLotNumberChange,
        onKeyDownLotNumberExpirationDateUpdate,
        onChangeFunc,
        segments,
        scenarioDescription,
        isViewer,
        tagLotNumbers,
        expirationDates
    }) => {

    let show = true;
    const classes = useRowStyles();
    const [ open, setOpen ] = useState(false);

    const handleCollapse = () => {
        setOpen(!open)
    }

    useEffect( () => {},[scenarioDescription]);

    if(scenarioDescription) {
        return (
            <>
                <TableRow className={!!show ? null : classes.tableRow} hover={!show} key={`key=${index}`}>
                    {!!show ? (
                        <>
                            <TableCell colSpan={2} className={classes.cell}>
                                <Tooltip title="Origin Point" placement="top" aria-label="origin-point">
                                    <IconButton aria-label="expand row" size="small" onClick={() => handleCollapse()}>
                                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </>
                    ) : (
                        <TableCell className={classes.cell}></TableCell>
                    )}
                    <TableCell colSpan={2} className={classes.cell}>
                        {scenario.scenarioname}
                    </TableCell>
                    <TableCell className={classes.cell} colSpan={3}>
                        <form onSubmit={e => onKeyDownTextUpdate(e, 'scenarioDescription', scenario.scenarioid)} noValidate autoComplete="off">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <TextField
                                    type="text"
                                    multiline
                                    inputProps={{
                                        style: {
                                            fontFamily: 'Gotham',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            width: '70%',
                                            height: 'auto'
                                        }
                                    }}
                                    rows={3}
                                    title={Object.keys(scenarioDescription).length === 0 ? '' : scenarioDescription[index]}
                                    InputProps={{ disableUnderline: true }}
                                    value={Object.keys(scenarioDescription).length === 0 ? '' : scenarioDescription[index]}
                                    disabled={testCompleted || isViewer}
                                    onChange={e => onTextChangeFunc(e, index, 'scenarioDescription')}
                                />
                                <Tooltip title="Submit Description" placement="top" aria-label="origin-points">
                                    <IconButton
                                        type="submit"
                                        style={{ position: 'relative', right: '40px' }}
                                        disabled={testCompleted || isViewer}
                                    >
                                        <CloudUploadIcon style={{color: "rgba(0, 0, 0, 0.54)"}} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </form>
                    </TableCell>
                </TableRow>
                <TableRow >
                    <TableCell style={{ borderBottom: 'none', border: 'none' }} className={classes.cell}></TableCell>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={7}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table aria-label="ops">
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: '#F3F3F3' }}>
                                            <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}></TableCell>
                                            <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}></TableCell>
                                            <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}></TableCell>
                                            <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}></TableCell>
                                            <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}></TableCell>
                                            <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}>OP Name</TableCell>
                                            <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}>Tag*</TableCell>
                                            <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}>Tag Lot Number</TableCell>
                                            <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}>Expiration Date</TableCell>
                                        </TableRow>
                                        <NestedTableOP
                                            index={index}
                                            segments={segments}
                                            scenario={scenario}
                                            isViewer={isViewer}
                                            testCompleted={testCompleted}
                                            tagLotNumbers={tagLotNumbers}
                                            expirationDates={expirationDates}
                                            onChangeFunc={onChangeFunc}
                                            handleTagLotNumberChange={handleTagLotNumberChange}
                                            onKeyDownLotNumberExpirationDateUpdate={onKeyDownLotNumberExpirationDateUpdate}
                                            ops={ops} />
                                    </TableHead>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    } else {
        return null;
    }

}
