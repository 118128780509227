import React, { useState, useContext } from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Modal                from "@material-ui/core/Modal";
import Backdrop             from "@material-ui/core/Backdrop";
import Fade                 from "@material-ui/core/Fade";
import Button               from "@material-ui/core/Button";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import TableContainer       from "@material-ui/core/TableContainer";
import {AlertTestCompleted} from "../../components/Alert/AlertTestCompleted";
import {makeStyles}         from "@material-ui/core/styles";
import Typography           from "@material-ui/core/Typography";
import './ProjectDetails.css';
import results from "../../api/results";
import floorPlans from "../../api/floorPlans";
import axios from 'axios';
import {Context} from "../../context/Context";
import projects from "../../api/projects";

const useRowStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(7, 5, 7),
        textAlign: 'center',
        fontFamily: "Gotham"
    },
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'left',
        padding: '10px !important'
    },
    bodyCell: {
        textAlign: 'left',
        padding: '10px !important'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

export const ProjectResultsTable = ({ projectId, resultsTestList, gradientReader, isResultsUploaded, verificationProject, verificationLikeProject, atLeastOneNonVerificationLike }) => {
    const classes = useRowStyles();
    const { state } = useContext(Context);
    const newAxiosInstance = axios.create();
    const [ openResultsModal, setOpenResultsModal ] = useState(false);
    const [ resultsData, setResultsData ] = useState({op: [], sp: []});
    const [ activeFPWidth, setActiveFPWidth ] = useState(null);
    const [ activeFPHeight, setActiveFPHeight ] = useState(null);
    const [ openTestCompletedAlert, setOpenTestCompletedAlert ] = useState(false);
    const [ activeFloorPlanUrl, setActiveFloorPlanUrl  ] = useState('');
    const [ errorMsg, setErrorMsg ] = useState('');

    const handleClosingAlert = () => setOpenTestCompletedAlert(!openTestCompletedAlert);
    const handleModalOpen = () => {
        setOpenResultsModal(!openResultsModal);
    }
    const handleModalClose = () => {
        setActiveFloorPlanUrl(null);
        setActiveFPWidth(null);
        setActiveFPHeight(null);
        setResultsData({op: [], sp: []});
        setOpenResultsModal(!openResultsModal);
    }
    const downloadFloorPlan = async (filename) => {
        // Step 1: get pre-signed url by sending axios request to server
        const result = await floorPlans.getPresignedURL({
            projectId: projectId,
            filename: filename,
            action: 'getObject'
        });

        // Step 2: Download file from AWS s3 bucket as a blob
        // Making straight axios call in order to access the ProgressEvent interface which allows up to caputre download progress and pass that data along to spinner component
        const response = await newAxiosInstance.get(result.data.preSignedURL, {
            responseType: 'blob',
        }, null);

        //  Step 3: Download file in required format - create a blob url and render in img tag
        let blob = new Blob([response.data], { type: response.data.type });
        let createObjectURL = window.URL.createObjectURL || window.webkitURL.createObjectURL;
        const url = createObjectURL(blob);
        setActiveFloorPlanUrl(url);
    }
    const getImageSize = async () => {
        let img  = document.querySelector("#activeFP");

        setActiveFPWidth(parseInt(img.naturalWidth));
        setActiveFPHeight(parseInt(img.naturalHeight));
    }
    const onRowSelected = async (testId, testType, method, op) => {
        const result = await results.getHeatMap(projectId, {
            projectId: projectId,
            testId: testId,
            method: method,
            testType: testType,
            op: op,
        });

        if (result.data.success) {
            handleModalOpen();
            await downloadFloorPlan(result.data.data.floorplan);
            setResultsData({op: result.data.data.op, sp: result.data.data.sp});
        } else {
            if (result.data.error.message) {
                setErrorMsg(result.data.error.message);
            }
        }
    }

    const downloadAugmentedData = async (verificationLikePart = false) => {
        const project = await projects.getOneProject(projectId);
        let projectCreationDate = '';
        if (project && project.data.data) {
            const dateObj = new Date(project.data.data.createdAt);
            projectCreationDate = dateObj.getFullYear()+'-' + (dateObj.getMonth()+1) + '-'+dateObj.getDate();
        }

        results.getAugmentedData(projectId, verificationLikePart)
            .then(response => {
                let contentType = 'text/csv';
                let csvFile = new Blob([response.data.data], {type: contentType});
                let createObjectURL = window.URL.createObjectURL || window.webkitURL.createObjectURL;
                let url = createObjectURL(csvFile);
                let a = document.createElement('a');
                a.href = url;
                a.download = `${projectCreationDate} PID ${projectId} ${state.projectName} Portal Augmented Data.csv`;
                a.click();
            });
    }

    const downloadVerificationSummary = async () => {
        results.getVerificationSummary(projectId)
            .then(response => {
                console.log(response, 'response')
                if (!response || !response.data || !response.data.success) {
                    throw new Error('No response from server');
                }
                const data = response.data.data;
                if (!data || !Object.keys(data)) {
                    throw new Error('Invalid response data');
                }

                const { content, filename } = data;

                const contentType = 'text/csv';
                const csvFile = new Blob([content], { type: contentType });

                const createObjectURL = window.URL.createObjectURL || window.webkitURL.createObjectURL;
                const url = createObjectURL(csvFile);

                const a = document.createElement('a');
                a.href = url;
                a.download = `${filename}.csv`;

                a.click();
            }).catch((err) => {
                console.log('downloadVerificationSummary err: ', err);
            });
    }

    const getColorByValue = (value) =>  {
        const pointValue = Math.round(100 / 7);
        const percentValue = pointValue * parseFloat(value);
        const colors = gradientReader.getColor(percentValue);

        return 'rgb('+ colors[0] +', '+ colors[1] +', '+ colors[2] +')';
    }

    if (!resultsTestList) {
        return null;
    }
    return (
        <div>
            {isResultsUploaded && state.accessLevel === 'Admin' && state.roleName === 'Safetracer' && (
                <>
                    <div className="grid-wrapper">
                            <Typography variant="h5" color="textPrimary" style={{ display: 'flex' }}>
                                <p style={{ margin: 0, width: 'fit-content', whiteSpace: 'nowrap' }}>
                                    Augmented Data
                                </p>
                                <div style={{ marginLeft: 'auto'}}>
                                    {(atLeastOneNonVerificationLike || verificationProject) && 
                                        <Button
                                        onClick={() => downloadAugmentedData()}
                                        size="small"
                                        variant="outlined"
                                        className="add-edit-button"
                                    >
                                        Download augmented data
                                        </Button> }
                                    {!!verificationLikeProject && !verificationProject && 
                                        <Button
                                            onClick={() => downloadAugmentedData(true)}
                                            size="small"
                                            variant="outlined"
                                            className="sample-plan-button"
                                            startIcon={<CloudDownloadIcon/>}
                                        >
                                            Augmented Dilution
                                        </Button>}
                                    {(!!verificationProject || !!verificationLikeProject) && 
                                        <Button
                                            onClick={() => downloadVerificationSummary()}
                                            size="small"
                                            variant="outlined"
                                            className="sample-plan-button"
                                            startIcon={<CloudDownloadIcon/>}
                                        >
                                            Summary
                                        </Button>}
                                </div>
                            </Typography>
                    </div>
                    <div className="grid-wrapper" style={{ display: 'none'}}>
                        <div>
                            <TableContainer style={{ marginTop: '15px', boxShadow: 'none' }} component={Paper}>
                                <Table aria-label="project-results-list-table">
                                    {resultsTestList.map((test, testKey) => (
                                        <TableBody>
                                            {test.testType === 'miniSurvey' && (
                                                <TableRow hover={true} key={`test-key-${testKey}`}>
                                                    <TableCell
                                                        onClick={() => onRowSelected(test.testId, test.testType, null, null)}
                                                        className={classes.bodyCell + " table-cell-pointer"}
                                                    >{test.testName}</TableCell>
                                                </TableRow>
                                            )}
                                            {test.testType !== 'miniSurvey' && (
                                                <>
                                                    <TableRow key={`test-key-${testKey}`}>
                                                        <TableCell className={classes.bodyCell}>{test.testName}</TableCell>
                                                    </TableRow>
                                                    {test.list.map((row, rowKey) => (
                                                        <>
                                                            {test.testType === 'recirculation' && (
                                                                <TableRow hover={true} key={`row-key-${testKey}-${rowKey}`}>
                                                                    <TableCell
                                                                        onClick={() => onRowSelected(test.testId, test.testType, row.method, null)}
                                                                        className={classes.bodyCell + " table-cell-pointer"}
                                                                    >&nbsp;&nbsp;&nbsp;{row.method}</TableCell>
                                                                </TableRow>
                                                            )}
                                                            {(test.testType === 'generalSurvey' || test.testType === 'dilution') && (
                                                                <>
                                                                    <TableRow key={`row-key-${testKey}-${rowKey}`}>
                                                                        <TableCell className={classes.bodyCell}>&nbsp;&nbsp;&nbsp;{row.method}</TableCell>
                                                                    </TableRow>
                                                                    {row.op.map((op, opKey) => (
                                                                        <TableRow hover={true} key={`op-key-${rowKey}-${opKey}`}>
                                                                            <TableCell
                                                                                onClick={() => onRowSelected(test.testId, test.testType, row.method, op)}
                                                                                className={classes.bodyCell + " table-cell-pointer"}
                                                                            >&nbsp;&nbsp;&nbsp; - {op}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        </TableBody>
                                    ))}
                                </Table>
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    className={classes.modal}
                                    open={openResultsModal}
                                    onClose={() => handleModalClose()}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}>
                                    <div>
                                        <Fade in={openResultsModal}>
                                            <div className={classes.paper}>
                                                <div className="grid-wrapper">
                                                    <div>
                                                        <h2 id="transition-modal-title">
                                                            Heat map
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="grid-wrapper-info">
                                                    <div>
                                                        {activeFloorPlanUrl && !activeFPWidth && (
                                                            <img src={activeFloorPlanUrl} id="activeFP" onLoad={getImageSize} />
                                                        )}
                                                        {activeFPWidth && activeFPHeight && (
                                                            <svg style={{
                                                                width: (activeFPWidth + 130) + "px",
                                                                height: activeFPHeight + 'px',
                                                                backgroundImage: 'url(' + activeFloorPlanUrl + ')',
                                                                backgroundRepeat: 'no-repeat',
                                                                backgroundSize: 'contain',
                                                                backgroundPosition: 'top left'
                                                            }}>
                                                                <defs>
                                                                    <linearGradient id="gradient" gradientTransform="rotate(90)">
                                                                        <stop offset="0%"  stopColor="green" />
                                                                        <stop offset="14.3%"  stopColor="green" />
                                                                        <stop offset="28.6%"  stopColor="green" />
                                                                        <stop offset="42.8%"  stopColor="green" />
                                                                        <stop offset="57.1%"  stopColor="lightgreen" />
                                                                        <stop offset="71.4%" stopColor="yellow" />
                                                                        <stop offset="84.7%" stopColor="red" />
                                                                        <stop offset="100%" stopColor="red" />
                                                                    </linearGradient>
                                                                </defs>
                                                                {resultsData.sp.map((sp) => (
                                                                    <g>
                                                                        <circle cx={parseFloat(sp.spcoordx)} cy={parseFloat(sp.spcoordy)} r="35" fill={getColorByValue(sp.reduction)} fillOpacity="0.8" />
                                                                        <circle cx={parseFloat(sp.spcoordx)} cy={parseFloat(sp.spcoordy)} r="15" fill="rgb(255, 255, 255)" fillOpacity="0.8" stroke="black" strokeWidth="1" />
                                                                        <text x={parseFloat(sp.spcoordx)} y={parseFloat(sp.spcoordy) + 2} textAnchor="middle" alignmentBaseline="middle" fontFamily="Arial">{sp.reduction}</text>
                                                                        <rect x={parseFloat(sp.spcoordx) - 20} y={parseFloat(sp.spcoordy) + 17} width="41" height="16" fill="lightblue" fillOpacity="0.85" stroke="black" strokeWidth="1" />
                                                                        <text x={parseFloat(sp.spcoordx)} y={parseFloat(sp.spcoordy) + 26} textAnchor="middle" alignmentBaseline="middle" fontFamily="Arial" fontSize="0.7em">{sp.spnumber}</text>
                                                                    </g>
                                                                ))}
                                                                {resultsData.op.map((op) => (
                                                                    <g>
                                                                        <circle cx={parseFloat(op.opcoordx)} cy={parseFloat(op.opcoordy)} r="10" fill = "rgb(255, 0, 0)" fillOpacity="0.8" stroke="black" strokeWidth="1" />
                                                                        <rect x={parseFloat(op.opcoordx) - 22} y={parseFloat(op.opcoordy) + 12} width="45" height="16" fill="lightblue" fillOpacity="0.85" stroke="black" strokeWidth="1" />
                                                                        <text x={parseFloat(op.opcoordx)} y={parseFloat(op.opcoordy) + 21} textAnchor="middle" alignmentBaseline="middle" fontFamily="Arial" fontSize="0.7em">{op.opnumber}</text>
                                                                    </g>
                                                                ))}
                                                                <g>
                                                                    <rect x={activeFPWidth} y={activeFPHeight - 150}  width="30" height="140" fill="url(#gradient)"/>
                                                                    <line x1={activeFPWidth} y1={activeFPHeight - 130} x2={activeFPWidth + 60} y2={activeFPHeight - 130} stroke="white" strokeOpacity="0.3" />
                                                                    <text x={activeFPWidth + 35} y={activeFPHeight - 128} fontFamily="Arial" alignmentBaseline="middle" fontSize="0.8em">6 (99.9999%)</text>
                                                                    <line x1={activeFPWidth} y1={activeFPHeight - 110} x2={activeFPWidth + 60} y2={activeFPHeight - 110} stroke="white" strokeOpacity="0.3" />
                                                                    <text x={activeFPWidth + 35} y={activeFPHeight - 108} fontFamily="Arial" alignmentBaseline="middle" fontSize="0.8em">5 (99.999%)</text>
                                                                    <line x1={activeFPWidth} y1={activeFPHeight - 90} x2={activeFPWidth + 60} y2={activeFPHeight - 90} stroke="white" strokeOpacity="0.3" />
                                                                    <text x={activeFPWidth + 35} y={activeFPHeight - 88} fontFamily="Arial" alignmentBaseline="middle" fontSize="0.8em">4 (99.99%)</text>
                                                                    <line x1={activeFPWidth} y1={activeFPHeight - 70} x2={activeFPWidth + 60} y2={activeFPHeight - 70} stroke="white" strokeOpacity="0.3" />
                                                                    <text x={activeFPWidth + 35} y={activeFPHeight - 68} fontFamily="Arial" alignmentBaseline="middle" fontSize="0.8em">3 (99.9%)</text>
                                                                    <line x1={activeFPWidth} y1={activeFPHeight - 50} x2={activeFPWidth + 60} y2={activeFPHeight - 50} stroke="white" strokeOpacity="0.3" />
                                                                    <text x={activeFPWidth + 35} y={activeFPHeight - 48} fontFamily="Arial" alignmentBaseline="middle" fontSize="0.8em">2 (99%)</text>
                                                                    <line x1={activeFPWidth} y1={activeFPHeight - 30} x2={activeFPWidth + 60} y2={activeFPHeight - 30} stroke="white" strokeOpacity="0.3" />
                                                                    <text x={activeFPWidth + 35} y={activeFPHeight - 28} fontFamily="Arial" alignmentBaseline="middle" fontSize="0.8em">1 (90%)</text>
                                                                    <text x={activeFPWidth + 35} y={activeFPHeight - 8} fontFamily="Arial" alignmentBaseline="middle" fontSize="0.8em">0 (0%)</text>
                                                                    <text x={activeFPWidth} y={activeFPHeight - 185} fontFamily="Arial" fontSize="0.8em">Indicator Tag</text>
                                                                    <text x={activeFPWidth} y={activeFPHeight - 165} fontFamily="Arial" fontSize="0.8em">
                                                                        <tspan>Reduction Log</tspan>
                                                                        <tspan baselineShift="sub" fontSize="0.8em">10</tspan>
                                                                    </text>
                                                                </g>
                                                            </svg>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="grid-wrapper">
                                                    <div>
                                                        <Button
                                                            onClick={() => handleModalClose()}
                                                            size="small"
                                                            className="modal-button"
                                                        >
                                                            Close
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fade>
                                    </div>
                                </Modal>
                            </TableContainer>
                        </div>
                    </div>
                    {!!openTestCompletedAlert && (<AlertTestCompleted flag={openTestCompletedAlert} msg={errorMsg} closeFunc={handleClosingAlert} />)}
                </>
            )}
        </div>
    );
};