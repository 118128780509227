import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const MuiAlertComponent = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

export const AlertGeneral = ({ open, alertMsg, closeFunc, severity }) => {
  //const classes = useStyles();
  const vertical = "middle";
  const horizontal = "center";
  let backgroundColor;
  let color;

  if(severity === 'error') {
      backgroundColor = '#ff0000';
      color = '#FFFFFF'
  }

  return (
    //<div className={classes.root}>
    <div>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={6000}>
          <MuiAlertComponent 
            style={{backgroundColor: backgroundColor, color: color, borderColor: backgroundColor, fontFamily: "Gotham"}} 
            onClose={closeFunc} 
            severity={severity}>
            {alertMsg}
          </MuiAlertComponent>
      </Snackbar>
    </div>
  );
}