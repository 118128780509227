import dataUtil from './dataUtil';

/**
 * summary: create a "normalized" data model for a veriDART project
 */
class ProjectLogicalDataModel {
    constructor(projectId, completionHandler, errorHandler, progressHandler) {
        this.projectId = projectId;

        this.project = null;
        this.tests = [];
        this.scenarios = [];
        this.segments = [];
        this.intervals = [];
        this.originPoints = [];
        this.samplePoints = [];
        this.samples = [];
        this.sampleCollectors = [];
        this.tags = [];
        this.floorPlans = [];

        this.dataErrors = [];

        this.logicalModel = null;

        this.pendingCalls = [];

        this.maxGetDataAttempts = 5;
        this.getProjectDataAttempts = 0;
        this.getSampleCollectorLabelsAttempts = 0;
        this.getProjectSegmentDataAttempts = 0;
        this.getScenarioDataAttempts = 0;
        this.getOriginPointDataAttempts = 0;
        this.getSamplePointDataAttempts = 0;
        this.getSampleDataAttempts = 0;



        /**
         * params
         *
         * string msg[]
         */
        if (completionHandler !== undefined && typeof successHandler === 'function') {
            this.completionHandler = completionHandler;
        }
        /**
         * params
         *
         * string msg[]
         */
        if (errorHandler !== undefined && typeof errorHandler === 'function') {
            this.errorHandler = errorHandler;
        }
        /**
         * params
         *
         * int percentComplete
         * string message
         */
        if (progressHandler !== undefined && typeof progressHandler === 'function') {
            this.errorHandler = errorHandler;
        }
    }

    handleGetProjectDataSuccess(projectId, projectData) {
        this.project = {
            id: projectData.id,
            name: projectData.name,
            address: projectData.address,
            customer: projectData.endCustomer,
            description: projectData.description,
            partner: projectData.partners,
            created: projectData.createdAt,
            status: projectData.status,
        };

        this.getSampleCollectorLabels(this.projectId);

        projectData.tests.map((test) => {
            this.tests.push({
                id: test.testid,
                projectId: test.projectId,
                name: test.testname,
                type: test.testtype,
                goal: test.goal,
                overview: test.overview,
                airSamplerRate: test.airsamplerrate,
                surfaceSampleArea: test.surfacesamplerrate,
                isCompleted: test.testexecuted,
            });

            this.getProjectSegmentData(projectId, test.testid);
            this.getOriginPointData(projectId, test.testid);
            this.getSamplePointData(projectId, test.testid);
            this.getSampleData(projectId, test.testid);

        });

    }

    async handleGetProjectDataFailure(projectId, response) {

        console.log('get project data error: ' + projectId + ' attempt: ' + (this.getProjectDataAttempts + 1));
        console.log(response);

        this.onError('failed to get project data. projectId: ' + projectId);

        if (this.getProjectDataAttempts < this.maxGetDataAttempts) {
            this.getProjectDataAttempts++;
            this.getProjectData(projectId);
        } else {
            this.dataErrors.push({
                msg: 'failed to get project data. projectId: ' + projectId,
                response: response,
            });

        }
    }

    async getProjectData(projectId) {
        this.pendingCalls.push({ networkCall: 1 });

        try {
            dataUtil.getProjectData(projectId,
                (projectId, projectData) => {
                    // success
                    this.pendingCalls.pop();
                    this.handleGetProjectDataSuccess(projectId, projectData);
                }, (projectId, response) => {
                    // error
                    this.pendingCalls.pop();
                    this.handleGetProjectDataFailure(projectId, response);
                });
        } catch (e) {

            console.log(e);

        }

    }

    handleGetSampleCollectorLabelsSuccess(projectId, sampleCollectorData) {
        if (sampleCollectorData !== null) {
            sampleCollectorData.map((item) => {
                this.sampleCollectors.push({
                    sampleNumber: item.sampleNumber,
                    spNumber: item.SPNumber,
                    plates: item.plates,
                    projectName: item.projectName,
                    qrCode: item.qrcode,

                    sampleType: item.sampleType,
                    surveyDate: item.surveyDate,
                });
            });
        }
    }

    async handleGetSampleCollectorLabelsFailure(projectId, response) {
        console.log('get sample collector data error ' + projectId + ' attempt: ' + (this.getSampleCollectorLabelsAttempts + 1));
        console.log(response);

        if (this.getSampleCollectorLabelsAttempts < this.maxGetDataAttempts) {
            this.getSampleCollectorLabelsAttempts++;
            this.getSampleCollectorLabels(projectId);
        } else {
            this.dataErrors.push({
                msg: 'failed to get sample collector data. projectId: ' + projectId,
                response: response,
            });

        }
    }

    async getSampleCollectorLabels(projectId) {
        this.pendingCalls.push({ networkCall: 1 });
        dataUtil.getSampleCollectorLabels(this.projectId,
            (projectId, sampleCollectorData) => {
                // success
                this.pendingCalls.pop();
                //console.log('get sample collector data success ' + projectId);
                //console.log(projectData);
                this.handleGetSampleCollectorLabelsSuccess(projectId, sampleCollectorData);
            }, (projectId, response) => {
                // error
                this.pendingCalls.pop();
                this.handleGetSampleCollectorLabelsFailure(projectId, response);
            });
    }

    handleGetProjectSegmentDataSuccess(projectId, testId, segmentData) {

        segmentData.map((segment) => {
            const displayIntervalDuration = segment.intervalduration === 'custom_ver' ? 5 : segment.intervalduration;

            this.segments.push({
                comments: segment.comments,
                floorplan: segment.floorplan,
                intervalCount: segment.intervalcount,
                intervalDuration: displayIntervalDuration,
                opCount: segment.op,
                plateKind: segment.platekind,
                projectId: segment.projectid,
                id: segment.segmentid,
                name: segment.segmentname,
                segmentsTested: segment.segmentstested,
                spCount: segment.sp,
                surveyDate: segment.surveydate,
                testId: segment.testid,
                sprayerType: segment.sprayername,
            });

            // get the scenario data
            this.getScenarioData(segment.segmentid, testId);
        })

    }

    async handleGetProjectSegmentDataFailure(projectId, testId, response) {

        console.log('get project segment data error ' + projectId + ' attempt: ' + (this.getProjectSegmentDataAttempts + 1));
        console.log(response);

        if (this.getProjectSegmentDataAttempts < this.maxGetDataAttempts) {
            this.getProjectSegmentDataAttempts++;
            this.getProjectSegmentData(projectId, testId);
        } else {
            this.dataErrors.push({
                msg: 'failed to get project segment data. projectId: ' + projectId + ' testId: ' + testId,
                response: response,
            });
        }
    }

    async getProjectSegmentData(projectId, testId) {

        this.pendingCalls.push({ networkCall: 1 });
        dataUtil.getProjectSegmentData(projectId, testId,
            (projectId, testId, segmentData) => {
                // success
                this.pendingCalls.pop();
                //console.log('get segment data success ' + projectId + ', ' + testId);
                //console.log(segmentData);
                this.handleGetProjectSegmentDataSuccess(projectId, testId, segmentData);

            }, (projectId, testId, response) => {
                // error
                this.pendingCalls.pop();
                //console.log('get segment data error ' + projectId + ', ' + testId);
                //console.log(response);
                this.handleGetProjectSegmentDataFailure(projectId, testId, response);

            });

    }

    handleGetScenarioDataSuccess(segmentId, testId, scenarioData) {


        scenarioData.map((scenario) => {
            this.scenarios.push({
                testId: testId,
                description: scenario.scenariodescription,
                id: scenario.scenarioid,
                name: scenario.scenarioname,
                segmentid: scenario.segmentid,
            });
        });

        //console.log(this.scenarios);
    }
    async handleGetScenarioDataFailure(segmentId, testId, response) {


        console.log('get scenario data error. segmentId: ' + segmentId + ' testId: ' + testId + ' attempt: ' + (this.getScenarioDataAttempts + 1));
        console.log(response);

        if (this.getScenarioDataAttempts < this.maxGetDataAttempts) {
            this.getScenarioDataAttempts++;
            // this.getScenarioDataData(segmentId);
        } else {
            this.dataErrors.push({
                msg: 'failed to get scenario data. segmentId: ' + segmentId + ' testId: ' + testId,
                response: response,
            });
        }
    }
    async getScenarioData(segmentId, testId) {
        this.pendingCalls.push({ networkCall: 1 });
        dataUtil.getScenarioData(segmentId, testId,
            (segmentId, testId, scenarioData) => {
                //success
                this.pendingCalls.pop();
                //console.log('get scenario data success');
                //console.log(scenarioData);
                this.handleGetScenarioDataSuccess(segmentId, testId, scenarioData);

            },
            (segmentId, testId, response) => {
                // error
                this.pendingCalls.pop();
                //console.log('get scenario data error - segmentId: ' + segmentId);
                //console.log(response);
                this.handleGetScenarioDataFailure(segmentId, testId, response);
            });
    }

    handleGetOriginPointDataSuccess(projectId, testId, opData) {

        opData.map((op) => {
            let tagLots = [];
            if (op.tagLotNumber !== undefined && op.tagLotNumber !== null) {
                tagLots = op.tagLotNumber.map((tag) => {
                    return {
                        lotNumber: tag.lotNumber,
                        expirationDate: tag.expirationDate,
                        tag: tag.tag,
                    }
                });
            }

            //console.log("tagLots testId " + testId + ", opId " + op.opid);
            //console.log(tagLots);

            const tags = op.tags.map((tag) => {
                const tagLot = tagLots.filter(t => t.tag === tag.tag);
                //console.log(tagLot);
                return {
                    tag: tag.tag,
                    scenarioId: tag.scenarioId,
                    lotNumber: tagLot.length === 1 ? tagLot[0].lotNumber : null,
                    expirationDate: tagLot.length === 1 ? tagLot[0].expirationDate : null,
                }

            });
            this.originPoints.push({
                testId: testId,
                floorplan: op.floorplan,
                x: op.opcoordx,
                y: op.opcoordy,
                id: op.opid,
                name: op.opname,
                number: op.opnumber,
                segmentId: op.segmentid,
                sprayNodeId: op.spraynodeid,
                tags: tags,
            });

            //console.log(op);
            //console.log(this.floorPlans);
            if (op.floorplan !== null &&
                this.floorPlans.filter(item => item.fileName === op.floorplan).length < 1) {
                this.floorPlans.push({ fileName: op.floorplan });

                this.downloadFloorPlan(projectId, op.floorplan, op.heatmapversion);
            }

        });

    }

    async handleGetOriginPointDataFailure(projectId, testId, response) {
        console.log('get origin point data error ' + projectId + ' attempt: ' + (this.getOriginPointDataAttempts + 1));
        console.log(response);

        if (this.getOriginPointDataAttempts < this.maxGetDataAttempts) {
            this.getOriginPointDataAttempts++;
            this.getOriginPointData(projectId, testId);
        } else {
            this.dataErrors.push({
                msg: 'failed to get origin point data. projectId: ' + projectId + ' testId: ' + testId,
                response: response,
            });
        }
    }

    async getOriginPointData(projectId, testId) {

        this.pendingCalls.push({ networkCall: 1 });
        dataUtil.getOriginPointData(projectId, testId,
            (projectId, testId, opData) => {
                // success
                this.pendingCalls.pop();
                //console.log('get origin point data success ' + projectId + ', ' + testId);
                //console.log(opData);
                this.handleGetOriginPointDataSuccess(projectId, testId, opData);


            }, (projectId, testId, response) => {
                // error
                this.pendingCalls.pop();

                this.handleGetOriginPointDataFailure(projectId, testId, response);

            });

    }


    handleDownloadFloorPlanSuccess(projectId, fileName, url, heatmapversion) {
        this.floorPlans = this.floorPlans.map((item) => {
            if (item.fileName === fileName) {
                return {
                    fileName: fileName,
                    url: url,
                    heatmapVersion: !!heatmapversion,
                }
            } else {
                return item;
            }
        })
    }

    async handleDownloadFloorPlanFailure(projectId, fileName, response) {
        console.log('download floor plan error ' + projectId + ', ' + fileName);
        console.log(response);

        // todo: incorporate retry per file name

        this.dataErrors.push({
            msg: 'failed download floor plan for projectId: ' + projectId + ' fileName: ' + fileName,
            response: response,
        });
    }

    async downloadFloorPlan(projectId, fileName, heatmapversion) {
        if (fileName === null || fileName.trim().length < 1) {
            return;
        }

        this.pendingCalls.push({ networkCall: 1 });
        dataUtil.downloadFloorPlan(projectId, fileName,
            (projectId, fileName, url) => {
                // success handler
                this.pendingCalls.pop();
                // update the floorPlans list
                this.handleDownloadFloorPlanSuccess(projectId, fileName, url, heatmapversion);

                //console.log(this.floorPlans);
            },
            (projectId, fileName, response) => {
                // error Handler
                this.pendingCalls.pop();

                this.handleDownloadFloorPlanFailure(projectId, fileName, response);
            },
            (percentCompleted) => {
                // progress Handler
                //no pop here!!!
            }
        )

    }
    handleGetSamplePointDataSuccess(projectId, testId, spData) {
        spData.map((sp) => {

            this.samplePoints.push({
                testId: testId,
                airSamplerRate: sp.airSamplerRate,
                airSamplerNodeId: sp.airsamplernodeid,
                collectionMethod: sp.collectionmethod,
                floorplan: sp.floorplan,
                segmentId: sp.segmentid,
                x: sp.spcoordx,
                y: sp.spcoordy,
                id: sp.spid,
                name: sp.spname,
                number: sp.spnumber,
                surfaceSamplerRate: sp.surfaceSampleRate,
            });
        });
    }

    async handleGetSamplePointDataFailure(projectId, testId, response) {
        console.log('get sample point data error ' + projectId + ' attempt: ' + (this.getSamplePointDataAttempts + 1));
        console.log(response);

        if (this.getSamplePointDataAttempts < this.maxGetDataAttempts) {
            this.getSamplePointDataAttempts++;
            this.getSamplePointData(projectId, testId);
        } else {
            this.dataErrors.push({
                msg: 'failed to get sample point data. projectId: ' + projectId + ' testId: ' + testId,
                response: response,
            });
        }
    }

    async getSamplePointData(projectId, testId) {

        this.pendingCalls.push({ networkCall: 1 });
        dataUtil.getSamplePointData(projectId, testId,
            (projectId, testId, spData) => {
                // success
                this.pendingCalls.pop();
                //console.log('get sample point data success ' + projectId + ', ' + testId);
                //console.log(spData);
                this.handleGetSamplePointDataSuccess(projectId, testId, spData);
                //console.log(this.samplePoints);

            }, (projectId, testId, response) => {
                // error
                this.pendingCalls.pop();
                this.handleGetSamplePointDataFailure(projectId, testId, response);
            });
    }


    handleGetSampleDataSuccess(projectId, testId, sampleData) {
        sampleData.map((sample) => {

            this.samples.push({
                testId: testId,
                endTime: sample.endtime,
                id: sample.sampleid,
                name: sample.samplename,
                type: sample.sampletype,
                rate: sample.samplerate,
                number: sample.samplenumber,
                scenarioId: sample.scenarioid,
                x: sample.spcoordx,
                y: sample.spcoordy,
                spId: sample.spid,
                spName: sample.spname,
                spNumber: sample.spnumber,
                startTime: sample.starttime,
                timeInterval: sample.timeInterval,
            });
        });
    }

    async handleGetSampleDataFailure(projectId, testId, response) {
        console.log('get sample data error ' + projectId + ' attempt: ' + (this.getSampleDataAttempts + 1));
        console.log(response);
        if (this.getSampleDataAttempts < this.maxGetDataAttempts) {
            this.getSampleDataAttempts++;
            this.getSampleData(projectId, testId);
        } else {
            this.dataErrors.push({
                msg: 'failed to get sample data. projectId: ' + projectId + ' testId: ' + testId,
                response: response,
            });
        }
    }


    getSampleData(projectId, testId) {


        this.pendingCalls.push({ networkCall: 1 });
        dataUtil.getSampleData(projectId, testId,
            (projectId, testId, sampleData) => {
                // success
                this.pendingCalls.pop();
                //console.log('get sample data success ' + projectId + ', ' + testId);
                //console.log(sampleData);
                this.handleGetSampleDataSuccess(projectId, testId, sampleData);

            }, (projectId, testId, response) => {
                // error
                this.pendingCalls.pop();
                this.handleGetSampleDataFailure(projectId, testId, response);

            });
    }

    async build() {
        //console.log('build()');
        await this.getProjectData(this.projectId);

        let counter = 600;  //basically a 60 second max
        while (this.pendingCalls.length > 0 && counter > 0) {
            //console.log('waiting for pending calls ' + this.pendingCalls.length + ', ' + counter);
            await new Promise(r => setTimeout(r, 100));
            counter--;
        }

        this.normalize();
    }

    onCompletion(message, data) {
        if (this.completionHandler !== undefined) {
            this.completionHandler(this.newCompletionEvent(message, data));
        }
    }

    onError(message, response) {
        if (this.errorHandler !== undefined) {
            this.errorHandler(this.newErrorEvent(message, response));
        }
    }

    newCompletionEvent(message, data) {
        return {
            message: message,
            data: data,
        }
    }

    newErrorEvent(message, response) {
        return {
            message: message,
            response: response,
        };
    }
    addSegmentValuesToTests() {

        this.tests.map((test) => {
            const s = this.segments.filter(s => s.testId === test.id);
            if (s.length > 0) {
                test.opCount = s.reduce((a, b) => ({ opCount: a.opCount + b.opCount })).opCount;
                test.spCount = s.reduce((a, b) => ({ spCount: a.spCount + b.spCount })).spCount;
                test.floorplan = s[0].floorplan;
                test.intervalCount = s[0].intervalCount;
                test.intervalDuration = Number(s[0].intervalDuration);
                test.testDate = s[0].surveyDate;
            } else {
                test.opCount = 0;
                test.spCount = 0;
                test.floorplan = null;
                test.intervalCount = null;
                test.intervalDuration = null;
                test.testDate = null;
            }
        });

    }


    addCollectionMethodsToTest() {
        //console.log('addCollectionMethodsToTest()');

        this.tests.map((test) => {
            let collectionMethods = [];

            const samplePoints = this.samplePoints.filter(s => s.testId === test.id);
            //console.log(samplePoints);
            if (samplePoints.length > 0) {
                const cm = samplePoints[0].collectionMethod;
                //console.log(samplePoints[0]);
                //console.log('cm: ' + cm);
                if (cm === 'filterandswab') {
                    collectionMethods.push('filter');
                    collectionMethods.push('swab');
                } else if (cm !== undefined && cm !== null) {
                    collectionMethods.push(cm);
                } else {
                    collectionMethods.push('n/a');
                }
            } else {
                collectionMethods.push('n/a');
            }
            test.collectionMethods = collectionMethods;
        });
    }



    addPlaceholderScenarioToTests() {



        this.tests.map((test) => {
            const s = this.scenarios.filter(s => s.testId === test.id);
            if (s.length < 1) {
                this.scenarios.push({
                    testId: test.id,
                    description: 'default',
                    id: null,
                    name: 'scenario1',
                    segmentid: null,
                });

            }

        });

    }

    addSequentialNumberToTests() {
        let number = 0;

        this.tests.sort(function (a, b) {
            if (a.id < b.id) {
                return -1;
            }
            if (a.id > b.id) {
                return 1;
            }
            return 0;
        }).map((test) => {
            test.sequenceNumber = ++number;
        });
    }

    addSequentialNumberToScenarios() {
        this.tests.map((test) => {
            let number = 0;
            this.scenarios.filter(s => s.testId === test.id).map((scenario) => {
                scenario.sequenceNumber = ++number;
            })
        });

    }

    addScenarioSequenceNumberToOpTags() {
        this.scenarios.map((scenario) => {
            let originPoints = this.originPoints.filter(s => s.testId === scenario.testId);

            // a null scenario id means it was added for general survey tests that have no scenarios, may also be mini survey??

            if (originPoints.length > 0) {
                originPoints.map((op) => {
                    if (op.tags !== null && op.tags.length > 0) {
                        op.tags.map((tag) => {
                            if (scenario.id !== null) {
                                if (tag.scenarioId == scenario.id) {
                                    tag.scenarioSequenceNumber = scenario.sequenceNumber;
                                }
                            } else {
                                tag.scenarioSequenceNumber = 1;
                            }
                        });
                    }
                });
            }
        });
    }

    addIntervalNumberAndScenarioSequenceNumberToSamples() {

        this.tests.map((test) => {
            let sampleTypes = [];
            this.samples.filter(s => s.testId === test.id).map((sample) => {
                if (!sampleTypes.includes(sample.type)) {
                    sampleTypes.push({ type: sample.type, count: 0 });
                }
            });


            this.samplePoints.filter(s => s.testId === test.id).map((samplePoint) => {
                //console.log(samplePoint);

                this.scenarios.filter(s => s.testId === test.id).sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.spName) {
                        return 1;
                    }
                    return 0;
                }).map((scenario) => {
                    //console.log(scenario);
                    sampleTypes.map((sampleType) => { sampleType.count = 0 });
                    //console.log(sampleTypes);

                    this.samples.filter(s => s.testId === test.id && s.spId === samplePoint.id && s.scenarioId === scenario.id).sort(function (a, b) {
                        if (Number(a.startTime) < Number(b.startTime)) {
                            return -1;
                        }
                        if (Number(a.startTime) > Number(b.startTime)) {
                            return 1;
                        }
                        return 0;
                    }).map((sample) => {

                        //console.log(sampleTypes.filter(t => t.type == sample.type));
                        sample.intervalNumber = sampleTypes.filter(t => t.type == sample.type)[0].count++ + 1;
                        sample.scenarioSequenceNumber = scenario.sequenceNumber;
                    });
                });

            });

        });
    }

    normalize() {
        this.addSegmentValuesToTests();
        this.addPlaceholderScenarioToTests();
        this.addSequentialNumberToTests();
        this.addSequentialNumberToScenarios();
        this.addIntervalNumberAndScenarioSequenceNumberToSamples();
        this.addCollectionMethodsToTest();
        this.addScenarioSequenceNumberToOpTags()


        console.log('Tests');
        console.log(this.tests);

        console.log("Segments");
        console.log(this.segments);

        console.log("Scenarios");
        console.log(this.scenarios);

        console.log("Origin Points");
        console.log(this.originPoints);

        console.log("Sample Points");
        console.log(this.samplePoints);

        console.log("Samples");
        console.log(this.samples);

        console.log("Sample Collectors");
        console.log(this.sampleCollectors);

        console.log("Data Errors");
        console.log(this.dataErrors);

    }



    getOriginPoints(testId) {
        //console.log("getOriginPoints " + testId);
        const result = this.originPoints.filter(item => item.testId === testId).sort(function (a, b) {
            const aNum = a.number.toUpperCase();
            const bNum = b.number.toUpperCase();
            if (aNum < bNum) {
                return -1;
            }
            if (aNum > bNum) {
                return 1;
            }
            return 0;
        });
        //console.log(result);
        return result;
    }

    getOriginPointsByScenario(scenarioId) {
        return this.originPoints.map((item) => {
            if (item.tags !== null &&
                item.tags.filter(tag => tag.scenarioId === scenarioId) > 0) {
                return item;
            }
        })
            .filter(item => item !== undefined)

            .sort(function (a, b) {
                const aNum = a.number.toUpperCase();
                const bNum = b.number.toUpperCase();
                if (aNum < bNum) {
                    return -1;
                }
                if (aNum > bNum) {
                    return 1;
                }
                return 0;
            });
    }
    getSamplePoints(testId) {
        return this.samplePoints.filter(item => item.testId === testId).sort(function (a, b) {
            const aNum = a.number.toUpperCase();
            const bNum = b.number.toUpperCase();
            if (aNum < bNum) {
                return -1;
            }
            if (aNum > bNum) {
                return 1;
            }
            return 0;
        });
    }
    getScenarios(testId) {
        return this.scenarios.filter(item => item.testId === testId).sort(function (a, b) {
            if (a.id < b.id) {
                return -1;
            }
            if (a.id > b.id) {
                return 1;
            }
            return 0;
        });
    }
    getTests() {
        return this.tests.sort(function (a, b) {
            if (a.id < b.id) {
                return -1;
            }
            if (a.id > b.id) {
                return 1;
            }
            return 0;
        });
    }

    getFloorPlans(testId) {
        //console.log('getFloorPlans ' + testId);
        let floorPlans = [];
        this.originPoints.map((item) => {
            //console.log(item);
            if ((testId !== undefined && item.testId === testId) || testId === undefined) {
                if (!floorPlans.includes(item.floorplan)) {
                    floorPlans.push(item.floorplan);
                }
            }
        });
        return floorPlans;
    }
    getFloorPlan(fileName) {
        //console.log("getFloorPlan " + fileName);
        const result = this.floorPlans.filter(item => item.fileName === fileName);
        return result.length === 1 ? result[0] : null;
    }


}

export default ProjectLogicalDataModel;
