const utils = {
    truncate : (str, len) => {
        if(str.length > len) {
            str = str.slice(0, len - 3);
            return `${str}...`;
        } else {
            return `${str}`;
        } 
    }
}

export default utils;