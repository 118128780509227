import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import mergeImages from 'merge-images';
import Resizer from './Resizer'
import FloorPlanTemplateImage from '../../assets/Template-for-Floor-Plan.png';
import axios from 'axios';
import floorPlans from '../../api/floorPlans';
import RenamePanel from './RenamePanel';
import ProgressBar from '../ProgressBar';
import fileNameUtil from './fileNameUtil.js';
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
    modal: {
        position: 'fixed',
        top: 0,
        left: 0,
        background: 'rgba(0, 0, 0, 0.8)',
        zIndex: 99999,
        height: '100%',
        width: '100%',
    },
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#fff',
        width: '80%',
        height: '80%',
        padding: '20px',
    },
    modalTitle: {
        width: '100%',
        height: '60px',
    },
    modalBody: {
        width: '100%',
        height: 'calc(100% - 150px)',
        maxHeight: 'calc(100% - 150px)',
        marginTop: "20px",
        backgroundImage: 'linear-gradient(45deg, #EEEEEE 25%, transparent 25%), linear-gradient(-45deg, #EEEEEE 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #EEEEEE 75%), linear-gradient(-45deg, transparent 75%, #EEEEEE 75%)',
        backgroundSize: '20px 20px',
        backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
        overflow: 'hidden',
    },
    modalActions: {
        width: '100%',
        height: '40px',
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    isHidden: {
        visibility: 'hidden',
        opacity: 0,
        width: '100%',
    },
    isVisible: {
        visibility: 'visible',
        opacity: 1,
        transition: 'opacity 0.7s linear',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

class FloorPlanCropperDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeActivity: ['image/png', 'image/jpg', 'image/jpeg'].includes(props.imageFile.type) ? 'preview' : 'error',
            selectedImageFile: props.imageFile,
            selectedImage: null,
            croppedImage: null,
            croppedImageDimensions: null,

            saveFileName: props.imageFile.name,
            tempSaveFileName: props.imageFile.name,

            detailsAddress: '',
            detailsFloorNumber: '',

            modalBodyDimensions: null,
            cropperDimensions: null,
            cropperToolBarDimensions: null,
            cropper: null,

            workingFileVisible: true,
            workingFileDimensions: null,
            templateFileDimensions: null,
            imageViewDimensions: null,

            mergedImageVisible: false,
            previewImage: null,

            workingImage: null,
            scaledWorkingImage: null,
            mergedImage: FloorPlanTemplateImage,

            isRenameDoneDisabled: false,
            isCropDisabled: true,
            isUploadDisabled: true,

            percentUploadComplete: 0,
        }

        this.imageContainerDivRef = React.createRef();
        this.cropperContainerDivRef = React.createRef();
        this.cropperToolBarContainerDivRef = React.createRef();
        this.modalBodyDivRef = React.createRef();

        this.imageContainerRef = this.imageContainerRef.bind(this);
        this.cropperContainerRef = this.cropperContainerRef.bind(this);
        this.cropperToolBarContainerRef = this.cropperToolBarContainerRef.bind(this);
        this.modalBodyRef = this.modalBodyRef.bind(this);

        this.previewActivity = this.previewActivity.bind(this);
        this.cropActivity = this.cropActivity.bind(this);
        this.renameActivity = this.renameActivity.bind(this);
        this.detailsActivity = this.detailsActivity.bind(this);
        this.uploadActivity = this.uploadActivity.bind(this);
        this.errorActivity = this.errorActivity.bind(this);

        this.componentDidUpdatePreview = this.componentDidUpdatePreview.bind(this);
        this.componentDidUpdateCrop = this.componentDidUpdateCrop.bind(this);
        this.componentDidUpdateRename = this.componentDidUpdateRename.bind(this);
        this.componentDidUpdateDetails = this.componentDidUpdateDetails.bind(this);

        this.loadFile = this.loadFile.bind(this);
        this.imageSize = this.imageSize.bind(this);
        this.mergeFloorPlanIntoTemplate = this.mergeFloorPlanIntoTemplate.bind(this);
        this.onMergeImages = this.onMergeImages.bind(this);
        this.mergePosition = this.mergePosition.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.blobToFile = this.blobToFile.bind(this);
        this.dataURLtoFile = this.dataURLtoFile.bind(this);
        this.getCropData = this.getCropData.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        // load user selected imaage here
        const projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;

        const response = await floorPlans.getFloorPlans(projectId);
        if (response && response.data && response.data.data) {
            this.setState({
                floorPlanFiles: response.data.data
            }, () => {
                this.loadFile();
            });
        }

    }

    componentDidUpdate(prevProps, prevState) {


        switch (this.state.activeActivity) {
            case 'preview':
                this.componentDidUpdatePreview(prevProps, prevState)
                break;
            case 'crop':
                this.componentDidUpdateCrop(prevProps, prevState)
                break;

            case 'rename':
                break;
            case 'details':
                break;
            case 'upload':
                break;


        }
    }
    componentDidUpdatePreview(prevProps, prevState) {

        /*
        if (this.state.workingFileDimensions !== null && this.state.mergedImageVisible === false) {
            this.setState({ workingFileVisible: false, mergedImageVisible: true, previewImage: this.state.mergeImage });
            if (this.state.croppedImage !== null) {
                this.mergeFloorPlanIntoTemplate();
            }

        }
        */

        if (this.imageContainerRef() !== null) {
            const rect = this.imageContainerRef().getBoundingClientRect();

            const imageDimensions = this.scaleToFitDimensions(rect, this.state.templateFileDimensions);
            if (imageDimensions !== null && (this.state.imageViewDimensions === null || this.state.imageViewDimensions.width - imageDimensions > 20)) {
                this.setState({ imageViewDimensions: imageDimensions });
            }
        }
    }
    componentDidUpdateCrop(prevProps, prevState) {

        // crop view is now being displayed

        // need to calculate the cropper height
        // modal body - tool bar height


        if (this.modalBodyRef() !== null) {
            const rect = this.modalBodyRef().getBoundingClientRect();
            //console.log(rect);

            if (this.state.modalBodyDimensions === null ||
                this.state.modalBodyDimensions.height !== rect.height ||
                this.state.modalBodyDimensions.width !== rect.width) {
                this.setState({ modalBodyDimensions: { width: rect.width, height: rect.height } }, () => {
                    if (this.cropperToolBarContainerRef() !== null) {
                        const rect = this.cropperToolBarContainerRef().getBoundingClientRect();

                        if (this.state.cropperToolBarDimensions === null
                            || this.state.cropperToolBarDimensions.width !== rect.width
                            || this.state.cropperToolBarDimensions.height !== rect.height) {
                            this.setState({ cropperToolBarDimensions: rect }, () => {
                                if (this.state.modalBodyDimensions !== null && this.state.cropperToolBarDimensions !== null) {
                                    const editPanelDimensions = {
                                        width: this.state.modalBodyDimensions.width,
                                        height: this.state.modalBodyDimensions.height - this.state.cropperToolBarDimensions.height,
                                    };

                                    if (this.state.cropperDimensions === null ||
                                        this.state.cropperDimensions.width !== editPanelDimensions.width ||
                                        this.state.cropperDimensions.height !== editPanelDimensions.height) {
                                        this.setState({ cropperDimensions: editPanelDimensions });
                                    }
                                }
                            });
                        }
                    }
                })
            }
        }
    }
    componentDidUpdateRename(prevProps, prevState) {

    }
    componentDidUpdateDetails(prevProps, prevState) {

    }

    getCropData = () => {
        if (this.state.cropper !== null) {
            const cropData = this.state.cropper.getData();
            const dataURL = this.state.cropper.getCroppedCanvas().toDataURL('image/png', 1.0);
            let image = new Image();
            image.onload = () => {
                this.setState({
                    //previewImage: image,
                    croppedImage: image.src,
                    croppedImageDimensions: {
                        width: image.width,
                        height: image.height,
                    }
                    //activeActivity: 'preview',
                    //workingFileVisible: true,
                    //mergedImageVisible: false,
                }, () => {
                    this.mergeFloorPlanIntoTemplate();
                    this.setState({
                        activeActivity: 'preview',
                    })
                });

            }
            //image.width = cropData.width;
            //image.height = cropData.height;
            image.src = dataURL;
        }
    };

    onMergeImages(b64) {
        this.setState({ mergedImage: b64, previewImage: b64, isUploadDisabled: false });

    }
    mergeFloorPlanIntoTemplate() {
        //console.log("mergeFloorPlanIntoTemplate()");
        this.setState({ uploadDisabled: true });
        try {

            const sizeConstraint = {
                width: 2798,
                height: 1942,
            };

            //console.log(sizeConstraint);
            const size = this.imageSize(this.state.croppedImageDimensions, sizeConstraint);

            //console.log(size);
            let image = new Image();

            image.src = this.state.croppedImage;
            //image.src = this.state.selectedImage;
            //console.log(image);
            let resizedDataUrl = Resizer.resizeAndRotateImage(
                image, //image,
                size.width,//maxWidth,
                size.height,//maxHeight,
                size.width,//minWidth,
                size.height,//minHeight,
                "PNG",//compressFormat,
                100,//quality,
                0//rotation
            );

            //console.log(resizedDataUrl);
            const contentType = `image/${"PNG"}`;
            const blob = Resizer.b64toBlob(resizedDataUrl, contentType);
            const offset = this.mergePosition(size);
            //console.log (offset);

            mergeImages([
                { src: FloorPlanTemplateImage, x: 0, y: 0, },
                //{ src: URL.createObjectURL(blob), x: 540, y: 795, opacity: 1 },
                { src: URL.createObjectURL(blob), x: offset.x, y: offset.y, opacity: 1 },
            ], {crossOrigin: 'anonymous'}).then(b64 => this.onMergeImages(b64));

            //console.log(blob);
            this.setState({ scaledWorkingImage: URL.createObjectURL(blob) });

        } catch (err) {
            console.log(err);
        }
    }

    getImageForHeatmapSave = async (i = 0) => {
        const result = {
            success: false,
            heatmapFP: null,
        };
        try {
            /**
             * 1. Set size of final image container
             */
            const width = 2798;
            const height = 1942;

            /**
             * 2. Get new size of image so it would fit container. Resize the cropped image.
             */
            let _image = new Image();
            
            _image.src = this.state.croppedImage;

            //const image = this.removeHalfImageBlanks(_image);
            const image = _image;
            console.log({ width: image.width, height: image.height }, { width, height }, 'inputs')
            const size = this.imageSize({ width: image.width, height: image.height }, { width, height });
            console.log(size, 'size')
            const resizedDataUrl = Resizer.resizeAndRotateImage(
                image, //image,
                size.width, //maxWidth,
                size.height, //maxHeight,
                size.width, //minWidth,
                size.height, //minHeight,
                'PNG', //compressFormat,
                100, //quality,
                0 //rotation
            );        
            /**
             * 3. Create canvas and fill it with color. It would be our background.
             */
            const background = document.createElement('canvas');

            background.width = width;
            background.height = height;

            const ctx = background.getContext('2d');
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, width, height);
            /**
             * 4. Calculate offset for the cropped FP so that the FP would be centered in the final picture.
             */
            const offset = {
                x: (width - size.width) / 2,
                y: (height - size.height) / 2,
            };

            const blob = Resizer.b64toBlob(resizedDataUrl, 'image/PNG');
            /**
             * 5. Merge single-color background with the cropped & resized floorplan.
             */
            const finalImage = await mergeImages([
                { src: background.toDataURL('image/png'), x: 0, y: 0, },
                { src: URL.createObjectURL(blob), x: offset.x, y: offset.y, opacity: 1 },
            ], { 
                crossOrigin: 'anonymous',
            });

            result.success = true;
            result.heatmapFP = finalImage;

            return result;
        } catch (err) {
            /**
             * Sometimes, if the upload button were clicked too fast, this function
             * would complain on the 0 height of the image.
             * So try 3 times and then if nothing had changed return falsy result. 
             * 
             * Delete the condition below to see initial error.
             */
            if (i) {
                console.log('getImageForHeatmapSave error: ', i, err);
            }
            if (i < 3) {
                const j = i + 1;
                return new Promise(resolve => setTimeout(resolve, 200))
                    .then(() => this.getImageForHeatmapSave(j));
            } else {
                return result;
            }
        }
    };

    removeHalfImageBlanks = (imageObject) => {
		const { width, height } = imageObject;
		
		/** Canvas with original image */
        const canvas = document.createElement('canvas');

        canvas.setAttribute('width', width);
        canvas.setAttribute('height', height);

        const context = canvas.getContext('2d');
        context.drawImage(imageObject, 0, 0);

		/** Second canvas with halved image (all data present, just different size) */
        const oc = document.createElement('canvas');
        const octx = oc.getContext('2d');
      
        const halfWidth = Math.round(width * 0.5);
        const halfHeight = Math.round(height * 0.5);

        oc.setAttribute('width', halfWidth);
        oc.setAttribute('height', halfHeight);
      
        octx.drawImage(imageObject, 0, 0, halfWidth, halfHeight);
      
		/**
		 * Amount of non-white pixels to alert something is here
		 */
        const cropThreshold = 1;  
		/**
		 * get data for the whole image
		 */
        const imageData = octx.getImageData(0, 0, halfWidth, halfHeight);
        const data = imageData.data;

        const getRBG = (x, y) => {
          const offset = halfWidth * y + x;
          return {
            red: data[offset * 4],
            green: data[offset * 4 + 1],
            blue: data[offset * 4 + 2],
            opacity: data[offset * 4 + 3],
          };
        };

        const isWhite = (rgb) => {
          // many images contain noise, as the white is not a pure #fff white
          const thresholdValue = 200;
          return (
            rgb.opacity === 0 || (rgb.red > thresholdValue && rgb.green > thresholdValue && rgb.blue > thresholdValue)
          );
        };
      
		const scanWhiteSpaces = (direction, fromStart) => {
			const compareValueY = direction === 'height' ? halfHeight : halfWidth;
			const compareValueX = direction === 'height' ? halfWidth : halfHeight;

			let result = null; // All image is white

			if (fromStart) {
				for (let y = 0; y < compareValueY; y += 1) {
					let count = 0;
					for (let x = 0; x < compareValueX; x++) {
						const values = direction === 'height' ? [x, y] : [y, x];
						const rgb = getRBG(...values);
						if (!isWhite(rgb)) {
							count++;
							if (count > cropThreshold) {
								result = y * 2;
								break;
							}
						}
					}
					if (count > cropThreshold) break;
				}
			} else {
				for (let y = compareValueY - 1; y > -1; y -= 1) {
					let count = 0;
					for (let x = 0; x < compareValueX; x++) {
						const values = direction === 'height' ? [x, y] : [y, x];
						const rgb = getRBG(...values);
						if (!isWhite(rgb)) {
							count++;
							if (count > cropThreshold) {
								result = Math.min((y + 1) * 2, direction === 'height' ? height : width);
								break;
							}
						}
					}
					if (count > cropThreshold) break;
				}
			}
			return result;
		};
      
        const scanBlanks = (direction) => {
          const blanks = [];
      
          const lastPoint = direction === 'height' ? halfHeight : halfWidth;
      
          let current = 0;
          let lastIsWhite = false;
          let lastIsBlack = false;
          let lastBlack = 0;
          for (let y = 0; y < lastPoint; y++) {
            let count = 0;
            for (let x = 0; x < lastPoint; x++) {
              const rgb = getRBG(
                direction !== 'height' ? y : x,
                direction === 'height' ? y : x,
              );
              if (!isWhite(rgb)) {
                count++;
              }
            }
            if (count > 1 && lastIsWhite) {
              blanks.push([current > 0 ? current * 2 - 1 : 0, (y - 1) * 2 - 1]);
              current = y;
            }
            if (y === lastPoint - cropThreshold && lastIsWhite) {
              blanks.push([(lastBlack + 1) * 2 - 1, y * 2 - 1]);
              current = y;
            }
            lastIsWhite = count <= cropThreshold;
            lastIsBlack = !lastIsWhite;
            if (lastIsBlack) {
              lastBlack = y;
            }
          }
      
          return blanks; // all image is white
        };
      
        const crop = {};

        crop['Top'] = scanWhiteSpaces('height', true);
        crop['Bottom'] = scanWhiteSpaces('height', false);
        crop['Left'] = scanWhiteSpaces('width', true);
        crop['Right'] = scanWhiteSpaces('width', false);

        crop['Width'] = crop['Right'] - crop['Left'];
        crop['Height'] = crop['Bottom'] - crop['Top'];

        crop['BlanksTop'] = scanBlanks('height');
        crop['BlanksLeft'] = scanBlanks('width');
        
        const getCropPoints = (crop) => {
          const halfHeight = height / 2;
          const halfWidth = width / 2;
      
          const points = {
            startX: 0,
            startY: 0,
            finishX: 999999,
            finishY: 999999,
          };
      
          if (crop['BlanksTop'].length > 0) {
            crop['BlanksTop'].forEach((value) => {
              if (value[1] < halfHeight) {
                points.startY = value[1];
              }
              if (value[0] > halfHeight && points.finishY === 0) {
                points.finishY = value[0];
              }
            });
          } else {
            points.startY = 0;
            points.finishY = height;
          }
      
          if (crop['BlanksLeft'].length > 0) {
            crop['BlanksLeft'].forEach((value) => {
              if (value[1] < halfWidth) {
                points.startX = value[1];
              }
              if (value[0] > halfWidth && points.finishX === 0) {
                points.finishX = value[0];
              }
            });
          } else {
            points.startX = 0;
            points.finishX = width;
          }
      
          if (points.startY < crop.Top) {
            points.startY = crop.Top;
          }
          if (points.finishY > crop.Bottom) {
            points.finishY = crop.Bottom;
          }
      
          if (points.startX < crop.Left) {
            points.startX = crop.Left;
          }
          if (points.finishX > crop.Right) {
            points.finishX = crop.Right;
          }
          points.width = points.finishX - points.startX;
          points.height = points.finishY - points.startY;

          return points;
        };
      
        const points = getCropPoints(crop);
            
        // finally crop the guy
        canvas.setAttribute('width', points.width);
        canvas.setAttribute('height', points.height);
        canvas
          .getContext('2d')
          .drawImage(
            imageObject,
            points.startX,
            points.startY,
            points.width,
            points.height,
            0,
            0,
            points.width,
            points.height,
          );

        return canvas;
        // return {
        //   url: canvas.toDataURL(),
        //   leftShift: points.startX,
        //   topShift: points.startY,
        // };
      };

    modalBodyRef() {
        if (this.modalBodyDivRef === undefined || this.modalBodyDivRef === null) {
            return null;
        }
        return this.modalBodyDivRef.current;
    }

    imageContainerRef() {
        if (this.imageContainerDivRef === undefined || this.imageContainerDivRef === null) {
            return null;
        }
        return this.imageContainerDivRef.current;
    }

    cropperContainerRef() {
        if (this.cropperContainerDivRef === undefined || this.cropperContainerDivRef === null) {
            return null;
        }
        return this.cropperContainerDivRef.current;
    }

    cropperToolBarContainerRef() {
        if (this.cropperToolBarContainerDivRef === undefined || this.cropperToolBarContainerDivRef === null) {
            return null;
        }
        return this.cropperToolBarContainerDivRef.current;
    }
    loadFile() {

        let reader = new FileReader();
        reader.onload = (e) => {
            const name = fileNameUtil.appendExtensionToFileName(fileNameUtil.appendSuffixToFileName(fileNameUtil.removeExtension(this.state.saveFileName), this.state.floorPlanFiles));
            this.setState({
                selectedImage: e.target.result,
                croppedImage: e.target.result,
                previewImage: e.target.result,
                saveFileName: name,
                isCropDisabled: false,
            });
        };
        reader.readAsDataURL(this.state.selectedImageFile);
    }

    imageSize(fromDimensions, constraintDimensions) {
        //console.log(fromDimensions);
        //console.log(constraintDimensions);
        const scaleWidthConstraintRatio = constraintDimensions.width / fromDimensions.width;
        const scaleHeightConstraintRatio = constraintDimensions.height / fromDimensions.height;
        let ratio = 0;
        if (fromDimensions.height * scaleWidthConstraintRatio <= constraintDimensions.height) {
            ratio = scaleWidthConstraintRatio;
        } else {
            ratio = scaleHeightConstraintRatio;
        }

        //console.log("")
        const result = {
            width: fromDimensions.width * ratio,
            height: fromDimensions.height * ratio,
        };
        return result;
    }

    mergePosition(mergeImageDimensions) {

        const topLeft = {
            x: 540,
            y: 795,
        };
        const bottomRight = {
            x: 3338,
            y: 2737,
        };

        const containerSize = {
            width: bottomRight.x - topLeft.x,
            height: bottomRight.y - topLeft.y,
        };

        const resultPosition = {
            x: containerSize.width / 2 - mergeImageDimensions.width / 2 + topLeft.x,
            y: containerSize.height / 2 - mergeImageDimensions.height / 2 + topLeft.y,
        };

        return resultPosition;
    }

    scaleToFitDimensions(containerRect, objectDimensions) {
        if (this.state.templateFileDimensions === null) {
            return null;
        }
        const scaleWidthRatio = containerRect.width / objectDimensions.width;
        const scaleHeightRatio = containerRect.height / objectDimensions.height;

        const scaleRatio = scaleWidthRatio * objectDimensions.height <= containerRect.height ? scaleWidthRatio : scaleHeightRatio;

        const result = {
            width: scaleRatio * objectDimensions.width,
            height: scaleRatio * objectDimensions.height,
        };
        return result;
    }

    blobToFile(theBlob, fileName) {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    /**
     * Change temporary floor details if one of fields in floor modal editor was changed
     * @param field
     * @param value
     */
    handleChange(field, value) {
        switch (field) {
            case "address":
                this.setState({
                    detailsAddress: value.target.value ?? '',
                })
                break;
            case "floorNumber":
                this.setState({
                    detailsFloorNumber: value.target.value ?? '',
                })
                break;
            default:
                break;
        }
    }

    dataURLtoFile(dataurl, filename) {
        //https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    async uploadFile() {
        //todo: add error handling for projectId == null
        const projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;

        // Check if same filename exist in current list of files
        // Step 1: Get pre-signed url by sending axios request to server
        const fileInfo = {
            projectId,
            filename: this.state.saveFileName,
            filetype: 'image/png',
            action: 'putObject',
        };

        const result = await floorPlans.getPresignedURL(fileInfo);

        const dataForHeatmaps = {
            ...fileInfo,
            heatmapVersion: 1,
        };

        const resultHeatmaps = await floorPlans.getPresignedURL(dataForHeatmaps);

        const { success, heatmapFP } = await this.getImageForHeatmapSave();
        if (!success) {
            return false;
        }
        //1. check result/response code
        //todo: clean this up
        const preSignedURL = result && result.data ? result.data.preSignedURL : null;
        const heatmapsPreSigned = resultHeatmaps && resultHeatmaps.data ? resultHeatmaps.data.preSignedURL : null;

        if (!preSignedURL || !heatmapsPreSigned) {
            console.log('houston we got a problem, no presignedURL');
            //todo: handle this error, give the user some feedback
            return;
        }

        // todo: Check if user has permission/accessLevel to upload file
        //       actually if user doesn't have permissions they shouldn't
        //       be in this dialog at all

        // Step 2: Upload file to AWS S3 bucket using pre-signed url
        // Making straight axios call in order to access the ProgressEvent
        // interface which allows up to caputre upload progress and pass
        // that data along to spinner component
        const newAxiosInstance = axios.create();

        //todo: make sure mergedImage is formatted correctly as a file
        const { previewImage, saveFileName, detailsAddress, detailsFloorNumber } = this.state;
        this.setState({ 
            isLoading: true 
        });

        return newAxiosInstance.put(heatmapsPreSigned, this.dataURLtoFile(heatmapFP, saveFileName + '_heatmap'), {
            'Content-type': 'image/png',
        }).then((heatmap_res) => {
            return newAxiosInstance.put(preSignedURL, this.dataURLtoFile(previewImage, saveFileName), {
                'Content-type': 'image/png',
                // Progress bar to show the progress of upload while user is waiting
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percentUploadComplete = Math.round((loaded * 100) / total);
                    this.setState({ 
                        percentUploadComplete 
                    }, async () => {
                        if (percentUploadComplete === 100) {
                            this.setState({ 
                                isLoading: false 
                            });
                            if (typeof this.props.onUploadCompleted === 'function') {
                                //  Step 3: on success send uploaded file details to server
                                const data = {
                                    filename: saveFileName,
                                    address: detailsAddress || null,
                                    floornumber: detailsFloorNumber || null,
                                    heatmapversion: !!(heatmap_res && heatmap_res.status === 200),
                                };
    
                                // this seems weird because the floor plan data isn't associated to the file
                                await floorPlans.addFloorPlan(projectId, data);
    
                                //this.props.onCancel();
                                //todo: verify onUploadCompleted() is defined and a function
                                this.props.onUploadCompleted();
                            }
                        }
                    });
                },
            })
        }).catch((err) => {
            console.log('uploadFile error: ', err);
        });

        // Step 4: Get updated list of floorplans

        //this needs to be a function from the caller
        //const updatedList = await floorPlans.getFloorPlans(this.state.projectId);
        //this.setState({ floorPlans: updatedList.data.data });
 
    }

    previewActivity() {
        const { classes } = this.props;

        const imageWidth = this.state.imageViewDimensions !== null ? this.state.imageViewDimensions.width + "px" : "50%";
        const imageHeight = this.state.imageViewDimensions !== null ? this.state.imageViewDimensions.height + "px" : "50%";
        const imgStyle = {
            width: imageWidth,
            height: imageHeight,
        };

        return (
            <div className={classes.modalContent}>
                <div className={classes.modalTitle}>
                    <Typography variant='h5'>New Floor Plan Preview</Typography>
                    <Typography variant='subtitle1'>{this.state.saveFileName}</Typography>
                </div>
                <div
                    className={classes.modalBody}
                    ref={this.imageContainerDivRef}>
                    <img
                        src={this.state.previewImage}
                        alt="Working Image"
                        className={classes.isHidden}
                        style={imgStyle}
                        onLoad={(e) => {
                            if (this.state.croppedImageDimensions === null) {
                                this.setState({
                                    workingFileVisible: false,
                                    mergedImageVisible: true,
                                    previewImage: this.state.mergeImage,
                                    croppedImageDimensions: {
                                        width: e.target.naturalWidth,
                                        height: e.target.naturalHeight
                                    }
                                }, () => {
                                    if (this.state.workingFileDimensions === null) {
                                        this.setState({
                                            workingFileDimensions: {
                                                width: this.state.croppedImageDimensions.width,
                                                height: this.state.croppedImageDimensions.height
                                            }
                                        }, () => {
                                            this.mergeFloorPlanIntoTemplate();
                                        });
                                    } else {
                                        this.mergeFloorPlanIntoTemplate();
                                    }
                                });

                            } else if (this.state.mergedImageVisible === true) {

                                if (this.state.templateFileDimensions === null) {
                                    this.setState({
                                        templateFileDimensions: {
                                            width: e.target.naturalWidth,
                                            height: e.target.naturalHeight
                                        }
                                    });
                                }
                                e.target.className = classes.isVisible;
                            } else {
                                e.target.className = classes.isVisible;
                            }
                        }}
                    />
                </div>
                <div className={classes.modalActions}>
                    <div className={classes.buttons}>
                        <Button
                            onClick={this.props.onCancel}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            disabled={this.state.isCropDisabled}
                            onClick={(e) => {
                                this.setState({ activeActivity: 'crop' });
                            }}>
                            Crop
                        </Button>
                        <Button
                            color="primary"
                            onClick={(e) => {
                                this.setState({ activeActivity: 'rename' });
                            }}>
                            Rename
                        </Button>
                        <Button
                            color="primary"
                            onClick={(e) => {
                                this.setState({ activeActivity: 'details' });
                            }}>
                            Details
                        </Button>
                        <Button color="primary"
                            disabled={this.state.isUploadDisabled}
                            onClick={(e) => {
                                this.setState({ activeActivity: 'upload' })
                                this.uploadFile();
                            }}>
                            Upload
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    cropActivity() {
        const { classes } = this.props;

        const cropperWidth = this.state.cropperDimensions !== null ? this.state.cropperDimensions.width + 'px' : 0;
        const cropperHeight = this.state.cropperDimensions !== null ? this.state.cropperDimensions.height + 'px' : 0;

        return (
            <div className={classes.modalContent}>
                <div className={classes.modalTitle}>
                    <Typography variant='h5'>New Floor Plan Crop</Typography>
                    <Typography variant='subtitle1'>{this.state.saveFileName}</Typography>
                </div>
                <div className={classes.modalBody} ref={this.modalBodyDivRef}>
                    <div className={classes.editPanel}>
                        <Toolbar className={classes.toolBar} ref={this.cropperToolBarContainerDivRef}>
                            <IconButton className={classes.iconButton}
                                color="inherit"
                                onClick={() => {
                                    if (this.state.cropper !== null) {
                                        this.state.cropper.zoom(0.2);
                                    }

                                }}
                                aria-label="Close"
                            >
                                <ZoomInIcon />
                            </IconButton>
                            <IconButton className={classes.iconButton}
                                color="inherit"
                                onClick={() => {
                                    if (this.state.cropper !== null) {
                                        this.state.cropper.zoom(-0.2);
                                    }

                                }}
                                aria-label="Close"
                            >
                                <ZoomOutIcon />
                            </IconButton>
                            <IconButton className={classes.iconButton}
                                color="inherit"
                                onClick={() => {
                                    if (this.state.cropper !== null) {
                                        this.state.cropper.rotate(-45);
                                    }

                                }}
                                aria-label="Close"
                            >
                                <RotateLeftIcon />
                            </IconButton>
                            <IconButton className={classes.iconButton}
                                color="inherit"
                                onClick={() => {
                                    if (this.state.cropper !== null) {
                                        this.state.cropper.rotate(45);
                                    }

                                }}
                                aria-label="Close"
                            >
                                <RotateRightIcon />
                            </IconButton>
                        </Toolbar>
                        <div className='cropperContainer' ref={this.cropperContainerDivRef} style={{ height: cropperHeight, width: cropperWidth }} >
                            <Cropper
                                style={{ height: cropperHeight, width: cropperWidth }}
                                zoom={-2}
                                //aspectRatio={16 / 9}
                                //preview={'.' + classes.imgPreviewContainer}
                                guides={true}
                                src={this.state.selectedImage}

                                ref={cropper => {
                                    this.cropper = cropper;
                                }}
                                viewMode={1}
                                dragMode="move"
                                cropBoxMovable={true}

                                background={true}
                                responsive={true}
                                autoCropArea={0.8}
                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                ready={(event) => {
                                    //console.log(event);
                                    /*
                                                                        const canvas = instance.getCanvasData();
                                                                        console.log('canvas left: ' + canvas.left + ', top: ' + canvas.top + ', width: ' + canvas.width + ', height: ' + canvas.height);
                                                                        const cropBoxData = instance.getCropBoxData();
                                                                        console.log('cropBox left: ' + cropBoxData.left + ', top: ' + cropBoxData.top + ', width: ' + cropBoxData.width + ', height: ' + cropBoxData.height)
                                                                      this.setState({cropper: instance});
                                                                      */
                                }}
                                onInitialized={(instance) => {
                                    this.setState({ cropper: instance });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.modalActions}>
                    <div className={classes.buttons}>
                        <Button
                            onClick={(e) => {
                                this.setState({ activeActivity: 'preview' });
                            }}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            onClick={(e) => {
                                //todo: this.setState.croppedImage
                                this.getCropData();
                            }}>
                            Done
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    renameActivity() {
        const { classes } = this.props;

        return (
            <div className={classes.modalContent}>
                <div className={classes.modalTitle}>
                    <Typography variant='h5'>New Floor Plan Rename</Typography>
                    <Typography variant='subtitle1'>{this.state.saveFileName}</Typography>
                </div>

                <div className={classes.modalBody} style={{ background: "white" }}>
                    <RenamePanel
                        floorPlanFiles={this.state.floorPlanFiles}
                        sourceFileName={this.state.saveFileName}
                        onChange={(e) => {
                            // todo: connect this to state
                            this.setState({
                                isRenameDoneDisabled: !e.target.canSave,
                                tempSaveFileName: e.target.saveFileNameValue,
                            });
                        }}
                    />
                </div>

                <div className={classes.modalActions}>
                    <div className={classes.buttons}>
                        <Button
                            onClick={(e) => {
                                this.setState({ activeActivity: 'preview' });
                            }}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            disabled={this.state.isRenameDoneDisabled}
                            onClick={(e) => {
                                //todo set this.state.saveFileName
                                this.setState({
                                    activeActivity: 'preview',
                                    saveFileName: this.state.tempSaveFileName + '.png',
                                });
                            }}>
                            Done
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    detailsActivity() {
        const { classes } = this.props;

        return (
            <div className={classes.modalContent}>
                <div className={classes.modalTitle}>
                    <Typography variant='h5'>New Floor Plan Details</Typography>
                    <Typography variant='subtitle1'>{this.state.saveFileName}</Typography>
                </div>
                <div className={classes.modalBody} style={{ background: "white" }}>
                    <div className={classes.content}>
                        <div style={{marginTop: "20px"}}>
                            <TextField
                                fullWidth={true}
                                label="Address"
                                name="detailsAddress"
                                autoComplete="detailsAddress"
                                style={{fontFamily: 'Gotham'}}
                                onChange={(newValue) => this.handleChange("address", newValue)}
                                value={this.state.detailsAddress}
                                autoFocus
                            />
                        </div>
                        <div style={{marginTop: "20px"}}>
                            <TextField
                                fullWidth={true}
                                label="Floor Number"
                                name="detailsFloorNumber"
                                autoComplete="detailsFloorNumber"
                                style={{fontFamily: 'Gotham'}}
                                onChange={(newValue) => this.handleChange("floorNumber", newValue)}
                                value={this.state.detailsFloorNumber}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.modalActions}>
                    <div className={classes.buttons}>
                        <Button
                            color="primary"
                            disabled={this.state.isRenameDoneDisabled}
                            onClick={(e) => {
                                this.setState({ activeActivity: 'preview' });
                            }}>
                            Done
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    uploadActivity() {
        const { classes } = this.props;

        return (
            <div className={classes.modalContent}>
                <div className={classes.modalTitle}>
                    <Typography variant='h5'>New Floor Plan File Upload</Typography>
                    <Typography variant='subtitle1'>{this.state.saveFileName}</Typography>
                </div>
                <div className={classes.modalBody} style={{ background: "white" }}>
                    <ProgressBar
                        bgcolor='rgb(130, 188, 0)'
                        complete={this.state.percentUploadComplete}
                    />
                </div>
                <div className={classes.modalActions}>
                    <div className={classes.buttons}>
                        <Button
                            onClick={(e) => {
                                // todo: stop the upload in progress
                                this.setState({ activeActivity: 'preview' });
                            }}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    errorActivity() {
        const { classes } = this.props;

        return (
            <div className={classes.modalContent}>
                <div className={classes.modalTitle}>
                    <Typography variant='h5'>New Floor Plan Error</Typography>
                    <Typography variant='subtitle1'>{this.props.imageFile.name}</Typography>
                </div>
                <div className={classes.modalBody} style={{ background: "white" }}>
                    <div>
                        <Typography variant='body1'>The chosen file type is not supported. Please select a jpg or png image file.</Typography>
                    </div>
                </div>
                <div className={classes.modalActions}>
                    <div className={classes.buttons}>
                        <Button onClick={this.props.onCancel}>
                            Close
                        </Button>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.modal}>
                {
                    this.state.activeActivity === 'crop' ? this.cropActivity() :
                        this.state.activeActivity === 'rename' ? this.renameActivity() :
                            this.state.activeActivity === 'upload' ? this.uploadActivity() :
                                this.state.activeActivity === 'error' ? this.errorActivity() :
                                    this.state.activeActivity === 'details' ? this.detailsActivity() :
                                        this.previewActivity()
                }
            </div>
        )
    }
}
export default withStyles(styles)(FloorPlanCropperDialog);
