import React from '../../../node_modules/react';
import { Context } from '../../context/Context';
import tests from '../../api/tests';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Dropdown} from "../Dropdown/Dropdown";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {NestedTableOP} from "../NestedTable/NestedTableOP";
import { format, parseISO } from "date-fns";
import { DatePicker } from "@material-ui/pickers";

const styles = theme => ({
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'center',
        padding: '10px !important'
    },
    cell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        textAlign: 'center',
        borderRight: 'none',
        borderLeft: 'none',
    },
    tableRow: {
        cursor: 'pointer',
    }
});

class MiniSurveyTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editable: true,
            open: false,
            alertMsg: '',
            updateSuccessful: false,
            workFlow: false,
            segmentClicked: [],
            testCompleted: null,
            testId: '',
            accessLevel: JSON.parse(localStorage.getItem("accessLevel")) ? JSON.parse(localStorage.getItem("accessLevel")) : null,
            isViewer: false
        }
    }

    async componentDidMount() {
        let response = null;
        let testId = JSON.parse(localStorage.getItem("testId")) ? JSON.parse(localStorage.getItem("testId")) : null;
        let isViewer = false;

        if(this.state.accessLevel === 'Viewer') {
            isViewer = true;
        }

        this.setState({
            isViewer,
            testId: testId
        }, async () => {
            if(testId !== null) {
                response = await tests.getOneTest(this.state.testId);
                if(response.data.success) {
                    this.setState({ testCompleted: response.data.data.testexecuted });
                }
            }
        });
    }

    render() {
        const {
            segment,
            scenario,
            floorPlans,
            classes,
            onChangeFunc,
            onTextChangeFunc,
            onKeyDownTextUpdate,
            onSurveyDateUpdateFunc,
            handleTagLotNumberChange,
            onKeyDownLotNumberExpirationDateUpdate,
            ops,
            surveyDate,
            comments,
            tagLotNumbers,
            expirationDates
        } = this.props;
        const { testCompleted, isViewer } = this.state;

        const handleDateChange = e => {
            onSurveyDateUpdateFunc(format(e, 'yyyy-MM-dd'));
        }

        if (!segment || !scenario || !ops) {
            return (<main />)
        }
        return (

            <main>
                <TableContainer style={{ marginTop: '15px', width: '100%' }} component={Paper}>
                    <div>
                        <Table style={{ width: '100%' }} aria-label="segments dilution table">
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell style={{ width: "15%", padding: "20px" }} className={classes.headerCell}>Floor Plan*</TableCell>
                                    <TableCell className={classes.headerCell}>No: of OP</TableCell>
                                    <TableCell className={classes.headerCell}>No: of SP</TableCell>
                                    <TableCell className={classes.headerCell}>Survey Date*</TableCell>
                                    <TableCell className={classes.headerCell}>Comments</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.cell}>
                                        <Dropdown
                                            testCompleted={testCompleted}
                                            isViewer={isViewer}
                                            coltype='floorPlans'
                                            fpOptions={floorPlans}
                                            onChangeFunc={onChangeFunc}
                                            segment={segment} />
                                    </TableCell>
                                    <TableCell className={classes.cell}>{1}</TableCell>
                                    <TableCell className={classes.cell}>{4}</TableCell>
                                    <TableCell className={classes.cell}>
                                        <form onSubmit={e => handleDateChange(e)} noValidate autoComplete="off">
                                            <div>
                                                <DatePicker
                                                    variant="inline"
                                                    name='surveyDate'
                                                    format={'MMM do, yyyy'}
                                                    autoOk={true}
                                                    emptyLabel=''
                                                    inputProps={{ min: 0, style: { textAlign: 'left', paddingLeft: '10px', minWidth: '135px'} }}
                                                    value={surveyDate !== null ? parseISO(surveyDate) : null}
                                                    onChange={e => handleDateChange(e)}
                                                    disabled={testCompleted || isViewer}
                                                />
                                            </div>
                                        </form>
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        <form onSubmit={e => onKeyDownTextUpdate(e, 'comments', null, 0)} noValidate autoComplete="off">
                                            <div>
                                                <TextField
                                                    coltype='comments'
                                                    disabled={testCompleted || isViewer}
                                                    type="text"
                                                    multiline
                                                    rows={3}
                                                    inputProps={{
                                                        style: {
                                                            fontFamily: 'Gotham',
                                                            fontSize: '13px',
                                                            marginTop: '10px',
                                                            width: '75%',
                                                            height: 'auto'
                                                        }
                                                    }}
                                                    InputProps={{ disableUnderline: true }}
                                                    value={comments || ""}
                                                    onChange={e => onTextChangeFunc(e, 0, 'comments')}
                                                />
                                                <Tooltip title="Submit Comments" placement="top" aria-label="origin-points">
                                                    <IconButton
                                                        type="submit"
                                                        style={{ position: 'relative', right: '40px' }}
                                                        disabled={testCompleted || isViewer}
                                                    >
                                                        <CloudUploadIcon style={{color: "rgba(0, 0, 0, 0.54)"}} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </form>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
                <TableContainer style={{ marginTop: '15px', width: '100%' }} component={Paper}>
                    <div>
                        <Table aria-label="ops">
                            <TableHead>
                                <TableRow style={{ backgroundColor: '#F3F3F3' }}>
                                    <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell} colSpan={4}>Origin Points</TableCell>
                                    <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}></TableCell>
                                    <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}>OP Name</TableCell>
                                    <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}>Tag*</TableCell>
                                    <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}>Tag Lot Number</TableCell>
                                    <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}>Expiration Date</TableCell>
                                </TableRow>
                                <NestedTableOP
                                    index={0}
                                    segments={[segment]}
                                    scenario={scenario}
                                    isViewer={isViewer}
                                    testCompleted={testCompleted}
                                    tagLotNumbers={tagLotNumbers}
                                    expirationDates={expirationDates}
                                    onChangeFunc={onChangeFunc}
                                    handleTagLotNumberChange={handleTagLotNumberChange}
                                    onKeyDownLotNumberExpirationDateUpdate={onKeyDownLotNumberExpirationDateUpdate}
                                    ops={ops} />
                            </TableHead>
                        </Table>
                    </div>
                </TableContainer>
            </main>
        )
    }
}
MiniSurveyTest.contextType = Context;
export default withStyles(styles, { withTheme: true })(MiniSurveyTest);
