import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoginView from './views/LoginView/LoginView';
import Projects from './views/Projects/Projects.js';
import ProjectsView from './views/ProjectsView/ProjectsView.js';
import ProjectDetails from './views/ProjectDetails/ProjectDetails.js';
import createProject from './views/createProject/createProject.js';
import FloorPlans from './views/FloorPlans/FloorPlans.js';
import Segments from './views/Segments/Segments.js';
import Scenarios from './views/Scenarios/Scenarios.js';
import OriginPoints from './views/OriginPoints/OriginPoints.js'
import OriginPointsLabels from './views/OriginPointsLabels/OriginPointsLabels.js';
import SamplePoints from './views/SamplePoints/SamplePoints.js';
import SamplePointsLabels from './views/SamplePointsLabels/SamplePointsLabels.js';
import TestPlan from './views/TestPlan/TestPlan.js';
import TestPlanDilution from './views/TestPlan/TestPlanDilution.js';
import SamplePlanForProject from './views/SamplePlanForProject/SamplePlanForProject.js';
import SampleCollectorLabels from './views/SampleCollectorLabels/SampleCollectorLabels.js';
import SamplePlan from './views/SamplePlan/SamplePlan.js';
import { SamplesView } from './views/SamplesView/SamplesView';
import LabelsView from './views/LabelsView/LabelsView';
import ChecklistsView from './views/ChecklistsView/ChecklistsView';
import LaboratoryView from './views/LaboratoryView/LaboratoryView';
import QCChecklistView from './views/QCChecklistView/QCChecklistView';
import COCChecklistView from './views/COCChecklistView/COCChecklistView';
import { TagsChecklistView } from './views/TagsChecklistView/TagsChecklistView';
import { LabRequirementsChecklistView } from './views/LabRequirementsChecklistView/LabRequirementsChecklistView';
import { UsersView } from './views/UsersView/UsersView';
import NewUserView from './views/NewUserView/NewUserView';
import NewTestView from './views/NewTestView/NewTestView';
import { TagsView } from './views/TagsView/TagsView';
import { MaterialsChecklistView } from './views/MaterialsChecklistView/MaterialsChecklistView';
import { Navbar } from './components/Navbar/Navbar.js';
import { Drawer, IconButton } from '@material-ui/core';
import DrawerList from './components/DrawerList/DrawerList';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ResetPasswordView from './views/ResetPasswordView/ResetPasswordView';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { Context } from './context/Context';
import '../src/App.css';
import Visualizer from './views/Visualizer/Visualizer.js';
import DataCollectionView from './views/ProjectsView/DataCollectionView';


import { withStyles } from "@material-ui/core/styles";


const styles = theme => ({
    drawer: {
        width: 240,
        flexShrink: 0,
        position: 'fixed',
        zIndex: 1
    }
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            left: false
        };
    }

    toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ ...this.state, [anchor]: open });
    };

    render() {
        const { classes } = this.props;
        return (
            <main>
                <Route component={Navbar} />
                <Route exact path="/">
                    <Redirect to="/login" />
                </Route>
                <Route path='/login' component={LoginView} />
                <div style={{ display: 'flex' }}>
                    <div className='drawer-wrapper'>
                        {/*
                        <IconButton style={{ width: '100px', height: '100px' }} onClick={this.toggleDrawer('left', true)}>
                            <MenuOpenIcon style={{ color: '#008996' }} />
                        </IconButton>
                        */}
                        <Drawer
                            className={classes.drawer}
                            style={{ width: '290px' }}
                            variant="permanent"
                        //anchor='left' 
                        //open={this.state.left} 
                        //onClose={this.toggleDrawer('left', false)}
                        >
                            <DrawerList />
                        </Drawer>
                    </div>
                    <div className='app-content'>
                        <Switch>
                            <ProtectedRoute path='/projects' component={Projects} />
                            <ProtectedRoute path='/projectdetails' component={ProjectDetails} />
                            <ProtectedRoute path='/projectview' component={ProjectsView} />
                            <ProtectedRoute path='/create' component={createProject} />
                            <ProtectedRoute path='/floorplans' component={FloorPlans} />
                            <ProtectedRoute path='/segments' component={Segments} />
                            <ProtectedRoute path='/scenarios' component={Scenarios} />
                            <ProtectedRoute path='/originpoints' component={OriginPoints} />
                            <ProtectedRoute path='/labels/originpoints' component={OriginPointsLabels} />
                            <ProtectedRoute path='/samplepoints' component={SamplePoints} />
                            <ProtectedRoute path='/labels/samplepoints' component={SamplePointsLabels} />
                            <ProtectedRoute path='/testplan' component={TestPlan} />
                            <ProtectedRoute path='/testplandilution' component={TestPlanDilution} />
                            <ProtectedRoute path='/sampleplan' component={SamplePlanForProject} />
                            <ProtectedRoute path='/viewsamples' component={SamplesView} />
                            <ProtectedRoute path='/labels/samplecollectors' component={SampleCollectorLabels} />
                            <ProtectedRoute path='/:segmentId/sampleplan' component={SamplePlan} />
                            <ProtectedRoute path='/labelsview' component={LabelsView} />
                            <ProtectedRoute path='/checklistsview' component={ChecklistsView} />
                            <ProtectedRoute path='/qcchecklistview' component={QCChecklistView} />
                            <ProtectedRoute path='/cocchecklistview' component={COCChecklistView} />
                            <ProtectedRoute path='/tagschecklistview' component={TagsChecklistView} />
                            <ProtectedRoute path='/materialschecklistview' component={MaterialsChecklistView} />
                            <ProtectedRoute path='/laboratorysview' component={LaboratoryView} />
                            <ProtectedRoute path='/labrequirementchecklistview' component={LabRequirementsChecklistView} />
                            <ProtectedRoute path='/users' component={UsersView} />
                            <ProtectedRoute path='/newuser' component={NewUserView} />
                            <ProtectedRoute path='/newtest' component={NewTestView} />
                            <ProtectedRoute path='/visualizer' component={Visualizer} />
                            <ProtectedRoute path='/datacollectionview' component={DataCollectionView} />
                            <ProtectedRoute path='/tags' component={TagsView} />
                            <Route path='/resetpassword' component={ResetPasswordView} />
                        </Switch>
                    </div>
                </div>
            </main>
        )
    }
}
App.contextType = Context;
//export default App;

export default withStyles(styles, { withTheme: true })(App);