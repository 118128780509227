import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import tests from '../../api/tests';
import projects from '../../api/projects';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Tooltip from '@material-ui/core/Tooltip';
import { Context } from '../../context/Context';
import { useHistory } from "react-router";
import { CSVLink } from 'react-csv';
import SelectTestCompleted from '../SelectTestCompleted/SelectTestCompleted';
import { AlertTestCompleted } from '../../components/Alert/AlertTestCompleted';
import utils from '../../utils/utils';
import sampleplans from "../../api/sampleplans";
import projectStatuses from '../../data/projectStatusData';
import { testTypesConfig } from '../../config';

const useRowStyles = makeStyles((theme) => ({
    cell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        textAlign: 'center',
        borderRight: 'none',
        borderLeft: 'none',
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'center',
        padding: '10px !important'
    },
    tableRow: {
        cursor: 'pointer',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(7, 5, 7),
        textAlign: 'center',
        fontFamily: "Gotham"
    },
}));

export const NestedTable = ({ project, index, projectId, projectName, createdAt, status, testCompleted, projTests, fetchUpdatedProjects, projectFromProjectPortal }) => {
    const history = useHistory();
    const classes = useRowStyles();
    const [ open, setOpen ] = useState(false);
    const { setTypeName, setOnProject, setTypeId, setTestCompleted, state, setTestType, setProjectName, setNotOnProject, setProjectFromProjectPortal } = useContext(Context);
    const [ openProjectModal, setOpenProjectModal ] = useState(false);
    const [ openTestModal, setOpenTestModal ] = useState(false);
    const [ openTestCompletedAlert, setOpenTestCompletedAlert ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState('');
    let show = false;
    let clickable = false;
    let len = 0;
    let disableAddTest = false;
    let accessLevel = state.accessLevel;
    let projectHasCompletedTest = false;

    if (accessLevel === 'Viewer' || !!~['res_generated', 'published'].indexOf(status)) {
        disableAddTest = true;
    }

    const checkIfProjHasCompletedTests = project => {
        if(project.tests.length > 0) {    
            for(let test of project.tests) {
                if(test.testexecuted === true && (test.projectid === projectId)) {
                    projectHasCompletedTest = true;
                    break;
                }
            }
        } else if (status === 'published') {
            projectHasCompletedTest = true;
        }
    }

    const checkTestsLen = project => {
        if(project.hasOwnProperty('tests')) {
            let { tests } = project;
            return tests.length
        }
    }

    const handleCheckDate = createdAt => {
        let cutOffDate = '11/10/2020';
        cutOffDate = Date.parse(cutOffDate);
        createdAt = Date.parse(createdAt);
        return createdAt < cutOffDate;
    }

    //To show/hide nested table(s)
    len = checkTestsLen(project); {
        if(len > 0) {
            show = true;
        }
    }
    //To determine if old flow project or new flow project
    clickable = handleCheckDate(createdAt);

    const onRowSelected = (setTypeName, setOnProject, setTypeId, projectId, testId, typeName, type, setTestType, testType, projectName) => {
        setTypeName(typeName, type);
        setOnProject();
        setTypeId(projectId, testId, type);
        setTestType(testType);
        setProjectName(projectName);
        setProjectFromProjectPortal(projectFromProjectPortal);
        if (testType === 'generalSurvey'){
            history.push('/segments');
        } else {
            history.push('/scenarios');
        }

    }

    const updateOneTest = async(params) => {
        const { testid } = params.test;
        let data = { testCompleted: params.value === true ? true : false };
        let res = await tests.updateOneTest(testid, data);

        //logic to check if all tags have assigned lot numbers else launch alert
        if(res.data.error) {
            setErrorMsg(res.data.error.message);
            setOpenTestCompletedAlert(!openTestCompletedAlert);
        }

        let { success } = res.data;            
        if(success === true) {
            setTestCompleted(params.value);
            fetchUpdatedProjects();
        }
    }

    const onSelectChange = params => {
        updateOneTest(params)
    }

    const handleNewTestClick = projId => {
        let projectId = JSON.stringify(projId);
        setProjectFromProjectPortal(projectFromProjectPortal);
        localStorage.setItem("projectId", projectId);
        history.push('/newtest');
    }

    const handleModalOpen = (modalType, id) => {
        if(modalType === 'project') {
            setOpenProjectModal(!openProjectModal);
        }
        if(modalType === 'test') {
            if(id) {
                let testId = JSON.stringify(id);
                localStorage.setItem("testId", testId);
                setOpenTestModal(!openTestModal);
            }
        }
    }

    const handleModalClose = modalType => {
        if(modalType === 'project') {
            setOpenProjectModal(!openProjectModal);
        }
        if(modalType === 'test') {
            setOpenTestModal(!openTestModal);
        }
    }

    const handleDelete = async (id, type, project) => {
        if(type === 'project') {
            let response = await projects.deleteOneProject(id);
            if(response.data.success) {
                fetchUpdatedProjects();
                handleModalClose('project');
            }
        }
        if(type === 'test') {
            let testId = JSON.parse(localStorage.getItem("testId"));
            if(testId) {
                let response = await tests.removeOneTest(testId);
                if(response.data.success) {
                    fetchUpdatedProjects();
                    handleModalClose('test');
                    await sampleplans.updateSamplePlan(project.id)
                    localStorage.removeItem('testId');
                }
            }
        } 
    }

    const handleCollapse = () => setOpen(!open);

    const handleClosingAlert = () => setOpenTestCompletedAlert(!openTestCompletedAlert);

    const handleProjectFlow = (setNotOnProject, setProjectName, projectName, projId) => {
        let projectId = JSON.stringify(projId);
        localStorage.setItem("projectId", projectId);
        setNotOnProject();
        setProjectName(projectName);
        history.push('/projectdetails');
    }

    if(project) {
        checkIfProjHasCompletedTests(project);
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openProjectModal}
                    onClose={() => handleModalClose('project')}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}>
                    <div>
                        <Fade in={openProjectModal}>
                        <div className={classes.paper}>
                            <h2 id="transition-modal-title">Are you sure you want to delete "{projectName}" project?</h2>
                            <div>
                                <Button
                                    onClick={() => handleDelete(projectId, 'project')}
                                    size="small"
                                    style={{backgroundColor: "rgb(0, 137, 150)", color: "#ffffff", fontFamily: "Gotham", margin: "5px"}}>Yes
                                </Button>
                                <Button
                                    onClick={() => handleModalClose('project')}
                                    size="small"
                                    style={{backgroundColor: "rgb(0, 137, 150)", color: "#ffffff", fontFamily: "Gotham", margin: "5px"}}>No
                                </Button>
                            </div>
                        </div>
                        </Fade>
                    </div>
                </Modal>
                <TableRow className={classes.tableRow} hover={true} key={`key=${index}`}>
                    {!!show ? (
                        <>
                            <TableCell className={classes.cell}>
                                <IconButton aria-label="expand row" size="small" onClick={() => handleCollapse()}>
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </TableCell>
                        </>
                    ) : (
                        <TableCell className={classes.cell}></TableCell>
                    )}
                    {accessLevel !== 'Viewer' && (
                        <TableCell className={classes.cell}>
                            <IconButton disabled={projectHasCompletedTest} onClick={() => handleModalOpen('project')} aria-label="delete">
                                <DeleteForeverIcon />
                            </IconButton>
                        </TableCell>
                    )}
                    <TableCell className={classes.cell} onClick={!clickable ? () => handleProjectFlow(setNotOnProject, setProjectName, projectName, projectId) : () => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, null, projectName, "Project", setTestType, null)}>
                        <Tooltip title={`${project.name}`} placement="top" aria-label="project-name">
                            <div>{!!project.name ? utils.truncate(project.name, 25) : project.name}</div>
                        </Tooltip>
                    </TableCell>
                    <TableCell className={classes.cell} onClick={!clickable ? () => handleProjectFlow(setNotOnProject, setProjectName, projectName, projectId) : () => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, null, projectName, "Project", setTestType, null)}>
                        {project.id}
                    </TableCell>
                    <TableCell className={classes.cell} onClick={!clickable ? () => handleProjectFlow(setNotOnProject, setProjectName, projectName, projectId) : () => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, null, projectName, "Project", setTestType, null)}>
                        {project.endCustomer}
                    </TableCell>
                    <TableCell className={classes.cell} onClick={!clickable ? () => handleProjectFlow(setNotOnProject, setProjectName, projectName, projectId) : () => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, null, projectName, "Project", setTestType, null)}>
                        {project.partners}
                    </TableCell>
                    <TableCell className={classes.cell} onClick={!clickable ? () => handleProjectFlow(setNotOnProject, setProjectName, projectName, projectId) : () => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, null, projectName, "Project", setTestType, null)}>
                        {project.createdAt}
                    </TableCell>
                    <TableCell className={classes.cell} onClick={!clickable ? () => handleProjectFlow(setNotOnProject, setProjectName, projectName, projectId) : () => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, null, projectName, "Project", setTestType, null)}>
                        {project.description}
                    </TableCell>
                    <TableCell className={classes.cell} onClick={!clickable ? () => handleProjectFlow(setNotOnProject, setProjectName, projectName, projectId) : () => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, null, projectName, "Project", setTestType, null)}>
                        {projectStatuses[project.status] || ''}
                    </TableCell>
                    <TableCell className={classes.cell}>
                        <IconButton
                            disabled={!projectFromProjectPortal ? true : disableAddTest}  
                            onClick={() => handleNewTestClick(projectId)} 
                            aria-label="add">
                            <AddBoxIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
                {!!show && (
                    <>
                        <TableRow >
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={6}>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <Box margin={1}>
                                        <Table aria-label="purchases">
                                            <TableHead>
                                                <TableRow>
                                                    {accessLevel !== 'Viewer' && (
                                                        <>
                                                            <TableCell className={classes.headerCell}></TableCell>
                                                            <TableCell className={classes.headerCell}></TableCell>
                                                            <TableCell className={classes.headerCell}></TableCell>
                                                            <TableCell className={classes.headerCell}>Delete Test</TableCell>
                                                        </>
                                                    )}
                                                    <TableCell className={classes.headerCell}>Test Name</TableCell>
                                                    <TableCell className={classes.headerCell}>Type</TableCell>
                                                    <TableCell className={classes.headerCell}>Description</TableCell>
                                                    <TableCell className={classes.headerCell}>Test Completed</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {project.tests.map((test, i) => (
                                                <>
                                                    <TableBody key={`key-${i}`}>
                                                        <TableRow 
                                                            hover={true}
                                                            className={classes.tableRow}>
                                                                {accessLevel !== 'Viewer' && (
                                                                    <>
                                                                        <TableCell style={{ border: 'none' }}></TableCell>
                                                                        <TableCell style={{ border: 'none' }}></TableCell>
                                                                        <TableCell style={{ border: 'none' }}></TableCell>
                                                                        <TableCell className={classes.cell}>
                                                                            <IconButton disabled={test.testexecuted || !projectFromProjectPortal || status === 'published'} onClick={() => handleModalOpen('test', test.testid)} aria-label="delete">
                                                                                <DeleteForeverIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </>
                                                                )}
                                                            <TableCell 
                                                                onClick={() => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, test.testid, test.testname, "Test", setTestType, test.testtype, project.name)}
                                                                className={classes.cell}>{test.testname}</TableCell>
                                                            <TableCell 
                                                                onClick={() => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, test.testid, test.testname, "Test", setTestType, test.testtype, project.name)} 
                                                                className={classes.cell}>{testTypesConfig[test.testtype] || ''}</TableCell>
                                                            <TableCell 
                                                                onClick={() => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, test.testid, test.testname, "Test", setTestType, test.testtype, project.name)} 
                                                                className={classes.cell}>{test.overview}</TableCell>
                                                            <TableCell className={classes.cell}>
                                                                <SelectTestCompleted
                                                                    test={test} 
                                                                    testExecuted={test.testexecuted}
                                                                    onSelectChange={onSelectChange} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <Modal
                                                            aria-labelledby="transition-modal-title"
                                                            aria-describedby="transition-modal-description"
                                                            className={classes.modal}
                                                            open={openTestModal && JSON.stringify(test.testid) === localStorage.getItem("testId")}
                                                            onClose={() => handleModalClose('test')}
                                                            closeAfterTransition
                                                            BackdropComponent={Backdrop}
                                                            BackdropProps={{
                                                            timeout: 500,
                                                            }}>
                                                            <div>
                                                                <Fade in={openTestModal}>
                                                                    <div className={classes.paper}>
                                                                        <h2 id="transition-modal-title">Are you sure you want to delete "{test.testname}" test?</h2>
                                                                        <div>
                                                                            <Button
                                                                                onClick={() => handleDelete(test.testid, 'test', project)}
                                                                                size="small"
                                                                                style={{backgroundColor: "rgb(0, 137, 150)", color: "#ffffff", fontFamily: "Gotham", margin: "5px"}}>Yes
                                                                            </Button>
                                                                            <Button
                                                                                onClick={() => handleModalClose('test')}
                                                                                size="small"
                                                                                style={{backgroundColor: "rgb(0, 137, 150)", color: "#ffffff", fontFamily: "Gotham", margin: "5px"}}>No
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Fade>
                                                            </div>
                                                        </Modal>
                                                    </TableBody>
                                                </>
                                            ))}
                                        </Table>
                                    </Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </>
                )}
                {!!openTestCompletedAlert && (<AlertTestCompleted flag={openTestCompletedAlert} msg={errorMsg} closeFunc={handleClosingAlert} />)}
                
            </>
        );
    } else {
        return null;
    }
}