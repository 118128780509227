import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const styles = () => ({
  columnHeader: {
    minHeight: "200px",
    background: "rgb(0, 137, 150)", //'lightgray',
    padding: "10px",
  },
  table: {
    border: "1px solid gray !important",
    minWidth: 400,
  },
  cell: {
    padding: "4px !important",
    fontSize: "smaller",
    border: "1px solid gray !important",
  },
  row: {
    padding: "10px",
  },
  idColumn: {
    width: "15px",
  },
  warningList: {
    padding: "15px",
    margin: "15px",
  },
});

class TestDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  renderWarnings() {
    if (Array.isArray(this.props.warnings) && this.props.warnings.length > 0) {
      return (
        <>
          <Typography>Warnings:</Typography>
          <ol style={{ margin: "25px", fontFamily: `sans-serif`, color: 'red' }}>
            {this.props.warnings.map((w) => (
              <li key={w.description}>{w.description}</li>
            ))}
          </ol>
        </>
      );
    } else {
      return (
        <>
          <Typography>No warnings</Typography>
        </>
      );
    }
  }

  render() {
    return (
      <Box
        id="TestDetails"
        display="flex"
        justifyContent="space-between"
        pt={5}
      >
        <Box>
          <Typography
            variant="h5"
            color="textPrimary"
            align="left"
            style={{ fontFamily: "Gotham", fontSize: '1.2rem' }}
          >
            PID: {this.props.projectId} , {this.props.projectName}
          </Typography>
          {!this.props.headerOnly && <>
            <Typography>
              Test type: {this.props.test.type}
            </Typography>
            <Typography>
              Completed: {this.props.test.isCompleted ? "Yes" : "No"}
            </Typography>
            {this.renderWarnings()}</>}

        </Box>
        <Box>
          <Typography
            variant="h5"
            color="textPrimary"
            align="right"
            style={{ fontFamily: "Gotham", fontSize: '1.2rem' }}
          >
            Test Number - {this.props.order}
          </Typography>
          <Typography
            variant="h5"
            color="textPrimary"
            align="right"
            style={{ fontFamily: "Gotham", fontSize: '1.2rem' }}
          >
            Test Name - {this.props.test.name}
          </Typography>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(TestDetails);
