import React from "react";
import { Box } from "@material-ui/core";

const darkCell = {
  background: "rgb(0, 137, 150)",
  color: "white",
  padding: "5px",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: "smaller",
};

const lightCell = {
  background: "white",
  borderRight: "1px solid gray",
  borderBottom: "1px solid gray",
  color: "black",
  padding: "5px",
  textAlign: "center",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: "smaller",
};

const ScenarioOpTagsNotesTable = ({ scenarioDataWithOPs }) => {
  const renderTag = (item) => {
    let relevantTag = 'n/a';
    if (Array.isArray(item?.tag)) {
      item.tag.forEach((t) => {
        const [scId, tag] = t.split(":");
        if (parseInt(scId) === parseInt(item.scenarioId)) {
          relevantTag = tag;
        }
      });
    }
    return relevantTag;
  };

  const renderInnerTable = (tableData) => {
    return (
      <table
        style={{
          border: "1px solid gray",
          borderBottom: "none",
          borderRight: "none",
          width: "48%",
          borderSpacing: 0,
          margin: "16px 0",
        }}
      >
        <thead>
          <tr>
            <th style={darkCell} width="15%">
              Scenario
            </th>
            <th style={darkCell} width="15%">
              OP No
            </th>
            <th style={darkCell} width="15%">
              Tag
            </th>
            <th style={darkCell} width="55%">
              Notes
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item) => (
            <tr key={item.opNumber}>
              <td style={lightCell} width="15%">
                {item.scenarioId}
              </td>
              <td style={lightCell} width="15%">
                {item.opNumber}
              </td>
              <td style={lightCell} width="15%">
                {renderTag(item)}
              </td>
              <td style={lightCell} width="55%"></td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  if (Array.isArray(scenarioDataWithOPs)) {
    if (scenarioDataWithOPs.length === 0) {
      return null;
    }
    if (scenarioDataWithOPs.length === 1) {
      return renderInnerTable(scenarioDataWithOPs);
    } else {
      const halves = [
        scenarioDataWithOPs.slice(0, Math.ceil(scenarioDataWithOPs.length / 2)),
        scenarioDataWithOPs.slice(Math.ceil(scenarioDataWithOPs.length / 2)),
      ];
      return (
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          {renderInnerTable(halves[0])}
          {renderInnerTable(halves[1])}
        </Box>
      );
    }
  }
};

export default ScenarioOpTagsNotesTable;
