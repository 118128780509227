import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const styles = () => ({
    root: {
      "&$checked": {
        color: "#008996"
      },
      "&$disabled": {
        color: "#bdbdbd"
      }
    },
    checked: {},
    disabled: {}
})

export const CustomCheckbox = withStyles(styles)(Checkbox);