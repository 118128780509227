import React, { Component } from "react";
import tags from "../../api/tags";
import projects from "../../api/projects";
import { withRouter } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import NoteIcon from "@material-ui/icons/Note";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { CSVLink } from "react-csv";

class BtnCellRendererLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: false,
      orderForm: false,
      ofData: [],
    };
    this.handleButtonRouting = null;
    this.projectId = JSON.parse(localStorage.getItem("projectId"));
    this.projectName = localStorage.getItem("projectName");
    this.orderFormdata = [
      ["Contact/Billing"],
      ["", "Project Name", ""],
      ["", "Project Id", ""],
      ["", "Site Address", ""],
      ["Shipping Address"],
      ["", "Recipient Name"],
      ["", "Company Name"],
      ["", "Street Address"],
      ["", "Phone Number"],
      ["Items"],
      ["", "", "", ""],
      ["", "", "", ""],
      ["", "No: of 4 way samples, cables (power)", ""],
      ["", "No: of eSprayers, cables (com.)", ""],
      ["", "No: of sprayers Nozzle", ""],
      ["", "No: of wireless nodes, cables (power & com.)", ""],
      ["", "No: of extension cables", ""],
      ["", "No: of air pump/charger set @ 65psi", ""],
      ["", "No: of OP Label Sheets", "", ""],
      ["", "No: of SP Label Sheets", "", ""],
      ["", "No: of sample Label Sheets", "", ""],
      ["", "No: of Tests In-Progress Sign", ""],
      ["", "No: of gloves", ""],
      ["Tag Solution", "", ""],
    ];
  }

  handleOPLabelsClick = () => {
    this.props.history.push("/labels/originpoints");
  };

  handleSPLabelsClick = () => {
    this.props.history.push("/labels/samplepoints");
  };

  handleSCLabelsClick = () => {
    this.props.history.push("/labels/samplecollectors");
  };

  handleTagSolutionFormatting = (ofObj, tagsObj, id) => {
    const testTypesInProject =
      ofObj.testType !== "verification" ? "all" : "verification";

    // Common fields
    this.orderFormdata[1][2] = ofObj.projectName;
    this.orderFormdata[2][2] = id;
    this.orderFormdata[3][2] = ofObj.siteName;
    this.orderFormdata[6][2] = ofObj.endCustomer;

    // Some values and fields are different depending on if it's a verification project or a mixed project
    this.orderFormdata[10][1] =
      testTypesInProject === "all" ? "No: of cassettes" : "Standrard UL Kit";
    this.orderFormdata[10][2] =
      testTypesInProject === "all" ? ofObj.numberOfCassettes : "1";
    this.orderFormdata[11][1] =
      testTypesInProject === "all" ? "No: of SKC pumps" : "No: of cassettes";
    this.orderFormdata[11][2] =
      testTypesInProject === "all"
        ? ofObj.numberOfSKCPumps
        : ofObj.numberOfCassettes;
    this.orderFormdata[12][2] = testTypesInProject === "all" ? "2" : "";
    this.orderFormdata[13][2] =
      testTypesInProject === "all" ? ofObj.numberOfeSprayers : "";
    this.orderFormdata[14][2] =
      testTypesInProject === "all" ? ofObj.numberOfSprayersNozzle : "";
    this.orderFormdata[15][2] =
      testTypesInProject === "all" ? ofObj.wirelessNodes : "";
    this.orderFormdata[16][2] =
      testTypesInProject === "all" ? ofObj.wirelessNodes : "";
    this.orderFormdata[17][2] =
      testTypesInProject === "all" ? ofObj.numberOfAirPumps : "";
    this.orderFormdata[18][2] = ofObj.numberOfOPSheets ?? "";
    this.orderFormdata[19][2] = ofObj.numberOfSPSheets ?? "";
    this.orderFormdata[20][2] = ofObj.numberOfSampleLabelSheets ?? "";
    this.orderFormdata[21][2] = "";
    this.orderFormdata[22][2] = ofObj.numberOfGloves;

    // Parse the tag names and counts
    const tagsData = parseTagCount(tagsObj);

    const hasSyringes = tagsData.syringes.length > 0;
    const has35Bucko = tagsData.bucko35.length > 0;
    const has60Bucko = tagsData.bucko60.length > 0;

    if (hasSyringes) {
      // Syringe calculations
      this.orderFormdata.push(["", "10ml syringes (5ml tag solution volume)"]);
      tagsData.syringes.forEach((item) => {
        const arrItem = ["", `No: of ${item.tag} syringes`, item.count];
        this.orderFormdata.push(arrItem);
      });
    }

    if (has35Bucko || has60Bucko) {
      // Bucko solution calculations
      this.orderFormdata.push(["", "Bucko Bottles"]);
      if (has35Bucko) {
        tagsData.bucko35.forEach((tagData) => {
          this.orderFormdata.push([
            "",
            `No: of ${tagData.tag} 35ml bottles`,
            tagData.count,
          ]);
        });
      }
      if (has60Bucko) {
        tagsData.bucko60.forEach((tagData) => {
          this.orderFormdata.push([
            "",
            `No: of ${tagData.tag} 60ml bottles`,
            tagData.count,
          ]);
        });
      }
    }

    // Comment section
    this.orderFormdata.push([["Comments"]]);
    this.orderFormdata.push([[""]]);

    this.setState({ ofData: this.orderFormdata });
  };

  getOrderFormData = async (id) => {
    let ofResp = await projects.getProjectOrderFormData(id);
    let tagsResp = await tags.getTagsCount(id);
    if (tagsResp.data.success && ofResp.data.success) {
      this.handleTagSolutionFormatting(
        ofResp.data.data,
        tagsResp.data.data,
        id
      );
    }
  };

  handleOrderFormDownload = (id) => {
    this.getOrderFormData(id);
  };

  componentDidMount() {
    if (this.props.data.labelType) {
      if (this.props.data.labelType === "Origin Points Labels") {
        this.handleButtonRouting = this.handleOPLabelsClick;
        this.setState({ label: true });
      } else if (this.props.data.labelType === "Sample Points Labels") {
        this.handleButtonRouting = this.handleSPLabelsClick;
        this.setState({ label: true });
      } else if (this.props.data.labelType === "Sample Collector Labels") {
        this.handleButtonRouting = this.handleSCLabelsClick;
        this.setState({ label: true });
      } else if (this.props.data.labelType === "Order Form") {
        this.handleOrderFormDownload(this.projectId);
        // } else if (this.props.data.labelType === "Project Configuration") {
        //     this.handleButtonRouting = this.handleProjectConfigurationClick;
        //     this.setState({ label: true });
      }
    }
  }

  render() {
    const { label, ofData } = this.state;
    return (
      <>
        {label ? (
          <IconButton
            onClick={() => this.handleButtonRouting()}
            aria-label="label-button"
          >
            <NoteIcon style={{ color: "#82BC00" }} />
          </IconButton>
        ) : (
          <CSVLink
            data={ofData}
            filename={`${this.projectName}-order-form.csv`}
            style={{ margin: "0 10px 0 10px", textDecoration: "none" }}
          >
            <IconButton aria-label="order-form-button">
              <AssignmentIcon style={{ color: "#82BC00" }} />
            </IconButton>
          </CSVLink>
        )}
      </>
    );
  }
}

export default withRouter(BtnCellRendererLabel);

const parseTagCount = (tagCountObject) => {
  const syringes = [];
  const bucko35 = [];
  const bucko60 = [];
  for (const tagName in tagCountObject) {
    const counts = tagCountObject[tagName];
    for (const sprayerName in counts) {
      const count = counts[sprayerName];
      if (sprayerName.toLowerCase() === "bucko") {
        /* Bucko solution calculations:
         * if it's used 3,4 or 5 times then a 60ml bottle can be used (holds 5 OP sprays)
         * if it's used 2 times then a 35ml bottle can be used (holds 2 OP sprays)
         * Calculate the minimum number of 60ml bottles and 35ml bottles needed
         */
        let bottle35count = 0;
        let bottle60count = 0;
        if (count > 2) {
          bottle60count = Math.floor(parseInt(count) / 5);
          const remainder = parseInt(count) % 5;
          if (remainder > 2) {
            bottle60count = bottle60count + 1;
          } else {
            bottle35count = 1;
          }
        } else {
          bottle35count = 1;
        }
        if (bottle35count > 0) {
          bucko35.push({ tag: tagName, count: bottle35count });
        }
        if (bottle60count > 0) {
          bucko60.push({ tag: tagName, count: bottle60count });
        }
      } else {
        syringes.push({ tag: tagName, count });
      }
    }
  }
  return {
    syringes: syringes.sort((a, b) => a.tag.localeCompare(b.tag)),
    bucko35: bucko35.sort((a, b) => a.tag.localeCompare(b.tag)),
    bucko60: bucko60.sort((a, b) => a.tag.localeCompare(b.tag)),
  };
};
