import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const styles = (theme) => ({
  columnHeader: {
    minHeight: "200px",
    //maxHeight: '20px',
    //border: '3px solid #73AD21',
    background: "rgb(0, 137, 150)", //'lightgray',
    padding: "10px",
    //align: 'left',
    //transform: 'rotate(270deg)',
  },

  columnHeaderText: {
    //transform: 'rotate(270deg)', /* Equal to rotateZ(45deg) */
    //textAlign: 'left',
    //margin: '10px',
    //minWidth: '200px',
    //position: 'absolute',
    //right: '0px',
  },
  table: {
    border: "1px solid gray !important", //1px solid rgba(224, 224, 224, 1);
    //borderWidth: '1px !important',
    //borderSpacing: '2px !important',
    minWidth: 400,
  },
  cell: {
    padding: "4px !important",
    //margin: '10px',
    fontSize: "smaller",
    border: "1px solid gray !important",
  },
  row: {
    //border: '1px solid #73AD21',
    padding: "10px",
  },
  noteRow: {
    height: "32px !important",
  },
  idColumn: {
    width: "15px",
  },
});


class Notes extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        const { classes } = this.props;
        const items = [];
        for (let i = 0; i < this.props.rows; i++) {

            if (this.props.items !== undefined && this.props.items.length > i) {
                items.push(this.props.items[i]);
            } else {
                items.push("");
            }

        }
        return (

            <Table className={classes.table} size="medium" aria-label="notes">
                {(this.props.noHeader === undefined || this.props.noHeader === false) && (
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="right">{this.props.headerText}</TableCell>


                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow key={index} className={classes.noteRow}><TableCell className={classes.cell} align="left" >{item}</TableCell ></TableRow>
                    ))}


                </TableBody>
            </Table>

        );
    }

}

export default withStyles(styles)(Notes);
