import { ThreeSixtySharp } from '@material-ui/icons';
import React from 'react';
//import Table from 'react-bootstrap/Table'
import PlacableObject from './PlacableObject';
import "./Visualizer.css";

class PlacableObjectList extends React.Component {

    /*
     * originPoints - raw origin point list from API result
     * samplePoints - raw sample point list from API result
     * originPointToPlacableObject - function to convert raw origin point to PlacableObject
     * samplePointToPlacableObject - function to convert raw sample point to PlacableObject
     */
    constructor(props) {
        super(props);
        // todo: check and handle null or undefined properties 
        this.state = {
            filter: "Both",
            floorplanFilter: this.props.selectedFloorplan,
        }
        this.originPointToPlacableObject = this.originPointToPlacableObject.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.isOriginPointDirty = this.isOriginPointDirty.bind(this);
        this.isSamplePointDirty = this.isSamplePointDirty.bind(this);

        //console.log(props);
    }

    onFilterChange(e) {
        //console.log("PlacableObjectList - onFilterChange: " + e.target.value);
        this.setState({ filter: e.target.value });
    }

    render() {
        let originPointItems = [];
        //console.log("floorPlanFilter " + this.props.selectedFloorplan);

        if (this.state.filter === "Origin-Points" || this.state.filter === 'Both') {
            //console.log("PlaceableOpject List");
            //console.log(this.props.originPoints);
            originPointItems = this.props.originPoints.map((item) => {
                if (this.props.selectedFloorplan !== undefined && this.props.selectedFloorplan !== null) {
                    if (item.floorplan === this.props.selectedFloorplan) {
                        return this.originPointToPlacableObject(item);
                    } else {
                        return;
                    }
                } else {
                    return this.originPointToPlacableObject(item);
                }

            });
        }

        let samplePointItems = [];
        if (this.state.filter === "Sample-Points" || this.state.filter === 'Both') {
            samplePointItems = this.props.samplePoints.map((item) => {
                if (this.props.selectedFloorplan !== undefined && this.props.selectedFloorplan !== null) {
                    if (item.floorplan === this.props.selectedFloorplan) {
                        return this.samplePointToPlacableObject(item);
                    } else {
                        return;
                    }
                } else {
                    return this.samplePointToPlacableObject(item);
                }

            });
        }

        return (
            <div className="origin-sample-point-list">
                <h5>Origin and Sample Points</h5>
                <div className="control-group" onChange={this.onFilterChange}>
                    <label className="control control--radio">All
                        <input type="radio" name="placable-filter" value="Both" checked={this.state.filter === "Both"} />
                        <div className="control__indicator"></div>
                    </label>
                    <label className="control control--radio">Origin Points
                        <input type="radio" name="placable-filter-f" value="Origin-Points" checked={this.state.filter === "Origin-Points"} />
                        <div className="control__indicator"></div>
                    </label>
                    <label className="control control--radio">Sample Points
                        <input type="radio" name="placable-filter" value="Sample-Points" checked={this.state.filter === "Sample-Points"} />
                        <div className="control__indicator"></div>
                    </label>
                </div>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th className="number-column">Number</th>
                                <th className="name-column">Name</th>

                            </tr>
                        </thead>
                        <tbody>
                            {originPointItems}
                            {samplePointItems}

                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    isOriginPointDirty(e) {
        this.props.isOriginPointDirty(e);
    }

    isSamplePointDirty(e) {
        this.props.isSamplePointDirty(e);
    }

    originPointToPlacableObject(rawOp) {
        /*
          opid: 1440,
          opnumber: "OP-015",
          opname: null,
          opcoordx: "76.1627408993576",
          opcoordy: "124.8074074074074",
          spraynodeid: null,
          segmentid: 212,
          floorplan: "survey floorplan.png",
          taglotnumbers: null,
          tags:
        */

        return (

            <PlacableObject
                isEditable={this.props.isEditable}
                objectType="OriginPoint"
                id={rawOp.opid}
                number={rawOp.opnumber}
                name={rawOp.opname}
                x={rawOp.opcoordx}
                y={rawOp.opcoordy}
                floorPlan={rawOp.floorplan}
                original={rawOp.original}
                isDirty={rawOp.isDirty}
                onChange={(e) => {
                    //console.log("PlaceableObjectList.js - onChangeHandler");
                    //console.log(e);
                    switch (e.target.name) {
                        case "xname":
                            //console.log("name changed for item id:" + e.id + ", " + e.target.value);
                            if (this.props.onChange !== undefined && typeof this.props.onChange === 'function') {
                                this.props.onChange(e);
                            }
                            break;
                        default:
                            console.log("PlaceableObjectList.js - unsupported value: " + e.target.name);
                    }
                }}
                onChanged={(e) => {
                    //console.log("onChangedHandler");

                    if (this.props.onChanged !== undefined && typeof this.props.onChanged === 'function') {
                        this.props.onChanged(e);
                    }


                }}
            />

        );
    }


    samplePointToPlacableObject(rawSp) {
        /*
          opid: 1440,
          opnumber: "OP-015",
          opname: null,
          opcoordx: "76.1627408993576",
          opcoordy: "124.8074074074074",
          spraynodeid: null,
          segmentid: 212,
          floorplan: "survey floorplan.png",
          taglotnumbers: null,
          tags:
        */

        return (

            <PlacableObject
                isEditable={this.props.isEditable}
                objectType="SamplePoint"
                id={rawSp.spid}
                number={rawSp.spnumber}
                name={rawSp.spname}
                x={rawSp.spcoordx}
                y={rawSp.spcoordy}
                floorPlan={rawSp.floorplan}
                original={rawSp.original}
                isDirty={rawSp.isDirty}
                onChange={(e) => {
                    console.log("PlaceableObjectList.js - onChangeHandler");
                    switch (e.target.name) {
                        case "xname":
                            //console.log("name changed for item id:" + e.id + ", " + e.target.value);
                            if (this.props.onChange !== undefined && typeof this.props.onChange === 'function') {
                                this.props.onChange(e);
                            }
                            break;
                        default:
                            console.log("PlaceableObjectList.js - unsupported value: " + e.target.name);
                    }
                }}
                onChanged={(e) => {
                    //console.log("PlaceableObjectList.js - onChangedHandler");

                    if (this.props.onChanged !== undefined && typeof this.props.onChanged === 'function') {
                        this.props.onChanged(e);
                    }
                }}
            />
        );
    }
}


export default PlacableObjectList;