import axios from "axios";
import config from "../config.js";

const BASEURL = config.baseURL + "/buildings";

const buildings = {
  createBuilding: async (data) => {
    const response = await axios.post(BASEURL, data);
    return response;
  },
  assignToTests: async (buildingId, testsIds) => {
    if (!+buildingId) return;
    const response = await axios.put(BASEURL + `/assignToTests/${buildingId}`, { testsIds });
    return response;
  }

};

export default buildings;