

import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const MuiAlertComponent = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const AlertNewTest = ({ msg, noGST }) => {
  const [ open, setOpen ] = useState(false);

  useEffect(() => {
    if (noGST === false) {
      setOpen(true);
    }
  }, [noGST]);
  
  const toggleClose = () => setOpen(false);

  return (
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} style={{ position: 'absolute', width: '800px', top: '-35px' }} open={open} >
        <MuiAlertComponent
          style={{ backgroundColor: "#008996", color: "#ffffff", borderColor: "#82BC00", fontFamily: "Gotham" }}
          onClose={toggleClose} 
          severity='info'>
          {msg}
        </MuiAlertComponent>
      </Snackbar>
  );
}