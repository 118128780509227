import axios from 'axios';
import config from '../config.js';

const BASEURL = config.baseURL + '/projects';
const projects = {
  getAllProjects : async(config = {}) => {
    const response = await axios.get (BASEURL + '', config);
    return response;
  },
  addProject : async(projectToBeAdded) => {
    const response = await axios.post (BASEURL + '/create', projectToBeAdded);
    return response;
  },
  getOneProject : async(projectId) => {
    const response = await axios.get (BASEURL + '/' + projectId);
    return response;
  },
  getProjectOrderFormData: async(projectId) => {
    const response = await axios.get (BASEURL + '/' + projectId + '/orderform', {});
    return response;
  },
  updateOneProject: async(projectId, data) => {
    const response = await axios.put (BASEURL + '/' + projectId, data);
    return response;
  },
  deleteOneProject: async(projectId) => {
    const response = await axios.delete (BASEURL + '/' + projectId);
    return response;
  },
  withdrawPublication: async(projectId) => {
    const response = await axios.put (BASEURL + '/withdrawPublication/' + projectId);
    return response;
  },
  updateStatus: async(projectId, status) => {
    const response = await axios.put (BASEURL + '/status/' + projectId, { status });
    return response;
  }
};


export default projects;
