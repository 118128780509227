import axios from 'axios';
import config from '../config.js';
// Testplan is part of segments microservice
const BASEURL = config.baseURL + '/segments';

const testPlan = {
  getTestPlan : async(testId, projectId) => {
    if(testId !== null) {
      const response = await axios.get (BASEURL + '/testPlan?testId=' + testId, {});
      return response;
    } else {
      const response = await axios.get (BASEURL + '/testPlan?projectId=' + projectId, {});
      return response;
    }
  },
  updateTestPlan : async(testPlanToBeUpdated) => {
    const response = await axios.put (BASEURL + '/testPlan' , testPlanToBeUpdated);
    return response;
  }
};


export default testPlan;