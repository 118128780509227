import React, { useState, useContext, useEffect } from 'react';
import {Context} from "../../context/Context";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {CustomCheckbox} from "../../components/CustomCheckbox/CustomCheckbox";
import Modal                from "@material-ui/core/Modal";
import Backdrop             from "@material-ui/core/Backdrop";
import Fade                 from "@material-ui/core/Fade";
import Button               from "@material-ui/core/Button";
import TableContainer       from "@material-ui/core/TableContainer";
import {useHistory}         from "react-router-dom";
import tests                from "../../api/tests";
import {AlertTestCompleted} from "../../components/Alert/AlertTestCompleted";
import {makeStyles}         from "@material-ui/core/styles";
import Typography           from "@material-ui/core/Typography";
import './ProjectDetails.css';
import { Warning } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import sampleplans from "../../api/sampleplans";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import { testTypesConfig } from '../../config';

const useRowStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(7, 5, 7),
        textAlign: 'center',
        fontFamily: "Gotham"
    },
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'left',
        padding: '10px !important'
    },
    bodyCell: {
        textAlign: 'left',
        padding: '10px !important'
    },
    modal:       {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center'
    },
}));

export const ProjectTests = ({ projectId, projectName, projectTests, projectCompleted, isResultsUploaded, testsCoverage, fetchUpdatedProject, projectStatus, updateProjectStatus, projectFromProjectPortal }) => {
    const history = useHistory();
    const classes = useRowStyles();
    const { setTypeName, setOnProject, setTypeId, state, setTestType, setProjectName, setNotOnProjectDetails, setProjectFromProjectPortal } = useContext(Context);
    const [ openTestModal, setOpenTestModal ] = useState(false);
    const [ openTestCompletedAlert, setOpenTestCompletedAlert ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState('');
    const [ testId, setTestId ] = useState('');
    const [ cantDeleteTests, setCantDeleteTests ] = useState(false);
    const [ canAddTests, setCanAddTests ] = useState(false);
    const [ editTestModal, setEditTestModal ] = useState(false);
    const [ testName, setTestName ] = useState('');
    const [ testGoal, setTestGoal ] = useState('');
    const [ selectedTestId, setSelectedTestId ] = useState(0);
    const [ testOverview, setTestOverview ] = useState('');

    const handleNewTestClick = projId => {
        let projectId = JSON.stringify(projId);
        localStorage.setItem("projectId", projectId);
        setProjectFromProjectPortal(projectFromProjectPortal);
        history.push('/newtest');
    }

    useEffect(() => {
        const cantDelete = projectStatus && !~['new', 'in_progress'].indexOf(projectStatus);
        const canAdd = projectStatus && !~['res_generated', 'published'].indexOf(projectStatus);
        setCantDeleteTests(cantDelete);
        setCanAddTests(canAdd);
    }, [projectStatus]);

    const handleSamplePlan = projId => {
        history.push('/sampleplan');
    }
    const handleModalOpen = (id) => {
        if (id) {
            let testId = JSON.stringify(id);
            localStorage.setItem("testId", testId);
            setTestId(testId);
            setOpenTestModal(!openTestModal);
        }
    }
    const handleModalClose = () => {
        setOpenTestModal(!openTestModal);
    }
    const handleDelete = async (id) => {
        if (id) {
            let response = await tests.removeOneTest(id);
            if (response.data.success) {
                await fetchUpdatedProject();
                handleModalClose();
                await sampleplans.updateSamplePlan(projectId)
                localStorage.removeItem('testId');
            }
        }
    }
    const handleTestExecuted = async (testid, executed) => {
        const newCompletionStatus = executed !== true;
        let data = { testCompleted: newCompletionStatus };
        const testNotMarkedExecutedBeforeUpdate = projectTests.reduce((counter, test) => 
            counter - Number(test.testexecuted), projectTests.length);
        let res = await tests.updateOneTest(testid, data);
        if (res.data.success === true) {
            if (testNotMarkedExecutedBeforeUpdate === 1 && newCompletionStatus) {
                await updateProjectStatus('executed');
            }
            await fetchUpdatedProject();
        } else {
            setOpenTestCompletedAlert(!openTestCompletedAlert);
            setErrorMsg(res.data.error.message);
        }
    }
    const handleClosingAlert = () => setOpenTestCompletedAlert(!openTestCompletedAlert);

    const showTestsCoverageColumn = () => {
        if (isResultsUploaded && Object.keys(testsCoverage).length > 0 && state.accessLevel === 'Admin' && state.roleName === 'Safetracer') {
            return true;
        }

        return false;
    }
    const showTestCoverage = (testId) => {
        if (!(testId in testsCoverage)
            || !('exists' in testsCoverage[testId])
            || !('total' in testsCoverage[testId])
            || testsCoverage[testId].total === 0
        ) {
            return false;
        }

        return true;
    }
    const getTestsCoverageText = (testId) => {
        if (!showTestCoverage(testId)) {
            return '';
        }

        const percent = Math.round((testsCoverage[testId].exists / testsCoverage[testId].total) * 100);

        return percent + '% (' + testsCoverage[testId].exists + ' of ' + testsCoverage[testId].total + ')';
    }

    const onRowSelected = (setTypeName, setOnProject, setTypeId, projectId, testId, typeName, type, setTestType, testType, projectName) => {
        setTypeName(typeName, type);
        setTypeId(projectId, testId, type);
        setTestType(testType);
        setProjectName(projectName);
        setProjectFromProjectPortal(projectFromProjectPortal);
        setOnProject();
        setNotOnProjectDetails();
        if (testType === 'generalSurvey'){
            history.push('/segments');
        } else {
            history.push('/scenarios');
        }
    }

    const handleChange = (field, value) => {
        switch (field) {
            case "testName":
                setTestName(value.target.value);
                break;
            case "testGoal":
                setTestGoal(value.target.value);
                break;
            case "testOverview":
                setTestOverview(value.target.value);
                break;
        }
    }

    const handleEditModalOpen = (id) => {
        setSelectedTestId(id);
        const test = projectTests.find((t) => t.testid === id);
        if (test) {
            setTestName(test.testname);
            setTestGoal(test.goal);
            setTestOverview(test.overview);
        }
        setEditTestModal(true);
    }

    const handleEditModalClose = () => {
        setEditTestModal(false);
    }

    const handleEditSaveTest = async () => {
        if (!testName || !selectedTestId) {
            return;
        }
        await tests.updateOneTest(selectedTestId, {
            testName: testName,
            goal: testGoal,
            overview: testOverview,
        });
        await fetchUpdatedProject();
        setTestName(null);
        setTestGoal(null);
        setTestOverview(null);
        setEditTestModal(false);
    }

    if (!projectTests) {
        return null;
    }

    return (
        <div>
            <div className="grid-wrapper">
                <div>
                    <Typography variant="h5" color="textPrimary">
                        Tests
                        {state.accessLevel !== 'Viewer' && canAddTests && (
                            <Button
                                size="small"
                                variant="outlined"
                                className="add-edit-button"
                                disabled={projectCompleted}
                                onClick={() => handleNewTestClick(projectId)}
                            >
                                Add
                            </Button>
                        )}
                        {(!!projectTests.filter(test => test.hasplans).length && projectTests.length > 0) && (
                            <Button
                                size="small"
                                variant="outlined"
                                className="sample-plan-button"
                                onClick={() => handleSamplePlan(projectId)}
                            >
                                Sample plan
                            </Button>
                        )}

                    </Typography>
                </div>
            </div>
            <div className="grid-wrapper">
                <div>
                    <TableContainer style={{ marginTop: '15px', boxShadow: 'none' }} component={Paper}>
                        <Table aria-label="project-list-table">
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    {state.accessLevel !== 'Viewer' && (
                                        <TableCell className={classes.headerCell}/>
                                    )}
                                    <TableCell className={classes.headerCell}>Done</TableCell>
                                    {showTestsCoverageColumn() && (
                                        <TableCell className={classes.headerCell}>Results coverage</TableCell>
                                    )}
                                    <TableCell className={classes.headerCell}>Test ID</TableCell>
                                    <TableCell className={classes.headerCell}>Test Num</TableCell>
                                    <TableCell className={classes.headerCell}>Type</TableCell>
                                    <TableCell className={classes.headerCell}>Name</TableCell>
                                    <TableCell className={classes.headerCell}>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            {projectTests.map((test, i) => (
                                <TableBody key={`key-${i}`}>
                                    <TableRow hover={true}>
                                        {state.accessLevel !== 'Viewer' && (
                                            <TableCell className={classes.bodyCell}>
                                                <IconButton disabled={test.testexecuted || cantDeleteTests} onClick={() => handleModalOpen(test.testid)} aria-label="delete" className="delete-button">
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                                <IconButton disabled={test.testexecuted || cantDeleteTests} onClick={() => handleEditModalOpen(test.testid)} className="delete-button">
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        )}
                                        <TableCell className={classes.bodyCell}>
                                            <div className='materials-table-checkbox-container'>
                                                <div className='materials-table-checkbox-container-cell'>
                                                    <div>
                                                        {test.hasplans && (<CustomCheckbox
                                                            className="checkbox"
                                                            value={test.testexecuted}
                                                            checked={test.testexecuted}
                                                            disabled={projectCompleted}
                                                            onClick={() => handleTestExecuted(test.testid, test.testexecuted)}
                                                        />)}
                                                        {!test.hasplans && (
                                                            <Tooltip title="Incomplete Test details" aria-label="Incomplete Test details"><Warning color="secondary"   /></Tooltip>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>
                                        {showTestsCoverageColumn() && (
                                            <TableCell className={classes.bodyCell + " table-cell-pointer"}>
                                                { getTestsCoverageText(test.testid) }</TableCell>
                                        )}
                                        <TableCell 
                                            onClick={() => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, test.testid, test.testname, "Test", setTestType, test.testtype, projectName)}
                                            className={classes.bodyCell + " table-cell-pointer"}>{test.testid}</TableCell>
                                        <TableCell 
                                            onClick={() => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, test.testid, test.testname, "Test", setTestType, test.testtype, projectName)}
                                            className={classes.bodyCell + " table-cell-pointer"}>{test.displayNumber || ''}</TableCell>
                                        <TableCell
                                            onClick={() => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, test.testid, test.testname, "Test", setTestType, test.testtype, projectName)}
                                            className={classes.bodyCell + " table-cell-pointer"}>{testTypesConfig[test.testtype] || ''}</TableCell>
                                        <TableCell
                                            onClick={() => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, test.testid, test.testname, "Test", setTestType, test.testtype, projectName)}
                                            className={classes.bodyCell + " table-cell-pointer"}>{test.testname}</TableCell>
                                        <TableCell
                                            onClick={() => onRowSelected(setTypeName, setOnProject, setTypeId, projectId, test.testid, test.testname, "Test", setTestType, test.testtype, projectName)}
                                            className={classes.bodyCell + " table-cell-pointer"}>{test.overview}</TableCell>
                                    </TableRow>
                                </TableBody>
                            ))}
                        </Table>
                    </TableContainer>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openTestModal}
                        onClose={() => handleModalClose()}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}>
                        <div>
                            <Fade in={openTestModal}>
                                <div className={classes.paper}>
                                    <h2 id="transition-modal-title">Are you sure you want to delete this test?</h2>
                                    <div>
                                        <Button
                                            onClick={() => handleDelete(testId)}
                                            size="small"
                                            className="modal-button">
                                            Yes
                                        </Button>
                                        <Button
                                            onClick={() => handleModalClose()}
                                            size="small"
                                            className="modal-button">
                                            No
                                        </Button>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </Modal>
                </div>
            </div>
            {!!openTestCompletedAlert && (<AlertTestCompleted flag={openTestCompletedAlert} msg={errorMsg} closeFunc={handleClosingAlert} />)}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={editTestModal}
                onClose={handleEditModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div style={{ minWidth: 400 }}>
                    <Fade in={editTestModal}>
                        <div className={classes.paper}>
                            <div className="grid-wrapper">
                                <div>
                                    <h2 id="transition-modal-title">
                                        Edit Test
                                    </h2>
                                </div>
                            </div>
                            <div className="grid-wrapper">
                                <div>
                                    <TextField
                                        fullWidth={true}
                                        label="Name"
                                        name="testName"
                                        autoComplete="testName"
                                        style={{ fontFamily: 'Gotham' }}
                                        onChange={(newValue) => handleChange("testName", newValue)}
                                        value={testName ?? ''}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        fullWidth={true}
                                        label="Goal"
                                        name="testGoal"
                                        autoComplete="tag-intercept"
                                        style={{ fontFamily: 'Gotham' }}
                                        onChange={(newValue) => handleChange("testGoal", newValue)}
                                        value={testGoal ?? ''}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        fullWidth={true}
                                        label="Description"
                                        name="testOverview"
                                        autoComplete="tag-lotnumber"
                                        style={{ fontFamily: 'Gotham' }}
                                        onChange={(newValue) => handleChange("testOverview", newValue)}
                                        value={testOverview ?? ''}
                                    />
                                </div>
                            </div>
                            <div className="grid-wrapper" style={{ marginTop: 15 }}>
                                <div>
                                    <Button
                                        onClick={handleEditModalClose}
                                        size="small"
                                        className="modal-button"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleEditSaveTest}
                                        disabled={!(testName)}
                                        size="small"
                                        className="modal-button"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </Modal>
        </div>
    );
};