import React from '../../../node_modules/react';
import sampleplans from '../../api/sampleplans';
import Button from '@material-ui/core/Button';
import { Context } from '../../context/Context';
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CSVLink } from 'react-csv';
import './TestPlan.css';

const styles = theme => ({
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'center',
        padding: '10px !important'
    },
    cell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        textAlign: 'center',
        borderRight: 'none',
        borderLeft: 'none',
        whiteSpace: 'nowrap',
        padding: '10px !important'
    },
    loneCell: {
        fontFamily: 'Gotham',
        fontSize: '20px',
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    }
});

class TestPlanDilution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectId: null,
            testPlan: [],
            testPlanData: []
        };
        this.getTestPlanData = this.getTestPlanData.bind(this);
    }
   
    async componentDidMount() {
        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        let testplanNavButton = document.querySelector('#sBtn');
        if(testplanNavButton) {
            testplanNavButton.classList.add('selected');
        }
        
        let getSPRes;
        let testId = JSON.parse(localStorage.getItem("testId"));
        let segmentid = JSON.parse(localStorage.getItem("segmentid"));
        let testType = JSON.parse(localStorage.getItem("testType"));
        let testPlanData;
        this.setState({ "projectId": projectId, "testType": testType });

        getSPRes = await sampleplans.getSamplePlanForProject(testId);
        if(getSPRes.data.success) {
            if(getSPRes.data.data && getSPRes.data.data.length > 0) {
                this.setState({ testPlan: getSPRes.data.data });
                testPlanData = await this.getTestPlanData();
                this.setState({ testPlanData: testPlanData });
            } else {
                sampleplans.createSamplePlan(segmentid).then(async (result) => {
                    if(result.data.success) {
                        getSPRes = await sampleplans.getSamplePlanForProject(testId);
                        if(getSPRes.data.success) {
                            if(getSPRes.data.data.length > 0) {
                                this.setState({ testPlan: getSPRes.data.data });
                                testPlanData = await this.getTestPlanData();
                                this.setState({ testPlanData: testPlanData });
                            }
                        }
                    }
                });
            }
        } else {
            console.log('err');
        }
    };

    async getTestPlanData() {
        let result = [];

        let dataObj = {stripnumbers:['StripNumber'],opnumbers:['OP'],tags:['Tag'],A:['A'],B:['B'],C:['C'],D:['D'],E:['E'],F:['F'],G:['G'],H:['H']};
        await this.state.testPlan.forEach(async plan => {
            dataObj.stripnumbers.push(plan.stripnumber);
            dataObj.opnumbers.push(plan.opnumber);
            dataObj.tags.push(plan.tag);
            dataObj.A.push(plan.r1? plan.r1 : ' - ');
            dataObj.B.push(plan.r2? plan.r2 : ' - ');
            dataObj.C.push(plan.r3? plan.r3 : ' - ');
            dataObj.D.push(plan.r4? plan.r4 : ' - ');
            dataObj.E.push(plan.r5? plan.r5 : ' - ');
            dataObj.F.push(plan.r6? plan.r6 : ' - ');
            dataObj.G.push(plan.r7? plan.r7 : ' - ');
            dataObj.H.push(plan.r8? plan.r8 : ' - ');
        });

        if (this.state.testType === 'recirculation') {
            delete dataObj.opnumbers;
        }

        for(var key in dataObj) {
            result.push(dataObj[key]);
        }

        return result;
    }

    render() {
        const { testPlan, testPlanData, projectId } = this.state;
        const { classes } = this.props;

        if(testPlan.length > 0) {
            return (
                <Context.Consumer>
                    {context => (
                        <div style={{ marginTop: '100px' }} className="test-plan-dilution-container">
                            <div className="test-plan-dilution-header-container">
                                <Typography variant="h5" style={{fontFamily: "Gotham", marginTop: "30px", marginLeft: "100px"}} color="textPrimary" align="center">{`(Project Id ${projectId}) ${context.state.projectName}: ${context.state.testName}`}</Typography>
                                <div className="test-plan-btn-container">
                                    <CSVLink 
                                        data={testPlanData}
                                        filename={`${context.state.projectName}-${context.state.testName}-sample-plan.csv`}
                                        style={{margin: '0 10px 0 10px', textDecoration: "none"}}>
                                            <Button size="small" variant="outlined" onClick={this.handleViewPlanForProjectClick} style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", margin: "5px", fontFamily: "Gotham"}}>Download</Button>
                                    </CSVLink>
                                </div>
                            </div> 
                            <div className="test-plan-table-container">

                                <div>
                                    <TableContainer style={{ marginTop: '15px', width: '500px' }} ref={(el) => el && el.style.setProperty('overflow', 'auto', "important")} component={Paper}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell} variant="head" style={{ backgroundColor: '#F3F3F3' }}>stripnumber</TableCell>
                                                        {testPlan.map((strip, i) => (
                                                            <TableCell key={`stripnumber-${i}`} className={classes.cell}>{strip.stripnumber ? strip.stripnumber : '-'}</TableCell>
                                                        ))}
                                                </TableRow>
                                                {this.state.testType === 'dilution' && (
                                                <TableRow>
                                                    <TableCell className={classes.headerCell} variant="head" style={{ backgroundColor: '#F3F3F3' }}>OP</TableCell>
                                                        {testPlan.map((strip, i) => (
                                                            <TableCell key={`opnumber-${i}`} className={classes.cell}>{strip.opnumber ? strip.opnumber : '-'}</TableCell>
                                                        ))}
                                                </TableRow>
                                                )}
                                                <TableRow>
                                                    <TableCell className={classes.headerCell} variant="head" style={{ backgroundColor: '#F3F3F3' }}>tag</TableCell>
                                                        {testPlan.map((strip, i) => (
                                                            <TableCell key={`tag-${i}`} className={classes.cell}>{strip.tag ? strip.tag : '-'}</TableCell>
                                                        ))}
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell} variant="head" style={{ backgroundColor: '#F3F3F3' }}>A</TableCell>
                                                        {testPlan.map((strip, i) => (
                                                            <TableCell key={`r1-${i}`} className={classes.cell}>{strip.r1 ? strip.r1 : '-'}</TableCell>
                                                        ))}
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell} variant="head" style={{ backgroundColor: '#F3F3F3' }}>B</TableCell>
                                                        {testPlan.map((strip, i) => (
                                                            <TableCell key={`r2-${i}`} className={classes.cell}>{strip.r2 ? strip.r2 : '-'}</TableCell>
                                                        ))}
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell} variant="head" style={{ backgroundColor: '#F3F3F3' }}>C</TableCell>
                                                        {testPlan.map((strip, i) => (
                                                            <TableCell key={`r3-${i}`} className={classes.cell}>{strip.r3 ? strip.r3 : '-'}</TableCell>
                                                        ))}
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell} variant="head" style={{ backgroundColor: '#F3F3F3' }}>D</TableCell>
                                                        {testPlan.map((strip, i) => (
                                                            <TableCell key={`r4-${i}`} className={classes.cell}>{strip.r4 ? strip.r4 : '-'}</TableCell>
                                                        ))}
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell} variant="head" style={{ backgroundColor: '#F3F3F3' }}>E</TableCell>
                                                        {testPlan.map((strip, i) => (
                                                            <TableCell key={`r5-${i}`} className={classes.cell}>{strip.r5 ? strip.r5 : '-'}</TableCell>
                                                        ))}
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell} variant="head" style={{ backgroundColor: '#F3F3F3' }}>F</TableCell>
                                                        {testPlan.map((strip, i) => (
                                                            <TableCell key={`r6-${i}`} className={classes.cell}>{strip.r6 ? strip.r6 : '-'}</TableCell>
                                                        ))}
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell} variant="head" style={{ backgroundColor: '#F3F3F3' }}>G</TableCell>
                                                        {testPlan.map((strip, i) => (
                                                            <TableCell key={`r7-${i}`} className={classes.cell}>{strip.r7 ? strip.r7 : '-'}</TableCell>
                                                        ))}
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell} variant="head" style={{ backgroundColor: '#F3F3F3' }}>H</TableCell>
                                                        {testPlan.map((strip, i) => (
                                                            <TableCell key={`r8-${i}`} className={classes.cell}>{strip.r8 ? strip.r8 : '-'}</TableCell>
                                                        ))}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </div>
                    )}
                </Context.Consumer>
            )
        } else {
            return null;
        }
    }
}
TestPlanDilution.contextType = Context;
export default withStyles(styles, { withTheme: true })(TestPlanDilution);