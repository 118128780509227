import React from 'react';
import Typography from "@material-ui/core/Typography";

export const OpLabelTags = ({ tags }) => {
    let tagValues = [];
    let tagValuesFirstRow = [];
    let keysArr = [];
    let keysFirstRow = [];

    const currateTags = tags => {
        //Set scenario number for label
        let keys = Object.keys(tags.tags);
        keys = keys.map(key => {
            return key.slice(8);
        });
        keysArr = [...keys];
        //Create arr of tags
        let values = Object.values(tags.tags);
        values.forEach(val => {
            val = val.slice(4);
            tagValues = [...tagValues, val];
        });
        //If less than 3 scenarios create one row
        if(tagValues.length <= 3) {
            return (
                <section style={{ display: 'flex', flexDirection: 'row' }}>
                    {tagValues.map((val, i) => (
                        <div key={i}>{`${keys[i]}:${val},`}</div>
                    ))}
                </section> 
            )
        }
        //If more than 3 scenarios create two rows
        if(tagValues.length > 3) {
            tagValuesFirstRow = tagValues.splice(0, 3);
            keysFirstRow = keysArr.splice(0, 3);
            return (
                <>
                    <section style={{ display: 'flex', flexDirection: 'row' }}>
                        {tagValuesFirstRow.map((val, i) => (
                            <Typography variant="body2" color="textPrimary" align="left">{`${keysFirstRow[i]}:${val},`}</Typography>
                        ))}
                    </section>
                    <section style={{ display: 'flex', flexDirection: 'row' }}>
                        {tagValues.map((val, i) => (
                            <Typography variant="body2" color="textPrimary" align="left">{`${keysArr[i]}:${val},`}</Typography>
                        ))}
                    </section>
                </> 
            )
        }
    }

    return (
        <main>{currateTags(tags)}</main>
    )
        
}