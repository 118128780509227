import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useRef, useState } from "react";
import AreaInfoForm from "./components/AreaInfoForm";
import BackdropFetch from "./components/BackdropFetch";
import MainInfoForm from "./components/MainInfoForm";

import portfolios from "../../api/portfolios";
import buildings from "../../api/buildings";
import projects from '../../api/projects';
import contracts from '../../api/contracts';
import testsAPI from '../../api/tests';
import segments from '../../api/segments';

const useRowStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiInput-underline:after": {
      borderBottomColor: "#82bc00",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 5, 3),
    textAlign: "center",
    fontFamily: "Gotham",
    width: "70%",
    display: "block",
    minHeight: "400px",
  },
  closeBtnModal: {
    display: "block",
    marginLeft: "auto",
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  stepLabel: {
    "& .MuiStepIcon-active, .MuiStepIcon-completed": {
      color: "#82bc00",
    },
  },
  button_mr: {
    marginRight: theme.spacing(1)
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(3)
  },
  buttons_spacer: { flex: "1 1 auto" }
}));

const fullFormData = {
  _meta: {
    stepsRequired: {
      0: ["portfolio_name", "building_name"],
      1: ["region_name", "territory_name", "site_name", "building_address"],
    },
  },
  portfolio_name: {
    defaultValue: "",
    required: true,
    step: 0,
  },
  building_name: {
    defaultValue: "",
    required: true,
    step: 0,
  },
  floor_amount: {
    defaultValue: "",
    required: false,
    step: 0,
  },
  square_fts: {
    defaultValue: "",
    required: false,
    step: 0,
  },
  floor_square_fts: {
    defaultValue: "",
    required: false,
    step: 0,
  },
  region_name: {
    defaultValue: "",
    required: true,
    step: 1,
  },
  territory_name: {
    defaultValue: "",
    required: true,
    step: 1,
  },
  site_name: {
    defaultValue: "",
    required: true,
    step: 1,
  },
  building_address: {
    defaultValue: "",
    required: true,
    step: 1,
  },
};

export default function PublishProjectModal(props) {
  const { visible, closeModal, projectData } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState();
  const [formRules, setFormRules] = useState();
  const [initialDataParsed, setInitialDataParsed] = useState(false);
  const [isDataFetching, setDataFetching] = useState(false);
  const [errorAlert, setErrorAlert] = useState([false, ""]);
  const [buildingCreated, setBuildingCreated] = useState(false);
  const [testData, setTestData] = useState(null);
  const [projectTestsDates, setProjectTestsDates] = useState(null);

  const classes = useRowStyles();

  const steps = ["Main info", "Address"];

  const buildingFormRef = useRef(null);
  const areaFormRef = useRef(null);

  useEffect(() => {
    if (projectData && projectData.id) {
      const stepFromLocal = localStorage.getItem(`publishStep_${projectData.id}`);
      const dataFromLocal = localStorage.getItem(`publishDetails_${projectData.id}`);
      let dataObjParsed = {};

      if (!stepFromLocal) {
        localStorage.setItem(`publishStep_${projectData.id}`, 0);
      }
      if (dataFromLocal) {
        try {
          const parsedData = JSON.parse(dataFromLocal);

          if (parsedData && typeof parsedData === "object") {
            dataObjParsed = parsedData;

            let shouldShowSecondStep = true;
            for (let key of fullFormData._meta.stepsRequired[0]) {
              if (!parsedData[key]) shouldShowSecondStep = false;
            }
            if (shouldShowSecondStep) setActiveStep(1);
          }
        } catch (err) {}
      }
      const fullDataObj = getFormDataObj(dataObjParsed);
      setFormData(fullDataObj);
      setInitialDataParsed(true);
    }
    return () => {
      localStorage.removeItem(`publishStep_${projectData.id}`);
      localStorage.removeItem(`publishDetails_${projectData.id}`);
    };
  }, [projectData.id]);

  const getFormDataObj = (parsedData = {}) => {
    const fullDataObj = {};
    const formRulesObj = {};
    for (let key in fullFormData) {
      if (key !== "_meta") {
        fullDataObj[key] = parsedData[key] || fullFormData[key].defaultValue;
        formRulesObj[key] = fullFormData[key].required;
      }
    }
    if (!formRules) {
      setFormRules(formRulesObj);
    }
    return fullDataObj;
  }

  const handleNext = async () => {
    setErrorAlert([false, ""]);
    if (activeStep === 0) {
      if (buildingFormRef) {
        try {
          buildingFormRef.current.requestSubmit(); // triggers form submission from outside
        } catch (err) {
          console.log("err");
        }
      }
    }
    if (activeStep === 1) {
      if (areaFormRef) {
        try {
          areaFormRef.current.requestSubmit(); // triggers form submission from outside
        } catch (err) {
          console.log("err");
        }
      }
    }
  };
  const handleBack = () => {
    setErrorAlert([false, ""]);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleModalClose = () => {
    closeModal();
    /** Warning: modal does not unmount without living of the whole page
     * and controls through visibility parameter
     * every temporary data should be cleared
     */
    setFormData(getFormDataObj());
    setErrorAlert([false, ""]);
    setActiveStep(0);
    localStorage.removeItem(`publishStep_${projectData.id}`);
    localStorage.removeItem(`publishDetails_${projectData.id}`);
  };
  const mainInfoSubmit = (values) => {
    try {
      const newFullData = {};
      for (let key in formData) {
        newFullData[key] = values[key] || formData[key];
      }
      localStorage.setItem(`publishDetails_${projectData.id}`, JSON.stringify(newFullData));
      localStorage.setItem(`publishStep_${projectData.id}`, 2);
      setActiveStep(1);
      setFormData(newFullData);
    } catch (err) {
      console.log("mainInfoSubmit Err", err);
    }
  };
  const finalSubmit = async (values) => {
    const dataToSubmit = {
      ...formData,
      ...values,
    };
    let portfolioId = null;
    let buildingId = null;
    let siteId = null;

    return Promise.resolve()
      .then(async () => {
        const dataToLocalStorage = JSON.stringify(dataToSubmit);
        localStorage.setItem(`publishDetails_${projectData.id}`, dataToLocalStorage);
        for (let step in fullFormData._meta.stepsRequired) {
          const req_fields = fullFormData._meta.stepsRequired[step];
          for (let field of req_fields) {
            if (!dataToSubmit[field]) {
              throw new Error("Required data missing");
            }
          }
        }
        setFormData(dataToSubmit);
        setDataFetching(true);

        if (!projectData.endCustomer) throw new Error();

		const trimmedPortfolioName = trimString(dataToSubmit.portfolio_name);

        const portfolioData = {
          portfolioName: trimmedPortfolioName,
          endCustomerName: projectData.endCustomer,
        };

        return portfolios
          .createPortfolio(portfolioData)
          .then(async(response) => {
            portfolioId = response.data.data ? response.data.data.portfolioid : null;
            if (!portfolioId && response.data.error === 'Portfolio for this Customer already exist') {
              const endCustomerId = projectData.endCustomerId;
              const response = await portfolios.getPortfolioByName(trimmedPortfolioName, endCustomerId);
              if (validateResponseObj(response)) {
                portfolioId = dataFromResponse(response).portfolioid;
              }
            }
            if (!portfolioId) throw new Error("Portfolio creation");

            const bodyObj = {
              portfolioId,
              buildings: [
                {
                  regionName: trimString(dataToSubmit.region_name),
                  territoryName: trimString(dataToSubmit.territory_name),
                  siteName: trimString(dataToSubmit.site_name),
                  buildingName: trimString(dataToSubmit.building_name),
                  buildingAddress: trimString(dataToSubmit.building_address),
                  totalSqft: dataToSubmit.square_fts,
                  numberOfFloors: dataToSubmit.floor_amount,
                  floorSqeft: dataToSubmit.floor_square_fts,
                },
              ],
            };
            return buildings.createBuilding(bodyObj);
          })
          .then(async(response_build) => {
            if (!validateResponseObj(response_build)) {
              throw new Error();
            }
            buildingId = +(dataFromResponse(response_build)?.buildingIds?.[0]);
            siteId = +(dataFromResponse(response_build)?.sitesIds?.[0]);

            if (!buildingId || !siteId) {
              throw new Error();
            }
            let testsData = testData;
            if (!testData) {
              const tests_response = await testsAPI.getAllTests(projectData.id);
              if (validateResponseObj(tests_response)) {
                const tests = dataFromResponse(tests_response);
                testsData = getTestsData(tests);
              }
            }
            const { testsIds, testsInfo } = testsData;

            const dates = projectTestsDates || [];
            if (!projectTestsDates) {
              const segmentsResponse = await segments.getSegments(null, projectData.id);
              if (validateResponseObj(segmentsResponse)) {
                const segmentsInfo = dataFromResponse(segmentsResponse);
				const datesFromSegments = segmentsInfo.reduce((res, segment) => {
					const { surveydate } = segment;
					if (!surveydate) return res;

					const date = new Date(surveydate);

					if (!res[0] || !res[1]) {
						res[0] = date;
						res[1] = date;
						return res;
					}
					if (date < res[0]) {
						res[0] = date;
					}
					if (date > res[1]) {
						res[1] = date;
					}
					return res;
				}, dates);
              }
			  if (dates.length) {
				setProjectTestsDates(dates);
			  }
            }

			if (!dates.length) {
				throw new Error('Segments date error');
			}

            const bodyObjContract = {
              portfolioId,
              contractName: `${projectData.name} (Auto)`,
              largeSurvey: testsInfo.largeSurveyTests,
			  smallDilution: testsInfo.smallDilutionTests,
              largeDilution: testsInfo.largeDilutionTests,
              miniSurvey: testsInfo.miniSurveyTests,
              recirculation: testsInfo.recirculationTests,
			  verification: testsInfo.verificationTests,
			  startDate: dates[0],
			  endDate: dates[1],
            };

            const contracts_create_response = await contracts.createContract(bodyObjContract);
            if (!validateResponseObj(contracts_create_response)) {
              throw new Error();
            }
            const { contractid } = dataFromResponse(contracts_create_response);
            const bodyObjSiteContract = {
              siteContractName: `${projectData.name} (Auto)`,
              partner: projectData.companyid,
              contractId: contractid,
              site: siteId,
			  verificationTests: testsInfo.verificationTests,
              ...testsInfo
            }
            const site_contract_response = await contracts.createSiteContract(bodyObjSiteContract);
            if (!validateResponseObj(site_contract_response)) {
              throw new Error();
            }

			const { sitecontractid } = site_contract_response.data.data;

            const bodyObjTestPackage = {
				building: buildingId,
				projectid: projectData.id,
				sitecontractid,
				verificationTests: testsInfo.verificationTests,
				testPackageName: `${projectData.name} (Auto)`,
				...testsInfo
			};

			const response_testpackage_create = await contracts.createTestPackage(bodyObjTestPackage);
			if (!validateResponseObj(response_testpackage_create)) {
			throw new Error();
			}
			
			const tests_building_response = await buildings.assignToTests(buildingId, testsIds);
			if (!validateResponseObj(tests_building_response)) {
			throw new Error();
			}

            const response_status_update = await projects.updateStatus(projectData.id, 'published');
            if (validateResponseObj(response_status_update)) {
              setDataFetching(false);
              setBuildingCreated(true);
              props.changeProjectStatus();
              setTimeout(() => {
                handleModalClose();
              }, 500);
              setTimeout(() => {
                setBuildingCreated(false);
              }, 3000);
            } else throw new Error("Project status update");
            
          })
          .catch((err) => {
            const example_error_msg = "Sorry, something went wrong.";
            console.log(err, "err");
            setErrorAlert([true, example_error_msg]);
            setDataFetching(false);
          });
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getTestsData = (tests) => {
    const res = Array.isArray(tests) && tests.reduce((res, test) => {
      const { testtype, testid } = test;
      if (testid) {
        res.testsIds.push(testid);
        switch (testtype) {
		  case 'dilutionLarge':
          case 'dilution':
            res.testsInfo.largeDilutionTests += 1;
            break;
		  case 'dilutionSmall':
			res.testsInfo.smallDilutionTests += 1;
			break;
          case 'recirculation':
            res.testsInfo.recirculationTests += 1;
            break;
          case 'miniSurvey':
            res.testsInfo.miniSurveyTests += 1;
            break;
          case 'generalSurvey':
            res.testsInfo.largeSurveyTests += 1;
            break;
		  case 'verification':
			res.testsInfo.verificationTests += 1;  
          default:
            break;
        }
      }
      return res;
    }, {
      testsIds: [],
      testsInfo: {
        recirculationTests: 0,
        largeSurveyTests: 0,
        largeDilutionTests: 0,
		smallDilutionTests: 0,
        miniSurveyTests: 0,
		verificationTests: 0,
      }
    });
    if (res) {
      setTestData(res);
      return res;
    }
    return null;
  };

  const validateResponseObj = (obj) => !!(obj && obj.data && obj.data.success && obj.data.data);

  const dataFromResponse = (obj) => obj.data.data;

  const trimString = (str) => (typeof str !== "string" ? "" : str.trim());

  return (
    <React.Fragment>
      <Snackbar
        open={buildingCreated}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" style={{ width: "100%" }}>
          Succesfully published!
        </Alert>
      </Snackbar>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={visible}
      >
        <Fade in={visible && initialDataParsed}>
          <div className={classes.paper}>
            <BackdropFetch open={isDataFetching} />
            <IconButton
              className={classes.closeBtnModal}
              children={<CloseIcon />}
              onClick={handleModalClose}
            />
            <Stepper activeStep={activeStep}>
              {steps.map((label, _) => {
                const stepProps = {};
                const labelProps = { className: classes.stepLabel };
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <React.Fragment>
              <Container style={{ gap: "20px" }}>
                {errorAlert[0] && (
                  <Alert className={classes.alert} severity="error">
                    {errorAlert[1]}
                  </Alert>
                )}
                {activeStep + 1 === 1 && (
                  <MainInfoForm
                    portfolioField={true}
                    ref={buildingFormRef}
                    submitHandler={mainInfoSubmit}
                    data={formData}
                    fieldsRules={formRules}
                  />
                )}
                {activeStep + 1 === 2 && (
                  <AreaInfoForm
                    ref={areaFormRef}
                    submitHandler={finalSubmit}
                    data={formData}
                    fieldsRules={formRules}
                  />
                )}
              </Container>
              <Box className={classes.buttons}>
                {activeStep !== 0 && (
                  <Button
                    color="inherit"
                    className={classes.button_mr}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                )}
                <Box  className={classes.buttons_spacer} />
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}