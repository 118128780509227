import React from "react";
import TestDetails from "./TestDetails";
import Notes from "./Notes";
import TestSprayTable from "./TestSprayTable";
import TestScenarioList from "./TestScenarioList";
import ScenarioOpTagsNotesTable from "./ScenarioOpTagsNotesTable";
import ScenarioCountTable from "./ScenarioCountTable";
import ScenariosWithSPs from "./ScenariosWithSPs";
import OPsAndSPs from "./OPsAndSPs";
import AnnotatedFloorPlan from "../Visualizer/AnnotatedFloorPlan";

const ProjectConfigurationTestPage = ({
  test,
  number,
  projectName,
  projectId,
  warnings,
  samples,
  SPs,
  OPs,
  scenarioData,
  sprayData,
  scenarioSummary,
  scenariosWithSPs,
  scenarioDataWithOPs,
  allOPs,
  allSPs
}) => {


  const renderFloorPlan = (test) => {
    if (Array.isArray(test.floorPlans) && test.floorPlans.length > 0) {
      return <AnnotatedFloorPlan
        originPoints={allOPs.filter(
          (item) => item.testId === test.id
        )}
        samplePoints={allSPs.filter(
          (item) => item.testId === test.id
        )}
        imageFile={test.floorPlans[0] !== null ? test.floorPlans[0].url : null}
        fileName={test.floorPlans[0] !== null ? test.floorPlans[0].fileName : null}
        heatmapVersionFP={
          !!(test.floorPlans[0] && test.floorPlans[0].heatmapVersion)
        }
        size={{ width: 1000, height: 2500 }}
      />
    } else {
      return null;
    }
  };

  return (
    <>
    <div style={{ pageBreakAfter: "always", pageBreakBefore: "always" }}>
      <TestDetails
        test={test}
        order={number}
        projectName={projectName}
        projectId={projectId}
        warnings={warnings}
      />
      <Notes headerText={test.name + " Notes"} rows="4" />
      <br />
      <TestSprayTable details={sprayData} test={test} />
      <br />
      <TestScenarioList scenarios={scenarioData ?? []} />
      <br />
      <ScenarioOpTagsNotesTable
        scenarioDataWithOPs={
          scenarioDataWithOPs?.sort((a, b) => a.scenarioId - b.scenarioId) ?? []
        }
      />
      <br />
      <ScenarioCountTable
        test={test}
        scenarioSummary={scenarioSummary}
        sampleCount={samples.filter((item) => item.testId === test.id).length}
        SPs={SPs}
      />
      <br />
      <ScenariosWithSPs data={scenariosWithSPs?.scenarios ?? []} />
    </div>
      <div style={{ pageBreakAfter: "always", pageBreakBefore: "always" }}>
        <TestDetails
          test={test}
          order={number}
          projectName={projectName}
          projectId={projectId}
          warnings={warnings}
          headerOnly
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <OPsAndSPs OPs={OPs} SPs={SPs} />
          <div style={{ flexGrow: 1, padding: '0 10px' }}>
            {renderFloorPlan(test)}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectConfigurationTestPage;
