
import React from 'react';

import segments from '../../api/segments';
import projects from '../../api/projects';
import tests from '../../api/tests';
import originpoints from '../../api/originpoints';
import samplepoints from '../../api/samplepoints';
import floorPlans from '../../api/floorPlans';
import common from '../../api/common';
import sampleplans from "../../api/sampleplans";
import testPlan from "../../api/testPlan";

import { Context } from '../../context/Context';

import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { DatePicker } from "@material-ui/pickers";

import { Alert } from '../../components/Alert/Alert';
import { AlertLotNumber } from '../../components/Alert/AlertLotNumber';
import {NestedTableSegmentOP} from "../../components/NestedTable/NestedTableSegmentOP";
import DataLoadingDialog from "../../components/DataLoadingDialog/DataLoadingDialog";

import { format, parseISO } from "date-fns";
import Select, { components } from "react-select";

import './segments.css';

const styles = theme => ({
    cell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        textAlign: 'center',
        borderRight: 'none',
        borderLeft: 'none',
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'center',
        padding: '10px !important'
    },
    tableRow: {
        cursor: 'pointer',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(7, 5, 7),
        textAlign: 'center',
        fontFamily: "Gotham"
    },
    root: {
        '& > *': {
            margin: theme.spacing(0),
            position: 'relative',
            top: '15px',
        },
    },
    greenButton: {
        backgroundColor: "#82BC00",
        color: "#ffffff",
        borderColor: "#82BC00",
        marginRight: "5px",
        fontFamily: "Gotham",
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(130, 188, 0, 0.7)'
        }
    }
});

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

class Segments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectId: null,
            testId: null,
            floorPlan: null,
            floorPlans: [],
            floorPlanOptions: [],
            sprayer: {label:'Bucko', value: 1},
            sprayerOptions: [],
            comments: '',
            totalOP: null,
            totalSP: null,
            plateKind: null,
            surveyDate: null,
            numberOfOP: [{ value: 8, label: '8' }, { value: 16, label: '16' }],
            numberOfSP: [{ value: 12, label: '12' }, { value: 24, label: '24' }],
            numberOfPlates: [{ value: 'A', label: 'A' }, { value: 'B', label: 'B' }],
            selectSPDisabled: true,
            selectedSPCollectionMethod: null,
            SPCollectionMethods: [{ value: 'filter', label: 'Filter' }, { value: 'swab', label: 'Swab' }, { value: 'filterandswab', label: 'Filter and Swab' }],
            selectedSampleRate: null,
            sampleRate: '',
            sampleRateSet: false,
            sampleRates: [{ value: '2.0', label: '2.0' }, { value: '2.5', label: '2.5' }, { value: '3.0', label: '3.0' }, { value: '3.5', label: '3.5' }],
            segments: [],
            displayOP: false,
            disableOP: true,
            displaySP: false,
            displayPK: false,
            displaySampleRate: false,
            displayPlateKind: false,
            testPlanCreated: false,
            open: false,
            alertMsg: '',
            updateSuccessful: false,
            workFlow: false,
            zeroValue: false,
            isUpdating: false,
            opNumbers: [],
            tags: [],
            opIds: [],
            launchLotAlert: false,
            lotNumAlertMsg: '',
            tagLotNumbers: {},
            expirationDates: {},
            ops: [],
            segmentComments: {},
            indexOps: {},
            segmentId: null,
            projectFromProjectPortal: false
        };
        this.sampleType = null;
        // this.handleInputChange = this.handleInputChange.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDisplayOPClick = this.handleDisplayOPClick.bind(this);
        this.handleDisplaySPClick = this.handleDisplaySPClick.bind(this);
        this.handleOpenVisualizerClick = this.handleOpenVisualizerClick.bind(this);
    }

    handleOpeningAlert = success => {
        this.setState({
            open: !this.state.open,
            alertMsg: !!success ? 'Segments updated!' : 'Error updating segments!',
            updateSuccessful: !!success
        });
    }

    handleClosingAlertDefineSegs = () => {
        this.setState({
            open: false,
            alertMsg: '',
            updateSuccessful: false
        });
    }

    handleDataExtraction = opData => {
        let tagLotNumbers = {};
        let expirationDates = {};
        let tag = null;
        if (opData) {
            opData.forEach((obj) => {
                if (obj) {
                    const { opid } = obj;

                    if (obj.tags.length > 0) {
                        tag = obj.tags[0].tag;
                    }

                    //If OP has no lot num assigned yet
                    if (obj.taglotnumbers === null) {
                        tagLotNumbers = { ...tagLotNumbers, [opid]: '' };
                        expirationDates = { ...expirationDates, [opid]: '' };
                    }

                    //If OP has lot num assigned
                    if (obj.tagLotNumber) {
                        if (obj.tagLotNumber.length === 0) {
                            //If tagLotNumber array empty handle assignments
                            tagLotNumbers = { ...tagLotNumbers, [opid]: '' };
                            expirationDates = { ...expirationDates, [opid]: '' };
                        } else if (obj.tagLotNumber === []) {
                            tagLotNumbers = { ...tagLotNumbers, [opid]: '' };
                            expirationDates = { ...expirationDates, [opid]: '' };
                        } else {
                            tagLotNumbers = { ...tagLotNumbers, [opid]: obj.tagLotNumber[0].lotNumber };
                        }

                        if (obj.tagLotNumber[0]) {
                            if (obj.tagLotNumber[0].expirationDate !== 'undefined' || obj.tagLotNumber[0].expirationDate !== 'null') {
                                expirationDates = { ...expirationDates, [opid]: obj.tagLotNumber[0].expirationDate };
                            }
                            if (obj.tagLotNumber[0].expirationDate === 'undefined' || obj.tagLotNumber[0].expirationDate === 'null') {
                                expirationDates = { ...expirationDates, [opid]: '' };
                            }
                        }
                    }

                    this.setState({
                        tagLotNumbers,
                        expirationDates
                    });
                }
            });

            //Fetch all OPs so that table can receive updated data
            originpoints.getAllOriginpoints(this.state.testId, this.state.projectId).then(result => {
                if (result.data.success) {
                    if (result.data.data) {
                        if (result.data.data.length > 0) {
                            this.setState({ ops: result.data.data });
                        }
                    }
                } else {
                    console.log('api error: ', result.data.error)
                }
            });
        }

        //Emptying temp arrs in order to avoid duplication upon table recreation
        tagLotNumbers = {};
        expirationDates = {};
    }

    redrawSegments = data => {
        if (data.success) {
            this.setState({ segments: data.data }, () => {
                data.data.forEach(async (segment, i) => {
                    await this.setSegmentOps(segment, i);
                    if (!segment.surveydate || segment.surveydate === "Invalid date") {
                        segment.surveydate = null;
                    } else {
                        segment.surveydate = new Date(segment.surveydate).toISOString().slice(0,10);
                    }

                    const plateKind = segment.platekind === 'none' ? null : segment.platekind;

                    this.setState({
                        plateKind: {value: plateKind, label: plateKind},
                        floorPlan: {value: segment.floorplan, label: segment.floorplan},
                        testPlanCreated: true,
                        surveyDate: segment.surveydate,
                        comments: segment.comments,
                        displayPK: plateKind !== null
                    });
                });
            });
        }
    }

    selectValueChanged = async (event, testId, segmentId) => {
        let value = event.target.value;
        let colId = event.target.name;
        let segmentToUpdate = {
            'testId': testId
        };

        if (colId === 'floorPlans') {
            segmentToUpdate.floorPlan = value;
        } else if (colId === 'samplePoints') {
            segmentToUpdate.sp = value;
        } else if (colId === 'plates') {
            segmentToUpdate.plateKind = value;
        }
        try {
            //there seems to be an error on the server, yet the update still happens.
            //Todo: look into this
            await segments.updateSegment(segmentId, segmentToUpdate);
            await sampleplans.updateSamplePlan(this.state.projectId)
        } catch (e) {
            console.log(e);
        }
        //get current data anyway
        let response = await segments.getSegments(this.state.testId, this.state.projectId);
        this.redrawSegments(response.data);
    }

    handleTextFieldOnChange = (event, type, i, opid) => {
        let value = event.target.value;

        if (type === 'lotNumber') {
            this.setState({ tagLotNumbers: { ...this.state.tagLotNumbers, [opid]: value } });
        }
        if (type === 'expirationDate') {
            this.setState({ expirationDates: { ...this.state.expirationDates, [opid]: value } });
        }
        if (type === 'sampleRate') {
            this.setState({ sampleRate: value });
        }
    }

    onKeyDownCommentsUpdate = async (e, segmentId, testId, index) => {
        e.preventDefault();
        let segmentToUpdate = { "testId": testId };
        segmentToUpdate.comments = this.state.segmentComments[index];
        let response = await segments.updateSegment(segmentId, segmentToUpdate);
        if (response.data.success) {
            this.setState({
                launchLotAlert: true,
                lotNumAlertMsg: 'Comments successfully updated'
            });
            let response = await segments.getSegments(this.state.testId, this.state.projectId);
            if (response.data.success) {
                this.setState({ segments: response.data.data }, () => {
                    response.data.data.forEach((segment, i) => {
                        this.setState(prevState => ({
                            segmentComments: { ...prevState.segmentComments, [i]: segment.comments }
                        }));
                    });
                });
            }
        }
    }

    setSegmentOps = async (segment, index) => {
        let res = await originpoints.getOriginpoints(segment.segmentid);
        if (res.data.success) {
            if (res.data.data.length > 0) {
                let segmentOps = res.data.data;
                this.setState({ indexOps: { ...this.state.indexOps, [index]: segmentOps } });
                //This function call clears lotNum/exDate from UI when updating plate kind
                this.handleDataExtraction(res.data.data);
            } else {
                let segmentOps = res.data.data;
                this.setState({ indexOps: { ...this.state.indexOps, [index]: segmentOps } });
            }
        }
    }

    componentDidMount() {
        let response;
        let oneCheckResponse;
        let completed = null;

        //logic to handle highlighting navbar tab
        let segmentsNavButton = document.querySelector('#sBtn')
        if (segmentsNavButton) {
            segmentsNavButton.classList.add('selected');
        }
        //With this delay, componentMounts without notifying JSX of any of the testCompleted checks
        setTimeout(() => {
            let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
            let testId = JSON.parse(localStorage.getItem("testId")) ? JSON.parse(localStorage.getItem("testId")) : null;
            let projectFromProjectPortal = JSON.parse(localStorage.getItem("projectFromProjectPortal"));

            this.setState({
                projectId: projectId,
                testId: testId,
                projectFromProjectPortal
            }, async () => {
                response = await segments.getSegments(this.state.testId, this.state.projectId);
                this.redrawSegments(response.data);

                let sprayerRes = await common.getSprayers();
                if(sprayerRes && sprayerRes.data && sprayerRes.data.success === true) {
                    let sprayerOptions = [];
                    for (let i=0;i<sprayerRes.data.data.length;i++) {
                        sprayerOptions.push({
                            label: sprayerRes.data.data[i].sprayername,
                            value: sprayerRes.data.data[i].sprayerid
                        });
                    }
                    this.setState({sprayerOptions: sprayerOptions});
                }

                if (testId !== null) {
                    oneCheckResponse = await tests.getOneTest(this.state.testId);
                    if (oneCheckResponse && oneCheckResponse.data && oneCheckResponse.data.data) {
                        completed = oneCheckResponse.data.data.testexecuted;
                        this.setState({
                            sampleRate: oneCheckResponse.data.data.airsamplerrate,
                            sampleRateSet: oneCheckResponse.data.data.airsamplerrate !== ''
                        });
                        let sprayer = {
                                        label: oneCheckResponse.data.data.sprayername,
                                        value: oneCheckResponse.data.data.sprayerid
                                    }
                        this.setState({sprayer: sprayer });
                    }
                } else {
                    oneCheckResponse = await projects.getOneProject(this.state.projectId);
                    if (oneCheckResponse) {
                        completed = oneCheckResponse.data.data.testCompleted;
                    }
                }

                if (oneCheckResponse && completed !== null) {
                    this.setState({ testCompleted: completed }, () => {
                        if ((this.context.state.accessLevel === 'Admin' || this.context.state.accessLevel === 'Editor') && this.state.testCompleted === false) {
                            // If the project has no segments created show initial workflow prompt
                            if (this.state.segments) {
                                if (this.state.segments.length === 0) {
                                    this.setState({
                                        open: true,
                                        workFlow: true,
                                        displaySampleRate: false,
                                        alertMsg: 'Please define sample type then specify number of OP and optionally SP'
                                    });
                                } else {
                                    if (this.state.segments.length === 1) {
                                        this.setState({ displayPlateKind: true });
                                    }
                                    this.setState({
                                        displaySampleRate: true,
                                        testPlanCreated: true
                                    });
                                }
                            }
                        }
                    });
                }

                //  Get all floorplan image files for projectId
                floorPlans.getFloorPlans(this.state.projectId).then(result => {
                    this.setState({
                        floorPlans: result.data.data,
                        floorPlanOptions: result.data.data.map(fp => ({label: fp.filename, value: fp.filename}))
                    });
                });
                // Get all origin points for projectId
                this.refreshOPs();
                // Get all sample points for projectId
                samplepoints.getAllSamplepoints(this.state.testId, this.state.projectId).then(result => {
                    if (result.data.success) {
                        if (result.data.data && result.data.data.length > 0) {
                            let airSamplerRate;
                            if (result.data.data[0].airSamplerRate) {
                                if (result.data.data[0].airSamplerRate !== null) {
                                    airSamplerRate = result.data.data[0].airSamplerRate;
                                }
                            }
                            let value = result.data.data[0].collectionmethod;
                            let label;
                            if (value === 'filterandswab') {
                                label = 'Filter and Swab';
                            } else {
                                label = result.data.data[0].collectionmethod[0].toUpperCase() + result.data.data[0].collectionmethod.slice(1);
                            }
                            this.setState({
                                selectedSPCollectionMethod: { value: value, label: label },
                                sampleRate: airSamplerRate,
                                displaySP: true,
                                totalSP: { value: result.data.data.length, label: result.data.data.length }
                            });
                        }
                    }
                });
            });
        }, 0);
    };
    componentWillUnmount() {
        localStorage.removeItem("projectFromProjectPortal");
    }
    componentDidUpdate(preProps, preState) {
        if (this.state.selectedSPCollectionMethod !== null) {
            let values = Object.values(this.state.selectedSPCollectionMethod);
            this.sampleType = values[1];
        }
    }

    handleUpdateSampleType = async (sampleType) => {
        segments.updateSamples({ testId: this.state.testId, collectionMethod: this.state.selectedSPCollectionMethod.value }).then(async result => {
            if ( result.data.success ) {
                this.context.setSampleType(sampleType);
                await sampleplans.updateSamplePlan(this.state.projectId)
            }
        });
    }

    handleSPCollectionMethodChange = selectedOption => {
        this.setState({
            selectedSPCollectionMethod: selectedOption,
            displaySampleRate: true,
            displayOP: true
        }, async () => {
            await this.handleUpdateSampleType(selectedOption.value);
        });
    }

    handleSampleRateChange = e => {
        e.preventDefault();
        let sampleRate = this.state.sampleRate;
        sampleRate = sampleRate.toString();
        if(sampleRate.length <= 0) {
            console.log('Invalid Sample Rate!');
            this.setState({
                launchLotAlert: true,
                lotNumAlertMsg: 'Please provide a valid Air sampler rate'
            })
        } else {
            this.setState({ selectedSampleRate: this.state.sampleRate }, async () => {
                tests.setSampleRate({ testId: this.state.testId, sampleRate: sampleRate }).then(async result => {
                    if ( result.data.success ) {
                        this.setState({
                            launchLotAlert: true,
                            sampleRateSet: true,
                            lotNumAlertMsg: 'Air sample rate successfully updated'
                        });
                    }
                });
            });
        }
    }

    handleAddComment = e => {
        e.preventDefault();
        let comments = this.state.comments;
        comments = comments.toString();
        this.setState({
            comments: comments,
        });
        this.updateFieldInAllSegments('comments', comments).then(this.refreshSegments).then(() => {
            this.setState({
                launchLotAlert: true,
                lotNumAlertMsg: 'Comments successfully updated'
            });
        });
    }

    handleUpdateSurveyDate = e => {
        let surveyDate = format(e, 'yyyy-MM-dd');
        surveyDate = surveyDate.toString();
        this.setState({
            surveyDate: surveyDate,
        }, () => {
            this.updateTestPlan().then(async () => {
                this.setState({
                    launchLotAlert: true,
                    lotNumAlertMsg: 'Survey data successfully updated'
                });
                // await sampleplans.updateSamplePlan(this.state.projectId)
            });
        });
    }

    updateTestPlan = async () => {
        let segmentsTestedId = [];
        this.state.segments.forEach((segment) => {
            segmentsTestedId.push((segment.segmentid).toString());
        });

        for (const segment of this.state.segments) {
            let testPlanToUpdate = {
                "testId": this.state.testId,
                "segmentId": segment.segmentid,
                "surveyDate": this.state.surveyDate ?? null,
                "segmentsTested": segmentsTestedId,
                "comments": segment.comments
            }

            const result = await testPlan.updateTestPlan(testPlanToUpdate);
            if (!result.data.success) {
                console.log("Error updating test plan: ", result.data.error);
            }
        }
    }

    handleSelectOP = selectedOption => {
        // event.preventDefault();
        // let value = parseInt(event.target.children[0].value);

        const value = parseInt(selectedOption.value);
        const totalSegments = (value === 16) ? 2 : 1;

        this.setState({
            totalOP: selectedOption,
            selectSPDisabled: totalSegments > 1,
            displayPlateKind: totalSegments === 1,
            testPlanCreated: false,
            isUpdating: true
        }, async () => {
            try {
                if (value === 0) {
                    this.setState({ 
                        zeroValue: true,
                        isUpdating: false 
                    });
                } else {
                    const data = { projectId: this.state.projectId, testId: this.state.testId, totalSegments: totalSegments };
                    //  1. Create selected number of segments for the project
                    segments.createSegments(data).then(async () => {
                        //  2. Get newly created segments
                        const response = await segments.getSegments(this.state.testId, this.state.projectId);
                        this.setState({ segments: response.data.data });
                        //  3. On success, create OP for each segment in project
                        if (response.data.success === true) {
                            this.redrawSegments(response.data.data);
                            //  4. Create OP for project
                            originpoints.createOriginpoints({ testId: this.state.testId }).then(async response => {
                                if ( response.data.success === true ) {
                                    this.setState({
                                        displayOP: true
                                    });
                                    //setSegmentOps() calls originpoints.getOriginpoints so invoking after creating OPs
                                    this.state.segments.forEach((segment, i) => {
                                        this.setSegmentOps(segment, i);
                                    });
                                    if ( totalSegments === 2 ) {
                                        // If there are 2 segments, we know the plate kind already, so we can proceed with the test plan
                                        this.setPlateKind().then(this.updateTestPlan).then(this.refreshSegments).then(async () => {
                                            await sampleplans.updateSamplePlan(this.state.projectId);
                                            this.refreshOPs();
                                        }).catch((err) => this.stopUpdateAndAlertError(err.message));
                                        this.setState({ displayPK: true });
                                    } else {
                                        this.setState({ displayPK: false });
                                        await sampleplans.updateSamplePlan(this.state.projectId).then(()=>{
                                            this.setState({ isUpdating: false });
                                        }).catch(err => this.stopUpdateAndAlertError(err.message));
                                    }
                                    this.setState({ testPlanCreated: true });
                                } else {
                                    console.log('Error creating OP');
                                    throw new Error('Error creating OP');
                                }
                            }).catch(err => this.stopUpdateAndAlertError(err.message));
                            //  5. Create SP for project
                            samplepoints.createSamplepoints({ testId: this.state.testId, collectionMethod: this.state.selectedSPCollectionMethod.value }).then(async response => {
                                if ( response.data.success === true ) {
                                    this.setState({
                                        displaySP: true,
                                        totalSP: { value: response.data.data.length, label: response.data.data.length }
                                    });
                                } else {
                                    console.log('Error creating SP');
                                    throw new Error('Error creating SP');
                                }
                                await sampleplans.updateSamplePlan(this.state.projectId).then(()=>{
                                    //this.setState({ isUpdating: false });
                                }).catch(err => this.stopUpdateAndAlertError(err.message));
                            }).catch(err => this.stopUpdateAndAlertError(err.message));
                        } else {
                            console.log('Error creating Segments');
                            throw new Error('Error creating Segments');
                        }
                    }).catch(err => this.stopUpdateAndAlertError(err.message));

                    this.setState({
                        workFlow: true,
                        plateKind: null,
                        floorPlan: null,
                        comments: '',
                        isUpdating: false
                    });
                }
            } catch (err) {
                this.stopUpdateAndAlertError(err.message);
            }
        });
    }

    handleSelectSP = selectedOption => {
        const value = parseInt(selectedOption.value);

        this.setState({ totalSP: selectedOption, displayPlateKind: true, isUpdating: true });
        this.updateFieldInAllSegments('sp', value).then(async () => {
            await sampleplans.updateSamplePlan(this.state.projectId);
            await this.refreshSegments().then(()=>{
                this.setState({ isUpdating: false });
            }).catch(err => this.stopUpdateAndAlertError(err.message));
        }).catch(err => this.stopUpdateAndAlertError(err.message));

    }

    stopUpdateAndAlertError = (message = '') => {
        this.setState({
            isUpdating: false,
            open: true,
            updateSuccessful: false,
            workFlow: false,
            alertMsg: message || 'Something went wrong'
        });
    };

    handleSelectFloorPlan = selectedOption => {
        const value = selectedOption.value;
        this.setState({ floorPlan: selectedOption, isUpdating: true });
        this.updateFieldInAllSegments('floorPlan', value).then(async () => {
            // await sampleplans.updateSamplePlan(this.state.projectId);
            await this.refreshSegments().then(()=>{
                this.setState({ isUpdating: false });
            }).catch(err => this.stopUpdateAndAlertError(err.message));
        }).catch(err => this.stopUpdateAndAlertError(err.message));
    }


    enableNavButtons = () => {
        let NavButtons = document.querySelectorAll('#clBtn, #labBtn');
        if(NavButtons) {
            NavButtons.forEach(value => {
                value.style.visibility = 'visible'
            });
        }
    }

    disableNavButtons = () => {
        let NavButtons = document.querySelectorAll('#clBtn, #labBtn');
        if(NavButtons) {
            NavButtons.forEach(value => {
                value.style.visibility = 'hidden'
            });
        }
    }

    handleSelectSprayer = selectedOption => {
        this.setState({ sprayer: selectedOption, isUpdating: true }, async () => {
            tests.updateOneTest(this.state.testId, { sprayerId: this.state.sprayer.value }).then(result => {
                if (result.data.success) {
                    console.log('result: ', result);
                }
                this.setState({ isUpdating: false });
            }).catch(err => this.stopUpdateAndAlertError(err.message));
        }).catch(err => this.stopUpdateAndAlertError(err.message));
    }

    refreshSegments = async () => {
        const response = await segments.getSegments(this.state.testId, this.state.projectId);
        this.setState({segments: response.data.data});
    }

    refreshOPs = () => {
        originpoints.getAllOriginpoints(this.state.testId, this.state.projectId).then(result => {
            if (result.data.success) {
                if (result.data.data.length > 0) {
                    this.setState({
                        displayOP: true,
                        ops: result.data.data,
                        selectSPDisabled: result.data.data.length > 8,
                        totalOP: { value: result.data.data.length, label: result.data.data.length },
                        isUpdating: false
                    });
                    this.handleDataExtraction(result.data.data);
                }
            } else {
                console.log('api error: ', result.data.error);
            }
        }).catch(err => this.stopUpdateAndAlertError(err.message));
    }

    updateSegmentField = async (segmentId, fieldName, value) => {
        let response = await segments.updateSegment(segmentId,
            {testId: this.state.testId, [fieldName]: value}
        );
        if (response.data.success !== true) {
            console.log('Error updating segment', response.data.error);
        }
    }

    updateFieldInAllSegments = async (fieldName, value) => {
        return Promise.all(this.state.segments.map(async (segment) => {
            await this.updateSegmentField(segment.segmentid, fieldName, value);
        }));
    }

    setPlateKind = async (value = null) => {
        const response = await segments.getSegments(this.state.testId, this.state.projectId);
        if (response.data.success !== true) {
            console.log('Error fetching segments', response.data.error);
            return;
        }
        if (response.data.data.length === 1 && value) {
            //for one segment use specified value
            return this.updateSegmentField(response.data.data[0].segmentid, 'plateKind', value);
        } else if (response.data.data.length === 2) {
            await this.updateSegmentField(response.data.data[0].segmentid, 'plateKind', 'A');
            return this.updateSegmentField(response.data.data[1].segmentid, 'plateKind', 'B');
        }
    }

    handleSelectPlateKind = selectedOption => {
        const value = selectedOption.value;
        this.setState({isUpdating: true });
        this.setPlateKind(value).then(this.updateTestPlan).then(this.refreshSegments).then(async () => {
            await sampleplans.updateSamplePlan(this.state.projectId).then(()=>{
                this.setState({ isUpdating: false });
            }).catch(err => this.stopUpdateAndAlertError(err.message));
            this.refreshOPs();
        }).catch(err => this.stopUpdateAndAlertError(err.message));
        this.setState({
            plateKind: selectedOption,
            testPlanCreated: true,
            displayPK: true
        });
    }

    handleViewPlanForProjectClick = () => {
        this.props.history.push('/sampleplan');
    }

    handleOpenVisualizerClick() {
        this.props.history.push('/visualizer');
    }

    handleDisplayOPClick() {
        this.props.history.push('/originpoints');
    }

    handleDisplaySPClick() {
        this.props.history.push('/samplepoints');
    }

    handleViewSamplesClick = () => this.props.history.push('/viewsamples');

    onKeyDownLotNumberExpirationDateUpdate = async (e, opId, tag, type, ops, index, segmentId) => {
        let requestBody = {};
        let value = e.target.children[0].children[0].children[0].children[0].value;
        e.preventDefault();

        if (type === 'lotNumber') {
            requestBody = {
                OPId: opId,
                tag: tag,
                tagLotNumber: value.toString()
            }

            let responseSetLotNum = await originpoints.setTagLotNumber(requestBody);
            if (responseSetLotNum.data.success) {
                this.setState({
                    launchLotAlert: true,
                    lotNumAlertMsg: 'Lot number successfully updated'
                });

                //Fetch OPs for all segments
                let opsRes = await originpoints.getAllOriginpoints(this.state.testId);
                if (opsRes.data.success) {
                    //Pass the res to func that sets data for UI
                    this.handleDataExtraction(opsRes.data.data)
                }
            } else {
                this.setState({
                    launchLotAlert: true,
                    lotNumAlertMsg: 'Please assign a plate kind and provide a valid lot number'
                }, () => {
                    setTimeout(() => {
                        this.setState({ launchLotAlert: false });
                    }, 1000);
                });
                this.state.tagLotNumbers[opId] = '';
            }
        }
        if (type === 'expirationDate') {
            if (value !== '') {
                let lotNum;
                for ( const opObj of ops ) {
                    ops.indexOf(opObj);
                    if (opId === opObj.opid) {
                        if (opObj.tagLotNumber) {
                            if (opObj.tagLotNumber.length > 0) {
                                lotNum = opObj.tagLotNumber[0].lotNumber;

                                requestBody = {
                                    OPId: opId,
                                    tag: tag,
                                    tagLotNumber: lotNum,
                                    expirationDate: value.toString()
                                }
                                let responseSetExDate = await originpoints.setTagLotNumber(requestBody);
                                if (responseSetExDate.data.success) {
                                    this.setState({
                                        launchLotAlert: true,
                                        lotNumAlertMsg: 'Expiration date successfully updated'
                                    });
                                    let responseGetOPExDate = await originpoints.getOriginpoints(segmentId);
                                    if (responseGetOPExDate.data.success) {
                                        this.handleDataExtraction(responseGetOPExDate.data.data)
                                    }
                                } else {
                                    this.setState({
                                        launchLotAlert: true,
                                        lotNumAlertMsg: 'Please provide a valid expiration date'
                                    }, () => {
                                        setTimeout(() => {
                                            this.setState({ launchLotAlert: false });
                                        }, 2000);
                                    });
                                    this.state.expirationDates[opId] = '';
                                }
                            }
                        }

                        if (opObj.taglotnumbers === null || opObj.tagLotNumber.length === 0) {
                            this.setState({
                                launchLotAlert: true,
                                lotNumAlertMsg: 'Please assign tag lot number first before submitting expiration date'
                            }, () => {
                                setTimeout(() => {
                                    this.setState({ launchLotAlert: false });
                                }, 12000);
                            });
                            this.state.expirationDates[opId] = '';
                        }

                    }
                }
            } else {
                this.setState({
                    launchLotAlert: true,
                    lotNumAlertMsg: 'Please assign tag lot number first before submitting expiration date'
                }, () => {
                    setTimeout(() => {
                        this.setState({ launchLotAlert: false });
                    }, 2000);
                });
                this.state.expirationDates[opId] = '';
            }
        }
    }

    handleClosingAlert = () => {
        this.setState({
            launchLotAlert: false,
            lotNumAlertMsg: ''
        });
    }

    render() {
        const {
            open,
            alertMsg,
            updateSuccessful,
            workFlow,
            segments,
            projectId,
            ops,
            testCompleted,
            launchLotAlert,
            lotNumAlertMsg,
            tagLotNumbers,
            expirationDates,
            displaySampleRate,
            isUpdating
        } = this.state;
        const { classes } = this.props;
        return (
            <Context.Consumer>
                {context => (
                    <>
                    {isUpdating === true && (
                        <DataLoadingDialog
                            title="The test is being updated"
                            message='Updating test data, this will take a few moments.'
                            onCancel={() => {
                                this.setState({ isUpdating: false });
                            }} />
                    )}
                        <div className="segments-container">
                            <div>
                                <Typography variant="h5" color="textPrimary" align="center" style={{ fontFamily: "Gotham", marginTop: "20px" }}>{`(Project Id ${projectId}) ${context.state.projectName}: ${context.state.testName}`}</Typography>
                            </div>
                            <div className="segments-btn-container">
                                <div className="segments-no-segments-container segments-btn-container-child"
                                    style={{ minWidth: '162px', fontFamily: "Gotham", marginTop: '36px' }}>
                                    <Select
                                        value={this.state.selectedSPCollectionMethod}
                                        onChange={this.handleSPCollectionMethodChange}
                                        isDisabled={this.state.testCompleted || this.context.state.accessLevel === 'Viewer'}
                                        options={this.state.SPCollectionMethods}
                                        components={{
                                            ValueContainer: CustomValueContainer
                                        }}
                                        placeholder="Sample Type*"
                                        styles={{
                                            container: (provided) => ({
                                                ...provided,
                                                marginTop: 50,
                                                marginRight: 12
                                            }),
                                            valueContainer: (provided) => ({
                                                ...provided,
                                                overflow: "visible"
                                            }),
                                            placeholder: (provided, state) => ({
                                                ...provided,
                                                position: "absolute",
                                                top: state.hasValue || state.selectProps.inputValue ? -15 : "50%",
                                                transition: "top 0.1s, font-size 0.1s",
                                                fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                                            })
                                        }}>
                                    </Select>
                                </div>
                                {(displaySampleRate === true && this.sampleType !== 'Swab') && (
                                    <div className='segments-input-element-container' style={{paddingTop: '15px', marginTop: '53px'}}>
                                        <form onSubmit={e => this.handleSampleRateChange(e)} noValidate autoComplete="off">
                                            <TextField
                                                type="text"
                                                name='sampleRate'
                                                label='Air Sample Rate (L/min)*'
                                                inputProps={{ min: 0, style: { textAlign: 'center', paddingLeft: '10px', marginTop: '8px'} }}
                                                value={this.state.sampleRate || ""}
                                                onChange={e => {
                                                    let { value } = e.target;
                                                    this.setState({ sampleRate: value });
                                                }}
                                                disabled={testCompleted || this.context.state.accessLevel === 'Viewer' || this.state.selectedSPCollectionMethod === null || this.sampleType === 'Swab'}
                                                style={{width: '200px'}}
                                            />
                                            <Tooltip title="Submit Air Sample Rate" placement="top" aria-label="air-sample-rate"
                                                     style={{ marginTop: '24px' }}>
                                                <div>
                                                    <IconButton
                                                        type="submit"
                                                        disabled={testCompleted || this.context.state.accessLevel === 'Viewer' || this.state.selectedSPCollectionMethod === null || this.sampleType === 'Swab'}
                                                    >
                                                        <CloudUploadIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </form>
                                    </div>
                                )}
                                {((this.state.testCompleted || this.state.displayOP)) && (
                                <div className="segments-no-segments-container segments-btn-container-child"
                                     style={{
                                         fontFamily: "Gotham", marginLeft: '12px',
                                         marginTop: '36px', display: 'flex', flexDirection: 'row'
                                     }}>
                                    <Select
                                        value={this.state.totalOP}
                                        onChange={this.handleSelectOP}
                                        options={this.state.numberOfOP}
                                        isDisabled={this.state.testCompleted || this.context.state.accessLevel === 'Viewer'}
                                        components={{
                                            ValueContainer: CustomValueContainer
                                        }}
                                        placeholder="No of OP*"
                                        styles={{
                                            container: (provided) => ({
                                                ...provided,
                                                marginTop: 50,
                                                width: 130
                                            }),
                                            valueContainer: (provided) => ({
                                                ...provided,
                                                overflow: "visible"
                                            }),
                                            placeholder: (provided, state) => ({
                                                ...provided,
                                                position: "absolute",
                                                top: state.hasValue || state.selectProps.inputValue ? -15 : "50%",
                                                transition: "top 0.1s, font-size 0.1s",
                                                fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                                            })
                                        }}>
                                    </Select>
                                </div>
                                )}
                                {(this.state.testCompleted || this.state.displaySP) && (
                                <div className="segments-no-segments-container segments-btn-container-child"
                                     style={{
                                         fontFamily: "Gotham", marginLeft: '12px',
                                         marginTop: '36px', display: 'flex', flexDirection: 'row'
                                     }}>
                                    <Select
                                        value={this.state.totalSP}
                                        onChange={this.handleSelectSP}
                                        options={this.state.numberOfSP}
                                        isDisabled={!this.context.state.projectFromProjectPortal || this.state.selectSPDisabled || this.state.testCompleted || this.context.state.accessLevel === 'Viewer'}
                                        components={{
                                            ValueContainer: CustomValueContainer
                                        }}
                                        placeholder="No of SP*"
                                        styles={{
                                            container: (provided) => ({
                                                ...provided,
                                                marginTop: 50,
                                                width: 130
                                            }),
                                            valueContainer: (provided) => ({
                                                ...provided,
                                                overflow: "visible"
                                            }),
                                            placeholder: (provided, state) => ({
                                                ...provided,
                                                position: "absolute",
                                                top: state.hasValue || state.selectProps.inputValue ? -15 : "50%",
                                                transition: "top 0.1s, font-size 0.1s",
                                                fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                                            })
                                        }}>
                                    </Select>
                                </div>
                                )}
                                {(this.state.testCompleted || this.state.displayPlateKind) && (
                                    <div className="segments-no-segments-container segments-btn-container-child"
                                         style={{
                                             fontFamily: "Gotham", marginLeft: '12px',
                                             marginTop: '36px', display: 'flex', flexDirection: 'row'
                                         }}>
                                    <Select
                                        value={this.state.plateKind}
                                        onChange={this.handleSelectPlateKind}
                                        options={this.state.numberOfPlates}
                                        isDisabled={this.state.testCompleted || this.context.state.accessLevel === 'Viewer'}
                                        components={{
                                            ValueContainer: CustomValueContainer
                                        }}
                                        placeholder="Plate Kind*"
                                        styles={{
                                            container: (provided) => ({
                                                ...provided,
                                                marginTop: 50,
                                                width: 130
                                            }),
                                            valueContainer: (provided) => ({
                                                ...provided,
                                                overflow: "visible"
                                            }),
                                            placeholder: (provided, state) => ({
                                                ...provided,
                                                position: "absolute",
                                                top: state.hasValue || state.selectProps.inputValue ? -15 : "50%",
                                                transition: "top 0.1s, font-size 0.1s",
                                                fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                                            })
                                        }}>
                                    </Select>
                                </div>
                                )}
                            </div>
                            {(this.state.testCompleted || this.state.testPlanCreated) && (
                            <div className="segments-input-container">
                                <div style={{ gridRow: '1', gridColumn: '1' }}>
                                    <div className='segments-input-element-container'>
                                        <Select
                                            value={this.state.floorPlan}
                                            onChange={this.handleSelectFloorPlan}
                                            options={this.state.floorPlanOptions}
                                            isDisabled={this.state.testCompleted || this.context.state.accessLevel === 'Viewer'}
                                            components={{
                                                ValueContainer: CustomValueContainer
                                            }}
                                            placeholder="Floor Plan*"
                                            label="Floor Plan"
                                            styles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: '100%',
                                                    marginTop: 20
                                                }),
                                                valueContainer: (provided) => ({
                                                    ...provided,
                                                    overflow: "visible"
                                                }),
                                                placeholder: (provided, state) => ({
                                                    ...provided,
                                                    position: "absolute",
                                                    top: state.hasValue || state.selectProps.inputValue ? -15 : "50%",
                                                    transition: "top 0.1s, font-size 0.1s",
                                                    fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                                                })
                                            }}>
                                        </Select>
                                    </div>
                                </div>

                                <div style={{ gridRow: '1', gridColumn: '2' }}>
                                    <div className='segments-input-element-container'>
                                        <Select
                                            value={this.state.sprayer}
                                            onChange={this.handleSelectSprayer}
                                            options={this.state.sprayerOptions}
                                            isDisabled={this.state.testCompleted || this.context.state.accessLevel === 'Viewer'}
                                            components={{
                                                ValueContainer: CustomValueContainer
                                            }}
                                            placeholder="Sprayer*"
                                            label="Sprayer"
                                            styles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    marginTop: 20,
                                                    minWidth: '150px',
                                                    maxWidth: '200px',
                                                }),
                                                valueContainer: (provided) => ({
                                                    ...provided,
                                                    overflow: "visible"
                                                }),
                                                placeholder: (provided, state) => ({
                                                    ...provided,
                                                    position: "absolute",
                                                    top: state.hasValue || state.selectProps.inputValue ? -15 : "50%",
                                                    transition: "top 0.1s, font-size 0.1s",
                                                    fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                                                })
                                            }}>
                                        </Select>
                                    </div>
                                </div>

                                <div style={{ gridRow: '2', gridColumn: '1' }}>
                                    <div className='segments-input-element-container' style={{paddingTop: '15px'}}>
                                        <form onSubmit={e => this.handleUpdateSurveyDate(e)} noValidate autoComplete="off">
                                            <DatePicker
                                              variant="inline"
                                              label="Survey Date*"
                                              name='surveyDate'
                                              autoOk={true}
                                              format={'MMM do, yyyy'}
                                              emptyLabel=''
                                              inputProps={{ min: 0, style: { textAlign: 'left', paddingLeft: '10px', minWidth: '135px'} }}
                                              value={this.state.surveyDate !== null ? parseISO(this.state.surveyDate) : null}
                                              onChange={e => this.handleUpdateSurveyDate(e)}
                                              disabled={testCompleted || this.context.state.accessLevel === 'Viewer'}
                                            />
                                        </form>
                                    </div>
                                </div>
                                <div style={{ gridRow: '1 / 3', gridColumn: '3' }}>
                                    <div className='segments-input-element-container'>
                                        <form onSubmit={e => this.handleAddComment(e)} className={classes.root} noValidate autoComplete="off">
                                            <TextField
                                                type="text"
                                                label="Comments"
                                                name='comments'
                                                inputProps={{ min: 0, style: { textAlign: 'left', height: '105px', paddingLeft: '10px' } }}
                                                value={this.state.comments || ""}
                                                multiline
                                                rowsMax={5}
                                                onChange={e => { this.setState({ comments: e.target.value });}}
                                                disabled={testCompleted || this.context.state.accessLevel === 'Viewer'}
                                                style={{width: '550px', top: '3px'}}
                                            />
                                            <Tooltip title="Add Comment" placement="top" aria-label="test-comment"
                                                     style={{ marginTop: '90px' }}>
                                                <div>
                                                    <IconButton type="submit"
                                                        disabled={testCompleted || this.context.state.accessLevel === 'Viewer'}>
                                                        <CloudUploadIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            )}
                            <div className="segments-btn-container">
                                <div className="segments-buttons segments-btn-container-child"
                                     style={{ minWidth: '480px' }}>
                                    {this.state.displayPK && (<Button size="small" variant="outlined" className={classes.greenButton} onClick={this.handleOpenVisualizerClick}>Floor plans</Button>)}
                                    {this.state.displayPK && (<Button size="small" variant="outlined" className={classes.greenButton} onClick={this.handleDisplayOPClick}>Origin points</Button>)}
                                    {this.state.displayPK && (<Button size="small" variant="outlined" className={classes.greenButton} onClick={this.handleDisplaySPClick}>Sample points</Button>)}
                                    {this.state.displayPK && (<Button size="small" variant="outlined" className={classes.greenButton} onClick={this.handleViewSamplesClick}>Samples</Button>)}
                                </div>
                            </div>
                            {this.state.displayPK && (
                            <div className="segments-table-container" style={{ marginLeft: 0 }}>
                                <TableContainer style={{ marginTop: '15px' }} component={Paper}>
                                    <div>
                                        <Table style={{ minWidth: '480px' }} aria-label="origin points">
                                            <TableHead className={classes.tableHeader}>
                                                <TableRow id="row">
                                                    <TableCell className={classes.headerCell}></TableCell>
                                                    <TableCell className={classes.headerCell}>OP Name</TableCell>
                                                    <TableCell className={classes.headerCell}>Tag</TableCell>
                                                    <TableCell className={classes.headerCell}>Tag Lot Number</TableCell>
                                                    <TableCell className={classes.headerCell}>Expiration Date</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {ops.map((segOp, i) => (
                                                <NestedTableSegmentOP
                                                    key={`nestedSegOp-segOp-${i}`}
                                                    index={i}
                                                    ops={ops}
                                                    segOp={segOp}
                                                    segments={segments}
                                                    handleTextFieldOnChange={this.handleTextFieldOnChange}
                                                    onKeyDownLotNumberExpirationDateUpdate={this.onKeyDownLotNumberExpirationDateUpdate}
                                                    expirationDates={expirationDates}
                                                    tagLotNumbers={tagLotNumbers}
                                                    isViewer={this.context.state.accessLevel === 'Viewer'}
                                                    testCompleted={testCompleted}
                                                />
                                           ))}
                                           </TableBody>
                                       </Table>
                                    </div>
                                </TableContainer>
                            </div>
                            )}
                            {!!open && (<Alert flag={open} msg={alertMsg} closeFunc={this.handleClosingAlertDefineSegs} updateSuccessful={updateSuccessful} workFlow={workFlow} />)}
                            {!!launchLotAlert && (<AlertLotNumber launchLotAlert={launchLotAlert} lotNumAlertMsg={lotNumAlertMsg} handleClosingAlert={this.handleClosingAlert} />)}
                        </div>
                    </>
                )}
            </Context.Consumer>
        )
    }
}

Segments.contextType = Context;

export default withStyles(styles, { withTheme: true })(Segments);