import React, { useState } from 'react';
import TextField from "@material-ui/core/TextField";

/**
 * Search with deferred update of external value caused by passed onChange.
 * Suitable for React version 16.8 and all that are higher.
 * Deferred update is used for triggering the search through the data only after user stops typing,
 * so there would be fewer operations.
 */
function InputSearch({
  placeholder = '',
  onChange,
  deferredUpdate = true,
  delayMs = 240,
}) {
  const [searchValue, setSearchValue] = useState('');

  const [lastUpdateMs, setLastUpdateMs] = useState(0);
  const [updateTimeoutId, setUpdateTimeoutId] = useState(0);

  const conditionalUpdate = (value) => {
    const now = Date.now();
    let diff = null;
    if (lastUpdateMs) {
      diff = now - lastUpdateMs;
    }
    setLastUpdateMs(now);

    if (updateTimeoutId && diff < delayMs) {
      clearTimeout(updateTimeoutId);
    }
    const timeoutId = setTimeout(() => onChange(value), delayMs);
    setUpdateTimeoutId(timeoutId);
  };

  const handleValueChange = (value) => {
    if (typeof value !== 'string') return;

    const valueTrimmed = value.trim();

    setSearchValue(value);
    if (typeof onChange === 'function') {
      if (deferredUpdate) {
        conditionalUpdate(valueTrimmed);
      } else {
        onChange(valueTrimmed);
      }
    }
  };

  return (
    <TextField
      id="input-with-icon-adornment"
      InputProps={{
        placeholder: placeholder || 'Search',
      }}
      style={{ fontFamily: 'Gotham', width: '100%' }}
      fullWidth
      variant="standard"
      onChange={(e) => handleValueChange(e.target.value)}
      value={searchValue}
      autoComplete="off"
    />
  );
}

export default React.memo(InputSearch);
