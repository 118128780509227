import { VERIFICATION_LIKE_TESTS } from '../../config';

export function tableConfigConstructor (testType, isSafetracer = false, isViewer = true, onChange = () => {}, floorPlans = [], scenarios = []) {
	if (!~VERIFICATION_LIKE_TESTS.indexOf(testType)) return {};

	const opConfig = getOpColumnConfig(isSafetracer, isViewer, onChange);
	const spConfig = getSpColumnConfig(isSafetracer, isViewer, onChange);;

	const mainTableConfig = {
		floor_plan: {
			headerName: 'Floor Plan*',
			isDropdown: true,
			dropdownProps: {
			coltype: 'floorPlans',
		},
			dropdownExtraProps: {
				onChangeFunc: onChange,
				fpOptions: floorPlans,
			}
		},
		scenarios: {
			headerName: 'Scenarios',
			isDropdown: true,
			dropdownProps: {
				coltype: 'scenarios',
			},
			dropdownExtraProps: {
				onChangeFunc: onChange,
				scenarios: scenarios,
			},
		},
		OPs: opConfig,
		SPs: spConfig,
		intervalCount: {
			headerName: 'Interval count',
			isDropdown: false,
			constant_view: true,
			data_key: 'intervalcount',
		},
		intervalDuration: {
			headerName: 'Interval duration',
			isDropdown: false,
			constant_view: true,
			data: 'Custom (5 min)',
		},
		surveyDate: {
			headerName: 'Survey Date*',
			isDropdown: false,
		},
		comments: {
			headerName: 'Comments',
			isDropdown: false
		},
	};

	return mainTableConfig;
};

export const areaOpSpCountConfig = {
	dilutionLarge: {
		op_square: 2000,
		sp_square: 4000,
	},
	dilutionSmall: {
		op_square: 2000,
		sp_square: 4000,
	},
	verification: {
		op_square: 2000,
		sp_square: 4000,
	},
};

const areaConfirmativeConfig = {
	range_validator: {
		sq_ft: (val) => !!(parseInt(val, 10) && (val - 100 >= 0)),
		celling_height: (val) => !!(parseInt(val, 10) && (val - 15 <= 0)),
	},
	on_validation_fail: {
		sq_ft: {
			textConfirm: (val) => `Are you sure that the square of the area is ${val} ft?`,
		},
		celling_height: {
			textConfirm: (val) => `Are you sure that the height of the ceiling is ${val} ft?`,
		},
		all: {
			textConfirm: (values) => `Are you sure that the square of the area is ${values.sq_ft} ft and the height of the ceiling is ${values.celling_height} ft?`,
		}
	}
};

export const areaSectionConfig = {
	dilutionLarge: {
		header: 'Test area',
		alertText: 'Test settings are not available without test area information',
		form: {
			confirmative: areaConfirmativeConfig,
			restrict: {
				range_validator: {
					sq_ft: (val) => !!(parseInt(val, 10) && (val > 4000 && val <= 8000)),
					celling_height: (val) => !!parseInt(val, 10),
				},
				on_validation_fail: {
					sq_ft: {
						textReject:  `Large Dilution expects test area to be greater than 4000 sq.ft and less than 8000 sq.ft`,
					},
				}
			},
		},
	},
	dilutionSmall: {
		header: 'Test area',
		alertText: 'Test settings are not available without test area information',
		form: {
			confirmative: areaConfirmativeConfig,
			restrict: {
				range_validator: {
					sq_ft: (val) => !!(parseInt(val, 10) && (val > 0 && val <= 4000)),
					celling_height: (val) => !!parseInt(val, 10),
				},
				on_validation_fail: {
					sq_ft: {
						textReject:  `Small Dilution expects test area to be less than 4000 sq.ft`,
					},
				}
			},
		},
	},
	verification: {
		header: 'Testing info',
		alertText: 'Test settings are not available without building information',
		form: {}
	},
};

const getOpColumnConfig = (isSafetracer, isViewer, onChange) => {
	const nonEditable = {
		headerName: 'No: of OP*',
		isDropdown: false,
		constant_view: true,
		data_key: 'op',
	};

	const editable = {
		headerName: 'No: of OP*',
		isDropdown: true,
		dropdownProps: {
		coltype: 'originPointsVerification',
		},
		dropdownExtraProps: {
			onChangeFunc: onChange,
		},
	}

	return isSafetracer && !isViewer ? editable : nonEditable;
};

const getSpColumnConfig = (isSafetracer, isViewer, onChange) => {
	const editable = {
		headerName: 'No: of SP',
		isDropdown: true,
		dropdownProps: {
		coltype: 'samplePointsVerification',
		},
		dropdownExtraProps: {
			onChangeFunc: onChange,
		},
	};

	const nonEditable = {
		headerName: 'No: of SP',
		isDropdown: false,
		constant_view: true,
		data_key: 'sp',
	};

	return isSafetracer && !isViewer ? editable : nonEditable;
};