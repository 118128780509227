import axios from 'axios';
import config from '../config.js';

const USERSURL = config.baseURL + '/auth/users';
const REGISTERURL = config.baseURL + '/auth/users/register';
const USERUPDATEACCESSLEVELURL = config.baseURL + '/auth/users';
const USERUPDATEPASSWORDLURL = config.baseURL + '/auth/users/password';
const REMOVEONUSERURL = config.baseURL + '/auth/users/';


const newAxiosInstance = axios.create();

const users  = {
  getUsers : async() => {
    const response = await axios.get (USERSURL);
    return response;
  },
  registerUser: async(data)=> {
    const response = await axios.post (REGISTERURL, data);
    return response;
  },
  updateUserAccessLevel : async(userId, body) => {
    const response = await axios.put (`${USERUPDATEACCESSLEVELURL}/${userId}`, body);
    return response;
  },
  updateUserPassword: async(data) => {
    const response = await newAxiosInstance.put (`${USERUPDATEPASSWORDLURL}`, data);
    return response;
  },
  removeOneUser: async (userId) => {
    return await axios.delete(REMOVEONUSERURL + userId);
  },
};

export default users;