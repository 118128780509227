import React from 'react';
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Notes from "./Notes";
const styles = (theme) => ({
    columnHeader: {


        minHeight: '200px',
        //maxHeight: '20px',
        //border: '3px solid #73AD21',
        background: 'rgb(0, 137, 150)',//'lightgray',
        padding: '10px',
        //align: 'left',
        //transform: 'rotate(270deg)',
    },

    columnHeaderText: {
        //transform: 'rotate(270deg)', /* Equal to rotateZ(45deg) */
        //textAlign: 'left',
        //margin: '10px',
        //minWidth: '200px',
        //position: 'absolute',
        //right: '0px',
    },
    table: {
        border: '1px solid gray !important',//1px solid rgba(224, 224, 224, 1);
        //borderWidth: '1px !important',
        //borderSpacing: '2px !important',
        minWidth: 400,
    },
    cell: {
        padding: '4px !important',
        //margin: '10px',
        fontSize: 'smaller',
        border: '1px solid gray !important',
    },
    noteCell: {
        fontSize: 'smaller',
        width: '65%',
        border: '1px solid gray !important',
    },
    row: {
        //border: '1px solid #73AD21',
        padding: '10px',
    },
    idColumn: {
        width: '15px',
    }

})

/**
 * props
 *    id
 *    number
 *    name
 *    type
 *    overview
 *    isCompleted
 */
class ScenarioDetails extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        const { classes } = this.props;
        return (
            <div id="ScenarioDetails">
                <TableContainer style={{ width: '99.5%' }}>

                    <div style={{ paddingTop: '10px', paddingBottom: '10px' }} >
                        <Typography variant='h6'>Scenarios</Typography>
                    </div>
                    <Table className={classes.table} size="small" aria-label="Scenario data rows">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="right">Scenario</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="left">Description</TableCell>

                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white', width: '35%' }} align="left">Notes</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.details !== undefined && this.props.details.map((scenario) => (
                                <TableRow key={scenario.scenaioId}>
                                    <TableCell className={classes.cell} align="right">
                                        {scenario.scenarioNames[0].split('scenario')[1]}
                                    </TableCell>
                                    <TableCell className={classes.cell} align="left">
                                        {scenario.scenarioDescriptions}
                                    </TableCell>

                                    <TableCell className={classes.noteCell} align="right" >

                                        <Notes
                                            noHeader="true"
                                            rows="6"
                                            items={['Start Date', 'Start Time', 'HVAC Settings', 'Other Environmental Factors']}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div >
        );
    }

}

export default withStyles(styles)(ScenarioDetails);