import React from 'react';
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { VERIFICATION_LIKE_TESTS } from '../../config';

const customSamplesDurationConfig = {
    ver: {
        0: [0, 5],
        1: [45, 50],
        2: [50, 55],
        3: [55, 60]
    }
}; 

const styles = (theme) => ({
    columnHeader: {


        minHeight: '200px',
        //maxHeight: '20px',
        //border: '3px solid #73AD21',
        background: 'rgb(0, 137, 150)',//'lightgray',
        padding: '10px',
        //align: 'left',
        //transform: 'rotate(270deg)',
    },

    columnHeaderText: {
        //transform: 'rotate(270deg)', /* Equal to rotateZ(45deg) */
        //textAlign: 'left',
        //margin: '10px',
        //minWidth: '200px',
        //position: 'absolute',
        //right: '0px',
    },
    table: {
        border: '1px solid gray !important',//1px solid rgba(224, 224, 224, 1);
        //borderWidth: '1px !important',
        //borderSpacing: '2px !important',
        minWidth: 400,
    },
    cell: {
        padding: '4px !important',
        //margin: '10px',
        fontSize: 'medium !important',
        border: '1px solid gray !important',
    },
    row: {
        //border: '1px solid #73AD21',
        padding: '10px',
    },
    idColumn: {
        width: '15px',
    }

})


class SampleSummary extends React.Component {
    constructor(props) {
        super(props);

        this.calculateIntervalPeriods = this.calculateIntervalPeriods.bind(this);
        this.composeSampleTypeCollectionRows = this.composeSampleTypeCollectionRows.bind(this);
    }

    calculateIntervalPeriods() {

        let intervalPeriods = [];
        let start = 0;
        if (!!~VERIFICATION_LIKE_TESTS.indexOf(this.props.test.type)) {
            const intervalsConfig = customSamplesDurationConfig['ver'];
            for (let interval in Object.values(intervalsConfig)) {
                const intervalTimes = intervalsConfig[interval];
                intervalPeriods.push(
                    <div key={interval}>
                        <p> {intervalTimes[0]} - {intervalTimes[1]} min</p><br />
                    </div>        
                )
            }
            return intervalPeriods;
        }
        for (let i = 0; i < this.props.test.intervalCount; i++) {
            let end = start + this.props.test.intervalDuration;
            intervalPeriods.push(
                <div key={i}>
                    <p> {start} - {end} min</p><br />
                </div>
            );

            start += this.props.test.intervalDuration;
        }
        return intervalPeriods;

    }

    composeSampleTypeCollectionRows() {
        const { classes } = this.props;
        let sampleTypes = [];
        this.props.test.collectionMethods.map((sampleType) => {

            if (sampleType === 'filter') {
                sampleTypes.push(
                    <TableRow>
                        <TableCell className={classes.cell} align="left">filter</TableCell>
                        <TableCell className={classes.cell} align="left">{this.props.test.airSamplerRate} liters/minute</TableCell>
                        <TableCell className={classes.cell} align="left"></TableCell>
                    </TableRow>
                )
            } else if (sampleType === 'swab') {
                sampleTypes.push(
                    <TableRow>
                        <TableCell className={classes.cell} align="left">swab</TableCell>
                        <TableCell className={classes.cell} align="left">SOP-80097</TableCell>
                        <TableCell className={classes.cell} align="left"></TableCell>
                    </TableRow>
                )
            } else {
                sampleTypes.push(
                    <TableRow>
                        <TableCell className={classes.cell} align="left">{sampleType}</TableCell>
                        <TableCell className={classes.cell} align="left">n/a</TableCell>
                        <TableCell className={classes.cell} align="left"></TableCell>
                    </TableRow>
                )
            }


        });
        return sampleTypes;
    }

    render() {
        const { classes } = this.props;
        const intervalPeriods = this.calculateIntervalPeriods();
        const sampleTypeCollectionRows = this.composeSampleTypeCollectionRows();

        //console.log(intervalPeriods);

        return (
            <div className="SampleSummary" >
                <p style={{ paddingTop: '10px', paddingBottom: '10px' }} >
                    <Typography variant='h6'>Sample Collection Methods</Typography>
                </p>
                <TableContainer style={{ width: '99.5%' }}>
                    <Table className={classes.table} size="small" aria-label="Collection Methods">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="right">Sample Type</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="right">Measurement</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white', width: '35%' }} align="right">Notes</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {sampleTypeCollectionRows}
                        </TableBody>
                    </Table>
                    <p style={{ paddingTop: '10px', paddingBottom: '10px' }} >
                        <Typography variant='h6'>Sample Summary</Typography>
                    </p>

                    <Table className={classes.table} size="medium" aria-label="Sample Summary">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="right">Scenario Count</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="right">Interval Count</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="right">Sample Point Count</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="right">Sample Type Count</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="right">Sample Count</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="right">Interval Duration (minutes)</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="left">Interval Times</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white', width: '35%' }} align="left">Notes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.cell}>{this.props.scenarioCount}</TableCell>
                                <TableCell className={classes.cell}>{this.props.test.intervalCount}</TableCell>
                                <TableCell className={classes.cell}>{this.props.test.spCount}</TableCell>
                                <TableCell className={classes.cell}>{this.props.test.collectionMethods.length}</TableCell>
                                <TableCell className={classes.cell}>{this.props.sampleCount}</TableCell>
                                <TableCell className={classes.cell}>{this.props.test.intervalDuration || (!!~VERIFICATION_LIKE_TESTS.indexOf(this.props.test.type) && '5')}</TableCell>
                                <TableCell className={classes.cell}>{intervalPeriods}</TableCell>
                                <TableCell className={classes.cell}></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

        );
    }

}

export default withStyles(styles)(SampleSummary);