import React, { useContext, useState } from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import {useHistory} from "react-router-dom";
import {AlertTestCompleted} from "../../components/Alert/AlertTestCompleted";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import './ProjectDetails.css';
import floorPlans from "../../api/floorPlans";
import projectsApi from '../../api/projects';
import axios from 'axios';
import {FullPageLoader} from "../../components/FullPageLoader/FullPageLoader";
import Dropzone from "react-dropzone";
import Dialog from "../../components/FloorPlanCropperDialog/FloorPlanCropperDialog";
import { Context } from '../../context/Context';
import TextField from "@material-ui/core/TextField";

const useRowStyles = makeStyles((theme) => ({
    paper:     {
        backgroundColor: theme.palette.background.paper,
        boxShadow:       theme.shadows[5],
        padding:         theme.spacing(7, 5, 7),
        textAlign:       'center',
        fontFamily:      "Gotham"
    },
    bodyCell:  {
        textAlign: 'left',
        padding:   '10px !important'
    },
    bodyHover: {
        backgroundColor: 'transparent',
        "&:hover, &:focus": {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        },
    },
    modal:       {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center'
    },
}));

export const ProjectDetailsFloorPlan = ({ projectId, floorPlansData, projectCompleted, fetchUpdatedProject }) => {
    const history = useHistory();
    const classes = useRowStyles();
    const newAxiosInstance = axios.create();
    const [ openDeleteFloorPlanModal, setOpenDeleteFloorPlanModal ] = useState({});
    const [ openEditFloorPlanModal, setOpenEditFloorPlanModal ] = useState(false);
    const [ floorPlanId, setFloorPlanId ] = useState('');
    const [ floorPlanAddress, setFloorPlanAddress ] = useState('');
    const [ floorPlanFloorNumber, setFloorPlanFloorNumber ] = useState('');
    const [ activeFloorPlanId, setActiveFloorPlanId ] = useState('');
    const [ activeFloorPlanFileName, setActiveFloorPlanFileName ] = useState('');
    const [ activeFloorPlanUrl, setActiveFloorPlanUrl  ] = useState('');
    const [ showNewFloorPlanDialog, setShowNewFloorPlanDialog] = useState(false);
    const [ selectedImageFile, setSelectedImageFile ] = useState(null);
    const [ loadingPercentCompleted, setLoadingPercentCompleted] = useState(0);
    const [ isLoading, setIsLoading] = useState(false);
    const [ openFloorPlanCompletedAlert, setOpenFloorPlanCompletedAlert ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState('');
    const { state } = useContext(Context);

    /**
     * Open modal for deleting floor plan
     * @param floorPlan
     */
    const handleModalOpen = (floorPlan) => {
        setOpenDeleteFloorPlanModal(floorPlan);
    }

    /**
     * Close floor plan deleting modal
     */
    const handleModalClose = () => {
        setOpenDeleteFloorPlanModal({});
    }

    /**
     * Open modal for editing floor plan details
     * @param floorPlan
     */
    const handleModalEditOpen = (floorPlan) => {
        setFloorPlanId(floorPlan.floorplanid ?? '');
        setFloorPlanAddress(floorPlan.address ?? '');
        setFloorPlanFloorNumber(floorPlan.floornumber ?? '');
        setOpenEditFloorPlanModal(true);
    }

    /**
     * Close modal with details about floor
     */
    const handleModalEditClose = () => {
        setFloorPlanId('');
        setFloorPlanFloorNumber('');
        setFloorPlanAddress('');
        setOpenEditFloorPlanModal(false);
    }

    /**
     * Update details about floor plan (address, floor number)
     */
    const handleSubmit     = () => {
        new Promise((resolve, reject) => {
            const result = floorPlans.updateFloorPlan(floorPlanId, {
                address:     floorPlanAddress,
                floornumber: floorPlanFloorNumber
            });
            resolve(result);
        }).then(() => {
            fetchUpdatedProject();
            handleModalEditClose();
        });
    }

    /**
     * Change temporary floor details if one of fields in floor modal editor was changed
     * @param field
     * @param value
     */
    const handleChange = (field, value) => {
        switch (field) {
            case "address":
                setFloorPlanAddress(value.target.value ?? '');
                break;
            case "floorNumber":
                setFloorPlanFloorNumber(value.target.value ?? '');
                break;
            default:
                break;
        }
    }

    /**
     * Removing of a floor plan
     * @param id
     * @returns {Promise<void>}
     */
    const handleDelete = async (id) => {
        if (id) {
            const response = await floorPlans.deleteFloorPlan(id);
            if (response.data.success) {
                await fetchUpdatedProject();
                handleModalClose();
            } else {
                if (response.data.error.message) {
                    setErrorMsg(response.data.error.message);
                    setOpenFloorPlanCompletedAlert(!openFloorPlanCompletedAlert);
                }
            }
        }
    }

    /**
     * Hiding alertbox after click to a close button
     */
    const handleClosingAlert = () => setOpenFloorPlanCompletedAlert(!openFloorPlanCompletedAlert);

    /**
     * Display thumbnail of a floor plan after selecting line
     * @param floorPlan
     * @returns {Promise<void>}
     */
    const handleCellClick = async (floorPlan) => {
        if (activeFloorPlanId === floorPlan.floorplanid) {
            clearActiveFloorPlan();
            return;
        }
        setActiveFloorPlanId(floorPlan.floorplanid);

        let projectId = localStorage.getItem("projectId") ?? '';
        let testId =localStorage.getItem("testId") ?? '';
        // setActiveFloorPlanFileName(`Test ${testId} PID ${projectId} Floor Plan Clean`);
        setActiveFloorPlanFileName(floorPlan.filename);

        await downloadFile(floorPlan.floorplanid, floorPlan.filename);
    }

    /**
     * Hiding floor plan thumbnail
     */
    const clearActiveFloorPlan = () => {
        setActiveFloorPlanFileName('');
        setActiveFloorPlanId('');
        setActiveFloorPlanUrl('');
    }

    /**
     * Operations after drag&drop (or selecting) an image into the dropzone
     * @param files
     * @returns {Promise<void>}
     */
    const uploadFile = async (files) => {
        if (!files || !files.length) {
            return;
        }

        const file = files[0];
        setSelectedImageFile(file)
        setShowNewFloorPlanDialog(true);
    }

    /**
     * Downloading image with a floor plan from the S3 bucket
     * @param floorplanid
     * @param filename
     * @returns {Promise<void>}
     */
    const downloadFile = async (floorplanid, filename) => {
        setIsLoading(true);

        // Step 1: get pre-signed url by sending axios request to server
        const result = await floorPlans.getPresignedURL({
            projectId: projectId,
            filename: filename,
            action: 'getObject'
        });

        // Step 2: Download file from AWS s3 bucket as a blob
        // Making straight axios call in order to access the ProgressEvent interface which allows up to caputre download progress and pass that data along to spinner component
        const response = await newAxiosInstance.get(result.data.preSignedURL, {
            responseType: 'blob',
            // Progress bar to show the progress of download while user is waiting
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setLoadingPercentCompleted(percentCompleted);
                setIsLoading(percentCompleted === 100);
            }
        });

        setIsLoading(false);

        //  Step 3: Download file in required format - create a blob url and render in img tag
        let blob = new Blob([response.data], { type: response.data.type });
        let createObjectURL = window.URL.createObjectURL || window.webkitURL.createObjectURL;
        const url = createObjectURL(blob);
        setActiveFloorPlanUrl(url);
    }

    if (!floorPlansData) {
        return null;
    }

    const updateProjectStatus = async (i) => {
        try {
            const result = await projectsApi.updateStatus(projectId, 'in_progress');
            if (!result?.data?.success) throw new Error();

        } catch (err) {
            if (i < 2) {
                return updateProjectStatus(i + 1);
            } else {
                console.log('Project status update error: ', err);
                return null;
            }
        }
    }
    return (
        <div>
            {selectedImageFile && showNewFloorPlanDialog === true && (
                <Dialog
                    imageFile={selectedImageFile}
                    floorPlanFiles={floorPlansData}
                    onCancel={() => {
                        setShowNewFloorPlanDialog(false);
                        setSelectedImageFile(null);
                    }}
                    onUploadCompleted={async () => {
                        if (Array.isArray(floorPlansData) && !floorPlansData.length) {
                            await updateProjectStatus(0);
                        }
                        setShowNewFloorPlanDialog(false);
                        setSelectedImageFile(null);
                        fetchUpdatedProject();
                    }}
                />
            )}
            <div className="grid-wrapper">
                <div>
                    <Typography variant="h5" color="textPrimary">
                        Floor Plans
                        {state.accessLevel !== 'Viewer' && (
                            <Dropzone onDrop={(acceptedFiles) => uploadFile(acceptedFiles)}>
                                {({ getRootProps, getInputProps }) => (
                                    <section style={{
                                        border: '1px solid #82BC00',
                                        textAlign: 'center',
                                        height: '35px',
                                        width: '360px',
                                        fontWeight: 'bold',
                                        lineHeight: '35px',
                                        fontSize: '16px',
                                        fontFamily: 'Gotham',
                                        float: 'right',
                                    }}>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p>Drag and drop some file here, or click to select a file</p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        )}
                    </Typography>
                </div>
                <div>
                    {activeFloorPlanUrl && state.accessLevel !== 'Viewer' &&
                        <Typography variant="h5" color="textPrimary">
                            <a
                                style={{ textDecoration: 'none' }}
                                href={activeFloorPlanUrl}
                                download={activeFloorPlanFileName}
                            >
                                <Button
                                    size="small"
                                    variant="outlined"
                                    className="add-edit-button"
                                >
                                    Download file
                                </Button>
                            </a>
                        </Typography>
                    }
                </div>
            </div>
            <div className="grid-wrapper">
                <div>
                    <TableContainer style={{ marginTop: '15px', boxShadow: 'none' }} component={Paper}>
                        <Table aria-label="project-list-table">
                            {floorPlansData.map((floorPlan, i) => (
                                <TableBody key={`key-${i}`} className={classes.bodyHover}>
                                    <TableRow className={`${floorPlan.floorplanid === activeFloorPlanId ? "active-row" : ""}`}>
                                        {state.accessLevel !== 'Viewer' && (
                                            <TableCell className={classes.bodyCell} rowSpan={2}>
                                                <IconButton
                                                    title="Delete floor plan"
                                                    aria-label="delete"
                                                    className="delete-button"
                                                    onClick={() => handleModalOpen(floorPlan)}>
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                                <IconButton
                                                    disabled={projectCompleted}
                                                    title="Update floor plan details"
                                                    aria-label="edit"
                                                    className="delete-button"
                                                    onClick={() => handleModalEditOpen(floorPlan)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        )}
                                        <TableCell
                                            className={classes.bodyCell + " table-cell-pointer"}
                                            onClick={() => handleCellClick(floorPlan)}
                                        >
                                            {floorPlan.filename ?? "\u00A0"}
                                        </TableCell>
                                        <TableCell
                                            className={classes.bodyCell + " table-cell-pointer"}
                                            onClick={() => handleCellClick(floorPlan)}
                                        >
                                            {floorPlan.url ?? "\u00A0"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={`${floorPlan.floorplanid === activeFloorPlanId ? "active-row" : ""}`}>
                                        <TableCell
                                            className={classes.bodyCell + " table-cell-pointer"}
                                            onClick={() => handleCellClick(floorPlan)}
                                        >
                                            {floorPlan.address ?? "\u00A0"}
                                        </TableCell>
                                        <TableCell
                                            className={classes.bodyCell + " table-cell-pointer"}
                                            onClick={() => handleCellClick(floorPlan)}
                                        >
                                            {floorPlan.floornumber ?? "\u00A0"}
                                        </TableCell>
                                    </TableRow>
                                    <Modal
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        className={classes.modal}
                                        open={openDeleteFloorPlanModal === floorPlan}
                                        onClose={() => handleModalClose()}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                            timeout: 500,
                                        }}>
                                        <div>
                                            <Fade in={openDeleteFloorPlanModal === floorPlan}>
                                                <div className={classes.paper}>
                                                    <h2 id="transition-modal-title">Are you sure you want to delete this floor plan?</h2>
                                                    <div>
                                                        <Button
                                                            onClick={() => handleDelete(floorPlan.floorplanid)}
                                                            size="small"
                                                            className="modal-button">
                                                            Yes
                                                        </Button>
                                                        <Button
                                                            onClick={() => handleModalClose()}
                                                            size="small"
                                                            className="modal-button">
                                                            No
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Fade>
                                        </div>
                                    </Modal>
                                </TableBody>
                            ))}
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    {activeFloorPlanUrl && <img src={activeFloorPlanUrl} width="100%" style={{ overflow: 'scroll' }} />}
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openEditFloorPlanModal}
                    onClose={() => handleModalClose()}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                    <div>
                        <Fade in={openEditFloorPlanModal}>
                            <div className={classes.paper}>
                                <div className="grid-wrapper">
                                    <div>
                                        <h2 id="transition-modal-title">
                                            Update floor plan details
                                        </h2>
                                    </div>
                                </div>
                                <div className="grid-wrapper-info">
                                    <div>
                                        <TextField
                                            fullWidth={true}
                                            label="Address"
                                            name="address"
                                            autoComplete="address"
                                            style={{fontFamily: 'Gotham'}}
                                            onChange={(newValue) => handleChange("address", newValue)}
                                            value={floorPlanAddress}
                                            autoFocus
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            fullWidth={true}
                                            label="Floor number"
                                            name="floorNumber"
                                            autoComplete="floorNumber"
                                            style={{fontFamily: 'Gotham'}}
                                            onChange={(newValue) => handleChange("floorNumber", newValue)}
                                            value={floorPlanFloorNumber}
                                        />
                                    </div>
                                </div>
                                <div className="grid-wrapper">
                                    <div>
                                        <Button
                                            onClick={e => handleSubmit(e)}
                                            size="small"
                                            className="modal-button"
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            onClick={() => handleModalEditClose()}
                                            size="small"
                                            className="modal-button"
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </Modal>
            </div>
            {!!isLoading && (<FullPageLoader progress={loadingPercentCompleted} />)}
            {!!openFloorPlanCompletedAlert && (<AlertTestCompleted flag={openFloorPlanCompletedAlert} msg={errorMsg} closeFunc={handleClosingAlert} />)}
        </div>
    );
};