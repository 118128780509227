import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Alert } from '../../Alert/Alert';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { useForm } from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Tooltip from '@material-ui/core/Tooltip';

import './styles.css';

const BuildingInfoForm = ({ 
	viewOnly, 
	vertical, 
	onSubmit, 
	initialValues,
	validationRules = {},
}) => {
	const { register, handleSubmit, getValues, errors } = useForm({
		defaultValues: initialValues,
		reValidateMode: 'onSubmit',
	});

	const [confirmMessage, setConfirmMessage] = useState('');
	const [shouldConfirm, setShouldConfirm] = useState(false);

	const [rejectMessage, setRejectMessage] = useState('');
	const [rejectOpen, setRejectOpen] = useState(false);

	const addingStyle = vertical ? 'vertical' : 'horizontal';
	const wrapperClass = `building-form ${addingStyle}`;

	const errorsMessages = {
		sq_ft: {
			pattern: 'Sq.ft must be a number',
			required: 'Sq.ft required'
		},
		celling_height: {
			pattern: 'Ceiling height must be a number',
			required: 'Ceiling height is required'
		}
	};

	const getHelperText = (field) => {
		if (errors[field]) {
			const { type } = errors[field];
			return errorsMessages[field][type];
		}
		return '';
	};

	const submitData = async (values) => {
		const { confirmative, restrict } = validationRules;
		let canSubmit = !restrict && !confirmative;

		if (restrict) {
			const { range_validator, on_validation_fail } = restrict;
			if (range_validator) {
				let rejectText = '';
				for (let valueKey of Object.keys(values)) {
					const isOk = range_validator[valueKey](values[valueKey]);
					if (!isOk) {
						rejectText = on_validation_fail[valueKey].textReject;
						break;
					}
				}
				if (rejectText) {
					setRejectMessage(rejectText);
					setRejectOpen(true);
					return;
				}
			}
		}

		if (confirmative) {
			const { range_validator, on_validation_fail } = confirmative;
			if (range_validator) {
				const validationFail = [];
				for (let valueKey of Object.keys(values)) {
					const isOk = range_validator[valueKey](values[valueKey]);
					if (!isOk) {
						validationFail.push(valueKey);
					}
				}
				if (validationFail.length) {
					let confirmText;
					if (validationFail.length > 1) {
						confirmText = on_validation_fail.all.textConfirm(values);
					} else {
						const valueKey = validationFail[0];
						confirmText = on_validation_fail[valueKey].textConfirm(values[valueKey]);
					}
					setConfirmMessage(confirmText);
					setShouldConfirm(true);
				} else {
					canSubmit = true;
				}
			} else {
				canSubmit = true;
			}
		}

		if (canSubmit && typeof onSubmit === 'function') {
			await onSubmit(values);
		}
	};

	const afterConfirm = async () => {
		closeConfirmDialog();
		if (typeof onSubmit === 'function') {
			const values = getValues();
			await onSubmit(values);
		}
	};

	const closeConfirmDialog = () => {
		setShouldConfirm(false);
		setTimeout(() => setConfirmMessage(''), 200);
	};

	const closeRejectAlert = () => {
		setRejectOpen(false);
		setTimeout(() => setRejectMessage(''), 200);
	};

	return (
		<>
			<Alert flag={rejectOpen} msg={rejectMessage} closeFunc={closeRejectAlert} updateSuccessful={false}/>
			<Dialog
				open={shouldConfirm}
				onClose={closeConfirmDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{confirmMessage}
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button onClick={closeConfirmDialog} color="primary">
					No
				</Button>
				<Button onClick={afterConfirm} color="primary" autoFocus>
					Yes, I'm sure
				</Button>
				</DialogActions>
			</Dialog>
			<form 
				onSubmit={handleSubmit(submitData)} 
				noValidate
				className={wrapperClass}
			>
				<TextField
					inputRef={register({ required: true, pattern: /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/i })}
					id="sq_ft"
					label="Sq.ft"
					name="sq_ft"
					error={errors.sq_ft}
					helperText={errors.sq_ft && getHelperText('sq_ft')}
					disabled={viewOnly}
				/>
				<TextField
					inputRef={register({ required: true, pattern: /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/i })}
					id="celling_height"
					label="Ceiling height"
					name="celling_height"
					error={errors.celling_height}
					helperText={errors.celling_height && getHelperText('celling_height')}
					disabled={viewOnly}
				/>
				{!viewOnly && 
					<Tooltip title="Submit building info" placement="top" aria-label="building-info-btn">
						<IconButton type="submit">
							<CloudUploadIcon />
						</IconButton>
					</Tooltip> }
			</form>
		</>
	);
};

export default BuildingInfoForm;