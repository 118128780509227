import originpointAPI from '../../api/originpoints.js'
import samplepointAPI from '../../api/samplepoints.js'


const dataUtils = {
  syncOriginPointToServer: async (originPoint, successHandler, errorHandler) => {
    //console.log("syncOriginPointToServer");
    //this is what is in the spec
    const data = {
      "OPId": originPoint.opid,
      "OPName": originPoint.opname,
      "OPCoordX": originPoint.opcoordx,
      "OPCoordY": originPoint.opcoordy,
    };

    const response = await originpointAPI.setOriginPoint(data);
    //console.log(response);

    if (response !== null) {

      if (response.status === 200 && response.data !== null && response.data.success === true) {
        successHandler(originPoint);
      } else {
        errorHandler(originPoint, response);
      }
    }
  },

  syncSamplePointToServer: async (samplePoint, successHandler, errorHandler) => {
    //console.log("syncSamplePointToServer");
    //this is what is in the spec
    const data = {
      "SPId": samplePoint.spid,
      "SPName": samplePoint.spname,
      "SPCoordX": samplePoint.spcoordx,
      "SPCoordY": samplePoint.spcoordy,
    };
    //console.log(data);
    const response = await samplepointAPI.setSamplePoint(data);
    //console.log(response);

    if (response !== null) {

      if (response.status === 200 && response.data !== null && response.data.success === true) {
        successHandler(samplePoint);
      } else {
        errorHandler(samplePoint, response);
      }
    }
  },

  isOriginPointDirty: (current, name, x, y) => {
    //console.log("isOriginPointDirty op: " + current.opnumber + ", [" + name + ", " + current.original.opname + "], [" + x + ", "+ current.original.opcoordx+ "], ["+ y + ", " + current.original.opcoordy + "]");

    if (name !== undefined) {
      if (current.original.opname !== name) {
        //console.log("--- Names are different");
        return true;
      }
    } else {
      if (current.opname !== current.original.opname) {
        //console.log("--- Names are different");
        return true;
      }
    }

    if (x !== undefined) {
      if (current.original.opcoordx === undefined) {
        //console.log("--- original opcoordx is undefined");
        return true;
      }
      if (current.original.opcoordx !== x) {
        //console.log("--- X is different");
        return true;
      }
    } else {
      if (current.opcoordx !== current.original.opcoordx) {
        //console.log("--- X is different");
        return true;
      }
    }

    if (y !== undefined) {
      if (current.original.opcoordy === undefined) {
        //console.log("--- original opcoordy is undefined");
        return true;
      }
      if (current.original.opcoordy !== y) {
        //console.log("--- Y is different");
        return true;
      }
    } else {
      if (current.opcoordy !== current.original.opcoordy) {
        //console.log("--- Y is different");
        return true;
      }
    }

    return false;
  },
  //todo: use the same pattern that is used for origin points
  isSamplePointDirty: (current, name, x, y) => {
    //console.log("isSamplePointDirty sp: " + current.spnumber + ", [" + name + ", " + current.original.spname + "], [" + x + ", "+ current.original.spcoordx+ "], ["+ y + ", " + current.original.spcoordy + "]");
    //console.log(current);

    if (name !== undefined) {
      if (current.original.spname !== name) {
        //console.log("--- Names are different");
        return true;
      }
    } else {
      if (current.spname !== current.original.spname) {
        //console.log("--- Names are different");
        return true;
      }
    }

    if (x !== undefined) {
      if (current.original.spcoordx === undefined) {
        //console.log("--- original spcoordx is undefined");
        return true;
      }
      if (current.original.spcoordx !== x) {
        //console.log("--- X is different");
        return true;
      }
    } else {
      if (current.spcoordx !== current.original.spcoordx) {
        //console.log("--- X is different");
        return true;
      }
    }

    if (y !== undefined) {
      if (current.original.spcoordy === undefined) {
        //console.log("--- original spcoordy is undefined");
        return true;
      }
      if (current.original.spcoordy !== y) {
        //console.log("--- Y is different");
        return true;
      }
    } else {
      if (current.spcoordy !== current.original.spcoordy) {
        //console.log("--- Y is different");
        return true;
      }
    }
    return false;
  }
}

export default dataUtils;