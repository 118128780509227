import React from 'react';
import { Context } from '../../context/Context';

import tests from '../../api/tests';

import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import { DatePicker } from "@material-ui/pickers";
import MuiAlert from '@material-ui/lab/Alert';

import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import OpTableRow from "./components/OpTableRow";
import BuildingInfoForm from './components/BuildingInfoForm';
import ScenariosRow from './components/ScenariosRow';

import { Dropdown } from "../Dropdown/Dropdown";

import { format, parseISO } from "date-fns";
import { tableConfigConstructor, areaSectionConfig } from './config';

import './VolumeBasedTest.css';

const styles = () => ({
    tableHeaderStyle: {
        backgroundColor: '#F3F3F3'
    },
    headerCellStyle: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'center',
        padding: '10px !important',
		borderBottom: 'none'
    },
    cellStyle: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        textAlign: 'center',
        borderRight: 'none',
        borderLeft: 'none',
    },
	headerCellStyle_10: {
		width: 'calc(10% * 1)',
	},
	headerCellStyle_45: {
		width: 'calc(10% * 4.5)',
	},
	scrollOverflow: {
		overflow: 'scroll !important',
		width: '100%'
	},
});

class VolumeBasedTest extends React.Component {
    constructor(props) {
        super(props);

		const testId = JSON.parse(localStorage.getItem('testId')) || null;
		const accessLevel = JSON.parse(localStorage.getItem('accessLevel')) || null;
		const roleName = JSON.parse(localStorage.getItem('roleName')) || null;

		const isViewer = accessLevel === 'Viewer';
		const isSafetracer = roleName === 'Safetracer';

        this.state = {
            testId,
            accessLevel,
			isSafetracer,
            isViewer,
			tagsList: [1, 2, 3, 4, 5, 6, 7, 8].map(num => `Tag-C${num}`),
			tagsListAvailable: [],
			buildingInfo: {},
			scenarios: [],
			ops: [],
			displayTestDetails: false,
			testCompleted: null,
        };

		const testType = this.props.testType;
		this.areaSectionConfig = areaSectionConfig[testType] || {};
    };

    async componentDidMount() {
		this.mounted = true;

		const { testId } = this.state;
		if (testId) {
			const response = await tests.getOneTest(this.state.testId);
			if (response.data.success) {
				const { testexecuted, buildingdata } = response.data.data;
				let displayTestDetails = false;
				if (buildingdata && Object.keys(buildingdata) && buildingdata.sq_ft && buildingdata.celling_height) {
					displayTestDetails = true;
				}
				if (this.mounted) {
					this.setState({ 
						testCompleted: testexecuted,
						buildingInfo: buildingdata,
						displayTestDetails
					});
				}
			}
		}
    }

	componentWillUnmount() {
		this.mounted = false;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.ops !== this.props.ops) {
			this.updateListAvailableTags();
		}
	}

	getTableConfig = () => {
		const { isSafetracer, isViewer } = this.state;
		const { onChangeFunc, floorPlans, scenarios, testType } = this.props;

		const mainVerTableConfig = tableConfigConstructor(testType, isSafetracer, isViewer, onChangeFunc, floorPlans, scenarios);

		return mainVerTableConfig;
	};

	renderHeaderCells = (type = 'mainSettings') => {
		const { headerCellStyle } = this.props.classes;
		const namesOptions = {
			mainSettings: Object.values(this.getTableConfig()).map((column => column.headerName)),
			OPs: ['OP Name', 'Tag*', 'Tag Lot Number', 'Expiration Date'],
			scenarios: ['', 'Scenario Name', 'Description'],
		};

		if (!Array.isArray(namesOptions[type])) return;

		return namesOptions[type].map((name, i) => {
			const props = {
				className: headerCellStyle,
			};

			if (type === 'scenarios') {
				props.className += ` ${this.props.classes[`headerCellStyle_${i ? '45' : '10'}`]}`
			}
			return <TableCell key={i} {...props}>{name}</TableCell>
		})
	};
	
	cellWithDropdown = (coltype, cell_key, dropdownExtraProps = { 
		onChangeFunc: null, disableSelect: null, fpOptions: null 
	}, cellStyle) => {
		const { segment } = this.props;
		const { testCompleted, isViewer } = this.state;

		const cellProps = {
			testCompleted,
			isViewer,
			coltype,
			segment,
		};
		
		const { 
			onChangeFunc,
			disableSelect,
			fpOptions,
			scenarios
		} = dropdownExtraProps;

		if (onChangeFunc) {
			cellProps.onChangeFunc = onChangeFunc;
		}
		if (disableSelect) {
			cellProps.disableSelect = disableSelect;
		}
		if (fpOptions) {
			cellProps.fpOptions = fpOptions;
		}
		if (scenarios) {
			cellProps.scenarios = scenarios;
		}

		return (
			<TableCell key={cell_key} className={cellStyle}>
				<Dropdown
					{...cellProps} />
			</TableCell>
		)
	};

	handleDateChange = (e) => {
		this.props.onSurveyDateUpdateFunc(format(e, 'yyyy-MM-dd'));
	};

	renderDataCells = () => {
		const { segment, classes } = this.props;
		const { cellStyle } = classes;

		if (!segment || !Object.keys(segment)) return; 

		const tableConfig = this.getTableConfig();
		const result = [];
		for (let column in tableConfig) {
			const {
				isDropdown,
				dropdownProps,
				dropdownExtraProps,
				constant_view,
				data_key,
				data
			} = tableConfig[column];

			const cell_key = result.length;

			if (isDropdown) {
				result.push(this.cellWithDropdown(dropdownProps.coltype, cell_key, dropdownExtraProps, cellStyle));
			} else if (constant_view) {
				const text = data_key ? segment[data_key] : data;
				result.push(this.cellConstantView(cell_key, text, cellStyle));
			}
		}

		return result;
	};

	cellConstantView = (cell_key, text, cellStyle) => {
		return (
			<TableCell key={cell_key} className={cellStyle}>
				{text || ''}
			</TableCell>
		)
	};

	handleBuildingInfoUpdate = async (values) => {
		const { displayTestDetails } = this.state;

		const updateSuccess = await this.props.updateTestBuildingData(values);
		if (!displayTestDetails && updateSuccess) {
			this.setState({
				displayTestDetails: true
			})
		}
	};
	
	scenariosOPsTable = (scenario) => {
        const {
            segment,
            onChangeFunc,
            ops,
		} = this.props;

		if (!Array.isArray(ops) || !ops.length) return;

		const { testCompleted, isViewer, tagsListAvailable } = this.state;

		const viewOP = Object.assign({}, ops[0]);

		const { tagLotNumber, tags } = viewOP;
		const { scenarioid } = scenario;

		const _tags = [];
		const _taglotnumber = [];

		for (let tag of tags) {
			if (tag && tag.scenarioId && +tag.scenarioId === +scenarioid) {
				_tags.push(tag);
			}
		}

		if (_tags.length && Array.isArray(tagLotNumber)) {
			const tagName = _tags[0].tag;
			for (let tagInfo of tagLotNumber) {
				const { tag } = tagInfo;
				if (tag === tagName) {
					_taglotnumber.push(tagInfo);
				}
			}
		}

		viewOP.tags = _tags;
		viewOP.tagLotNumber = _taglotnumber;

		const opsHeader = this.renderHeaderCells('OPs');

		return (
			<>
				<Table size="small">
					<TableHead style={{ borderBottom: '1px solid rgb(0, 0, 0, 0.15)', borderTop: '1px solid rgb(0, 0, 0, 0.15)'}}>
						<TableRow>
							{opsHeader}
						</TableRow>
					</TableHead>
					<TableBody style={{ margin: '10px 0px !important' }}>
						<OpTableRow
							type='all'
							tagsList={tagsListAvailable}
							OPs={ops}
							viewOP={viewOP}
							scenario={scenario}
							disabledActions={isViewer || testCompleted}
							onTagChange={(e, opArr = ops) => {
								const oldTagName = viewOP.tags[0] && viewOP.tags[0].tag || '';
								return onChangeFunc(e, null, 'tag', [segment], opArr, scenario)
								.then((success) => {
									if (success) {
										const newTagName = e.target.value;
										this.updateListAvailableTags(oldTagName, newTagName);
									}
								}).catch(err => console.log('err tag update', err));
							}}
						/>
					</TableBody>
				</Table>
			</>
		)
	};

	updateListAvailableTags = (tagNameToAdd = '', tagNameToRemove = '') => {
		const { tagsListAvailable, tagsList } = this.state;
		let _tagsAvailable = [];

		if (!tagNameToAdd && !tagNameToRemove) {
			const { ops } = this.props;
			const opsTags = [];
			if (Array.isArray(ops) && ops[0] && ops[0].tags) {
				for (let tag of ops[0].tags) {
					opsTags.push(tag.tag);
				}
			}
			for (let tagName of tagsList) {
				if (!~opsTags.indexOf(tagName)) {
					_tagsAvailable.push(tagName);
				}
			}
		} else {
			_tagsAvailable = tagsList.reduce((res, tagName) => {
				if (tagName === tagNameToRemove) return res;
				if (tagNameToAdd === tagName || ~tagsListAvailable.indexOf(tagName)) {
					res.push(tagName);
				}

				return res;
			}, []);
		}

		this.setState({
			tagsListAvailable: _tagsAvailable
		});
	}

    render() {
        const {
            segment,
            scenarios,
            classes,
            onTextChangeFunc,
            onKeyDownTextUpdate,
            ops,
            surveyDate,
            comments,
			handleScenarioDescriptionUpdate,
		} = this.props;

		const { testCompleted, isViewer, buildingInfo, displayTestDetails } = this.state;
		const { tableHeaderStyle, cellStyle } = classes;
		const commentsInputProps = {
			style: {
				textAlign: 'center',
				fontFamily: 'Gotham',
				fontSize: '13px',
				marginTop: '10px',
				height: 'auto'
			}
		};

        if (!segment || !scenarios || !ops) {
            return (<main />)
        }

		const scenariosHeader = this.renderHeaderCells('scenarios');
		const mainSettingsHeader = this.renderHeaderCells();
		const viewOnly = testCompleted || isViewer;

        return (
            <main className='ver-settings--container'>
				{!displayTestDetails && 
					<MuiAlert style={{ marginTop: '18px' }} severity="info">
						{this.areaSectionConfig.alertText}
					</MuiAlert>
				}
				<div key="building-form" className='ver-settings--block'>
					<h3 className='building-info-header'>{this.areaSectionConfig.header}</h3>
					<div className='building-info--form'>
						<BuildingInfoForm
							onSubmit={this.handleBuildingInfoUpdate}
							validationRules={this.areaSectionConfig.form}
							initialValues={{
								sq_ft: (buildingInfo && buildingInfo.sq_ft) || '',
								celling_height: (buildingInfo && buildingInfo.celling_height) || ''
							}}
							viewOnly={viewOnly}
						/>
					</div>
				</div>
				{displayTestDetails && <>
					<div key='settings-table' className='ver-settings--block'>
						<h3 className='test-info-header'>Test settings</h3>
						<TableContainer className={classes.scrollOverflow} component={Paper}>
							<Table className='ver-settings--general-table' aria-label="ver-general-table">
								<TableHead className={tableHeaderStyle}>
									<TableRow id='row'>
										{mainSettingsHeader}
									</TableRow>
								</TableHead>
								<TableBody className='table-body'>
									<TableRow id='row'>
										{this.renderDataCells()}
										<TableCell key='date-cell' className={cellStyle}>
											<DatePicker
												variant="inline"
												name='surveyDate'
												format={'MMM do, yyyy'}
												autoOk
												emptyLabel=''
												inputProps={{ min: 0, style: { textAlign: 'center'} }}
												value={surveyDate !== null ? parseISO(surveyDate) : null}
												onChange={e => this.handleDateChange(e)}
												disabled={testCompleted || isViewer}
											/>
										</TableCell>
										<TableCell key='comm-cell' className={cellStyle}>
											<form id='comments-form' onSubmit={e => onKeyDownTextUpdate(e, 'comments', null, 0)} noValidate autoComplete="off">
												<div id="comments-container">
												<TextField
													id="comments-input"
													coltype='comments'
													disabled={viewOnly}
													type="text"
													multiline
													minRows={3}
													inputProps={commentsInputProps}
													InputProps={{ disableUnderline: true }}
													value={comments || ''}
													onChange={e => onTextChangeFunc(e, 0, 'comments')}
												/>
												<Tooltip title="Submit Comments" placement="top" aria-label="comments-btn">
													<IconButton
														id='comments-btn'
														type="submit"
														disabled={viewOnly}
													>
														<CloudUploadIcon />
													</IconButton>
												</Tooltip>
												</div>
											</form>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</div>
					<div key='scenarios-table' className='ver-settings--block'>
						<h3 className='test-info-header'>Scenarios</h3>
						<TableContainer component={Paper}>
							<Table aria-label="ops">
								<TableHead>
									<TableRow className={tableHeaderStyle}>
										{scenariosHeader}
									</TableRow>
								</TableHead>
								<TableBody>
									{scenarios.map((scenario, i) => (
										<ScenariosRow
											key={i}
											scenario={scenario}
											collapseOpTable={this.scenariosOPsTable(scenario)}
											viewOnly={viewOnly}
											updateDescription={(value) => handleScenarioDescriptionUpdate(scenario.scenarioid, value)}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</div> 
				</>}
            </main>
        )
    }
}

VolumeBasedTest.contextType = Context;

export default withStyles(styles, { withTheme: true })(VolumeBasedTest);