import axios from 'axios';
import config from '../config.js';

const BASEURL = config.baseURL + '/samplepoints';
const BASEURLSAMPLES = config.baseURL + '/samplepoints/samples';

const samplepoints = {
  createSamplepoints : async(data) => {
    const response = await axios.post (BASEURL + '/create', data);
    return response;
  },
  getAllSamplepoints : async(testId, projectId) => {
    if(testId !== null) {
      const response = await axios.get (BASEURL + '?testId=' + testId , {});
      return response;
    } else {
      const response = await axios.get (BASEURL + '?projectId=' + projectId , {});
      return response;
    }
  },
  setSamplePoint : async(data) => {
    const response = await axios.put (BASEURL, data);
    return response;
  },
  getOriginpoints : async(segmentId) => {
    const response = await axios.get (BASEURL + '/' + segmentId , {});
    return response;
  },
  getLabels : async(testId, projectId) => {
    if(testId !== null) {
      const response = await axios.get (BASEURL + '/labels?testId=' + testId , {});
      return response;
    } else {
      const response = await axios.get (BASEURL + '/labels?projectId=' + projectId , {});
      return response;
    }
  },
  getAllSamples: async(testId, projectId) => {
    if(testId !== null) {
      const response = await axios.get (BASEURLSAMPLES + '?testId=' + testId , {});
      return response;
    } else {
      const response = await axios.get (BASEURLSAMPLES + '?projectId=' + projectId , {});
      return response;
    }
  },
  getDetails: async(testId) => {
    const response = await axios.get (BASEURL + '/details?testId=' + testId , {});
    return response;
  }
};


export default samplepoints;