import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Context } from '../../context/Context';

const useRowStyles = makeStyles({
    cell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        textAlign: 'left',
        borderRight: 'none',
        borderLeft: 'none',
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'center',
        padding: '10px !important'
    },
    tableRow: {
        cursor: 'pointer',
    },
    cellContent: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        textAlign: 'center',
        borderRight: 'none',
        borderLeft: 'none',
    }
});

export const NestedTableOP = ({
        ops,
        index,
        testCompleted,
        onChangeFunc,
        segments,
        scenario,
        isViewer,
        handleTagLotNumberChange,
        onKeyDownLotNumberExpirationDateUpdate,
        tagLotNumbers,
        expirationDates
    }) => {

    let disable = false;
    const classes = useRowStyles();
    const { state } = useContext(Context);
    let accessLevel = state.accessLevel;
    let { scenarioid } = scenario;
    let tagsList = ['Tag-C1', 'Tag-C2', 'Tag-C3', 'Tag-C4', 'Tag-C5', 'Tag-C6', 'Tag-C7', 'Tag-C8'];

    if(accessLevel === 'Viewer') {
        disable = true;
    }

    //Func to extract selected tags from OP obj
    const extractSelectedTags = ops => {
        let selectedTags = [];
        if(ops[0].tags.length > 0) {
            ops[0].tags.forEach((tagObj, i) => {
                if(tagObj) {
                    selectedTags.push(tagObj.tag);
                }
            });
        }
        return selectedTags;
    }

    //Func to remove selected tags from tagList which populates dropdown
    const removeSelectedTags = (selectedTags, tagsList) => {
        tagsList = tagsList.filter(tag => !selectedTags.includes(tag));
        return tagsList;
    }

    if(ops.length > 0) {
        let selectedTags = extractSelectedTags(ops);
        tagsList = removeSelectedTags(selectedTags, tagsList);
    }

    //Function: checks if scenario id matches scenarioid in OP Tags array, then return that value for select dropdown else value is empty string
    const setTagValue = (scenarioid, ops) => {
        scenarioid = scenarioid.toString();
        let value = '';

        ops[0].tags.forEach((tag, i) => {
            if(tag.scenarioId === scenarioid) {
                value = tag.tag;
            }
        });
        return value;
    }

    //Function: check current tagVal then iterate over lotNums arr and identify matchin tagVals; assign the lotNumber in obj as lot value
    const setLotNumValue = tagLotNumber => {
        let lotNumVal;
        if(tagLotNumber) {
            if(tagLotNumber.length > 0) {
                tagLotNumber.forEach((tagLotObj, i) => {
                    let currTagVal = setTagValue(scenarioid, ops);
                    if(currTagVal === tagLotObj.tag) {
                        lotNumVal = tagLotObj.lotNumber;
                    }
                });
            }
        }
        return lotNumVal;
    }

    const setExpirationDateValue = tagLotNumber => {
        let exDateVal;
        if(tagLotNumber) {
            if(tagLotNumber.length > 0) {
                tagLotNumber.forEach((tagLotObj, i) => {
                    let currTagVal = setTagValue(scenarioid, ops);
                    if(currTagVal === tagLotObj.tag) {
                        //Exit function if date is undefined (this occurs when user assigns lotNum then refreshes browser before assign ex date)
                        if(tagLotObj.expirationDate === "undefined") {
                            return;
                        }
                        exDateVal = tagLotObj.expirationDate;
                    }
                });
            }
        }
        return exDateVal;
    }

    if(ops.length !== 0) {
        return (
            <>
                <TableRow>
                    <TableCell style={{ borderBottom: 'none' }} className={classes.cell}></TableCell>
                    <TableCell style={{ borderBottom: 'none' }} className={classes.cell}></TableCell>
                    <TableCell style={{ borderBottom: 'none' }} className={classes.cell}></TableCell>
                    <TableCell style={{ borderBottom: 'none' }} className={classes.cell}></TableCell>
                    <TableCell style={{ borderBottom: 'none' }} className={classes.cell}></TableCell>
                    <TableCell style={{ borderBottom: 'none' }} className={classes.cellContent}>{ops[0].opnumber}{ops.length > 1 ? ' - ' + ops[ops.length - 1].opnumber : ''}</TableCell>
                    <TableCell style={{ borderBottom: 'none' }} className={classes.cellContent}>
                        <FormControl>
                            <Select
                                disableUnderline
                                style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }}
                                name='tag'
                                disabled={testCompleted || isViewer}
                                onChange={e => onChangeFunc(e, null, 'tag', segments, ops, scenario)}
                                value={setTagValue(scenarioid, ops)}
                                renderValue={(value) => value}
                            >
                                {tagsList.map((tag, i) => (
                                    <MenuItem key={`tag-key-${i}`} style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={tag}>{tag}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </TableCell>


                    <TableCell style={{ borderBottom: 'none' }} className={classes.cell}>
                        <form onSubmit={e => onKeyDownLotNumberExpirationDateUpdate(e, ops[0].opid, setTagValue(scenarioid, ops), 'lotNumber', ops, index)} className={classes.root} noValidate autoComplete="off">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <TextField
                                    type="text"
                                    disabled={true}
                                    inputProps={{
                                        style: { textAlign: 'center', fontFamily: 'Gotham', fontSize: '13px', marginTop: '10px' }
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    value={Object.keys(tagLotNumbers).length === 0 ? '' : tagLotNumbers[index]}
                                    onChange={e => handleTagLotNumberChange(e, index, 'lotNumber')}
                                />
                            </div>
                        </form>
                    </TableCell>
                    <TableCell style={{ borderBottom: 'none' }} className={classes.cell}>
                        <form onSubmit={e => onKeyDownLotNumberExpirationDateUpdate(e, ops[0].opid, setTagValue(scenarioid, ops), 'expirationDate', ops, index)} className={classes.root} noValidate autoComplete="off">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <TextField
                                    type="text"
                                    disabled={true}
                                    inputProps={{
                                        style: { textAlign: 'center', fontFamily: 'Gotham', fontSize: '13px', marginTop: '10px' }
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    value={Object.keys(expirationDates).length === 0 ? '' : expirationDates[index]}
                                    onChange={e => handleTagLotNumberChange(e, index, 'expirationDate')}
                                />
                            </div>
                        </form>
                    </TableCell>
                </TableRow>
            </>
        );
    } else {
        return null;
    }
}
