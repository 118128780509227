import React from 'react';
import logo from '../../assets/SafeTraces_Logo.Horiz.png';
import { infoCellPrimaryData } from '../../data/MaterialsChecklistData';
import { CheckListInfoCell } from '../CheckListInfoCell/CheckListInfoCell';
import './ChecklistTableHeader.css';

export const ChecklistTableHeader = ({ type }) => {
    return (
        <section className="checklist-header-summary">
            <div className="checklist-header-summary-cell">
                <img src={logo} alt="logo" className="checklist-header-logo" />
            </div>
            <div className="checklist-header-summary-cell checklist-header-summary-preparer-info">
                <div className="checklist-header-item1">{`${type} Check List`}</div>
                {infoCellPrimaryData.map((cell, i) => (
                    <div className="checklist-header-item2" key={`${cell}-${i}`}>
                        <CheckListInfoCell 
                            title={cell.title} 
                            text={cell.text} 
                        />
                    </div>
                ))}
            </div>
        </section> 
    )
}

