import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ButtonChecklist from '../ButtonChecklist/ButtonChecklist';
import './ChecklistsList.css';

const useStyles = makeStyles({
    tableHeader: {
      backgroundColor: '#F3F3F3',
      fontFamily: 'Gotham'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        padding: '10px !important'
    },
    cell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        borderRight: 'none',
        borderLeft: 'none'
    },
  });
  
  export const ChecklistsList = ({ getOrderFormData }) => {
    const classes = useStyles();
    const checklists = ['QC Check List', 'SafeTraces Chain of Custody'];
    //const checklists = ['QC Check List', 'Materials and Equipment Checklist', 'SafeTraces Chain of Custody'];
  
    return (
        <TableContainer style={{ marginTop: '20px' }} component={Paper}>
            <div id="checklists-view-wrapper">
                <Table aria-label="materials-table">
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell align="center" className={classes.headerCell}>Checklist Type</TableCell>
                            <TableCell align="center" className={classes.headerCell}>View Checklist</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {checklists.map((checklist, i) => (
                            <TableRow key={`${checklist}-${i}`}>
                                <TableCell align="center" width="300px" className={classes.cell}>
                                    {checklist}
                                </TableCell>
                                <TableCell align="center" className={classes.cell}>
                                    <ButtonChecklist checklist={checklist} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </TableContainer>
    );
}