import React from 'react';
import './CheckListInfoCell.css';

export const CheckListInfoCell = ({ title, text, len, pn, address }) => {
  return (
    <main>
      <div className="cell-wrapper">
          <div className="cell">{title}:</div>
          {len && (<div className="cell"> {len}</div>)}
          {pn && (<div className="cell"> {pn}</div>)}
          {address && (<div className="cell"> {address}</div>)}
          {text && (<div className="cell"> {text}</div>)}
      </div>
    </main>
  );
}