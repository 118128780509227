import React from '../../../node_modules/react';
import { Label } from '../Label/Label';
import Pagination from '@material-ui/lab/Pagination';
import { Context } from '../../context/Context';
import './Labels.css';

class Labels extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            warning: 'DO NOT REMOVE',
            spAndOpitemsPerPage: props.perPage || 10,
            scItemsPerPage: props.perPage || 90,
            page: 1
        };
        this.numberOfPages = 0;
        this.plates = false;
        this.blankLabel = {
            number: '',
            plates: [],
            projectName: '',
            qrcode: '',
            samplepoint: '',
            surveydate: ''
        }
        this.projectId = null;
    }

    setNumberOfPages = (labels, itemsPerPg) => {
        this.numberOfPages = Math.ceil(labels.length / itemsPerPg);
    }

    handlePageChange = (e, value) => {
        this.setState({ page: value });
    };

    handleSettingLabelDimensions = labels => {
        for(let key in labels[0]) {
            if(key === 'plates') {
                this.plates = true;
            }
        }
    }

    componentDidMount() {
        this.projectId = JSON.parse(localStorage.getItem("projectId"));
    }

    render() {
        let { labels, perPage } = this.props;
        const { warning, spAndOpitemsPerPage, scItemsPerPage, page } = this.state;
        this.handleSettingLabelDimensions(labels)
        console.log(perPage);

        if(this.plates) {
            this.setNumberOfPages(labels, scItemsPerPage);
        } else {
            this.setNumberOfPages(labels, spAndOpitemsPerPage);
        }

        return (
            <main>
                {this.plates ? (
                    <main className="labels-wrapper-sc ">
                        <section className="labels-grid-wrapper-sc">
                            <>{labels
                                .slice((page - 1) * scItemsPerPage, page * scItemsPerPage)
                                .map((label, i) => (
                                    <div key={`${i}-${label.projectId}`}>
                                        <Label
                                            projectName={label.projectName}
                                            sampleNumber={label.sampleNumber}
                                            spNumber={label.SPNumber}
                                            sampleType={label.sampleType}
                                            samplePoint={label.samplePoint}
                                            surveydate={label.surveydate}
                                            plates={label.plates}
                                            qrcode={label.qrcode}
                                            label={label}
                                            projectId={this.projectId}
                                        />
                                    </div>
                                ))}
                            </>
                        </section>
                    </main>
                ) : (       
                    <main className="labels-wrapper-op-sp">  
                            {typeof perPage !== 'undefined' && (
                                <>
                                    <section >
                                        {labels.reduce((all, one, i) => {
                                                const ch  = Math.floor(i / 10);
                                                all[ ch ] = [].concat((all[ ch ] || []), one);
                                                return all
                                            }, []).map((el, i) => (
                                                <div className="item-page">
                                                    {el.map((label, i) => (
                                                        <div key={`${i}-${label.projectId}`}>
                                                            <Label
                                                                warning={warning}
                                                                surveydate={label.surveydate}
                                                                projectName={label.projectName}
                                                                number={label.number}
                                                                name={label.name}
                                                                tag={label.tag}
                                                                qrcode={label.qrcode}
                                                                label={label}
                                                                projectId={this.projectId}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            ))
                                        }
                                    </section>
                                </>
                            )}
                            {typeof perPage === 'undefined' && (
                                <>
                                    <section className="labels-grid-wrapper-op-sp">
                                        {labels
                                            .slice((page - 1) * spAndOpitemsPerPage, page * spAndOpitemsPerPage)
                                            .map((label, i) => (
                                                <div key={`${i}-${label.projectId}`}>
                                                    <Label
                                                        warning={warning}
                                                        surveydate={label.surveydate}
                                                        projectName={label.projectName}
                                                        number={label.number}
                                                        name={label.name}
                                                        tag={label.tag}
                                                        qrcode={label.qrcode}
                                                        label={label}
                                                        projectId={this.projectId}
                                                    />
                                                </div>
                                            ))}
                                    </section>
                                </>
                            )}
                    </main>
                )}
                {typeof this.props.noPagination === 'undefined' && <section className="labels-pagination-wrapper">
                    <Pagination
                        count={this.numberOfPages}
                        page={page}
                        defaultPage={1}
                        onChange={this.handlePageChange}
                        color="standard"
                        size="medium"
                        showFirstButton
                        showLastButton
                    />
                </section>}

            </main>
        );
    }
}
Labels.contextType = Context;
export default Labels;













