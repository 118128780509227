import React, { useContext } from 'react';
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import './ProjectDetails.css';
import { Context } from '../../context/Context';
import projectStatuses from '../../data/projectStatusData';

export const ProjectDataView = ({ projectId, projectData, handleProjectEdit, 
    handleProjectPublish, handleProjectRemoval, publicationAccess, publishDisabled }) => {
    const { state } = useContext(Context);
    const handleEditProjectClick = projId => {
        let projectId = JSON.stringify(projId);
        localStorage.setItem("projectId", projectId);
        handleProjectEdit();
    }

    if (!projectData) {
        return null;
    }
    const disabledExplanationWrapper = (btn, text) => <Tooltip arrow title={text} aria-label="wrong-status">
            <span style={{float: 'right'}}>{btn}</span>
        </Tooltip>;

    const publicationControlBtn = (onClick, disabled, text) => {
        const defaultDisabled = !projectData.status || !~['res_generated', 'published'].indexOf(projectData.status);
        const btn = <Button
            size="small"
            variant="outlined"
            className="add-publish-button"
            disabled={defaultDisabled || disabled}
            style={{marginBottom: 0}}
            onClick={onClick}
        >{text}</Button>;

        return defaultDisabled ? disabledExplanationWrapper(btn, "Project must have results to be published") : btn;
    }
    
    const publishBtn = publicationControlBtn(() => handleProjectPublish(), publishDisabled, 'Publish on the Customer Portal');
    const removePublishBtn = publicationControlBtn(() => handleProjectRemoval(), false, 'Remove from customer portal');
    const getEditBtn = (disabled, reason = '') => {
        const editBtn = <Button
            size="small"
            variant="outlined"
            className="add-edit-button"
            onClick={() => handleEditProjectClick(projectId)}
            disabled={projectData.status === 'published'}
        >Edit</Button>;
        return disabled ? disabledExplanationWrapper(editBtn, reason) : editBtn;
    }

    return (
        <div>
            <div className="grid-wrapper">
                <div>
                    <Typography variant="h5" color="textPrimary">
                        {`Project (Id ${projectId})`}
                        {state.accessLevel !== 'Viewer' && 
                            getEditBtn(
                                projectData.status === 'published',
                                'Published projects can not be edited'
                        )}
                        {publicationAccess && <>
                            { projectData.status === 'published' ? removePublishBtn : publishBtn }
                        </>}
                    </Typography>
                </div>
            </div>
            <div className="grid-wrapper-info project-details-line-bottom">
                <div className="project-details-line-right">
                    <Typography variant="h6" color="textPrimary">
                        Name
                    </Typography>
                    { projectData.name || '\u00A0' }
                    <br/><br/>
                </div>
                <div>
                    <Typography variant="h6" color="textPrimary">
                        Status
                    </Typography>
                    { projectStatuses[projectData.status] || '\u00A0' }
                    <br/><br/>
                </div>
            </div>
            <div className="grid-wrapper-info project-details-line-bottom">
                <div className="project-details-line-right">
                    <Typography variant="h6" color="textPrimary">
                        Address
                    </Typography>
                    { projectData.address || '\u00A0'  }
                    <br/><br/>
                </div>
                <div>
                    <Typography variant="h6" color="textPrimary">
                        Customer
                    </Typography>
                    { projectData.endCustomer || '\u00A0' }
                    <br/><br/>
                </div>
            </div>
            <div className="grid-wrapper-info project-details-line-bottom">
                <div className="project-details-line-right">
                    <Typography variant="h6" color="textPrimary">
                        Description
                    </Typography>
                    { projectData.description || '\u00A0' }
                    <br/><br/>
                </div>
                <div>
                    <Typography variant="h6" color="textPrimary">
                        Partner
                    </Typography>
                    { projectData.partners || '\u00A0' }
                    <br/><br/>
                </div>
            </div>
        </div>
    );
};