import React from '../../../node_modules/react';
import originpoints from '../../api/originpoints';
import OriginPointsList from '../../components/OriginPointsList/OriginPointsList.js';
import { CSVLink } from 'react-csv';
import { Context } from '../../context/Context';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import './OriginPoints.css';

class OriginPoints extends React.Component {
    constructor(props) {
        super(props);
        this.state = {segmentId: null, originPoints: [], aggregatedOriginpoints: []};
        this.getAggregatedOriginPoints = this.getAggregatedOriginPoints.bind(this);
    }

    componentDidMount() {
        //logic to handle highlighting navbar tab
        let segmentsNavButton = document.querySelector('#sBtn')
        if(segmentsNavButton) {
            segmentsNavButton.classList.add('selected');
        }

        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        let testId = JSON.parse(localStorage.getItem("testId")) ? JSON.parse(localStorage.getItem("testId")) : null;
        
        this.setState({
            projectId: projectId,
            testId: testId
        }, async () => {
            const response = await originpoints.getAllOriginpoints(this.state.testId, this.state.projectId);
            this.setState ({ originPoints: response.data.data });
            const aggregatedOriginPoints = await this.getAggregatedOriginPoints();
            this.setState ({ aggregatedOriginPoints: aggregatedOriginPoints });
        });
    };

    async getAggregatedOriginPoints() {
        let result = [];
        if (this.state.originPoints && this.state.originPoints.length > 0) {
            await this.state.originPoints.forEach(async op => {
                if(op.tags && op.tags.length >0) {
                    await op.tags.forEach(async tag => {
                        result.push({
                            opid: op.opid,
                            opnumber: op.opnumber,
                            opname: op.opname,
                            opcoordx: op.opcoordx,
                            opcoordy: op.opcoordy,
                            scenarioId: tag.scenarioId==="null"?'':tag.scenarioId,
                            tag: tag.tag,
                            tagLotNumbers: op.tagLotNumber ? op.tagLotNumber : op.taglotnumbers
                        });
                    });
                } else {
                    if(op.opnumber.length > 0) {
                        result.push({ opnumber: op.opnumber });
                    } 
                }
            });
        }
        if(result.length > 0) {
            await result.forEach(async originpoint => {
                if(originpoint.tagLotNumbers && originpoint.tagLotNumbers.length > 0) {
                    await originpoint.tagLotNumbers.forEach(async lotnumber => {
                        if (originpoint.tag === lotnumber.tag) {
                            originpoint.taglotnumber = lotnumber.lotNumber;
                        }
                    });
                    delete originpoint['tagLotNumbers'];
                }
            });
        }
        return result;
    }
    
    handleSegmentsClick = (event) => {
        this.props.history.push('/' + this.state.projectId + '/segments');  
    }

    componentWillUnmount() {
        let segmentsNavButton = document.querySelector('#sBtn')
        if(segmentsNavButton) {
            segmentsNavButton.classList.remove('selected');
        }
    }

    render() {
        const { projectId, testId } = this.state;
        return (
            <Context.Consumer>
                {context => (
                    <div className="origin-points-container">
                        <div>
                            <Typography variant="h5" color="textPrimary" style={{fontFamily: "Gotham", marginTop: "30px"}} align="center">{`(Project Id ${projectId}) ${context.state.projectName}: ${context.state.testName} - Origin Points`}</Typography>
                        </div>
                        <div className="origin-points-btn-container">
                            <CSVLink 
                                data={this.state.originPoints}
                                filename={`Test ${testId} PID ${projectId} Origin Points Coordinates.csv`}
                                style={{margin: '0 10px 0 10px', textDecoration: "none"}}>
                                    <Button size="small" variant="outlined" style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", fontFamily: "Gotham"}} >Download</Button>
                            </CSVLink>
                        </div>
                        <div>
                            <OriginPointsList originPoints={this.state.originPoints} aggregatedOriginPoints={this.state.aggregatedOriginPoints} history={this.props.history}/>
                        </div>
                    </div>
                )}
            </Context.Consumer>
        )
    }
}

export default OriginPoints;


