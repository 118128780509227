import React, { useEffect, useRef, useState } from 'react';
import { makeStyles }                         from '@material-ui/core/styles';
import Table                                  from '@material-ui/core/Table';
import TableBody                              from '@material-ui/core/TableBody';
import TableCell                              from '@material-ui/core/TableCell';
import TableContainer                         from '@material-ui/core/TableContainer';
import TableHead                              from '@material-ui/core/TableHead';
import TableRow                               from '@material-ui/core/TableRow';
import Paper                                  from '@material-ui/core/Paper';
import Pagination                             from '@material-ui/lab/Pagination';
import { Context }                            from "../../context/Context";
import { NestedTableTag }                     from "../NestedTable/NestedTableTag";
import TextField                              from "@material-ui/core/TextField";

const useRowStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell:  {
        fontFamily: 'Gotham',
        fontSize:   '13px',
        fontWeight: '700',
        textAlign:  'center',
        padding:    '10px !important'
    },
}));

export const TagList = ({ users, tags, fetchTags, fetchTagProperties }) => {

    const classes = useRowStyles();

    const [ itemsPerPage, setItemsPerPage ]   = useState(10);
    const [ page, setPage ]                   = useState(1);
    const [ numberOfPages, setNumberOfPages ] = useState(0);
    const [ filter, setFilter ]               = useState('');

    const headers = [
        '',
        '',
        '',
        'Delete Tag',
        'Name',
        'Plate Kind',
        'Slope',
        'Intercept',
        'Lot Number',
        'Manufacture Date (Bulk saliva)',
        'Expiration Date (Bulk saliva)',
        'New Baseline',
    ];

    const handleFilter = (event) => {
        setFilter(event.target.value);
        // Reset to default page
        setPage(1);
    }

    const filterTag = (tag) => {
        //console.log('tag: ', tag);
        if (!filter) {
            return true;
        }

        // if (tag.tagid === parseInt(filter)) {
        //     return true;
        // }

        if (tag.taglotnumber === filter) {
            return true;
        }

        let tagName = tag.tag.toLowerCase();

        // True if found / False if not found
        return tagName.indexOf(filter.toLowerCase()) !== -1;
    }

    const handlePageChange = (e, page) => {
        setPage(page);
    };

    const handleNumberOfPages = (tags, itemsPerPg) => {
        let pages = Math.ceil(tags.filter((tag) => { return filterTag(tag) }).length / itemsPerPg);
        setNumberOfPages(pages);
    }

    const firstUpdate = useRef(true);
    useEffect(() => {
        handleNumberOfPages(tags, itemsPerPage);
        firstUpdate.current = false;
    });

    const tagSort = (a, b) => {
        const isSameTagName = a.tag == b.tag;
        if (isSameTagName) {
            return a.tagid > b.tagid ? 1 : -1;
        } else {
            return a.tag > b.tag ? 1 : -1;
        }
    };

    return (
        <Context.Consumer>
            {context => (context.state.accessLevel !== 'Admin' && context.state.roleName !== 'Safetracer') ? null : (
                <>
                    <div>
                        <TextField
                            id="filterTags"
                            label="Search by Name or Lot Number"
                            name="filterTags"
                            style={{ fontFamily: 'Gotham', width: '100%' }}
                            onChange={(e) => handleFilter(e)}
                        />
                    </div>
                    <TableContainer style={{ marginTop: '15px' }} component={Paper}>
                        <div>
                            <Table aria-label="tags-list-table">
                                <TableHead className={classes.tableHeader}>
                                    <TableRow>
                                        {headers.map((header, i) => (
                                            <TableCell key={`header-${i}`} className={classes.headerCell}>{header}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tags
                                        .sort((a, b) => tagSort(a, b))
                                        .filter((tag) => { return filterTag(tag) })
                                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                        .map((tag, i) => (
                                            <NestedTableTag key={'tag-' + i} tag={tag} users={users} fetchTags={fetchTags} fetchTagProperties={fetchTagProperties}/>
                                        ))}
                                </TableBody>
                            </Table>
                        </div>
                    </TableContainer>
                    <section className="labels-pagination-wrapper">
                        <Pagination
                            count={numberOfPages}
                            page={page}
                            defaultPage={1}
                            onChange={handlePageChange}
                            color="standard"
                            size="medium"
                            showFirstButton
                            showLastButton
                        />
                    </section>
                </>
            )}
        </Context.Consumer>
    );
}
