import React from 'react';
import projects from '../../api/projects';
import Typography from '@material-ui/core/Typography'
import { Form } from '../../components/Form/Form';
import authentication from '../../api/authentication';
import './createProject.css';
import { AlertGeneral } from '../../components/Alert/AlertGeneral';
import { Context } from '../../context/Context';

class createProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            address:'', 
            channelPartner:'',
            servicePartner:'', 
            endCustomer: '',
            users:[], 
            floorPlans: [], 
            segments:1, 
            description: '',
            companyNames: [],
            customerCompanies: [],
            alertMsg: '',
            open: false
        };
    
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.textFieldArr = [
            {name : 'name' , label: 'Project Name'},
            {name : 'address' , label: 'Address'},
            {name : 'description' , label: 'Description'},
            {name : 'endCustomer' , label: 'End Customer'},
            {name : 'channelPartner' , label: 'Partners'},
            {name : 'users' , label: 'Users'},
        ]
    }

    async componentDidMount() {
        const response = await authentication.getPartners();
        if (response && response.data && response.data.success) {
            response.data.data.forEach(obj => {
                this.setState(prevState => ({
                    companyNames: [...prevState.companyNames, obj.companyname]
                }));
            })
        }

        const customerResponse = await authentication.getAllCustomers();
        
        if (customerResponse && customerResponse.data && customerResponse.data.success) {
            customerResponse.data.data.forEach(obj => {
                this.setState(prevState => ({
                    customerCompanies: [...prevState.customerCompanies, obj.companyname]
                }));
            })
        } else {
            console.log('Error', customerResponse);
        }
    };

    handleSubmit(event) {
        if(!this.state.endCustomer && this.context.state.companyName === 'Safetraces') {
            console.log('NO end customer defined');
            this.setState({
                alertMsg: `Please select an end customer for the Project.`,
                open: true
            })
            return;
        }

        const newProject = {
            "name": this.state.name,
            "address": this.state.address,
            "endcustomer": this.state.endCustomer,
            "description": this.state.description,
            "users": this.state.users
        }
        newProject.partners = event.channelPartner;
        
        projects.addProject(newProject).then((result)=> {
            let data = result.data.data[0];
            let projectId = data.projectid;
            this.props.history.push('/projects');
        });
    }

    handleInputChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        
        this.setState({
            [name]: value
        });
    }

    handleAlertClose = () => this.setState({ open: !this.state.open });

    render() {
        const { companyNames, customerCompanies, alertMsg, open } = this.state;
        return (
            <>
                <main className="create-project-wrapper">
                    <div className="create-project-wrapper-header">
                        <Typography variant="h4" color="textPrimary" align="center" style={{fontFamily: "Gotham", marginTop: "30px"}}>Create Project Form</Typography>
                    </div>
                    <div className="create-project-wrapper-form">
                        <Form 
                            textFieldArr={this.textFieldArr}  
                            handleChangeFunc={this.handleInputChange}
                            handleSubmitFunc={this.handleSubmit}
                            companyNames={companyNames}
                            customerCompanies={customerCompanies}
                            type={`newProject`}
                        />
                    </div>
                </main>
                {!!open && (<AlertGeneral open={open} alertMsg={alertMsg} closeFunc={this.handleAlertClose} severity='error' />)}
            </>
        )
    }
}
createProject.contextType = Context;
export default createProject;