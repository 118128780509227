import React from '../../../node_modules/react';
import { Context } from '../../context/Context';
import tests from '../../api/tests';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { DilutionListSegment } from '../DilutionListSegment/DilutionListSegment';

const styles = theme => ({
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'center',
        padding: '10px !important'
    },
});

class ScenariosList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editable: true,
            open: false,
            alertMsg: '',
            updateSuccessful: false,
            workFlow: false,
            segmentClicked: [],
            testCompleted: null,
            testId: '',
            accessLevel: JSON.parse(localStorage.getItem("accessLevel")) ? JSON.parse(localStorage.getItem("accessLevel")) : null,
            isViewer: false
        }
    }

    async componentDidMount() {
        let response = null;
        let testId = JSON.parse(localStorage.getItem("testId")) ? JSON.parse(localStorage.getItem("testId")) : null;
        let isViewer = false;

        if(this.state.accessLevel === 'Viewer') {
            isViewer = true;
        }

        this.setState({
            isViewer,
            testId: testId
        }, async () => {
            if(testId !== null) {
                response = await tests.getOneTest(this.state.testId);
                if(response.data.success) {
                    this.setState({ testCompleted: response.data.data.testexecuted });
                }      
            }
        });
    }

    render() {
        const { 
            segments, 
            scenarios, 
            floorPlans, 
            classes,
            onChangeFunc, 
            onTextChangeFunc,
            onSurveyDateUpdateFunc,
            onKeyDownTextUpdate,
            handleTagLotNumberChange,
            onKeyDownLotNumberExpirationDateUpdate,
            ops, 
            disableScenarios, 
            surveyDate, 
            comments, 
            scenarioDescription, 
            tagLotNumbers,
            expirationDates,
            testType,
            projectFromProjectPortal
        } = this.props;
        const { testCompleted, isViewer } = this.state;
        return (
            
            <main>
                <TableContainer style={{ marginTop: '15px', width: '100%' }} component={Paper}>
                    <div>
                        <Table style={{ width: '100%' }} aria-label="segments dilution table">
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell style={{ width: "15%", padding: "20px" }} className={classes.headerCell}>Floor Plan*</TableCell>
                                    <TableCell className={classes.headerCell}>No: of OP{testType === 'recirculation' && '*'}</TableCell>
                                    <TableCell className={classes.headerCell}>No: of SP*</TableCell>
                                    <TableCell className={classes.headerCell}>Interval Count</TableCell>
                                    <TableCell className={classes.headerCell}>Interval Duration*</TableCell>
                                    <TableCell className={classes.headerCell}>Scenarios*</TableCell>
                                    <TableCell className={classes.headerCell}>Survey Date*</TableCell>
                                    <TableCell className={classes.headerCell}>Comments</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {segments.map((segment, i) => (
                                    <DilutionListSegment
                                        key={`dls-key-${i}`}
                                        index={i} 
                                        scenarios={scenarios}
                                        floorPlans={floorPlans}
                                        individualSegment={segment} 
                                        segments={segments}
                                        surveyDate={surveyDate}
                                        comments={comments}
                                        testCompleted={testCompleted}
                                        isViewer={isViewer}
                                        scenarioDescription={scenarioDescription}
                                        ops={ops}
                                        tagLotNumbers={tagLotNumbers}
                                        expirationDates={expirationDates}
                                        disableScenarios={disableScenarios} 
                                        projectFromProjectPortal={projectFromProjectPortal}
                                        onChangeFunc={onChangeFunc}
                                        onTextChangeFunc={onTextChangeFunc}
                                        onSurveyDateUpdateFunc={onSurveyDateUpdateFunc}
                                        handleTagLotNumberChange={handleTagLotNumberChange}
                                        onKeyDownLotNumberExpirationDateUpdate={onKeyDownLotNumberExpirationDateUpdate}
                                        onKeyDownTextUpdate={onKeyDownTextUpdate}
                                        testType={testType} />
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
            </main>                   
        )
    }
}
ScenariosList.contextType = Context;
export default withStyles(styles, { withTheme: true })(ScenariosList);