import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import sampleplans from '../../api/sampleplans';
import './LabRequirementsChecklistTable.css';

const styles = theme => ({
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '20px',
        textAlign: 'center'
    },
    headerCellHorizontal: {
        fontFamily: 'Gotham',
        fontSize: '20px',
        textAlign: 'left'
    },
    cell: {
        fontFamily: 'Gotham',
        fontSize: '16px',
        textAlign: 'center'
    }
});

class LabRequirementsChecklistTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            testType: null,
            platesListKeys: [],
            platesListVals: [],
            stripsListKeys: [],
            stripsListVals: [],
        };
    }

    async componentDidMount() {
        let testId = JSON.parse(localStorage.getItem("testId")) ? JSON.parse(localStorage.getItem("testId")) : null;
        let testType = JSON.parse(localStorage.getItem('testType'));
        this.setState({ testType });
        let samplesLabResponse = await sampleplans.getSamplesForLab(testId);

        if(samplesLabResponse.data.success) {
            this.setState({ 
                response: samplesLabResponse.data.data
             }, () => {
                if(this.state.testType === 'generalSurvey') {
                    if(this.state.response.platesList) {
                        this.setState({
                            platesListKeys: Object.keys(this.state.response.platesList),
                            platesListVals: Object.values(this.state.response.platesList)
                        });
                    }
                } else {
                    if(this.state.response.stripsList) {
                        this.setState({
                            stripsListKeys: Object.keys(this.state.response.stripsList),
                            stripsListVals: Object.values(this.state.response.stripsList)
                        });
                    }
                }
            });
        }    
    }

    render() {
        const { classes } = this.props;
        const { testType, response, platesListKeys, platesListVals, stripsListKeys, stripsListVals } = this.state;
        
        if(platesListKeys.length > 0 || stripsListKeys.length > 0) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                    <TableContainer style={{ marginTop: '15px', width: 400, height: 'auto' }} component={Paper}>
                        <Table style={{ width: 400 }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.headerCellHorizontal} variant="head" style={{ backgroundColor: '#F3F3F3' }}>No: of samples</TableCell>
                                    <TableCell className={classes.cell}>{response.numberOfSamples}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCellHorizontal} variant="head" style={{ backgroundColor: '#F3F3F3' }}>{testType === 'generalSurvey' ? 'Total No: of Plates' : 'Total No: of Strips'}</TableCell>
                                    <TableCell className={classes.cell}>{testType === 'generalSurvey' ? response.numberOfPlates : response.numberOfStrips}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table style={{ width: 400 }}>
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>{testType === 'generalSurvey' ? 'PlateKind' : 'Tag'}</TableCell>
                                    <TableCell className={classes.headerCell}>{testType === 'generalSurvey' ? 'No: of Plates' : 'No: of Strips'}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {testType === 'generalSurvey' ? (
                                    platesListKeys.length > 0 && (
                                        platesListKeys.map((plate, i) => (
                                            <TableRow key={`plate-key=${i}`}>
                                                <TableCell className={classes.cell}>{plate}</TableCell>
                                                <TableCell className={classes.cell}>{platesListVals[i]}</TableCell>
                                            </TableRow>
                                        ))
                                    )
                                ) : (
                                    stripsListKeys.length > 0 && (
                                        stripsListKeys.map((strip, i) => (
                                            <TableRow key={`strip-key=${i}`}>
                                                <TableCell className={classes.cell}>{strip}</TableCell>
                                                <TableCell className={classes.cell}>{stripsListVals[i]}</TableCell>
                                            </TableRow>
                                        ))
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            );
        } else {
            return null;
        }
    }  
}

export default withStyles(styles, { withTheme: true })(LabRequirementsChecklistTable);