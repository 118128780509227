import axios from "axios";
import config from "../config.js";

const BASEURL = config.baseURL + "/portfolios";

const portfolios = {
  createPortfolio: async (data) => {
    const response = await axios.post(BASEURL, data);
    return response;
  },
  getPortfolioByName: async (portfolioName, customerId) => {
    const response = await axios.get(BASEURL + `/single?portfolioName=${portfolioName}&customerId=${customerId}`);
    return response;
  },
};

export default portfolios;