import React from 'react';

export const SpLabelSns = ({ sns }) => {
    let snKeys = [];
    let snValues = [];
    let multiples = [];

    const split = (array, n) => {
        let [...arr]  = array;
        var res = [];
        while (arr.length) {
          res.push(arr.splice(0, n));
        }
        return res;
    }

    const getSnsValues = (sns) => {
        let result = '';
        sns.forEach(element => {
            result = result + element + ',';
        });
        return result.slice(0, -1);
    }

    const currateSns = sns => {
        if(sns && sns.length <= 4) {
            snKeys = 1;
            snValues = getSnsValues(sns);
        } else {
            multiples = split(sns, 4);
        }

        return (
            snKeys === 1 ? (
                <section style={{ display: 'flex', flexDirection: 'column' }}>
                    {/* {snValues.map((sn, i) => (
                        <div style={{ fontSize: '11px' }}>{`${snKeys}:${sn} `}</div>
                    ))} */}
                    {
                        <div style={{ fontSize: '11px' }}>{`${snKeys}:${snValues} `}</div>
                    }
                </section> 
            ) : (
                <section style={{ display: 'flex', flexDirection: 'column' }}>
                    {multiples.map((arr, i) => (
                        <div style={{ fontSize: '11px' }}>{`${i+1}:${arr.toString()} `}</div>
                    ))}
                </section> 
            )
        )
    }

    return (<main>{currateSns(sns)}</main>);    
}