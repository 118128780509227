import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

//import ProgressBar from '../ProgressBar';
import LinearProgress from '@material-ui/core/LinearProgress';




const styles = (theme) => ({
    modal: {
        position: 'fixed',
        top: 0,
        left: 0,
        background: 'rgba(0, 0, 0, 0.8)',
        zIndex: 99999,
        height: '100%',
        width: '100%',
    },
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#fff',
        width: '50%',
        height: '50%',
        padding: '20px',
    },
    modalTitle: {
        width: '100%',
        height: '60px',
        //background: 'red',
        //alignContent: 'center',
    },
    modalBody: {
        width: '100%',
        height: 'calc(100% - 100px)',
        maxHeight: 'calc(100% - 100px)',
        //background: 'yellow'
        backgroundImage: 'linear-gradient(45deg, #EEEEEE 25%, transparent 25%), linear-gradient(-45deg, #EEEEEE 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #EEEEEE 75%), linear-gradient(-45deg, transparent 75%, #EEEEEE 75%)',
        backgroundSize: '20px 20px',
        backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
        overflow: 'hidden',
    },
    modalActions: {
        width: '100%',
        height: '40px',
        //background: 'lightyellow',

    },

    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        //background: 'pink',

    },
    isHidden: {
        visibility: 'hidden',
        opacity: 0,
        width: '100%',
        //transition: 'visibility 0s 0.5s, opacity 1s linear',
    },
    isVisible: {
        //contain: 'content',
        visibility: 'visible',
        opacity: 1,
        transition: 'opacity 0.7s linear',
        //width: '100%',
        //maxHeight: '100%',
        //vmax: '60%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },



});

class DataLoadingDialog extends React.Component {
    constructor(props) {
        super(props);
        this.loadActivity = this.loadActivity.bind(this);


    }

    async componentDidMount() {


    }

    componentDidUpdate(prevProps, prevState) {



    }
    loadActivity() {

        const { classes } = this.props;


        return (
            <div className={classes.modalContent}>
                <div className={classes.modalTitle}>
                    <Typography variant='h5'>{this.props.title}</Typography>
                    <Typography variant='subtitle1'>{this.props.message}</Typography>
                </div>

                <div className={classes.modalBody} style={{ background: "white" }}>
                    <LinearProgress style={{ top: '45%' }} />
                </div>

                <div className={classes.modalActions}>
                    <div className={classes.buttons}>
                        <Button onClick={this.props.onCancel}>
                            Cancel
                        </Button>

                    </div>
                </div>
            </div>
        )
    }

    errorActivity() {

        const { classes } = this.props;


        return (
            <div className={classes.modalContent}>
                <div className={classes.modalTitle}>
                    <Typography variant='h5'>New Floor Plan Error</Typography>
                    <Typography variant='subtitle1'>some sub title for the error</Typography>
                </div>

                <div className={classes.modalBody} style={{ background: "white" }}>
                    <div>
                        <Typography variant='body1'>error message goes here</Typography>
                    </div>
                </div>

                <div className={classes.modalActions}>
                    <div className={classes.buttons}>
                        <Button onClick={this.props.onCancel}>
                            Close
                        </Button>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.modal}>
                {

                    this.loadActivity()
                }
            </div>
        )
    }
}
export default withStyles(styles)(DataLoadingDialog);