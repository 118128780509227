import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Context } from '../../context/Context';

export const ProtectedRoute = props => {

    const { isAuthenticated } = useContext(Context);

    //If user is authenticated proceed to component. Else redirect to login page

    return (
        <Route path={props.path} render={data => isAuthenticated(JSON.parse(localStorage.getItem('token'))) ?
            (
                <props.component {...data}></props.component>
            ) : (
                <Redirect to={{ pathname: './' }}></Redirect>
            )}>
        </Route>
    );
}