import React, { Component, createContext } from 'react';

export const Context = createContext();

export class ContextProvider extends Component {

    state = {
        onProject: JSON.parse(localStorage.getItem("onProject")),
        projectDetails: JSON.parse(localStorage.getItem("projectDetails")),
        projectName: localStorage.getItem("projectName"),
        testName: localStorage.getItem("testName"),
        projectId: JSON.parse(localStorage.getItem("projectId")),
        testId: JSON.parse(localStorage.getItem("testId")),
        authenticated: false,
        accessLevel: JSON.parse(localStorage.getItem("accessLevel")),
        roleName: JSON.parse(localStorage.getItem("roleName")),
        companyName: JSON.parse(localStorage.getItem("companyName")),
        testCompleted: JSON.parse(localStorage.getItem("testCompleted")),
        userEmail: JSON.parse(localStorage.getItem("userEmail")),
        userId: JSON.parse(localStorage.getItem("userId")),
        testType: JSON.parse(localStorage.getItem("testType")),
        sampleType: JSON.parse(localStorage.getItem("sampleType")),
        projectFromProjectPortal: JSON.parse(localStorage.getItem("projectFromProjectPortal")),
    }

    setOnProject = () => {
        this.setState({ onProject: true }, () => {
            let onProject = JSON.stringify(this.state.onProject);
            localStorage.setItem("onProject", onProject); 
        });
    }

    setNotOnProject = () => {
        this.setState({ onProject: false }, () => {
            let onProject = JSON.stringify(this.state.onProject);
            localStorage.setItem("onProject", onProject); 
        });
    }

    setOnProjectDetails = () => {
        this.setState({ projectDetails: true }, () => {
            let projectDetails = JSON.stringify(this.state.projectDetails);
            localStorage.setItem("projectDetails", projectDetails);
        });
    }

    setNotOnProjectDetails = () => {
        this.setState({ projectDetails: false }, () => {
            let projectDetails = JSON.stringify(this.state.projectDetails);
            localStorage.setItem("projectDetails", projectDetails);
        });
    }

    removeOnProject = () => {
        this.setState({ onProject: false }, () => {
            localStorage.setItem("onProject", false);
            //WE WANT TO REMOVE projectId FROM LOCALSTORAGE SO WE PULL CORRECT DATA WHEN SELECTING ANOTHER PROJECT FROM PROJECT LIST
            localStorage.removeItem("projectId");
        });    
    }
    
    setTypeName = (name, type) => {
        if(type === 'Project') {
            this.setState({ projectName: name }, () => {
                localStorage.setItem("projectName", name);
            });
        } else {
            this.setState({ testName: name }, () => {
                localStorage.setItem("testName", name);
            });
        }
    }

    setTypeId = (projId, testid, type) => {
        let projectId, testId;
        if(type === 'Project') {
            this.setState({ projectId: projId }, () => {
                projectId = JSON.stringify(this.state.projectId);
                localStorage.setItem("projectId", projectId);
            });
        } else {
            this.setState({ 
                testId: testid,
                projectId: projId
             }, () => {
                testId = JSON.stringify(this.state.testId);
                localStorage.setItem("testId", testId);
                projectId = JSON.stringify(this.state.projectId);
                localStorage.setItem("projectId", projectId);
            });
        }
    }

    setLoggedIn = () => {
        this.setState({ authenticated: true }, () => {
            //console.log('Context authenticated: ', this.state.authenticated);
        });
    } 

    setLoggedOut = () => {
        this.setState({ authenticated: false }, () => {
            //console.log('Context authenticated: ', this.state.authenticated);
        });
    } 

    isAuthenticated = token => {
        //console.log('isAuthenticated() token: ', token);
        if(token) {
            return true;
        }  
    } 

    setAccessLevel = accessLevel => {
        this.setState({ accessLevel }, () => {
            accessLevel = JSON.stringify(this.state.accessLevel);
            localStorage.setItem('accessLevel', accessLevel);
        });
    }

    setRoleName = roleName => {
        this.setState({ roleName }, () => {
            roleName = JSON.stringify(this.state.roleName);
            localStorage.setItem('roleName', roleName);
        });
    }

    setTestCompleted = testCompleted => {
        this.setState({ testCompleted }, () => {
            testCompleted = JSON.stringify(this.state.testCompleted);
            localStorage.setItem('testCompleted', testCompleted);
        });
    }

    setUserEmail = userEmail => {
        this.setState({ userEmail }, () => {
            userEmail = JSON.stringify(this.state.userEmail);
            localStorage.setItem('userEmail', userEmail);
        });
    }

    setUserId = userId => {
        this.setState({ userId }, () => {
            userId = JSON.stringify(this.state.userId);
            localStorage.setItem('userId', userId);
        });
    }
    
    setCompanyName = type => {
        this.setState({ companyName: type }, () => {
            let companyName = JSON.stringify(this.state.companyName);
            localStorage.setItem("companyName", companyName);
        });
    }

    setTestType = type => {
        this.setState({ testType: type }, () => {
            let testType = JSON.stringify(this.state.testType);
            localStorage.setItem("testType", testType);
        });
    }

    setSampleType = type => {
        this.setState({ sampleType: type }, () => {
            let sampleType = JSON.stringify(this.state.sampleType);
            localStorage.setItem("sampleType", sampleType);
        });
    }

    clearNames = () => {
        this.setState({
            projectName: '',
            testName: ''
        });
    }

    setProjectName = (name) => {
        this.setState({ projectName: name }, () => {
            localStorage.setItem("projectName", name);
        });
    }

    setProjectFromProjectPortal = (value) => {
        this.setState({ projectFromProjectPortal: value }, () => {
            localStorage.setItem("projectFromProjectPortal", value);
        })
    }

    render() {
        return (
            <Context.Provider value={{
                state: this.state,
                setOnProject: this.setOnProject,
                setNotOnProject: this.setNotOnProject,
                setOnProjectDetails: this.setOnProjectDetails,
                setNotOnProjectDetails: this.setNotOnProjectDetails,
                setTypeName: this.setTypeName,
                setProjectNameVisible: this.setProjectNameVisible,
                setTypeId: this.setTypeId,
                removeOnProject: this.removeOnProject,
                setLoggedIn: this.setLoggedIn,
                setLoggedOut: this.setLoggedOut,
                isAuthenticated: this.isAuthenticated,
                setAccessLevel: this.setAccessLevel,
                setRoleName: this.setRoleName,
                setCompanyName: this.setCompanyName,
                setTestCompleted: this.setTestCompleted,
                setUserEmail: this.setUserEmail,
                setUserId: this.setUserId,
                clearNames: this.clearNames,
                setTestType: this.setTestType,
                setProjectName: this.setProjectName,
                setSampleType: this.setSampleType,
                setProjectFromProjectPortal: this.setProjectFromProjectPortal
            }}>
                {this.props.children}
            </Context.Provider>
        )
    }
}
