import axios from 'axios';
import config from '../config.js';

const BASEURL = config.baseURL + '/tests';

const tests = {
    addTest: async (testToBeAdded) => {
        const response = await axios.post(BASEURL + '/create', testToBeAdded);
        return response;
    },
    getAllTests: async (projectId) => {
        const response = await axios.get(BASEURL + '?projectId=' + projectId, {});
        return response;
    },
    getOneTest: async (testId) => {
        const response = await axios.get(BASEURL + '/' + testId);
        return response;
    },

    getTestDetails: async (testId, responseHandler) => {
        const response = await axios.get(BASEURL + '/details/' + testId)
            .then(res => {
                if (responseHandler !== undefined && typeof responseHandler === 'function') {
                    responseHandler(res);
                }
            });
        return response;
    },

    updateOneTest: async (testId, data) => {
        const response = await axios.put(BASEURL + '/' + testId, data);
        return response;
    },
    removeOneTest: async (testId) => {
        const response = await axios.delete(BASEURL + '/' + testId, {});
        return response;
    },
    setSampleRate: async (data) => {
        const response = await axios.put(BASEURL + '/samplerate', data);
        return response;
    },
    getProjectConfigurationData: async (projectId) => {
        return await axios.get(
          BASEURL + "/forProjectConfiguration/" + projectId
        );
    },
    getDisplayNumbersProject: async (projectId) => {
        return await axios.get(
          BASEURL + `/displayNumbers?projectId=${projectId}&type=project`,
        );
    },
};


export default tests;
