import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CustomCheckbox } from '../CustomCheckbox/CustomCheckbox';
import { equipment } from '../../data/MaterialsChecklistData';
import TextField from '@material-ui/core/TextField';
import { ChecklistTableHeader } from '../../components/ChecklistTableHeader/ChecklistTableHeader';
import './MaterialsChecklistTable.css';

const useStyles = makeStyles({
    tableHeader: {
        backgroundColor: '#F3F3F3',
        fontFamily: 'Gotham'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '20px',
        textAlign: 'center'
        },
    cell: {
        fontFamily: 'Gotham',
        fontSize: '16px'
    }
});

export const MaterialsChecklistTable = () => {
  const classes = useStyles();
  const [ tags, setTags ] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']);
  const [ initals, setInitals ] = useState(['', '', '', '', '', '', '', '', '', '']);

    return (
        <>
            <TableContainer component={Paper}>
                <div className="materials-table-wrapper">
                    <Table aria-label="materials-table">
                        <TableHead className={classes.tableHeader}>
                            <TableRow>
                                <TableCell className={classes.headerCell}>Materials</TableCell>
                                <TableCell className={classes.headerCell}>Yes/No</TableCell>
                                <TableCell className={classes.headerCell}>Initials</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Test Plan`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Floorplan(s)`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Safety Data Sheets (SDS)`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Sample and Origin Points`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Origin Point Labels with Tag Id`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Sample Point Labels with Sampling ID`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Origin and Sample Point Location Markers (Balloons, Cups, Flags, etc.)`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Eppendorf Tubes for Filters`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Ziplock Plastic Bags for Samples (Small for Filters)`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Disposable Gloves (Medium and Large)`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Disposable Arm Sleeves`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Garbage Bags`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Pens, Markers`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Tape`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Tape Measure`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <div className="materials-table-second-header">
                    <ChecklistTableHeader type={`Materials and Equipment`} />
                </div>
                <div className="materials-table-wrapper">
                    <Table aria-label="materials-table">
                        
                        <TableHead className={classes.tableHeader}>
                            <TableRow>
                                <TableCell className={classes.headerCell}>Materials</TableCell>
                                <TableCell className={classes.headerCell}>Yes/No</TableCell>
                                <TableCell className={classes.headerCell}>Initials</TableCell>
                            </TableRow>
                        </TableHead>
                        
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Tweezers`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Swab and/or Cuvette Holder(s)`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Bleach Wipes`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Paper Towels`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Extra Labels`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`Deionized or Distilled Water`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    {`HVAC Layout(s) with Zone Location(s), Supply and Return Designations`}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <Table>
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell className={classes.headerCell}>Equipment</TableCell>
                            <TableCell className={classes.headerCell}>Yes/No</TableCell>
                            <TableCell className={classes.headerCell}>Initials</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                    <div className="tags-container text">Tags: 
                                        {tags.map((tag, i) => (
                                            <div className="tags-container" key={`${tag}-${i}`}>
                                                <div className="text">{tag}</div>
                                                <div>
                                                    <CustomCheckbox />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <div className='materials-table-checkbox-container'>
                                    <div className='materials-table-checkbox-container-cell'>
                                        <div className="text">Yes</div>
                                        <div>
                                            <CustomCheckbox />
                                        </div>
                                    </div>
                                    <div className='materials-table-checkbox-container-cell'>
                                        <div className="text">No</div>
                                        <div>
                                            <CustomCheckbox />
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableBody>
                    <TableBody>
                        {equipment.map((rowName, i) => (
                            <TableRow key={`${rowName}-${i}`}>
                                <TableCell className={classes.cell}>
                                    {rowName}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <div className='materials-table-checkbox-container'>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">Yes</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                        <div className='materials-table-checkbox-container-cell'>
                                            <div className="text">No</div>
                                            <div>
                                                <CustomCheckbox />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div className="materials-table-third-header">
                    <ChecklistTableHeader type={`Materials and Equipment`} />
                </div>
                <Table>
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell className={classes.headerCell}>Other</TableCell>
                            <TableCell className={classes.headerCell}>Initials</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {initals.map((rowName, i) => (
                            <TableRow key={`${rowName}-${i}`}>
                                <TableCell className={classes.cell}>{i+1}</TableCell>
                                <TableCell className={classes.cell}></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <footer>
                <div className="footer-cell">
                    <div className="text">Reviewed By:</div>
                </div>
                <div>
                    <TextField />
                </div>
                <div className="footer-cell">
                    <div className="text">Date:</div>
                </div>
                <div>
                    <TextField />
                </div>
            </footer>
        </>
    );
}