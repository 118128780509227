import React                from 'react';
import Typography           from "@material-ui/core/Typography";
import { ProjectTagsTable } from "./ProjectTagsTable";
import './ProjectDetails.css';

export const ProjectTags = ({projectId, projectTags, fetchUpdatedProject}) => {

    // If at least one origin point has tags then tags block is visible
    const isTagsVisible = () => {
        const items = [];
        Object.keys(projectTags).forEach((key) => {
            if (projectTags[key].tags.length === 0) {
                return;
            }
            items.push(projectTags[key]);
        });
        return (items.length === 0);
    }

    // Return nothing if there is no tags or now visible tags
    if (Object.keys(projectTags).length === 0 || isTagsVisible()) {
        return ('');
    }

    return (
        <div>
            <hr className="project-details-hr" />
            <div className="grid-wrapper">
                <div>
                    <Typography variant="h5" color="textPrimary">
                        Tags
                    </Typography>
                </div>
            </div>
            <div className="grid-wrapper">
                <div style={{display: "flex"}}>
                    <ProjectTagsTable
                        filterTags=""
                        projectId={projectId}
                        projectTags={projectTags}
                        fetchUpdatedProject={fetchUpdatedProject}
                    />
                </div>
            </div>
        </div>
    );
};
