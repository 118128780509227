import axios from 'axios';
import config from '../config.js';

const BASEURL = config.baseURL + '/originpoints';

const originpoints = {
  createOriginpoints : async(data) => {
    const response = await axios.post (BASEURL + '/create', data);
    return response;
  },
  getAllOriginpoints : async(testId, projectId) => {
    if(testId !== null) {
      const response = await axios.get (BASEURL + '?testId=' + testId , {});
      return response;
    } else {
      const response = await axios.get (BASEURL + '?projectId=' + projectId , {});
      return response;
    }
  },
  getOriginpoints : async(segmentId) => {
    const response = await axios.get (BASEURL + '/' + segmentId , {});
    return response;
  },
  setOriginPoint : async(data) => {
    const response = await axios.put (BASEURL, data);
    return response;
  },
  getLabels : async(testId, projectId) => {
    if(testId !== null) {
      const response = await axios.get (BASEURL + '/labels?testId=' + testId , {});
      return response;
    } else {
      const response = await axios.get (BASEURL + '/labels?projectId=' + projectId , {});
      return response;
    }
  },
  setOpTag : async(data) => {
    const response = await axios.put (BASEURL + '/tag', data);
    return response;
  },
  setTagLotNumber : async(data) => {
    const response = await axios.put (BASEURL + '/taglotnumber', data);
    return response;
  }
};


export default originpoints;