//  TODO: get process.env.REACT_APP_STAGE and define baseURL based on stage environment once domain name is created in platform

const env = process.env.REACT_APP_STAGE;

const getBaseURL = (env) => {

  console.log(env);

  if (env === "local") {
    return "http://localhost:3000/dev/v1";
  } else if (env === "dev") {
    //return "https://api.dev.veridart.thesafetraces.com";
    return "https://api.dev.veridart.ivinco.com";
  } else if (env === "test") {
    return "https://api.test.veridart.thesafetraces.com";
  } else if (env === "prod") {
    return "https://api.prod.veridart.thesafetraces.com";
  }
};

export const testTypesConfig = {
  generalSurvey: 'General Survey',
  verification: 'Verification',
  dilution: 'Dilution',
  miniSurvey: 'Mini Survey',
  recirculation: 'Recirculation',
  dilutionSmall: 'Dilution - Small',
  dilutionLarge: 'Dilution - Large',
};

export const VERIFICATION_LIKE_TESTS = ['verification', 'dilutionSmall', 'dilutionLarge'];

const config = {
  baseURL: getBaseURL(env),
};

export default config;

// aws dev: https://4mq9wz3gal.execute-api.us-west-2.amazonaws.com/development
// local: http://localhost:3000/dev
// aws test: https://9010irwm6i.execute-api.us-west-2.amazonaws.com/test
