
import axios from 'axios';
import config from '../config.js';

const BASEURL = config.baseURL + '/segments';

const segments = {
  createSegments : async(data) => {
    const response = await axios.post (BASEURL + '/create', data);
    return response;
  },
  getSegments : async(testId, projectId) => {
    if(testId !== null) {
      const response = await axios.get (BASEURL + '?testId=' + testId);
      return response;
    } else {
      const response = await axios.get (BASEURL + '?projectId=' + projectId,null, {params: {projectId}} );
      return response;
    }
  },
  updateSegment : async(segmentId, segmentToBeUpdated) => {
    const response = await axios.put (BASEURL + '/' + parseInt(segmentId), segmentToBeUpdated);
    return response;
  },
  updateSamples: async(data) => {
    const response = await axios.put (BASEURL + '/samples', data);
    return response;
  }
};


export default segments;