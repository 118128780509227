import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import users from '../../api/users';
import Pagination from '@material-ui/lab/Pagination';
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField';
import './TableComponent.css';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    /*
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    */
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'center',
        padding: '10px !important'
    },
    cell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        textAlign: 'center',
        borderRight: 'none',
        borderLeft: 'none'
    },
    paper: {
        height: 700,
        width: '100%',
        //marginTop: theme.spacing.unit * 3,
        marginTop: theme.spacing(3),
        overflow: 'auto',
      },
    wrapper: {
        width: '100%',
        marginBottom: 15
    },
    insideWrapper: {
        display: 'flex'
    },
    searchFieldWrapper: {
        flexGrow: 1,
        mr: 2
    },
    searchField: {
        marginLeft: '5%',
        width: '25%'
    }
}));

export const TableComponent = ({ props, headers, cellData, type, updateUserAccessLevel }) => {
    
    const Admin = 'Admin';
    const Editor = 'Editor';
    const Viewer = 'Viewer';
    let numberOfPages = 0;
    const classes = useStyles();
    const currentUserId = JSON.parse(localStorage.getItem("userId"));
    let [ search, setSearch ] = useState('');
    let [ page, setPage ] = useState(1);
    let [ selectedUser, setSelectedUser ] = useState({});
    let [ modalOpen, setModalOpen ] = useState(false);
    let [ open, setOpen ] = useState(false);
    let [ message, setMessage ] = useState(null);
    let [ err, setErr ] = useState(null);
    let [ itemsPerPage, setItemsPerPage ] = useState(10);

    /* 
    Table Row Logic
    - created rowRefs array
    - dynamically generated ref for each table row, using index as unique identifier, which are stored in rowRefs array
    - pass index to handleChange
    - traverse DOM and get all table row children (table cells) for that particular row via index; children returns HTMLCollection
    - iterate HTMLCollection and store values (innerHTML) for each row
    */

    const rowRefs= useRef([]);

    const topicOptions = [
        {id: 1, name: 'Admin', label: 'Admin'},
        {id: 2, name: 'Editor', label: 'Editor'},
        {id: 3, name: 'Viewer', label: 'Viewer'}
    ]

    const handleClick = (data) => {
        setSelectedUser(data);
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const isValidResponse = (response) => {
        return (
            response &&
            response.status === 200 &&
            response.data !== null &&
            response.data.success === true &&
            response.data.data !== undefined
        );
    }

    const deleteUser = async (id) => {
        const removeUser = await users.removeOneUser(id);
        if (isValidResponse(removeUser)) {
            setMessage('The user has been deleted');
            setOpen(true);
            handleModalClose();
            updateUserAccessLevel();
        } else {
            setErr(removeUser.data.error.message);
            setOpen(true);
            handleModalClose();
        }
    }

    const setUpdatedPermissionLevel = (rowVals, id, permission) => {
        const userId = id;
        const user = {
            companyName: rowVals[2],
            accessLevel:  permission 
        }
        users.updateUserAccessLevel(userId, user)
            .then(response => {
                if(response.data.success) {
                    //If successfully upated, make another api call to get updated table data
                    updateUserAccessLevel();
                }
            });
    }

    const getRowCellValues = (rowRefs, index) => {
        let rowVals = [];

        //This returns an HTMLCollection
        let rowCells = rowRefs.current[index].children;

        for(let value of rowCells) {
            rowVals.push(value.innerHTML);
        }
        //remove last element which is select dropdown
        rowVals.pop();
        return rowVals;
    }
    
    const handleChange = (e, index, cellDataID) => {
        let rowIndex = index;

        //get cell values for row
        let rowValues = getRowCellValues(rowRefs, rowIndex);

        //get updated permission
        let permission = e.target.value;

        //Send updated user info to endpoint
        setUpdatedPermissionLevel(rowValues, cellDataID, permission)
    }

    const handlePageChange = (e, value) => {
        setPage(page = value);
    };

    const handleSearch = (search) => {
        setSearch(search)
    }

    const filterItem = (item, search) => {
        if (!search) {
            return true;
          }
          let searchString = '';
          Object.keys(item).forEach((value) => {
            searchString +=
              ' ' + (typeof item[value] === 'string' ? item[value].toLowerCase() : '');
          });
          return searchString.indexOf(search.toLowerCase()) !== -1;
    }

    const setNumberOfPages = (cellData, itemsPerPg) => {
        numberOfPages = Math.ceil(cellData.length / itemsPerPg);
    }

    if(cellData) {
        setNumberOfPages(cellData, itemsPerPage);
    }

    return (
        <TableContainer className={classes.paper} component={Paper}>
            <div className="qc-table-wrapper">
                <>
                    <div className={classes.wrapper}>
                        <Box className={classes.insideWrapper}>
                            <Box className={classes.searchFieldWrapper}>
                                <TextField
                                    variant="standard"
                                    label={'Search'}
                                    name="filter"
                                    className={classes.searchField}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </Box>
                        </Box>
                    </div>
                </>
                <Table aria-label="qc-table">
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            {headers.map((header, i) => (
                                <TableCell key={`header-${i}`} className={classes.headerCell}>{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {type === 'users' && (
                        <TableBody>
                            {cellData
                            .filter((item) => filterItem(item, search))
                            .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                            .map((cell, i) => (
                                <TableRow ref={el => (rowRefs.current[i] = el)} children key={`key=${i}`}>
                                    <TableCell size="small" className={classes.cell}>
                                        {cell.firstname}
                                    </TableCell>
                                    <TableCell size="small" className={classes.cell}>
                                        {cell.lastname}
                                    </TableCell>
                                    <TableCell size="small" className={classes.cell}>
                                        {cell.companyname}
                                    </TableCell>
                                    <TableCell size="small" className={classes.cell}>
                                        {cell.email}
                                    </TableCell>
                                    <TableCell size="small" className={classes.cell}>
                                        <FormControl className={classes.formControl}>
                                            <Select
                                                disableUnderline
                                                style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }}
                                                value={cell.accesslevel}
                                                onChange={e => handleChange(e, i, cell.userid)}
                                            >
                                                <MenuItem style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={`${Admin}`}>Admin</MenuItem>
                                                <MenuItem style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={`${Editor}`}>Editor</MenuItem>
                                                <MenuItem style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={`${Viewer}`}>Viewer</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    {cell.userid === currentUserId ? (
                                        <TableCell size="small" className={classes.cell}>
                                            <Tooltip title="You cannot delete your own user account">
                                                <span>
                                                    <Button variant="contained" disabled>
                                                        Delete
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                    ) : (
                                        <TableCell size="small" className={classes.cell}>
                                            <Button variant="contained" onClick={() => handleClick(cell)}>
                                                Delete
                                            </Button>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    )}                    
                </Table>
            </div>
            <section className="labels-pagination-wrapper">
                <Pagination
                    count={numberOfPages}
                    page={page}
                    defaultPage={1}
                    onChange={handlePageChange}
                    color="standard"
                    size="medium"
                    showFirstButton
                    showLastButton
                />
            </section>
            <Dialog open={modalOpen} onClose={handleModalClose}>
                <DialogTitle sx={{ textAlign: 'center' }}>
                    Are you sure you want to delete {selectedUser.firstname}?
                </DialogTitle>
                <DialogActions>
                    <Button variant="outlined" onClick={handleModalClose}>
                        No
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => deleteUser(selectedUser.userid)}
                        color="primary"
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {err === null ? (
                    <Alert onClose={handleClose} severity="success" variant="filled">
                        {message}
                    </Alert>
                ) : (
                    <Alert onClose={handleClose} severity="error" variant="filled">
                        {err}
                    </Alert>
                )}
            </Snackbar>
        </TableContainer>
    );
}