import React from 'react';

const darkCell = {
  background: "rgb(0, 137, 150)",
  color: "white",
  padding: "5px",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: "smaller",
};

const lightCell = {
  background: "white",
  borderRight: "1px solid gray",
  color: "black",
  padding: "5px",
  textAlign: "center",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: "smaller",
};

const ScenarioCountTable = ({ scenarioSummary, sampleCount, test, SPs }) => {
  return (
    <table
      style={{
        border: "1px solid gray",
        borderRight: "none",
        width: "99.5%",
        borderSpacing: 0,
      }}
    >
      <thead>
        <tr>
          <th style={darkCell} width="10%">
            Scenario Count
          </th>
          <th style={darkCell} width="10%">
            Interval Count
          </th>
          <th style={darkCell} width="10%">
            SP Count
          </th>
          <th style={darkCell} width="10%">
            Sample count
          </th>
          <th style={darkCell} width="10%">
            Interval Duration
          </th>
          <th style={darkCell} width="50%">
            Notes
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={lightCell} width="10%">
            {scenarioSummary?.scenarios?.length ?? "0"}
          </td>
          <td style={lightCell} width="10%">
            {test.intervalCount ?? "0"}
          </td>
          <td style={lightCell} width="10%">
            {SPs?.length ?? "0"}
          </td>
          <td style={lightCell} width="10%">
            {sampleCount ?? "0"}
          </td>
          <td style={lightCell} width="10%">
            {test.intervalDuration ?? "0"}
          </td>
          <td style={lightCell} width="50%"></td>
        </tr>
      </tbody>
    </table>
  );
};

export default ScenarioCountTable;
