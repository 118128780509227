import React from '../../../node_modules/react';
import Button from '@material-ui/core/Button';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

class DeleteFPCellRenderer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accessLevel: null,
            testCompleted: null
        };
    }

    btnClickedHandler = (e, fpId) => {
        this.props.agGridReact.props.deleteFloorPlans(fpId);
    }

    render() {
        let { testCompleted, accessLevel, floorPlanId } = this.state;
        return (
            <IconButton disabled={this.props.agGridReact.props.testCompleted || this.props.agGridReact.props.accessLevel === 'Viewer'} onClick={e => this.btnClickedHandler(e, this.props.value)} aria-label="delete-floor-plan">
                <DeleteForeverIcon />
            </IconButton>
        )
      }
  }

export default DeleteFPCellRenderer;