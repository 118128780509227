class ProjectDataValidator {
    constructor(dataModel, minimumRequiredStringLength) {
        this.dataModel = dataModel;
        this.minimumRequiredStringLength = minimumRequiredStringLength !== undefined &&
            minimumRequiredStringLength >= 0 ? minimumRequiredStringLength : 8;

        this.testTypeConstraints = [
            {
                testType: 'generalSurvey',
                scenarioCount: [1],
                intervalCount: [1],
                originPointCount: [8, 16],
                samplePointCount: [12, 24],
                intervalDuration: [30],
                sampleCollectionMethods: ['filter', 'swab'],
                sampleCollectionMethodCount: { minimum: 1, maximum: 2 },
                isPlaceholderScenarioOk: true,
            },
            {
                testType: 'miniSurvey',
                scenarioCount: [1],
                intervalCount: [1],
                originPointCount: [1],
                samplePointCount: [4],
                intervalDuration: [30],
                sampleCollectionMethods: ['filter'],
                sampleCollectionMethodCount: { minimum: 1, maximum: 1 },
                isPlaceholderScenarioOk: true,
            },

            {
                testType: 'dilution',
                scenarioCount: [1, 2, 3, 4, 5],
                intervalCount: [1, 2, 3, 4],
                originPointCount: [1],
                samplePointCount: [1, 4],
                intervalDuration: [1, 2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15],
                sampleCollectionMethods: ['filter'],
                sampleCollectionMethodCount: { minimum: 1, maximum: 1 },
                isPlaceholderScenarioOk: false,
            },
            {
                testType: 'recirculation',
                scenarioCount: [1, 2, 3, 4, 5],
                intervalCount: [1],
                originPointCount: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                samplePointCount: [1, 2, 3, 4],
                intervalDuration: [30],
                sampleCollectionMethods: ['filter'],
                sampleCollectionMethodCount: { minimum: 1, maximum: 1 },
                isPlaceholderScenarioOk: false,
            },
            {
                testType: 'verification',
                scenarioCount: [1, 2, 3, 4, 5],
                intervalCount: [4],
                originPointCount: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                samplePointCount: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                intervalDuration: ['custom_ver'],
                sampleCollectionMethods: ['filter'],
                sampleCollectionMethodCount: { minimum: 1, maximum: 1 },
                isPlaceholderScenarioOk: false,
            },
            {
                testType: 'dilutionSmall',
                scenarioCount: [1, 2, 3, 4, 5],
                intervalCount: [4],
                originPointCount: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                samplePointCount: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                intervalDuration: ['custom_ver'],
                sampleCollectionMethods: ['filter'],
                sampleCollectionMethodCount: { minimum: 1, maximum: 1 },
                isPlaceholderScenarioOk: false,
            },
            {
                testType: 'dilutionLarge',
                scenarioCount: [1, 2, 3, 4, 5],
                intervalCount: [4],
                originPointCount: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                samplePointCount: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                intervalDuration: ['custom_ver'],
                sampleCollectionMethods: ['filter'],
                sampleCollectionMethodCount: { minimum: 1, maximum: 1 },
                isPlaceholderScenarioOk: false,
            }
        ];
        this.issues = [];
    }

    validate() {

        this.validateProject();
        console.log(this.issues);
        return this.issues;
    }

    validateProject() {
        this.validateDataAccess();
        console.log(this.issues);

        let project = this.dataModel.project;

        // project exists

        if (project === null) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                description: 'The project is not defined or accessable',
            });
            return;
        }

        /*
        // name - Required Text field
        const nameFieldIssue = this.validateRequiredTextField(project.name);
        if (nameFieldIssue !== null) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                description: 'project name: ' + nameFieldIssue,
            });
        }
        */
        /*
        // address - Required Text feld
        const addressFieldIssue = this.validateRequiredTextField(project.address);
        if (addressFieldIssue !== null) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                description: 'project address: ' + addressFieldIssue,
            });
        }
        */

        /*
        // description - Required Text field
        const descriptionFieldIssue = this.validateRequiredTextField(project.description);
        if (addressFieldIssue !== null) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                description: 'project description: ' + descriptionFieldIssue,
            });
        }
        */
        /*
        // customer - Require Text field
        const customerFieldIssue = this.validateRequiredTextField(project.customer);
        if (customerFieldIssue !== null) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                description: 'project customer: ' + customerFieldIssue,
            });
        }
        */
        // partner - ignore for now

        // Tests - has at least 1 test
        if (this.dataModel.tests.length < 1) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                description: 'There are no Tests defined for this project.',
            });
            return;
        } else {

            this.dataModel.tests.map((test) => {
                this.validateTest(test);
            });

        }
    }


    validateDataAccess() {
        if (this.dataModel.dataErrors.length > 0) {
            this.dataModel.dataErrors.map((issue) => {
                if (issue.response.message !== undefined) {
                    this.issues.push({
                        projectId: this.dataModel.projectId,
                        description: issue.msg + ' ' + issue.response.message,

                    });
                } else {
                    this.issues.push({
                        projectId: this.dataModel.projectId,
                        description: issue.msg + ' ' + issue.response.statusText,
                    });
                }
            });
        }
    }

    validateTest(test) {


        /* Ignore this test until the user has the ability to fix it
        // name - required Text field
        const nameFieldIssue = this.validateRequiredTextField(test.name);
        if (nameFieldIssue !== null) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: 'test name: ' + nameFieldIssue,
            });
        }
        */

        /* Ignore this test until the user has the ability to fix it
        // goal
        const goalFieldIssue = this.validateRequiredTextField(test.goal);
        if (goalFieldIssue !== null) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: 'test goal: ' + goalFieldIssue,
            });
        }
        */

        /* Ignore this test until the user has the ability to fix it
        // overview
        const overviewFieldIssue = this.validateRequiredTextField(test.overview);
        if (overviewFieldIssue !== null) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: 'test overview: ' + overviewFieldIssue,
            });
        }
        */


        // floor plan
        //const floorPlanFieldIssue = this.validateRequiredTextField(test.floorplan);
        if (test.floorplan == null || test.floorplan.trim().length < 1) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: 'A Floor Plan has not been selected.',
            });
        }
        // test date
        const testDateIssue = this.validateRequiredTextField(test.testDate);
        if (testDateIssue != null) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: 'The Test/Survey date is invalid.',
            });
        }


        const constraintIssues = this.validateTestConstraints(test);
        constraintIssues.map((issue) => {
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: issue,
            });
        });

        const scenarios = this.dataModel.scenarios.filter(s => s.testId === test.id);

        scenarios.map((scenario) => {
            // description
            const descriptionFieldIssue = this.validateRequiredTextField(scenario.description);
            if (descriptionFieldIssue !== null && scenarios.length > 1) {
                this.issues.push({
                    projectId: this.dataModel.projectId,
                    testId: test.id,
                    scenarioId: scenario.id,
                    description: 'The Description for scenario ' + scenario.sequenceNumber + ' ' + descriptionFieldIssue,
                });
            }
        });

        // validate ops

        const originPoints = this.dataModel.originPoints.filter(op => op.testId == test.id);

        // count

        if (originPoints.length !== test.opCount) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: 'The number of Origin Points defined for this test, ' + test.opCount + ' does not match the Origin Points found ' + originPoints.length,
            });
        }
        // name

        let opNotMapped = 0;
        let opNotNamed = 0;
        let scenariosMissingTags = 0;
        let scenariosMissingTagLot = 0;
        let scenariosMissingTagExp = 0;
        originPoints.map((op) => {
            // placed on floorplan

            if (op.x === null || op.y === null) {
                opNotMapped++;
            }

            if (op.name === null || op.name.trim().length < 1) {
                opNotNamed++;
            }

            scenariosMissingTags += scenarios.length - op.tags.length;
            /*

            if (op.tags.length !== scenarios.length) {
                scenariosMissingTags++;
            }*/
            op.tags.map((tag) => {
                if (tag.lotNumber === null || tag.lotNumber.trim().length < 1) {
                    scenariosMissingTagLot++;
                }

                if (tag.expirationDate === null || tag.expirationDate.trim().length < 1 || tag.expirationDate.trim().toLowerCase() === 'null') {
                    scenariosMissingTagExp++;
                }
            });
        });

        if (opNotNamed > 0) {
            const text = opNotNamed === 1 ? " Origin Point hasn't been named." : " Origin Points haven't been named.";
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: opNotNamed + text,
            });
        }



        if (opNotMapped > 0) {
            const text = opNotMapped === 1 ? " Origin Point hasn't been poistioned on the Floor Plan." : " Origin Points haven't been poistioned on the Floor Plan.";
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: opNotMapped + text,
            });

        }
        if (scenariosMissingTags > 0) {
            const text = scenariosMissingTags === 1 ? " Scenario Origin Point hasn't been assigned a Tag" : " Scenario Origin Points haven't been assigned a Tag.";

            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: scenariosMissingTags + text,
            });
        }
        if (scenariosMissingTagLot > 0) {
            const text = scenariosMissingTagLot === 1 ? " Scenario Origin Point hasn't been assigned a Tag Lot." : " Scenario Origin Points haven't been assigned a Tag Lot.";
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: scenariosMissingTagLot + text,
            });
        }
        if (scenariosMissingTagExp > 0) {
            const text = scenariosMissingTagExp === 1 ? " Scenario Origin Point hasn't been assigned a Tag Expiration" : " Scenario Origin Points haven't been assigned a Tag Expiration.";

            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: scenariosMissingTagExp + text,
            });

        }




        // validate sps

        const samplePoints = this.dataModel.samplePoints.filter(sp => sp.testId === test.id);


        // count

        if (samplePoints.length !== test.spCount) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: 'The number of Sample Points defined for this test, ' + test.spCount + ' does not match the Sample Points found ' + samplePoints.length,
            });
        }
        // name

        let spNotMapped = 0;
        let spNotNamed = 0;
        samplePoints.map((sp) => {
            // placed on floorplan

            if (sp.x === null || sp.y === null) {
                spNotMapped++;
            }

            if (sp.name === null || sp.name.trim().length < 1) {
                spNotNamed++;
            }

        });
        if (spNotNamed > 0) {
            const text = spNotNamed === 1 ? " Sample Point hasn't been named." : " Sample Points haven't been named.";
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: spNotNamed + text,
            });
        }
        if (spNotMapped > 0) {
            const text = spNotMapped === 1 ? " Sample Point hasn't been poistioned on the Floor Plan." : " Sample Points haven't been poistioned on the Floor Plan.";
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: spNotMapped + text,
            });

        }
        // validate samples

        const samples = this.dataModel.samples.filter(s => s.testId === test.id);

        // count
        const expectedSampleCount = test.spCount * scenarios.length * test.intervalCount * test.collectionMethods.length;
        if (samples.length < 1) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: 'Samples have not been generated.',
            });
        } else if (samples.length !== expectedSampleCount) {
            this.issues.push({
                projectId: this.dataModel.projectId,
                testId: test.id,
                description: 'The number of Samples defined for this test, ' + expectedSampleCount + ' does not match the Sample Points found ' + samples.length,
            });
        }

        // validate sample plan
        let testPlanWarningFound = false;
        samples.map((sample) => {
            if (testPlanWarningFound) {
                return;
            }

            if (this.dataModel.sampleCollectors.filter(s => s.sampleNumber === sample.number).length !== 1) {
                testPlanWarningFound = true;
                this.issues.push({
                    projectId: this.dataModel.projectId,
                    testId: test.id,
                    description: 'The Test Plan has not been generated or needs to be regenerated.',
                });
            }
        });



    }

    validateTestConstraints(test) {
        let issues = [];
        const constraints = this.testTypeConstraints.filter(c => test.type === c.testType);
        if (constraints < 1) {
            return ['testType, ' + test.type + ' is not supported.  Please contact SafeTraces Support.'];
        } else {
            const constraint = constraints[0];
            const scenarioCount = this.dataModel.scenarios.filter(s => s.testId === test.id).length;
            if (!constraint.scenarioCount.includes(scenarioCount)) {
                issues.push('scenario count, ' + scenarioCount + ', is not valid for test type ' + test.type);
            }
            if (constraint.intervalCount.length > 1 && !constraint.intervalCount.includes(test.intervalCount)) {
                if (test.intervalCount === null) {
                    issues.push('The Interval Count has not been defined.');
                } else {
                    issues.push('interval count, ' + test.intervalCount + ', is not valid for test type ' + test.type);
                }

            }
            if (!constraint.originPointCount.includes(test.opCount)) {
                if (test.opCount < 1) {
                    issues.push('The Origin Point count has not been defined.');
                } else {
                    issues.push('The origin point count, ' + test.opCount + ', is not valid for test type ' + test.type);
                }

            }
            if (!constraint.samplePointCount.includes(test.spCount)) {
                if (test.spCount < 1) {
                    issues.push('The Sample Point count has not been defined.');
                } else {
                    issues.push('The sample point count, ' + test.spCount + ', is not valid for test type ' + test.type);

                }
            }
            if (constraint.intervalDuration.length > 1 && !constraint.intervalDuration.includes(test.intervalDuration)) {

                if (test.intervalDuration === null) {
                    issues.push('The Interval Duration has not been defined.');
                } else {
                    issues.push('The Interval Duration , ' + test.intervalDuration + ', is not valid for test type ' + test.type);
                }
            }


            if (test.collectionMethods.length < 1 || (test.collectionMethods.length === 1 && test.collectionMethods[0] === 'n/a')) {
                issues.push('The Sample Collection Method has not been defined.');
            } else {
                test.collectionMethods.map((method) => {
                    if (!constraint.sampleCollectionMethods.includes(method)) {
                        issues.push('The Sample Collection Method, ' + method + ', is not valid for test type ' + test.type);
                    }
                });
            }


            if (constraint.sampleCollectionMethodCount < test.collectionMethods.length) {
                issues.push('there are too many sample collection methods, ' + test.collectionMethods.length + ' for test type ' + test.type);
            } else if (test.collectionMethods.length < 1) {
                issues.push('there needs to be at least 1 sample collection method for test type ' + test.type);
            }

            if (test.collectionMethods.includes('filter')) {
                if (test.airSamplerRate === null) {
                    issues.push('The air Sampler Rate is not defined.');
                } else if (test.airSamplerRate.trim().length < 1) {
                    issues.push('The air Sampler Rate is not defined.');
                } else if (isNaN(test.airSamplerRate)) {
                    issues.push('The air Sampler Rate is not a valid number. It should only include characters in 0 to 9 and the decimal point.');
                }

            }

            const placeHolderScenarios = this.dataModel.scenarios.filter(s => s.testId === test.id && s.id === null);
            if (placeHolderScenarios.length > 0 && constraint.isPlaceholderScenarioOk === false) {
                issues.push("Scenarios have not been defined.");
            }
        }
        return issues;
    }




    validateRequiredTextField(strValue) {
        switch (strValue) {
            case undefined:
                return "field is undefined.  Please contact SafeTraces Support.";
                break;
            case null:
                return "has not been defined.";
                break;
            default:
                if (strValue.trim().length < this.minimumRequiredStringLength) {
                    return strValue + " is too short, it should be at least " + this.minimumRequiredStringLength + " charaters long.";
                } else {
                    return null;
                }
        }
    }


}

export default ProjectDataValidator;
