import React from "react";
import TextField from "@material-ui/core/TextField";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";

import { useForm } from "react-hook-form";

const AreaInfoForm = React.forwardRef((props, ref) => {
  const { submitHandler, data, fieldsRules } = props;
  const { register, handleSubmit, errors } = useForm();

  const inputStyle = { fontFamily: "Gotham" };

  return (
    <React.Fragment>
      <Fade in={true}>
        <form ref={ref} onSubmit={handleSubmit(submitHandler)} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                inputRef={register({ required: fieldsRules.region_name })}
                id="region_name"
                label="Region"
                name="region_name"
                autoComplete="region_name"
                defaultValue={data.region_name}
                style={inputStyle}
                error={!!errors.region_name}
                helperText={errors.region_name && "Region is required"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                inputRef={register({ required: fieldsRules.territory_name })}
                id="territory_name"
                label="Territory"
                name="territory_name"
                autoComplete="territory_name"
                defaultValue={data.territory_name}
                style={inputStyle}
                error={!!errors.territory_name}
                helperText={errors.territory_name && "Territory is required"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                inputRef={register({ required: fieldsRules.site_name })}
                id="site_name"
                label="Site"
                name="site_name"
                autoComplete="site_name"
                defaultValue={data.site_name}
                style={inputStyle}
                error={!!errors.site_name}
                helperText={errors.site_name && "Site is required"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                inputRef={register({ required: fieldsRules.building_address })}
                id="building_address"
                label="Building address"
                name="building_address"
                autoComplete="building_address"
                defaultValue={data.building_address}
                style={inputStyle}
                error={!!errors.building_address}
                helperText={
                  errors.building_address && "Building address is required"
                }
              />
            </Grid>
          </Grid>
        </form>
      </Fade>
    </React.Fragment>
  );
});

export default AreaInfoForm;