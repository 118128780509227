import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import samplepoints from '../../api/samplepoints';
import { VERIFICATION_LIKE_TESTS } from '../../config';
import './SamplesTable.css';
import {Alert} from "../Alert/Alert";

const styles = theme => ({
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '20px',
        textAlign: 'center'
    },
    cell: {
        fontFamily: 'Gotham',
        fontSize: '16px',
        textAlign: 'center'
    }
});

class SamplesTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sampleDetails: {},
            topSamplingTimes: [],
            bottomSamplingTimes: [],
            itemsToShow: this.props.testType === 'dilution' ? 4 : 1,
            chunkedArr: [],
            error: null,
        };
    }

    splitArray(myArray, chunk_size){
        var index = 0;
        var arrayLength = myArray.length;
        var tempArray = [];

        for (index = 0; index < arrayLength; index += chunk_size) {
            let myChunk = myArray.slice(index, index+chunk_size);
            tempArray.push(myChunk);
        }
        return tempArray;
    }

    async componentDidMount() {
        //logic to handle highlighting navbar tab
        let segmentsNavButton = document.querySelector('#sBtn')
        if (segmentsNavButton) {
            segmentsNavButton.classList.add('selected');
        }

        let testId = JSON.parse(localStorage.getItem("testId")) ? JSON.parse(localStorage.getItem("testId")) : null;
        let response = await samplepoints.getDetails(testId);

        if (response.data.success) {
            let { itemsToShow } = this.state;
            const { data } = response.data;
            if (!!~VERIFICATION_LIKE_TESTS.indexOf(this.props.testType)) {
                itemsToShow = (data && data.intervalCount) || itemsToShow;
            }

            this.setState({
                sampleDetails: data,
                itemsToShow
            }, () => {
                let spTimes = Object.entries(this.state.sampleDetails.samplingTimes);
                this.setState({ topSamplingTimes: spTimes });
                let tSpTimes = this.state.sampleDetails.samples.reduce((samplesArr, obj) => {
                    if (obj.samplesAtInterval) {
                        let intervalSampleTimes = Object.values(obj.samplesAtInterval);
                        samplesArr = samplesArr.concat(intervalSampleTimes);
                    }
                    return samplesArr;
                }, []);

                let chunkedArr = this.splitArray(tSpTimes, this.state.itemsToShow);
                let error = null;
                if (chunkedArr.length !== data?.samples?.length) {
                    error = "Error processing samples data. Please consider re-generating the samples data.";
                } else if (chunkedArr.length === 0) {
                    error = "No samples data found. Consider re-generating the samples data.";
                }
                this.setState({
                    bottomSamplingTimes: tSpTimes,
                    chunkedArr,
                    error,
                });
            });
        }
    }

    componentDidUpdate() {
        let checklistsNavButton = document.querySelector('#sBtn');
        if(checklistsNavButton) {
            checklistsNavButton.classList.add('selected');
        }
    }

    componentWillUnmount() {
        let checklistsNavButton = document.querySelector('#sBtn');
        if(checklistsNavButton) {
            checklistsNavButton.classList.remove('selected');
        }
    }

    render() {
        const { sampleDetails, topSamplingTimes, chunkedArr, error } = this.state;
        const { classes } = this.props;
        let timesLen = chunkedArr[0];

        if(sampleDetails.samples !== undefined && timesLen !== undefined && chunkedArr !== undefined ) {
            return (
                <TableContainer component={Paper}>
                    <Alert msg={error} flag={Boolean(error)} closeFunc={() => { this.setState({error: null}) }} />
                    <div className="qc-table-wrapper">
                        <Table>
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>Airflow L/min</TableCell>
                                    <TableCell className={classes.headerCell}>SP Total</TableCell>
                                    <TableCell className={classes.headerCell}>Sampling Events</TableCell>
                                    <TableCell className={classes.headerCell}>Samples</TableCell>
                                    <TableCell className={classes.headerCell}>Sampling Duration</TableCell>
                                    <TableCell className={classes.headerCell}>Sampling Times</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.cell}>{sampleDetails.airSamplerRate}</TableCell>
                                    <TableCell className={classes.cell}>{sampleDetails.SPTotal}</TableCell>
                                    <TableCell className={classes.cell}>{sampleDetails.intervalCount}</TableCell>
                                    <TableCell className={classes.cell}>{sampleDetails.samplesTotal}</TableCell>
                                    <TableCell className={classes.cell}>{!!~VERIFICATION_LIKE_TESTS.indexOf(this.props.testType) ? 'Custom (5 min)': sampleDetails.intervalDuration}</TableCell>
                                    <TableCell className={classes.cell}>
                                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                                            {topSamplingTimes.map((sampleTime, i) => (
                                                <div key={`samplingTimes-${i}`} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                    <div style={{ marginRight: '5px' }}>{`${sampleTime[0]} = `}</div>
                                                    <div>{sampleTime[1]}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table aria-label="view-samples-table">
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    {(this.props.testType === 'dilution' || this.props.testType === 'recirculation') && (<TableCell className={classes.headerCell}>Scenario ID</TableCell>)}
                                    {(this.props.testType === 'dilution' || this.props.testType === 'recirculation') && (<TableCell className={classes.headerCell}>Scenario Name</TableCell>)}
                                    <TableCell className={classes.headerCell}>SP Number</TableCell>
                                    <TableCell className={classes.headerCell}>SP Name</TableCell>
                                    <TableCell className={classes.headerCell}>Sample Type </TableCell>
                                    {timesLen.map((sampleTime, i) => (
                                        <TableCell key={`sampleTime-key=${i}`} className={classes.headerCell}>{`T${i+1}`}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sampleDetails.samples.map((sampleDetail, i) => (
                                    <TableRow key={`sampleDetail-key=${i}`}>
                                        {(this.props.testType === 'dilution' || this.props.testType === 'recirculation') && (<TableCell className={classes.cell}>{sampleDetail.scenarioId}</TableCell>)}
                                        {(this.props.testType === 'dilution' || this.props.testType === 'recirculation') && (<TableCell className={classes.cell}>{sampleDetail.scenarioName}</TableCell>)}
                                        <TableCell className={classes.cell}>{sampleDetail.SPNumber}</TableCell>
                                        <TableCell className={classes.cell}>{sampleDetail.SPName}</TableCell>
                                        <TableCell className={classes.cell}>{sampleDetail.sampleType}</TableCell>
                                        <TableCell className={classes.cell}>{chunkedArr[i] && chunkedArr[i][0]}</TableCell>
                                        <TableCell className={classes.cell}>{chunkedArr[i] && chunkedArr[i][1]}</TableCell>
                                        <TableCell className={classes.cell}>{chunkedArr[i] && chunkedArr[i][2]}</TableCell>
                                        <TableCell className={classes.cell}>{chunkedArr[i] && chunkedArr[i][3]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
            );
        } else {
            return <>
                <Alert msg={error} flag={Boolean(error)} closeFunc={() => { this.setState({error: null}) }} />
            </>;
        }
    }
}

export default withStyles(styles, { withTheme: true })(SamplesTable);
