import React, { Component, Fragment, useEffect } from "react";
import { DropTarget } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
//import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PlacableObject from './PlacableObject';
import sizeScalingUtil from './sizeScalingUtil';
//import AnnotatedFloorPlan from './AnnotatedFloorPlan';


class ImageCanvas extends Component {
    constructor(props) {
        super(props);


        this.state = {
            width: props.width,
            height: props.height,
            fileHeight: props.floorPlan !== undefined && props.floorPlan !== null ? props.floorPlan.file.naturalHeight : null,
            fileWidth: props.floorPlan !== undefined && props.floorPlan !== null ? props.floorPlan.file.naturalWidth : null,
            x: 0,
            y: 0,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        }

        this.dropZoneRef = React.createRef();
        this.floorplanDimensions = this.floorplanDimensions.bind(this);
        this.originPointToPlacableObject = this.originPointToPlacableObject.bind(this);
        this.samplePointToPlacableObject = this.samplePointToPlacableObject.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.makeDropTargetElement = this.makeDropTargetElement.bind(this);
        //this.renderAsSVG = this.renderAsSVG.bind(this);

        //console.log("ImageCanvas " + this.state.fileHeight + " x " + this.state.fileWidth);
        const ro = new ResizeObserver((entries, observer) => {
            //console.log('Elements resized:', entries.length);
            entries.forEach((entry, index) => {
                const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
                //console.log(`Element ${index + 1}:`, `${width}x${height}`);
                this.setState({ width: width });
                this.setState({ height: height });
            });

        });


        const els = document.querySelectorAll('image-container');
        [...els].forEach(el => ro.observe(el)); // Watch multiple!

    }

    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        if (this.imageCanvasRef() !== null) {
            const rect = this.imageCanvasRef().getBoundingClientRect();
            const viewWidth = rect.width;
            const viewHeight = (this.state.fileHeight / this.state.fileWidth) * rect.width;
            this.viewX = rect.x;
            this.viewY = rect.y;

            this.setState({ width: viewWidth });
            this.viewWidth = viewWidth;


            this.setState({ height: viewHeight });
            this.viewHeight = viewHeight;
            this.setState({ x: this.viewX });

            this.setState({ y: this.viewY });


        }
    }

    componentDidUpdate() {
        //console.log("ImageCanvas.componentDidUpdate() imageCanvasRef: " + this.imageCanvasRef().getBoundingClientRect());

        if (this.imageCanvasRef() === null) {
            return;
        }
        const rect = this.imageCanvasRef().getBoundingClientRect();
        if (rect !== null) {
            //console.log("ImageCanvas.componentDidUpdate() rect " + rect.width + ", " + rect.height);
            const viewWidth = rect.width;
            const viewHeight = rect.height;
            const viewX = rect.x;
            const viewY = rect.y;

            if (this.viewHeight !== viewHeight || this.viewWidth !== viewWidth || viewX !== this.viewX || viewY !== this.viewY) {
                this.setState({ height: viewHeight });
                this.viewHeight = viewHeight;
                this.setState({ width: viewWidth });
                this.viewWidth = viewWidth;
                this.setState({ x: viewX });
                this.viewX = viewX;
                this.setState({ y: viewY });
                this.viewY = viewY;
            }
        }
    }

    floorplanDimensions() {
        const d = {
            width: this.state.fileWidth,
            height: this.state.fileHeight,
        }
        return d;
    }

    imageCanvasRef() {
        if (this.dropZoneRef === undefined || this.dropZoneRef === null) {
            return null;
        }
        return this.dropZoneRef.current;
    }

    originPointToPlacableObject(rawOp) {
        /*
          opid: 1440,
          opnumber: "OP-015",
          opname: null,
          opcoordx: "76.1627408993576",
          opcoordy: "124.8074074074074",
          spraynodeid: null,
          segmentid: 212,
          floorplan: "survey floorplan.png",
          taglotnumbers: null,
          tags:
        */
        const toRect = {
            width: this.state.width,
            height: this.state.height
        }

        const centeredCoordinates = sizeScalingUtil.toCoordiates(this.floorplanDimensions(), rawOp.opcoordx !== null ? Number(rawOp.opcoordx) : null, rawOp.opcoordy !== null ? Number(rawOp.opcoordy) : null, toRect);
        const zeroPointCoordinates = sizeScalingUtil.centerToZeroPoint(centeredCoordinates.x, centeredCoordinates.y, sizeScalingUtil.iconSize(this.floorplanDimensions(), toRect).width, sizeScalingUtil.iconSize(this.floorplanDimensions(), toRect).height);

        const scaledX = zeroPointCoordinates.x;
        const scaledY = zeroPointCoordinates.y;

        return (

            <PlacableObject
                isEditable={this.props.isEditable}
                objectType="OriginPoint"
                id={rawOp.opid}
                number={rawOp.opnumber}
                name={rawOp.opname}
                x={scaledX}
                y={scaledY}
                floorPlan={rawOp.floorplan}
                original={rawOp.original}
                isOnImageCanvas={true}
                isDirty={rawOp.isDirty}
                iconWidth={sizeScalingUtil.iconSize(this.floorplanDimensions(), toRect).width}
                iconHeight={sizeScalingUtil.iconSize(this.floorplanDimensions(), toRect).height}
                onChange={(e) => {
                    //console.log("ImageCanvas - onChangeHandler");
                    //console.log(e);
                    //console.log("name changed for item id:" + e.id + ", " + e.target.value);
                    if (this.props.onChange !== undefined && typeof this.props.onChange === 'function') {
                        this.props.onChange(e);
                    }

                }}
                onChanged={(e) => {
                    //console.log("onChangedHandler");

                    if (this.props.onChanged !== undefined && typeof this.props.onChanged === 'function') {
                        this.props.onChanged(e);
                    }


                }}
            />

        );
    }

    samplePointToPlacableObject(rawSp) {
        const toRect = {
            width: this.state.width,
            height: this.state.height
        }
        const centeredCoordinates = sizeScalingUtil.toCoordiates(this.floorplanDimensions(), rawSp.spcoordx !== null ? Number(rawSp.spcoordx) : null, rawSp.spcoordy !== null ? Number(rawSp.spcoordy) : null, toRect);
        const zeroPointCoordinates = sizeScalingUtil.centerToZeroPoint(centeredCoordinates.x, centeredCoordinates.y, sizeScalingUtil.iconSize(this.floorplanDimensions(), toRect).width, sizeScalingUtil.iconSize(this.floorplanDimensions(), toRect).height);

        const scaledX = zeroPointCoordinates.x;
        const scaledY = zeroPointCoordinates.y;

        return (

            <PlacableObject
                isEditable={this.props.isEditable}
                objectType="SamplePoint"
                id={rawSp.spid}
                number={rawSp.spnumber}
                name={rawSp.spname}
                x={scaledX}
                y={scaledY}
                floorPlan={rawSp.floorplan}
                original={rawSp.original}
                isOnImageCanvas={true}
                isDirty={rawSp.isDirty}
                iconWidth={sizeScalingUtil.iconSize(this.floorplanDimensions(), toRect).width}
                iconHeight={sizeScalingUtil.iconSize(this.floorplanDimensions(), toRect).height}
                onChange={(e) => {
                    //console.log("ImageCanvas - onChangeHandler");
                    //console.log(e);

                    //console.log("name changed for item id:" + e.id + ", " + e.target.value);
                    if (this.props.onChange !== undefined && typeof this.props.onChange === 'function') {
                        this.props.onChange(e);
                    }

                }}
                onChanged={(e) => {
                    //console.log("onChangedHandler");

                    if (this.props.onChanged !== undefined && typeof this.props.onChanged === 'function') {
                        this.props.onChanged(e);
                    }


                }}
            />

        );
    }

    makeDropTargetElement() {
        let viewWidth = this.state.width;
        let viewHeight = this.state.height;
        let originPointItems = [];
        let samplePointItems = [];

        //console.log("PlaceableOpject List");
        //console.log(this.props.originPoints);
        originPointItems = this.props.originPoints.map((item) => {
            if (item.floorplan === this.props.floorPlan.filename) {
                return this.originPointToPlacableObject(item);
            }
            return;
        });

        samplePointItems = this.props.samplePoints.map((item) => {
            if (item.floorplan === this.props.floorPlan.filename) {
                return this.samplePointToPlacableObject(item);
            }
            return;
        });
        if (this.props.floorPlan.file !== null) {
            return (
                <div ref={this.props.connectDropTarget} className="img-overlay-wrap" width={this.state.width + "px"}>
                    <img className="floor-plan" src={this.props.floorPlan.file} alt="floorplan" width={this.state.width + "px"}
                        onLoad={(e) => {
                            //console.log("Image.Load:" + e.target.naturalHeight + " x " + e.target.naturalWidth);
                            this.setState({ height: e.target.height });
                            this.setState({ width: e.target.width });
                            this.setState({ fileHeight: e.target.naturalHeight });
                            this.setState({ fileWidth: e.target.naturalWidth });
                        }} />
                    <div className="overlay" width={this.state.width + "px"} height={this.state.height + "px"}>
                        {originPointItems}
                        {samplePointItems}
                    </div>
                </div>
            );
        } else {
            return (
                <p>floor plan not loaded</p>
            );
        }

    }

    render() {
        //console.log(this.props.floorPlan);
        if (this.props.floorPlan === undefined || this.props.floorPlan === null || this.props.floorPlan.file === null) {
            return (<div />);
        }



        return (
            <div>

                <div className="image-frame" >
                    <div ref={this.dropZoneRef}>
                        {this.makeDropTargetElement()}
                    </div>
                </div>
            </div>
        );
    }
}

export default DropTarget(ItemTypes.PLACEABLE_OBJECT, {
    drop(props, monitor, component) {

        if (!component) {
            return;
        }

        //console.log("ImageCanvas drop" );

        const rect = component.imageCanvasRef().getBoundingClientRect();
        const item = monitor.getItem();

        if (item.iconHeight !== undefined && item.iconWidth !== undefined) {
            const initialSourceClientOffset = monitor.getInitialSourceClientOffset();
            const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();

            //console.log(initialSourceClientOffset);
            //console.log(differenceFromInitialOffset);

            const localX = initialSourceClientOffset.x + differenceFromInitialOffset.x - rect.x;
            const localY = initialSourceClientOffset.y + differenceFromInitialOffset.y - rect.y;

            //console.log ("DropTarge coordinates [" + localX + ", " + localY);


            const center = sizeScalingUtil.zeroPointToCenter(localX, localY, item.iconWidth, item.iconHeight);

            const coordinatesScaledToFloorplanFile = sizeScalingUtil.toCoordiates(rect, center.x, center.y, component.floorplanDimensions());

            const dropCoordinates = {
                id: item.id,
                x: coordinatesScaledToFloorplanFile.x > 0.0 ? coordinatesScaledToFloorplanFile.x : 0,
                y: coordinatesScaledToFloorplanFile.y > 0.0 ? coordinatesScaledToFloorplanFile.y : 0,
            };

            return dropCoordinates;
        } else {
            const ptrCoordinates = monitor.getClientOffset();
            const localX = ptrCoordinates.x - rect.x;
            const localY = ptrCoordinates.y - rect.y;
            const coordinatesScaledToFloorplanFile = sizeScalingUtil.toCoordiates(rect, localX, localY, component.floorplanDimensions());

            const dropCoordinates = {
                id: item.id,
                x: coordinatesScaledToFloorplanFile.x > 0.0 ? coordinatesScaledToFloorplanFile.x : 0,
                y: coordinatesScaledToFloorplanFile.y > 0.0 ? coordinatesScaledToFloorplanFile.y : 0,
            };
            return dropCoordinates;
        }


    },
}, (connect) => ({
    connectDropTarget: connect.dropTarget(),
}))(ImageCanvas);