import React from 'react';
import { CircularProgressSpinner } from '../CircularProgressSpinner/CircularProgressSpinner';
import './FullPageLoader.css';

export const FullPageLoader = ({ progress }) => {
    return (
        <div className="full-page-loader-container">
            <div className="spinner-container">
                <CircularProgressSpinner progress={progress} />
            </div>
        </div>
    );
}