import React from 'react';
import floorPlans from '../../api/floorPlans';
import FloorPlanList from '../../components/FloorPlanList/FloorPlanList.js';
import Dropzone from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { Context } from '../../context/Context';
import Typography from "@material-ui/core/Typography";
import projects from '../../api/projects';
import tests from '../../api/tests';
import { FullPageLoader } from '../../components/FullPageLoader/FullPageLoader';
import { AlertGeneral } from '../../components/Alert/AlertGeneral';
import './FloorPlans.css';

import axios from 'axios';
import Dialog from '../../components/FloorPlanCropperDialog/FloorPlanCropperDialog';


const newAxiosInstance = axios.create();

class FloorPlans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectId: null,
            floorPlans: [],
            url: null,
            isLoading: null,
            testCompleted: false,
            floorPlanId: null,
            downloadedFiles: [],
            alertMsg: '',
            alertOpen: false,
            showNewFloorPlanDialog: false,
            selectedImageFile: null,
        };
    }

    async componentDidMount() {
        let oneCheckResponse = null;
        let completed = null;
        //logic to handle highlighting navbar tab
        let floorPlanNavButton = document.querySelector('#fpBtn')
        if (floorPlanNavButton) {
            floorPlanNavButton.classList.add('selected');
        }

        //With this delay, componentMounts without notifying JSX of any of the testCompleted checks
        setTimeout(() => {
            let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
            let testId = JSON.parse(localStorage.getItem("testId")) ? JSON.parse(localStorage.getItem("testId")) : null;

            this.setState({
                projectId: projectId,
                testId: testId
            }, async () => {
                if (testId !== null) {
                    oneCheckResponse = await tests.getOneTest(this.state.testId);
                    if (oneCheckResponse) {
                        completed = oneCheckResponse.data.data.testexecuted;
                    }
                } else if (projectId) {
                    oneCheckResponse = await projects.getOneProject(projectId);
                    if (oneCheckResponse) {
                        completed = oneCheckResponse.data.data.testCompleted;
                    }
                }

                if (oneCheckResponse !== null && completed !== null) {
                    this.setState({ testCompleted: completed });
                }

                const response = await floorPlans.getFloorPlans(projectId);
                if (response && response.data && response.data.data) {
                    this.setState({ floorPlans: response.data.data });
                }
            });
        }, 0);
    }

    async uploadFile(files) {
        const file = files[0];

        this.setState({ showNewFloorPlanDialog: true, selectedImageFile: file })
        return;
    }

    async downloadFile(filename, floorPlanId) {
        // Step 1: get pre-signed url by sending axios request to server
        const result = await floorPlans.getPresignedURL({
            projectId: this.state.projectId,
            filename: filename,
            action: 'getObject'
        });
        // Step 2: Download file from AWS s3 bucket as a blob
        // Making straight axios call in order to access the ProgressEvent interface which allows up to capture download progress and pass that data along to spinner component
        const response = await newAxiosInstance.get(result.data.preSignedURL, {
            responseType: 'blob',
            // Progress bar to show the progress of download while user is waiting
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({ percentCompleted }, () => {
                    if (this.state.percentCompleted === 100) {
                        this.setState({ isLoading: false });
                    } else {
                        this.setState({ isLoading: true })
                    }
                });
            }
        })
        //  Step 3: Download file in required format - create a blob url and render in img tag
        let blob = new Blob([response.data], { type: response.data.type });
        let createObjectURL = window.URL.createObjectURL || window.webkitURL.createObjectURL;
        const url = createObjectURL(blob);
        this.setState({
            url: url,
            floorPlanId: floorPlanId
        }, () => {
            //Step 4. Create an array of all the download files. Elements are objects with blob ur with corresponding floor plan id
            this.setState({
                downloadedFiles: [...this.state.downloadedFiles, { floorPlanId: this.state.floorPlanId, url: this.state.url }]
            });
        });
    }

    onFileCellClicked(event) {
        let fileName = event.data.filename;
        let floorPlanId = event.data.floorplanid;
        this.downloadFile(fileName, floorPlanId);
    }

    handleAlertClose = () => this.setState({ alertOpen: !this.state.alertOpen });

    deleteFloorPlans = async (fpId) => {
        //Here we check if the currently selected floor plan id matches the floorPlanId in downloadedFiles && downloadedFiles obj matches url in state, clear state so img tag is emptied
        this.state.downloadedFiles.forEach(obj => {
            if (fpId === obj.floorPlanId) {
                if (obj.url === this.state.url) {
                    this.setState({ url: null })
                }
            }
        })

        const response = await floorPlans.deleteFloorPlan(fpId);
        if (response.data.success) {
            const updatedList = await floorPlans.getFloorPlans(this.state.projectId);
            if (updatedList.data.success) {
                this.setState({ floorPlans: updatedList.data.data });
            }
        } else {
            if (response.data.error.message === 'File is associated with a segment') {
                this.setState({
                    alertOpen: !this.state.alertOpen,
                    alertMsg: 'This file is associated with a segment so it cannot be deleted'
                });
            }
        }
    }

    render() {
        const { percentCompleted, isLoading, testCompleted, url, alertOpen, alertMsg, projectId } = this.state;
        return (
            <Context.Consumer>
                {context => (
                    <div className="John" >

                        {this.context.state.accessLevel !== 'Viewer' && this.state.showNewFloorPlanDialog === true && (
                            <Dialog
                                imageFile={this.state.selectedImageFile}
                                floorPlanFiles={this.state.floorPlans}
                                onCancel={() => {
                                    this.setState({ showNewFloorPlanDialog: false });
                                }}

                                onUploadCompleted={async () => {
                                    const response = await floorPlans.getFloorPlans(projectId);
                                    if (response && response.data && response.data.data) {
                                        //console.log(response.data.data);
                                        this.setState({
                                            floorPlans: response.data.data,
                                            showNewFloorPlanDialog: false
                                        });
                                    }
                                }}
                            />
                        )}

                        <div style={{ marginTop: '120px' }}>
                            <Typography variant="h5" color="textPrimary" style={{ fontFamily: "Gotham", marginTop: "30px", marginLeft: '150px' }} align="center">{`(Project Id ${projectId}) ${context.state.projectName}: ${context.state.testName}`}</Typography>

                            {!!alertOpen && (<AlertGeneral open={alertOpen} alertMsg={alertMsg} closeFunc={this.handleAlertClose} severity='error' />)}
                            <div className="ui container" style={{
                                margin: '20px 0 0 200px',
                                textAlign: 'right',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                width: '80vw'
                            }}>
                                <div style={{ width: '25%' }}>
                                    {this.context.state.accessLevel !== 'Viewer' && this.state.testCompleted === false && (
                                        <Dropzone onDrop={this.uploadFile.bind(this)}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section style={{
                                                    border: '1px solid #82BC00',
                                                    textAlign: 'center',
                                                    height: '35px',
                                                    width: '360px',
                                                    fontWeight: 'bold',
                                                    lineHeight: '35px'
                                                }}>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <p>Drag and drop some file here, or click to select a file</p>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    )}
                                    <FloorPlanList
                                        accessLevel={this.context.state.accessLevel}
                                        testCompleted={testCompleted}
                                        floorplans={this.state.floorPlans}
                                        deleteFloorPlans={this.deleteFloorPlans}
                                        onFileCellClicked={this.onFileCellClicked.bind(this)}>
                                    </FloorPlanList>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: '60%', marginLeft: '100px' }}>
                                    {this.context.state.accessLevel !== 'Viewer' && (
                                        <a style={{ textDecoration: 'none' }} href={this.state.url}>
                                            <Button size="small" variant="outlined" style={{ backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", marginRight: "28%", fontFamily: "Gotham", marginBottom: "10px" }}>Download File</Button>
                                        </a>
                                    )}
                                    <div style={{ marginRight: '28%' }}>
                                        <img src={url} width="600" height="500" style={{ overflow: 'scroll' }} />
                                    </div>

                                </div>
                            </div>
                            {!!isLoading && (<FullPageLoader progress={percentCompleted} />)}
                        </div>

                    </div>
                )}
            </Context.Consumer>
        )
    }
}
FloorPlans.contextType = Context;
export default FloorPlans;