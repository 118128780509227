import React from 'react';
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ContactsOutlined } from '@material-ui/icons';

const styles = (theme) => ({
    columnHeader: {


        minHeight: '200px',
        //maxHeight: '20px',
        //border: '3px solid #73AD21',
        background: 'rgb(0, 137, 150)',//'lightgray',
        padding: '10px',
        //align: 'left',
        //transform: 'rotate(270deg)',
    },

    columnHeaderText: {
        //transform: 'rotate(270deg)', /* Equal to rotateZ(45deg) */
        //textAlign: 'left',
        //margin: '10px',
        //minWidth: '200px',
        //position: 'absolute',
        //right: '0px',
    },
    table: {
        border: '1px solid gray !important',//1px solid rgba(224, 224, 224, 1);
        //borderWidth: '1px !important',
        //borderSpacing: '2px !important',
        minWidth: 400,
    },
    cell: {
        padding: '4px !important',
        //margin: '10px',
        fontSize: 'smaller',
        border: '1px solid gray !important',
    },
    row: {
        //border: '1px solid #73AD21',
        padding: '10px',
    },
    idColumn: {
        width: '15px',
    }

})


class SampleCollectionDetails extends React.Component {
    constructor(props) {
        super(props);

        this.composeIntervalTableCells = this.composeIntervalTableCells.bind(this);
        this.composeHeaderRow = this.composeHeaderRow.bind(this);
        this.getSampleTypesFromSamples = this.getSampleTypesFromSamples.bind(this);
        this.composeDetailRows = this.composeDetailRows.bind(this);
    }

    componentDidMount() {

    }

    composeIntervalTableCells(values, isHeader) {
        const { classes } = this.props;
        let cells = [];

        values.map((value) => {
            if (isHeader) {
                cells.push(
                    <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="right">{value}</TableCell>
                )
            } else {
                cells.push(
                    <TableCell className={classes.cell} align="right">{value}</TableCell>
                )
            }


        })
        return cells;
    }
    composeHeaderRow() {
        const { classes } = this.props;

        let values = [];

        for (let i = 0; i < this.props.intervalCount; i++) {
            values.push('I' + (i + 1));
        }

        const intervalCells = this.composeIntervalTableCells(values, true);

        return (

            <TableRow>
                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="right">Scenario</TableCell>
                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="left">Sample Type</TableCell>
                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="left">SP Number</TableCell>
                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="left">SP Name</TableCell>
                {intervalCells}
                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white', width: '35%' }} align="left">Notes</TableCell>
            </TableRow>

        )


    }

    getSampleTypesFromSamples() {
        let sampleTypes = [];
        this.props.samples.map((sample) => {
            if (!sampleTypes.includes(sample.type)) {
                sampleTypes.push(sample.type);
            }
        });
        return sampleTypes;
    }

    composeDetailRows() {
        const { classes } = this.props;

        let rows = [];

        let sampleTypes = this.getSampleTypesFromSamples();

        this.props.scenarios.map((scenario) => {

            //console.log(scenario);
            sampleTypes.map((sampleType) => {
                this.props.samplePoints.sort(function (a, b) {
                    if (a.number < b.number) {
                        return -1;
                    }
                    if (a.number > b.number) {
                        return 1;
                    }
                    return 0;
                }).map((samplePoint) => {



                    let values = [];
                    this.props.samples.filter(s => s.spId === samplePoint.id &&
                        s.scenarioSequenceNumber === scenario.sequenceNumber &&
                        s.type === sampleType)
                        .sort(function (a, b) {
                            if (a.intervalNumber < b.intervalNumber) {
                                return -1;
                            }
                            if (a.intervalNumber > b.intervalNumber) {
                                return 1;
                            }
                            return 0;
                        }).map((sample) => {
                            values.push(sample.number);
                        });

                    const intervalCells = this.composeIntervalTableCells(values, false);

                    rows.push(

                        <TableRow key={scenario.scenaioId + '+' + samplePoint.id + '+' + sampleType}>
                            <TableCell className={classes.cell} align="right">{scenario.sequenceNumber}</TableCell>
                            <TableCell className={classes.cell} align="left">{sampleType}</TableCell>
                            <TableCell className={classes.cell} align="left">{samplePoint.number}</TableCell>
                            <TableCell className={classes.cell} align="left">{samplePoint.name}</TableCell>
                            {intervalCells}
                            <TableCell className={classes.cell} align="left"></TableCell>
                        </TableRow>
                    );

                });

            });

        });

        return rows;

    }

    render() {
        const { classes } = this.props;

        const headerRow = this.composeHeaderRow();
        const detailRows = this.composeDetailRows();
        return (
            <div className="SampleCollectionDetails" style={{ marginTop: '20px' }}>
                <p style={{ paddingTop: '10px', paddingBottom: '10px' }} >
                    <Typography variant='h6'>Samples</Typography>
                </p>
                <TableContainer style={{ width: '99.5%' }}>

                    <Table className={classes.table} size="small" aria-label="Sample rows">
                        <TableHead>
                            {headerRow}
                        </TableHead>
                        <TableBody>
                            {detailRows}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }

}

export default withStyles(styles)(SampleCollectionDetails);