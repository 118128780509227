import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import tags from '../../api/tags';
import './TagsChecklistTable.css';

const styles = theme => ({
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '20px',
        textAlign: 'center'
    },
    cell: {
        fontFamily: 'Gotham',
        fontSize: '16px',
        textAlign: 'center'
    }
});

class TagsChecklistTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            tagKeys: [],
            tagValues: []
        };
    }

    async componentDidMount() {
        let testId = JSON.parse(localStorage.getItem("testId")) ? JSON.parse(localStorage.getItem("testId")) : null;
        let response = await tags.getTagsCount(testId);
        if(response.data.success) {
            
            this.setState({ 
                tagKeys: Object.keys(response.data.data),
                tagValues: Object.values(response.data.data),
            });
        }
    }

    render() {
        const { classes } = this.props;
        const { tagKeys, tagValues } = this.state;
        
        if(tagKeys.length > 0) {
            return (
                <TableContainer component={Paper}>
                    <div className="tags-table-wrapper">
                        <Table aria-label="qc-table">
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>Tags</TableCell>
                                    <TableCell className={classes.headerCell}>No: Times Used</TableCell>
                                    <TableCell className={classes.headerCell}>Comments</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tagKeys.map((tag, i) => (
                                        <TableRow key={`key=${i}`}>
                                            <TableCell className={classes.cell}>{tagKeys[i]}</TableCell>
                                            <TableCell className={classes.cell}>{tagValues[i]}</TableCell>
                                            <TableCell className={classes.cell}></TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
            );
        } else {
            return null;
        } 
    }  
}

export default withStyles(styles, { withTheme: true })(TagsChecklistTable);