import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import '../../../node_modules/ag-grid-community/dist/styles/ag-grid.css';
import '../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css';
import BtnCellRendererLabel from '../BtnCellRendererLabel/BtnCellRendererLabel';
import './LabelList.css';

export class LabelList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columnDefs: [
                {
                    headerName: "Labels/Form",
                    field: "labelType",
                    editable: false,
                    width: 290
                },
                {
                    headerName: 'View/Download',
                    field: 'labelTypeButton',
                    cellRenderer: 'btnCellRenderer'
                }
            ],
            rowData: [
                {
                    labelType: "Origin Points Labels",
                    labelTypeButton: "View Labels"
                },
                {
                    labelType: "Sample Points Labels",
                    labelTypeButton: "View Labels"
                },
                {
                    labelType: "Sample Collector Labels",
                    labelTypeButton: "View Labels"
                },
                {
                    labelType: 'Order Form',
                    field: 'orderForm',
                    cellRenderer: 'btnCellRenderer'
                }
                // {
                //     labelType: 'Project Configuration',
                //     labelTypeButton: "View"
                // }
            ],
            frameworkComponents: {
                btnCellRenderer: BtnCellRendererLabel
            }
        }
    }

    render() {
        return (
            <div
                className="ag-theme-alpine"
                style={{
                    marginTop: '20px',
                    height: (this.state.rowData.length * 42 + 51) + 'px',
                    width: '497px',
                    fontFamily: "gotham",
                    textAlign: "center"
                }}
                className="ag-theme-alpine all-label-list">
                <AgGridReact
                    columnDefs={this.state.columnDefs}
                    rowData={this.state.rowData}
                    frameworkComponents={this.state.frameworkComponents}>
                </AgGridReact>
            </div>
        );
    }
}
