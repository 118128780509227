import React, { useState, useContext }                from 'react';
import { makeStyles }                                 from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import CollectionsIcon                                from '@material-ui/icons/Collections';
import PeopleIcon                                     from '@material-ui/icons/People';
import TagsIcon                                       from '@material-ui/icons/LocalOffer';
import DescriptionIcon                                from '@material-ui/icons/Description';
import { withRouter }                                 from "react-router";
import { Context }                                    from '../../context/Context';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        marginTop: '60%'
    },
    icon: {
        "&:hover, &:focus": { 
            backgroundColor: 'rgba(0, 137, 150, .5)'
        }
    }
}));

const DrawerList = props => {
    
    const { history } = props;
    const { state, clearNames, setNotOnProject, setNotOnProjectDetails } = useContext(Context);
    const classes = useStyles();
    const [ list, setList ] = useState([
        {title: 'Projects', icon: <CollectionsIcon />, onClick: () => {
            history.push('/projects');
            localStorage.removeItem('projectName');
            localStorage.removeItem('projectId');
            localStorage.removeItem('onProject');
            localStorage.removeItem('testCompleted');
            localStorage.removeItem('testId');
            localStorage.removeItem('testName');
            localStorage.removeItem('testType');
            localStorage.removeItem('segmentid');
            localStorage.removeItem('created'); 
            localStorage.removeItem('orderFormData');
            clearNames();
        }}, 
        {title: 'Users', icon: <PeopleIcon />, onClick: () => {
                history.push('/users');
                setNotOnProject();
                setNotOnProjectDetails();
            } 
        },
        {title: 'Documentation', icon: <DescriptionIcon />, onClick: () => window.open('https://www.safetraces.com/documentation')},
        {title: 'Tags', icon: <TagsIcon />, onClick: () => {
                history.push('/tags');
                setNotOnProject();
                setNotOnProjectDetails();
            }
        },
    ]);

    let accessLevel = null;
    let roleName    = null;
    const checkAccessLevel = () => {
        accessLevel = state.accessLevel;
        roleName    = state.roleName;
    }
    checkAccessLevel();

    return (
        <Context.Consumer>
            {context => (
                <main className={classes.root}>
                    <List component="nav" aria-label="side-navigation">
                        {list.map((item, i) => (
                            (
                                item.title === 'Users' && accessLevel !== 'Admin'
                            ) || (
                                item.title === 'Tags'
                                && !(accessLevel === 'Admin' && roleName === 'Safetracer')
                            ) ? null : (
                                <ListItem key={`${i}`} className={classes.icon} onClick={item.onClick} button>
                                    <ListItemIcon style={{ color: '#82BC00' }}>{item.icon}</ListItemIcon>
                                    <ListItemText style={{ color: '#008996' }} primary={item.title} />
                                </ListItem>
                            )
                        ))}
                    </List>
                </main>
            )}
        </Context.Consumer>
    );
}

export default withRouter(DrawerList);
