import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ReactToPrint from "react-to-print";
import ProjectScope from "./ProjectScopeView";
import AnnotatedFloorPlan from "../Visualizer/AnnotatedFloorPlan";
import TestDetails from "./TestDetails";
import ScenarioDetails from "./ScenarioDetails";
import OriginPointDetails from "./OriginPointDetails";
import DataModel from "../../data/ProjectLogicalDataModel";
import ProjectDataValidator from "../../data/ProjectDataValidator";
import ProjectScopeTabulator from "../../data/ProjectScopeTabulator";
import SampleCollectionDetails from "./SampleCollectionDetails";
import SampleSummary from "./SampleSummary";
import DataLoadingDialog from "../../components/DataLoadingDialog/DataLoadingDialog";
import VeriDARTLogo from "../../assets/veriDART logo.png";
import NavBarUtil from "../../components/Navbar/NavBarUtil";
import Notes from "./Notes";
import originpoints from "../../api/originpoints";
import ProjectConfigurationTestPage from "./ProjectConfigurationTestpage";
import tests from "../../api/tests";

const styles = (theme) => ({
  columnHeader: {
    minHeight: "200px",
    //maxHeight: '20px',
    //border: '3px solid #73AD21',
    background: "rgb(0, 137, 150)", //'lightgray',
    padding: "10px",
    //align: 'left',
    //transform: 'rotate(270deg)',
  },

  columnHeaderText: {
    //transform: 'rotate(270deg)', /* Equal to rotateZ(45deg) */
    //textAlign: 'left',
    //margin: '10px',
    //minWidth: '200px',
    //position: 'absolute',
    //right: '0px',
  },
  table: {
    border: "1px solid gray !important", //1px solid rgba(224, 224, 224, 1);
    //borderWidth: '1px !important',
    //borderSpacing: '2px !important',
    minWidth: 400,
  },
  cell: {
    padding: "4px !important",
    //margin: '10px',
    fontSize: "smaller",
    border: "1px solid gray !important",
  },
  row: {
    //border: '1px solid #73AD21',
    padding: "10px",
  },
  idColumn: {
    width: "15px",
  },
});

const useNew = true;

class DataCollectionView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projectId: null,
      projectName: "",
      address: "",
      customer: "",
      testDate: "",
      floors: "n/a",
      contacts: "",
      originPoints: [],
      projectScopeDetails: [],
      floorPlans: [],
      loadingData: true,
      dataIssues: [],
      status: "",
      description: "",
      partner: "",
      tagInfo: [],
      testsDetailedData: {},
    };

    this.PrintableContainerRef = React.createRef();
    this.getTheProjectData = this.getTheProjectData.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }
  async componentDidMount() {
    NavBarUtil.setNavButtonActive("#pcBtn");
    const id = JSON.parse(localStorage.getItem("projectId"))
      ? JSON.parse(localStorage.getItem("projectId"))
      : null;
    //console.log('componentDidMount() ' + id);

    this.setState(
      {
        projectId: id,
      },
      this.getTheProjectData
    );
  }

  handleBack() {
    this.props.history.push("/projectview");
  }

  async getTheProjectData() {
    console.log("getTheProjectData() " + this.state.projectId);
    if (this.state.projectId === null) {
      return;
    }
    try {
      const detailedProjectTestData = await tests.getProjectConfigurationData(
        this.state.projectId
      );
      this.setState({ testsDetailedData: detailedProjectTestData.data.data });
    } catch (e) {
      console.error(e);
    }

    let ldm = new DataModel(
      this.state.projectId,
      (projectId, projectData) => {
        // success
        //console.log('build ProjectLogicalDataModel success');
        //console.log(projectData);
      },
      (projectId, response) => {
        // error
        console.log("build ProjectLogicalDataModel  error");
        //console.log(response);
      }
    );
    //console.log(ldm);
    await ldm.build();

    let dataValidator = new ProjectDataValidator(ldm, 3);
    const dataIssues = dataValidator.validate();

    const projectScopeTabulatedData = new ProjectScopeTabulator(ldm).tabulate();

    const opData = await originpoints.getAllOriginpoints(
      null,
      this.state.projectId
    );

    const tagInfo =
      opData?.data?.data
        ?.filter((item) => {
          if (
            Array.isArray(item?.tagLotNumber) &&
            item.tagLotNumber.length > 0
          ) {
            return item.tagLotNumber[0];
          }
        })
        ?.map((item) => item.tagLotNumber[0]) ?? [];

    const details = projectScopeTabulatedData.map((detail) => {
      return {
        ...detail,
        opTags: detail.opTags.join(",  "),
        sampleType: detail.sampleType.join(",  "),
        //scenarioDescriptions: detail.scenarioDescriptions.join(',  '),
        //scenarioNames: detail.scenarioNames.join(',  '),
      };
    });
    this.setState(
      {
        projectName: ldm.project !== null ? ldm.project.name : "",
        address: ldm.project !== null ? ldm.project.address : "",
        customer: ldm.project !== null ? ldm.project.customer : "",
        testDate: "", //TODO fill in the test dates
        contacts: "n/a",
        projectScopeDetails: details,
        originPoints: ldm.project !== null ? ldm.originPoints : "",
        samplePoints: ldm.project !== null ? ldm.samplePoints : "",
        samples: ldm.samples,
        scenarios: ldm.scenarios,
        builder: ldm,
        loadingData: false,
        details: details,
        dataIssues: dataIssues,
        status: ldm.project.status,
        description: ldm.project.description,
        partner: ldm.project.partner,
        tagInfo,
      },
      () => {
        //console.log(this.state.projectScopeDetails);
      }
    );
  }

  render() {
    const { classes } = this.props;
    //(this.state);
    let originPoints = [];
    let tests = [];
    let floorPlans = [];

    if (this.state.builder !== undefined) {
      tests = this.state.builder.getTests().map((test) => {
        floorPlans = this.state.builder.getFloorPlans(test.id).map((item) => {
          return this.state.builder.getFloorPlan(item);
        });

        return {
          ...test,
          floorPlans: floorPlans,
        };
      });

      //console.log(tests);
    }

    let currentDate = new Date().toISOString().slice(0, 10);

    return (
      <div>
        {this.state.loadingData === true && (
          <DataLoadingDialog
            title="Project Configuration"
            message="Loading and tabulating data, this will take a few moments."
            onCancel={() => {
              this.handleBack();
            }}
          />
        )}

        <div className="labels-flex-container">
          <Typography
            variant="h5"
            color="textPrimary"
            align="left"
            style={{ fontFamily: "Gotham" }}
          ></Typography>
          <ReactToPrint
            trigger={() => {
              return (
                <div>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{
                      backgroundColor: "#82BC00",
                      color: "#ffffff",
                      borderColor: "#82BC00",
                      marginLeft: "5px",
                      fontFamily: "Gotham",
                    }}
                  >
                    Print
                  </Button>
                </div>
              );
            }}
            documentTitle={`PID ${this.state.projectId} ${this.state.projectName} Project Conf DNLD ${currentDate}`}
            content={() => this.PrintableContainerRef.current}
          />
          {/* <Button onClick={this.handleBack} size="small" variant="outlined" style={{ backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", marginLeft: "5px", fontFamily: "Gotham" }}>Back</Button> */}
        </div>
        <div ref={this.PrintableContainerRef}>
          <div>
            <img
              src={VeriDARTLogo}
              style={{ width: "35%", height: "auto" }}
            />
            <Typography
              variant="h3"
              color="textPrimary"
              style={{ fontFamily: "Gotham", fontSize: "25px" }}
            >
              Project Configuration
            </Typography>
          </div>

          <ProjectScope
            projectId={this.state.projectId}
            projectName={this.state.projectName}
            address={this.state.address}
            customer={this.state.customer}
            testDates={this.state.testDate}
            floors="n/a"
            contacts={this.state.contacts}
            details={this.state.projectScopeDetails}
            dataIssues={this.state.dataIssues}
            status={this.state.status}
            description={this.state.description}
            partner={this.state.partner}
            tagInfo={this.state.tagInfo}
          />

          {this.state.dataIssues.filter((i) => i.testId === undefined).length >
            0 && (
            <div style={{ color: "red", marginTop: "20px" }}>
              <Typography
                variant="h5"
                align="left"
                style={{ fontFamily: "Gotham" }}
              >
                Warnings
              </Typography>
              <ol>
                {this.state.dataIssues
                  .filter((i) => i.testId === undefined)
                  .map((dataIssue) => {
                    return <li>{dataIssue.description}</li>;
                  })}
              </ol>
            </div>
          )}
          <div style={{ paddingTop: "40px", width: "99.5%" }}>
            <Notes headerText="Project Notes" rows="6" />
          </div>

          {tests.map((test, index) => (
            <ProjectConfigurationTestPage
              test={test}
              number={index + 1}
              projectName={this.state.projectName}
              projectId={this.state.projectId}
              warnings={this.state.dataIssues.filter(
                (i) => i.testId === test.id
              )}
              samples={this.state.samples}
              sprayData={this.state.testsDetailedData?.sprayData[test.id]}
              scenarioData={this.state.testsDetailedData?.scenarioData[test.id]}
              scenarioDataWithOPs={
                this.state.testsDetailedData?.scenarioDataWithOPs[test.id]
              }
              scenarioSummary={
                this.state.testsDetailedData?.scenarioSummary[test.id]
              }
              scenariosWithSPs={
                this.state.testsDetailedData?.scenariosWithSPs[test.id]
              }
              testsMeta={this.state.testsDetailedData?.testsMeta[test.id]}
              OPs={this.state.testsDetailedData?.OPs[test.id]}
              SPs={this.state.testsDetailedData?.SPs[test.id]}
              allOPs={this.state.originPoints}
              allSPs={this.state.samplePoints}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(DataCollectionView);
