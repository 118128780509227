import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import samplepoints from '../../api/samplepoints';
import './QCChecklistTable.css';

const styles = theme => ({
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '20px',
        textAlign: 'center'
    },
    cell: {
        fontFamily: 'Gotham',
        fontSize: '16px'
    }
});

class QCChecklistTable extends Component {

    state = { 
        SNresponse: [],
        SPresponse: [],
        sampleNumbers: [],
        spNumbers: [],
        samplePoints: [],
        spcoordx: [],
        spcoordy: []
    };

    extractValues = (arr, property) => {
        if(property === 'spnumber') {
            return arr.map(obj => obj.spnumber);
        } else if(property === 'spname') {
            return arr.map(obj => obj.spname);
        } else if(property === 'spcoordx') {
            return arr.map(obj => {
                return { 'spcoordx' : obj.spcoordx, 'spcoordy' : obj.spcoordy }
            });
        }
    }

    async componentDidMount() {
        let SNresponse;
        let SPresponse;
        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        let testId = JSON.parse(localStorage.getItem("testId")) ? JSON.parse(localStorage.getItem("testId")) : null;
        SNresponse = await samplepoints.getAllSamples(testId, projectId);
        
        if(SNresponse.data.success) {
            this.setState({ 
                SNresponse: SNresponse
            }, async () => {
                if(testId !== null) {
                    this.state.SNresponse.data.data.forEach(obj => {
                        const { samplenumber } = obj;
                        const { spnumber } = obj;
                        const { spcoordx } = obj;
                        const { spcoordy } = obj;
                        this.setState(prevState => ({
                            sampleNumbers: [...prevState.sampleNumbers, samplenumber],
                            samplePoints: [...prevState.samplePoints, spnumber],
                            spcoordx: [...prevState.spcoordx, spcoordx],
                            spcoordy: [...prevState.spcoordy, spcoordy]

                        }));
                    })
                } else {
                    SPresponse = await samplepoints.getAllSamplepoints(testId=null, projectId);
                    let spNums = this.extractValues(SPresponse.data.data, 'spnumber');
                    this.setState({ spNumbers: spNums });
                }
            });
        }
    }

    render() {
        const { sampleNumbers, spNumbers, samplePoints, spcoordx, spcoordy } = this.state;
        const { classes } = this.props;
        return (
            <TableContainer component={Paper}>
                <div className="qc-table-wrapper">
                    <Table aria-label="qc-table">
                        <TableHead className={classes.tableHeader}>
                            <TableRow>
                                <TableCell className={classes.headerCell}>Sample Number</TableCell>
                                <TableCell className={classes.headerCell}>Sample Point Number</TableCell>
                                <TableCell className={classes.headerCell}>Sample Point Coordinates</TableCell>
                                <TableCell className={classes.headerCell}>Sampled By</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sampleNumbers.length > 0 ? (
                                sampleNumbers.map((num, i) => (
                                    <TableRow key={`key=${i}`}>
                                        <TableCell style={{ textAlign:'center' }} className={classes.cell}>{num}</TableCell>
                                        <TableCell style={{ textAlign:'center' }} className={classes.cell}>{samplePoints[i]}</TableCell>
                                        <TableCell style={{ textAlign:'center' }} className={classes.cell}>{`${spcoordx[i]}, ${spcoordy[i]}`}</TableCell>
                                        <TableCell style={{ textAlign:'center' }} className={classes.cell}></TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                spNumbers.map((num, i) => (
                                    <TableRow key={`key=${i}`}>
                                        <TableCell style={{ textAlign:'center' }} className={classes.cell}>{num}</TableCell>
                                        <TableCell style={{ textAlign:'center' }} className={classes.cell}>{samplePoints[i]}</TableCell>
                                        <TableCell style={{ textAlign:'center' }} className={classes.cell}>{`${spcoordx[i]}, ${spcoordy[i]}`}</TableCell>
                                        <TableCell style={{ textAlign:'center' }} className={classes.cell}></TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </div>
            </TableContainer>
        );
    }  
}

export default withStyles(styles, { withTheme: true })(QCChecklistTable);