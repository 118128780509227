import React, { useRef } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import { Dropdown } from '../Dropdown/Dropdown';
import { makeStyles } from '@material-ui/core/styles';
import { NestedTableScenario } from '../NestedTable/NestedTableScenario';
import { format, parseISO } from "date-fns";
import { DatePicker } from "@material-ui/pickers";

const useRowStyles = makeStyles({
    cell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        textAlign: 'center',
        borderRight: 'none',
        borderLeft: 'none',
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'center',
        padding: '10px !important'
    },
    tableRow: {
        cursor: 'pointer',
    }
});

export const DilutionListSegment = ({ 
        index, 
        scenarios, 
        ops, 
        floorPlans, 
        individualSegment, 
        segments, 
        onChangeFunc, 
        onTextChangeFunc,
        onSurveyDateUpdateFunc,
        onKeyDownTextUpdate,
        handleTagLotNumberChange, 
        onKeyDownLotNumberExpirationDateUpdate,
        disableScenarios, 
        surveyDate, 
        comments, 
        testCompleted,
        isViewer,
        tagLotNumbers,
        expirationDates,
        scenarioDescription,
        testType,
        projectFromProjectPortal
    }) => {
            
    const classes = useRowStyles();
    const rowRefs = useRef([]);
    let segment;
    let open = true;

    const handleDateChange = e => {
        onSurveyDateUpdateFunc(format(e, 'yyyy-MM-dd'));
    }

    //Delay in receiving populated segments prop which breaks render, so checking segments.len else return null
    if(segments.length !== 0) {
        segment = segments[0];
        return (
            <>
                <TableRow ref={el => (rowRefs.current[index] = el)} className={classes.tableRow}>
                    <TableCell className={classes.cell}>
                        <Dropdown 
                            testCompleted={testCompleted} 
                            isViewer={isViewer} index={index} 
                            forwardRef={rowRefs.current[index]} 
                            coltype='floorPlans' 
                            fpOptions={floorPlans} 
                            onChangeFunc={onChangeFunc} 
                            segment={individualSegment} />
                    </TableCell>
                    <TableCell className={classes.cell}>
                        {testType === 'dilution' && (1)}
                        {testType === 'recirculation' && (
                            <Dropdown
                                testCompleted={testCompleted}
                                isViewer={isViewer}
                                index={index}
                                forwardRef={rowRefs.current[index]}
                                coltype='originPointsRecirculation'
                                onChangeFunc={onChangeFunc}
                                segment={individualSegment} />
                        )}
                    </TableCell>
                    <TableCell className={classes.cell}>
                        <Dropdown
                            testCompleted={testCompleted}
                            isViewer={isViewer}
                            disableSelect={testType === 'recirculation' ? false : !projectFromProjectPortal}
                            index={index}
                            forwardRef={rowRefs.current[index]}
                            coltype={testType === 'recirculation' ? 'samplePointsRecirculation' : 'samplePointsDilution'}
                            onChangeFunc={onChangeFunc}
                            segment={individualSegment} />
                    </TableCell>
                    <TableCell className={classes.cell}>
                        {testType === 'dilution' && (4)}
                        {testType === 'recirculation' && (1)}
                        {/*<Dropdown index={index} forwardRef={rowRefs.current[index]} coltype='intervalCount' onChangeFunc={onChangeFunc} segment={individualSegment} />*/}
                    </TableCell>
                    <TableCell className={classes.cell}>
                        {testType === 'dilution' && (
                            <Dropdown
                                testCompleted={testCompleted}
                                isViewer={isViewer} index={index}
                                forwardRef={rowRefs.current[index]}
                                coltype='intervalDuration'
                                onChangeFunc={onChangeFunc}
                                segment={individualSegment} />
                        )}
                        {testType === 'recirculation' && (30)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                        <Dropdown 
                            index={index} 
                            forwardRef={rowRefs.current[index]} 
                            coltype='scenarios' 
                            options={null} 
                            onChangeFunc={onChangeFunc} 
                            segment={individualSegment}
                            testCompleted={testCompleted} 
                            isViewer={isViewer}
                            disableScenarios={disableScenarios}
                            scenarios={scenarios} />
                    </TableCell>
                    <TableCell className={classes.cell}>
                        <form onSubmit={e => handleDateChange(e)} noValidate autoComplete="off">
                            <div style={{ marginLeft: '50px', display: 'flex', flexDirection: 'row' }}>
                                <DatePicker
                                    variant="inline"
                                    name='surveyDate'
                                    autoOk={true}
                                    format={'MMM do, yyyy'}
                                    inputProps={{ min: 0, style: { textAlign: 'left', paddingLeft: '10px', minWidth: '135px'} }}
                                    value={surveyDate !== null ? parseISO(surveyDate) : null}
                                    emptyLabel=''
                                    onChange={e => handleDateChange(e)}
                                    disabled={testCompleted || isViewer}
                                />
                            </div>
                        </form>
                    </TableCell> 
                    <TableCell className={classes.cell}>
                        <form onSubmit={e => onKeyDownTextUpdate(e, 'comments', null, index)} noValidate autoComplete="off">
                            <div style={{ marginLeft: '50px', display: 'flex', flexDirection: 'row' }}>
                                <TextField
                                    coltype='comments'
                                    disabled={testCompleted || isViewer} 
                                    type="text"
                                    multiline
                                    rows={3}
                                    inputProps={{
                                        style: { 
                                            textAlign: 'center', 
                                            fontFamily: 'Gotham', 
                                            fontSize: '13px', 
                                            marginTop: '10px',
                                            width: '75%',
                                            height: 'auto'
                                        }
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    value={comments}
                                    onChange={e => onTextChangeFunc(e, index, 'comments')} 
                                />
                                <Tooltip title="Submit Comments" placement="top" aria-label="origin-points">
                                    <IconButton  
                                        type="submit"
                                        style={{ position: 'relative', right: '40px' }}
                                        disabled={testCompleted || isViewer}
                                    >
                                        <CloudUploadIcon style={{color: "rgba(0, 0, 0, 0.54)"}} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </form>
                    </TableCell> 
                </TableRow>
                {scenarios.length !== 0 && (
                    <>
                        <TableRow >
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={6}>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <Box margin={1}>
                                        <Table aria-label="purchases">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}></TableCell>
                                                    <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}></TableCell>
                                                    <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}></TableCell>
                                                    <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}></TableCell>
                                                    <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}></TableCell>
                                                    <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}>Scenario Name</TableCell>
                                                    <TableCell style={{ borderBottom: 'none' }} className={classes.headerCell}>Description</TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                        {scenarios.map((scenario, i) => (
                            <NestedTableScenario  
                                key={`scenario-key-${i}`}
                                index={i}
                                scenario={scenario}
                                segments={segments}
                                scenarioDescription={scenarioDescription}
                                ops={ops}
                                isViewer={isViewer}
                                testCompleted={testCompleted}
                                tagLotNumbers={tagLotNumbers}
                                expirationDates={expirationDates}
                                onTextChangeFunc={onTextChangeFunc}
                                onKeyDownTextUpdate={onKeyDownTextUpdate}
                                handleTagLotNumberChange={handleTagLotNumberChange}
                                onKeyDownLotNumberExpirationDateUpdate={onKeyDownLotNumberExpirationDateUpdate}
                                onChangeFunc={onChangeFunc}
                            />
                        ))}
                    </>
                )}
            </>
        )
    } else {
        return null;
    }
}