import React from 'react';
import Typography from '@material-ui/core/Typography'
import { Form } from '../../components/Form/Form';
import users from '../../api/users';
import authentication from '../../api/authentication';
import { AlertGeneral } from '../../components/Alert/AlertGeneral';
import './NewUserView.css';

class NewUserView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName:'',
            lastName:'', 
            email:'',
            password:'', 
            accessLevel: '',
            dropdownsValues: [],
            companyNames: [],
            customerCompanies: [],
            selectedPartner: '',
            selectedCustomer: '',
            accessNotSet: null,
            alertMsg: '',
            open: false
        };

        this.newUserArr = [
            {name : 'firstName' , label: 'First Name'},
            {name : 'lastName' , label: 'Last Name'},
            {name : 'email' , label: 'Email'},
            {name : 'password' , label: 'Password'},
            {name : 'accessLevel' , label: 'Access Level'},
            //{name : 'roleName' , label: 'roleName'},
        ]
    }

    async componentDidMount() {
        const response = await authentication.getPartners();
        if (response && response.data && response.data.success) {
            response.data.data.forEach(obj => {
                this.setState(prevState => ({
                    companyNames: [...prevState.companyNames, obj.companyname]
                }));
            })
        } else {
            console.log('Error', response);
        }

        const customerResponse = await authentication.getAllCustomers();
        if(customerResponse.data.success) {
            customerResponse.data.data.forEach(obj => {
                this.setState(prevState => ({
                    customerCompanies: [...prevState.customerCompanies, obj.companyname]
                }));
            })
        } else {
            console.log('Error', customerResponse.data.success);
        }
    };

    onSubmit = async (values) => {
        //Values is returned by react-hook-form handleSubmit()
        let data = {
            firstname: values.firstName,
            lastname: values.lastName,
            email: values.email,
            password: values.password,
            accessLevel: values.accessLevel
        };
        if(this.state.selectedPartner && this.state.selectedCustomer) {
            this.setState({
                alertMsg: `Please provide either partner or customer company name. Both cannot be selected.`,
                open: true,
                selectedPartner: '',
                selectedCustomer: ''
            })
            return;
        } 
        if(this.state.selectedPartner) {
            data.companyName = this.state.selectedPartner;
            data.roleName = 'Partner';
        } else if(this.state.selectedCustomer) {
            data.companyName = this.state.selectedCustomer;
            data.roleName = 'Customer';
        }
        if(data.accessLevel === undefined) {
            this.setState({ accessNotSet: true }, () => {
                //console.log('this.state.accessNotSet: ', this.state.accessNotSet);
            });
            return;
        } else {
            const response = await users.registerUser(data);
            if(response.data.success) {
                this.props.history.push('/users');
            } else {
                if(response.data.error) {
                    let error = response.data.error;
                    // TODO: Need correct error response
                    if(Object.values(error).includes('Error')) {
                        let payload = JSON.parse(response.config.data);
                        let email = payload.email;
                        this.setState({
                            alertMsg: `Email ${email} already exists. Please use another email address.`,
                            open: true
                        })
                    }
                }
            }
        }
    }

    handleAlertClose = () => this.setState({ open: !this.state.open });

    handleInputChange = event => {
        const value = event.target.value;
        const name = event.target.name;
        
        this.setState({
            [name]: value
        });
    }

    handleSelectChange = (event, name) => {
        const value = event.target.value;
        
        this.setState({
            [name]: value
        });
    }

    render() {
        const { companyNames, alertMsg, open, customerCompanies } = this.state;
        return (
            <>
                <main style={{ marginTop: '35px' }} className="new-user-wrapper">
                    <div className="new-user-wrapper-header">
                        <Typography variant="h4" color="textPrimary" align="center" style={{fontFamily: "Gotham", marginTop: "30px"}}>New User Form</Typography>
                    </div>
                    <div className="new-user-wrapper-form">
                        <Form 
                            textFieldArr={this.newUserArr}  
                            handleChangeFunc={this.handleInputChange}
                            handleSubmitFunc={this.onSubmit}
                            companyNames={companyNames}
                            customerCompanies={customerCompanies}
                            type={`newUser`}
                            accessNotSet={this.state.accessNotSet}
                            selectedPartner={this.state.selectedPartner}
                            selectedCustomer={this.state.selectedCustomer}
                            handleSelectChange={this.handleSelectChange}
                        />
                    </div>
                </main>
                {!!open && (<AlertGeneral open={open} alertMsg={alertMsg} closeFunc={this.handleAlertClose} severity='error' />)}
            </>
        )
    }
}

export default NewUserView;