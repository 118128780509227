import React, { useRef, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import { Context } from '../../context/Context';
import logo from '../../assets/SafeTraces_Logo.Horiz.png';
import { VERIFICATION_LIKE_TESTS } from '../../config';
import authentication from '../../api/authentication';
import './Navbar.css';

const useStyles = makeStyles(() => ({
    nav: {
        backgroundColor: '#ffffff'
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    button: {
        borderColor: '#008996',
        color: '#008996',
        margin: '10px',
        fontFamily: 'Gotham',
        '&:hover': {
            fontWeight: 'bold',
        }
        
    } 
  }));

  export const Navbar = props => {
    const classes = useStyles();
    const { setNotOnProject } = useContext(Context);
    const { history } = props;
    
    const projectDetailsRef = useRef(null);
    const projectConfigurationRef = useRef(null);
    const segmentsRef = useRef(null);

    // const floorPlansRef = useRef(null);
    // const testPlansRef = useRef(null);
    // const checkListsRef = useRef(null);
    // const lablistsRef = useRef(null);
    // const labListsRef = useRef(null);

    const labelsRef = useRef(null);
    const firstUpdate = useRef(true);
    const { state } = useContext(Context);
    const { testType } = state;

    useEffect(() => {
        if (!firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        const buttons = document.querySelectorAll('.navbar-button-container > *');
        buttons.forEach(button => {
            button.style.visibility = 'visible';
        })
    });

    const handleLogout = async() => {
        const response = await authentication.logout();
        if(response.data.success) {
            localStorage.clear();
            props.history.push('/login');
        }
    }

    const handleCurrentTab = ref => {
        setTimeout(() => {
            ref.current.classList.add('selected');
        },0);
    }
    return (
        <main className="navbar-wrapper" style={{ position: 'fixed', zIndex: '11' }}>
            <AppBar className={classes.nav} position="static">
                <Context.Consumer>
                    {(context) => (
                        context.projectId !== null && (
                            <Toolbar className={classes.toolbar} position="fixed"  variant="regular">
                                <img src={logo} alt="logo" className="navbar-logo" />
                                <div className="navbar-button-container">
                                    {(!!context.state.projectDetails) && (
                                        <>
                                            <Button id="pBtn" ref={projectDetailsRef} name="project" className={classes.button} onClick={() => handleCurrentTab(projectDetailsRef)} component={NavLink} variant="outlined" to={`/projectdetails`}>Project Details {context.projectId}</Button>
                                            <Button id="pcBtn" ref={projectConfigurationRef} name="projectConfiguration" className={classes.button} onClick={() => handleCurrentTab(projectConfigurationRef)} component={NavLink} variant="outlined" to={`/datacollectionview`}>Project Configuration</Button>
                                            <Button id="lBtn" ref={labelsRef} name="labels" className={classes.button} onClick={() => handleCurrentTab(labelsRef)} component={NavLink} variant="outlined" to={`/projectview`}>Labels and Materials</Button>
                                            {/*<Button id="fpBtn" ref={floorPlansRef} name="floorPlans" className={classes.button} onClick={() => handleCurrentTab(floorPlansRef)} component={NavLink} variant="outlined" to={`/floorplans`}>Floor Plans</Button>*/}
                                        </>
                                    )}
                                    {(!!context.state.onProject) && (
                                        <>
                                          <Button id="ppBtn" name="projectPage" className={classes.button} variant="outlined" onClick={() => {
                                            setNotOnProject();
                                            history.push('/projectdetails');
                                          }}>Project Details</Button>
                                            {testType === 'generalSurvey' && (<Button id="sBtn" ref={segmentsRef} name="segments" className={classes.button} onClick={() => handleCurrentTab(segmentsRef)} component={NavLink}  variant="outlined" to="/segments">Test Details</Button>)}
                                            {!!~['dilution', 'recirculation', 'miniSurvey', ...VERIFICATION_LIKE_TESTS].indexOf(testType) && (<Button id="sBtn" ref={segmentsRef} name="segments" className={classes.button} onClick={() => handleCurrentTab(segmentsRef)} component={NavLink}  variant="outlined" to="/scenarios">Test Details</Button>)}
                                            {/*<Button id="lBtn" ref={labelsRef} name="labels" className={classes.button} onClick={() => handleCurrentTab(labelsRef)} component={NavLink} variant="outlined" to={`/labelsview`}>Labels</Button> */}
                                            {/* <Button id="clBtn" ref={checkListsRef} name="checklists" className={classes.button} onClick={() => handleCurrentTab(checkListsRef)} component={NavLink} variant="outlined" to={`/checklistsview`}>Checklists</Button> */}
                                            {/* {(testType === 'generalSurvey' || testType === 'dilution' || testType === 'miniSurvey' || testType === 'recirculation') && (<Button id="labBtn" ref={labListsRef} name="checklists" className={classes.button} onClick={() => handleCurrentTab(labListsRef)} component={NavLink} variant="outlined" to={`/laboratorysview`}>Laboratory</Button>)} */}
                                        </>
                                    )}
                                </div>
                                <div className="logout-wrapper">
                                    <Button onClick={handleLogout} className={classes.button} variant="outlined">Logout</Button>
                                    <div style={{ color: '#008996' }}><b>{`${JSON.parse(localStorage.getItem("userEmail"))}`}</b></div>
                                </div>
                            </Toolbar>
                        )
                    )}
                </Context.Consumer>   
            </AppBar>
        </main>
    );
}