export const materials = [
    'Test Plan',
    'Floorplan(s)',
    'HVAC Layout(s) with Zone Location(s), Supply and Return Designations',
    'Sample and Origin Points',
    'Origin Point Labels with Tag Id',
    'Sample Point Labels with Sampling ID',
    'Origin and Sample Point Location Markers (Balloons, Cups, Flags, etc.)',
    'Eppendorf Tubes for Filters',
    'Ziplock Plastic Bags for Samples (Small for Filters)',
    'Disposable Gloves (Medium and Large)',
    'Disposable Arm Sleeves',
    'Garbage Bags',
    'Pens, Markers',
    'Tape',
    'Tap Measure',
    'Tweezers',
    'Swab and/or Cuvette Holder(s)',
    'Bleach Wipes',
    'Paper Towels',
    'Extra Labels',
    'Deionized or Distilled Water',
    'Safety Data Sheets (SDS)'
];

export const equipment = [
    'Air Samplers: Quantity =',
    'Power Source for Air Samplers: Quantity =',
    'Air Sampler Test Kits: (Filter and Bags with Labels)',
    'Spray Buckos',
    'Swab Sampler Test Kits: (Swabs, Swab Boats and Bags with Labels)',
    'Temperature/RH Gage',
    'Anemometer',
];

export const infoCellPrimaryData = [
    { title: 'Prepared By', text: '' },
    { title: 'Program', text: 'veriDART' },
    { title: 'Document Number', text: '' },
    { title: 'Number of Pages', text: '' },
    { title: 'Effective Date', text: '' },
    { title: 'Version', text: '1' }
];

export const infoCellSecondaryData = [
    { title: 'Project Date', placeholder: 'Project Date' },
    { title: 'Checklist Preparation Date', placeholder: 'Checklist Preparation Date' },
    { title: 'Facility', placeholder: 'Facility' },
    { title: 'Location', placeholder: 'Location' }
];