import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import sampleplans from '../../api/sampleplans';
import samplepoints from '../../api/samplepoints';
import './COCChecklistTable.css';

const styles = theme => ({
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '20px',
        textAlign: 'center'
    },
    cell: {
        fontFamily: 'Gotham',
        fontSize: '16px'
    },
    loneCell: {
        fontFamily: 'Gotham',
        fontSize: '20px',
        textAlign: 'center'
    }
});

class COCChecklistTable extends Component {

    state = { 
        SNresponse: [],
        SPresponse: [],
        sampleNumbers: [],
        spNumbers: [],
        plates: [],
    };

    extractValues = (arr, property) => {
        if(property === 'spnumber') {
            return arr.map(obj => obj.sampleNumber);
        } else if(property === 'plates') {
            return arr.map(obj => obj.plates);
        }
    }

    async componentDidMount() {
        let plates;
        let SPresponse;
        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        let testId = JSON.parse(localStorage.getItem("testId")) ? JSON.parse(localStorage.getItem("testId")) : null;

        if(testId !== null) {
            SPresponse = await sampleplans.getLabels(testId, projectId);
            plates = this.extractValues(SPresponse.data.data, 'plates');
            this.setState({ 
                SPresponse: SPresponse,
                plates
            }, () => {
                this.state.SPresponse.data.data.forEach(obj => {
                    const { sampleNumber } = obj;
                    this.setState(prevState => ({
                        sampleNumbers: [...prevState.sampleNumbers, sampleNumber]
                    }));
                });
            });
        } else {
            SPresponse = await sampleplans.getLabels(testId=null, projectId);
            let spNums = this.extractValues(SPresponse.data.data, 'spnumber');
            plates = this.extractValues(SPresponse.data.data, 'plates');
            
            this.setState({ 
                spNumbers: spNums, 
                plates
            }); 
        }
    }

    render() {
        const { sampleNumbers, spNumbers, plates } = this.state;
        const { classes } = this.props;
        return (
            <>
                <TableContainer component={Paper}>
                    <div className="coc-table-wrapper">
                        <Table aria-label="">
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>Line</TableCell>
                                    <TableCell className={classes.headerCell}>Sample Numbers</TableCell>
                                    <TableCell className={classes.headerCell}>Plate Designation (A1, B2, Etc.)</TableCell>
                                    <TableCell className={classes.headerCell}>Operator, Verified on</TableCell>
                                    <TableCell className={classes.headerCell}>Comments</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sampleNumbers.length > 0 ? (
                                    sampleNumbers.map((num, i) => (
                                        <TableRow key={`key=${i}`}>
                                            <TableCell style={{ textAlign:'center' }} className={classes.cell}>{i+1}</TableCell>
                                            <TableCell style={{ textAlign:'center' }} className={classes.cell}>{num}</TableCell>
                                            <TableCell style={{ textAlign:'center' }} className={classes.cell}>{plates && (plates[i])}</TableCell>
                                            <TableCell style={{ textAlign:'center' }} className={classes.cell}></TableCell>
                                            <TableCell style={{ textAlign:'center' }} className={classes.cell}></TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    spNumbers.map((num, i) => (
                                        <TableRow key={`key=${i}`}>
                                            <TableCell style={{ textAlign:'center' }} className={classes.cell}>{i+1}</TableCell>
                                            <TableCell style={{ textAlign:'center' }} className={classes.cell}>{num && (num)}</TableCell>
                                            <TableCell style={{ textAlign:'center' }} className={classes.cell}>{plates && (plates[i])}</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
                <TableContainer component={Paper}>
                    <div className="coc-table-wrapper">
                        <Table aria-label="">      
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell colSpan={4} className={classes.loneCell}>Relinquished/Received By:</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>Print Name</TableCell>
                                    <TableCell className={classes.headerCell}>Signature</TableCell>
                                    <TableCell className={classes.headerCell}>Date &amp; Time Relinquished</TableCell>
                                    <TableCell className={classes.headerCell}>Date &amp; Time Received</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.cell}>1</TableCell>
                                    <TableCell className={classes.cell}></TableCell>
                                    <TableCell className={classes.cell}></TableCell>
                                    <TableCell className={classes.cell}></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.cell}>2</TableCell>
                                    <TableCell className={classes.cell}></TableCell>
                                    <TableCell className={classes.cell}></TableCell>
                                    <TableCell className={classes.cell}></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
                <TableContainer component={Paper}>
                    <div className="coc-table-wrapper">
                        <Table aria-label="">      
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell colSpan={4} className={classes.loneCell}>Received at Laboratory By:</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>Print Name</TableCell>
                                    <TableCell className={classes.headerCell}>Signature</TableCell>
                                    <TableCell className={classes.headerCell}>Date Received</TableCell>
                                    <TableCell className={classes.headerCell}>Time Received</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.cell}>1</TableCell>
                                    <TableCell className={classes.cell}></TableCell>
                                    <TableCell className={classes.cell}></TableCell>
                                    <TableCell className={classes.cell}></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.cell}>2</TableCell>
                                    <TableCell className={classes.cell}></TableCell>
                                    <TableCell className={classes.cell}></TableCell>
                                    <TableCell className={classes.cell}></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
            </>
        );
    }  
}

export default withStyles(styles, { withTheme: true })(COCChecklistTable);