import React from '../../../node_modules/react';
import { AgGridReact } from '../../../node_modules/ag-grid-react/main';
import '../../../node_modules/ag-grid-community/dist/styles/ag-grid.css';
import '../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css';

class SamplePointsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [{
                headerName: "SPNumber", field: "spnumber", width: 50
                }, {
                headerName: "SPName", field: "spname", width: 70
                },{
                    headerName: "CollectionMethod", field: "collectionmethod", width: 70
                },{
                    headerName: "AirSamplerQuantity", field: "airsamplerquantity", width: 50
                },{
                    headerName: "SPId", field: "spid", width: 100 
                }],
                defaultColDef: {
                    editable: true,
                    sortable: true,
                    flex: 1,
                    minWidth: 100,
                    filter: true,
                    resizable: true,
                }
        }

        this.onRowSelected = this.onRowSelected.bind(this);
    }
    
    onRowSelected(event) {
        // const projectId = event.data.id;
        // this.props.history.push('/' + projectId + '/segments');
    }
    

    render() {
        return (
            <div
            className="ag-theme-alpine"
            style={{
            height: '600px',
            width: '95%',
            textAlign: "center",
            margin:'10px' }}>
                <AgGridReact
                    pagination={true}
                    columnDefs={this.state.columnDefs}
                    rowData={this.props.samplePoints}
                    defaultColDef={this.state.defaultColDef}
                    rowSelection="single"
                    onRowSelected= {this.onRowSelected}>
                </AgGridReact>
            </div>
        );
    }
}

export default SamplePointsList;