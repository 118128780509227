import React from '../../../node_modules/react';
import { Context } from '../../context/Context';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import { NestedTable } from '../NestedTable/NestedTable';
import InputSearch from "../../components/InputSearch";

const styles = () => ({
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'center',
        padding: '10px !important'
    },
});

class ProjectList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            projects: [],
            projectId: null,
            filter: '',
        }
        this.show = false;
    }

    handlePageChange = (e, value) => {
        this.setState({ page: value });
        this.props.updatePage(value - 1);
    };

    setNumberOfPages = (projectsCount, itemsPerPg) => {
        this.numberOfPages = Math.ceil(projectsCount / itemsPerPg);
    }

    handleFilter = (value) => {
        this.props.updateQuery(value);
        this.setState({filter: value, page:1});
    }

    render() {
        const { page } = this.state;
        const { classes, accessLevel, rowsPerPage, totalProjects } = this.props;

        if(this.props.projects) {
            this.setNumberOfPages(totalProjects, rowsPerPage);
        }

        return (
            <main>
                <div>
                    <InputSearch
                        id="filterProjects"
                        placeholder="Search by Project ID or Name"
                        name="filterProjects"
                        onChange={value => this.handleFilter(value)}
                        delay={500}
                    />
                </div>
                <TableContainer style={{ marginTop: '15px' }} component={Paper}>
                    <div>
                        <Table aria-label="project-list-table">
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>Tests</TableCell>
                                    {accessLevel !== 'Viewer' && (
                                        <TableCell className={classes.headerCell}>Delete Project</TableCell>
                                    )}
                                    <TableCell className={classes.headerCell}>Project Name</TableCell>
                                    <TableCell className={classes.headerCell}>Project ID</TableCell>
                                    <TableCell className={classes.headerCell}>End Customer</TableCell>
                                    <TableCell className={classes.headerCell}>Partner</TableCell>
                                    <TableCell className={classes.headerCell}>Created</TableCell>
                                    <TableCell className={classes.headerCell}>Description</TableCell>
                                    <TableCell className={classes.headerCell}>Status</TableCell>
                                    <TableCell className={classes.headerCell}>New Tests</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.projects
                                    .map((project, i) => (
                                        <NestedTable 
                                            key={`key-${i}`} 
                                            project={project} 
                                            index={i}
                                            projTests={project.tests}
                                            projectId={project.id} 
                                            projectName={project.name}
                                            createdAt={project.createdAt} 
                                            status={project.status}
                                            testCompleted={project.testCompleted}
                                            projectFromProjectPortal={project.origin === 'project_portal'}
                                            fetchUpdatedProjects={this.props.fetchUpdatedProjects} />
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
                <section className="labels-pagination-wrapper">
                    <Pagination
                        count={this.numberOfPages}
                        page={page}
                        defaultPage={1}
                        onChange={this.handlePageChange}
                        color="standard"
                        size="medium"
                        showFirstButton
                        showLastButton
                    />
                </section> 
            </main>                   
        )
    }
}
ProjectList.contextType = Context;
export default withStyles(styles, { withTheme: true })(ProjectList);