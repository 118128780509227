import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

const useRowStyles = makeStyles({
	rowNoBorder: {
		'& > *': {
		  borderBottom: 'unset',
		},
	},
    fontSettings: {
        fontFamily: 'Gotham',
        fontSize: '13px',
    },
	innerTableContainer: {
		width: '90%', 
		marginLeft: 'auto', 
		padding: '4px 0px 16px 8px',
	},
});

const CommentForm = (props) => {
	const [descriptionText, setDescriptionText] = useState('');
	const { viewOnly, defaultValue, onSubmit } = props;

	useEffect(() => {
		setDescriptionText(defaultValue);
	}, [defaultValue]);

	const textFieldInputProps = {
		style: {
			textAlign: 'center',
			fontFamily: 'Gotham',
			fontSize: '13px',
			marginTop: '10px',
			height: 'auto'
		}
	};

	const handleDescriptionUpdate = async (e) => {
		try {
			e.preventDefault();
			if (typeof descriptionText === 'string' && defaultValue !== descriptionText) {
				await onSubmit(descriptionText);
			}
		} catch (err) {
			console.log('handleDescriptionUpdate err', err);
		}
	};

	return (
		<form id='scenariios-form' onSubmit={handleDescriptionUpdate} noValidate autoComplete="off">
			<div id="scenariios-container">
				<TextField
					id="scenariios-input"
					disabled={viewOnly}
					type="text"
					multiline
					minRows={3}
					inputProps={textFieldInputProps}
					InputProps={{ disableUnderline: true }}
					value={descriptionText || ''}
					onChange={(e) => setDescriptionText(e.target.value)}
				/>
				<Tooltip title="Submit description" placement="top" aria-label="scenariios-btn">
					<IconButton
						id='scenariios-btn'
						type="submit"
						disabled={viewOnly}
					>
						<CloudUploadIcon />
					</IconButton>
				</Tooltip>
			</div>
		</form>
	)
};

const ScenariosRow = (props) => {
	const { scenario, collapseOpTable, viewOnly, updateDescription } = props;
	const [open, setOpen] = useState(false);
	
	const classes = useRowStyles();

	if (!scenario || !Object.keys(scenario)) return;
	const { scenarioname, scenariodescription } = scenario;

	return (
		<>
			<TableRow className={classes.rowNoBorder}>
				<TableCell key='1' align='center'>
					<IconButton
						aria-label="expand row" 
						size="small" 
						onClick={() => setOpen(!open)
					}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell key='2' align='center' className={classes.fontSettings}>{scenarioname}</TableCell>
				<TableCell key='3' align='center' className={classes.fontSettings}>
					<CommentForm
						viewOnly={viewOnly}
						defaultValue={scenariodescription}
						onSubmit={updateDescription}
					/>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell colSpan={3}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box className={classes.innerTableContainer}>
							{collapseOpTable}
						</Box>
					</Collapse>
				</TableCell>
      		</TableRow>
		</>
    );
}

export default ScenariosRow;