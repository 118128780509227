import React, { Component } from 'react';
import { Context } from '../../context/Context';
import Typography from '@material-ui/core/Typography';
import { TableComponent } from '../../components/TableComponent/TableComponent';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Tooltip from '@material-ui/core/Tooltip';
import users from '../../api/users';
import './UsersView.css';

export class UsersView extends Component {

    state = {
        projectId: null,
        headerCategories: ['First Name', 'Last Name', 'Company Name', 'Email', 'Permission', 'Action'],
        users: [],
        update: false
    };

    async componentDidMount() {
        let projectId = localStorage.getItem("projectId");
        this.setState({ projectId: projectId });
        const response = await users.getUsers();
        this.setState({ 
            users: response.data.data,
        }, () => {
            //console.log('UsersView this.state.users: ', this.state.users)
        });
    };

    handleClick = () => {
        this.props.history.push('/newuser');
    }

    updateUserAccessLevel = async () => {
        const response = await users.getUsers();
        this.setState({ 
            users: response.data.data,
        }, () => {
            //console.log('UsersView this.state.users: ', this.state.users)
        });
    }

    render() {
        const { headerCategories, users } = this.state;
        return (
            <Context.Consumer>
                {context => (
                    <main className="uv-table-container">
                        <section className="uv-header-section">
                            <div className="uv-title-container">
                                <Typography variant="h4" color="textPrimary" align="center" style={{fontFamily: "Gotham"}}>{`Users`}</Typography>
                            </div>
                            <div className="uv-btn-container">
                                <Tooltip title="Add User" placement="top" aria-label="add-user">
                                    <IconButton style={{ color: '#82BC00' }} onClick={this.handleClick} aria-label="add-user-button">
                                        <AddBoxIcon style={{ height: '45px', width: '45px', marginRight: '20px' }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </section>
                        <section>
                            <div style={{ width: '95%' }} className="uv-materials-table-wrapper">
                                <TableComponent headers={headerCategories} cellData={users} type={`users`} updateUserAccessLevel={this.updateUserAccessLevel} />
                            </div>
                        </section>
                    </main>
                )}
            </Context.Consumer>
        );
    }

}