import React from "react";
import { withStyles } from '@material-ui/core/styles';
//https://dev.to/ramonak/react-how-to-create-a-custom-progress-bar-component-in-5-minutes-2lcl

const styles = (theme) => ({
    containerStyles: {
        height: 20,
        width: '85%',
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        margin: 50
    },

    fillerStyles: {
        height: '100%',
        //width: `${theme.completed}%`,
        //backgroundColor: theme.bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right'
    },
    labelStyles: {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
    },

})
class ProgressBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bgcolor: props.bgcolor,
            completed: props.completed,
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.containerStyles}>
                <div className={classes.fillerStyles} style={{ backgroundColor: this.props.bgcolor, width: this.props.complete + '%' }}>
                    <span className={classes.labelStyles}>{`${this.props.complete}%`}</span>
                </div>
            </div>
        );
    }


};

export default withStyles(styles)(ProgressBar);