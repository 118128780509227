import React from 'react';
import projects from '../../api/projects';
import ProjectList from '../../components/ProjectList/ProjectList.js';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Tooltip from '@material-ui/core/Tooltip';
import { Context } from '../../context/Context';
import './Projects.css';

class Projects extends React.Component {

    state = {
        projects: [],
        page: 0,
        totalProjects:1,
        rowsPerPage: 10,
        order: 'desc',
        query: '',
    };

    async componentDidMount() {
        localStorage.removeItem('projectFromProjectPortal');
        await this.fetchProjects();
    }

    async componentDidUpdate(prevProps, prevState) {
        if ( this.state.query !== prevState.query || this.state.page !== prevState.page) {
            await this.fetchProjects();
        }
    }

    fetchUpdatedProjects = async () => {
        await this.fetchProjects();
    }

    fetchProjects = async () => {
        const response = await projects.getAllProjects({
            params: {
                pageLimit: this.state.rowsPerPage,
                page: this.state.page,
                pageOrder: 'createdate',
                direction: this.state.order,
                query: this.state.query
            }
        });
        if(response && response.data && response.data.data && response.data.data.projects) {
            this.setState ({projects: response.data.data.projects, totalProjects: response.data.data.total});
            this.context.setNotOnProject();
            this.context.setNotOnProjectDetails();
        }
    }

    handleClick = () => {
        this.props.history.push('/create');
    }

    updateQuery = (query) => {
        this.setState({query: query, page:0});
    }
    updatePage = (page) => {
        this.setState({page});
    }

    render() {
        return (
            <Context.Consumer>
                {context => (
                    <div className="projects-container">
                        <Typography variant="h4" color="textPrimary" align="center" style={{fontFamily: "Gotham", marginTop: "30px"}}>My Projects</Typography>
                        <div className="projects-body">
                            {context.state.accessLevel !== 'Viewer' && (
                                <div className="projects-btn-container">
                                    <Tooltip title="Add Project" placement="top" aria-label="add-project">
                                        <IconButton style={{ color: '#82BC00' }} onClick={this.handleClick} aria-label="add-project-button">
                                            <AddBoxIcon style={{ height: '45px', width: '45px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )}
                            <ProjectList 
                                fetchUpdatedProjects={this.fetchUpdatedProjects}
                                updateQuery={this.updateQuery}
                                updatePage={this.updatePage}
                                projects={this.state.projects}
                                totalProjects={this.state.totalProjects}
                                rowsPerPage={this.state.rowsPerPage}
                                accessLevel={context.state.accessLevel}
                                history={this.props.history}/>
                        </div>
                    </div>
                )}
            </Context.Consumer>
        )
    }
}
Projects.contextType = Context;
export default Projects;