import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

export const Dropdown = ({ index, forwardRef, coltype, fpOptions, onChangeFunc, segment, scenarios, disableScenarios, testCompleted, isViewer, disableSelect }) => {
    let fpOptionsArr = [];
    const intervalDurations = [1, 2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15];
    const scenariosList = [1, 2, 3, 4, 5];
    const numSps = [12, 24, 36, 48, 60];
    const numSpsDilution = [1, 4];
    const numSpsRecirculation = [1, 2, 3, 4, 6, 8, 10, 12];
    const numOpsRecirculation = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const numOpsVerification = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const numSpsVerification = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const intervalCountsVerification = [1, 2, 3, 4];
    const plates = ['A', 'B'];
    let disableScenariosSelect = false;
    let testType = JSON.parse(localStorage.getItem('testType'));

    const getFloorPlans = fps => {
        let fpList = [];
        fps.forEach(floorplan => {
            fpList.push(floorplan.filename);
        });
        return fpList;
    }

    if (fpOptions !== undefined) {
        fpOptionsArr = getFloorPlans(fpOptions);
    }

    if (coltype === 'scenarios' && disableScenarios === true) {
        disableScenariosSelect = true;
    }

    const handleChange = (event, forwardRef) => {
        onChangeFunc(event, forwardRef, coltype, segment);
    };

    const renderSelectOptionsSwitch = (coltype, fpOptionsArr) => {
        switch (coltype) {
            case 'floorPlans':
                return fpOptionsArr.map((option, i) => (
                    <MenuItem key={`fp-key-${i}`} style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={option}>{option}</MenuItem>
                ))
            case 'samplePointsDilution':
                return numSpsDilution.map((sp, i) => (
                    <MenuItem key={`sp-key-${i}`} style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={sp}>{sp}</MenuItem>
                ))
            case 'samplePointsRecirculation':
                return numSpsRecirculation.map((sp, i) => (
                    <MenuItem key={`sp-key-${i}`} style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={sp}>{sp}</MenuItem>
                ))
            case 'originPointsRecirculation':
                return numOpsRecirculation.map((op, i) => (
                    <MenuItem key={`op-key-${i}`} style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={op}>{op}</MenuItem>
                ))
            case 'originPointsVerification': 
                const { sp } = segment;
                if (!sp || !+sp) return [];

                const availableOptionsOP = numOpsVerification.filter((op) => +op >= +sp);

                return availableOptionsOP.map((op, i) => (
                    <MenuItem key={`op-key-${i}`} style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={op}>{op}</MenuItem>
                ))

            case 'samplePointsVerification':
                const { op } = segment;
                if (!op || !+op) return [];

                const availableOptionsSP = numSpsVerification.filter((sp) => +op >= +sp);
                return availableOptionsSP.map((sp, i) => (
                    <MenuItem key={`scenario-key-${i}`} style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={sp}>{sp}</MenuItem>
                ))       
            /*
            case 'ops':
                return ops.map((op, i) => (
                    <MenuItem key={`op-key-${i}`} style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={op}>{op}</MenuItem>
                ))
            case 'intervalCount':
                return intervalCounts.map((interval, i) => (
                    <MenuItem key={`inter-key-${i}`} style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={interval}>{interval}</MenuItem>
                ))
            */

           case 'intervalCountVerification':
            return intervalCountsVerification.map((interval, i) => (
                <MenuItem key={`inter-key-${i}`} style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={interval}>{interval}</MenuItem>
            ))

            case 'intervalDuration':
                return intervalDurations.map((duration, i) => (
                    <MenuItem key={`dur-key-${i}`} style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={duration}>{`${duration} min`}</MenuItem>
                ))
            case 'scenarios':
                return scenariosList.map((scenario, i) => (
                    <MenuItem key={`scenario-key-${i}`} style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={scenario}>{scenario}</MenuItem>
                ))

            case 'samplePoints':
                return numSps.map((sp, i) => (
                    <MenuItem key={`scenario-key-${i}`} style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={sp}>{sp}</MenuItem>
                ))
            case 'plates':
                return plates.map((plate, i) => (
                    <MenuItem key={`scenario-key-${i}`} style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }} value={plate}>{plate}</MenuItem>
                ))
            default:
                break;
        }
    }

    const setSelectValue = (segment, coltype) => {
        let value = null;
        switch (coltype) {
            case 'floorPlans':
                if (segment.floorplan !== null) {
                    segment.floorplan = segment.floorplan;
                } else {
                    segment.floorplan = '';
                }
                value = segment.floorplan ? segment.floorplan : '';
                return value
            case 'originPointsVerification':
            case 'originPointsRecirculation':
                value = segment.op ? segment.op : '';
                return value
            case 'samplePoints':
            case 'samplePointsDilution':
            case 'samplePointsVerification':
            case 'samplePointsRecirculation':
                value = segment.sp ? segment.sp : '';
                return value
            case 'plates':
                //If plate is recieved from BE as 'none' we set to empty string so that MUI doesn't generate console warning
                if (segment.platekind === "none") {
                    segment.platekind = '';
                }
                value = segment.platekind ? segment.platekind : '';
                return value
            case 'intervalDuration':
                value = segment.intervalduration;
                return value === '5min' ? value : `${value} min`;
            case 'intervalCountVerification':
                value = segment.intervalcount;
                return value;
            case 'scenarios':
                value = scenarios.length > 0 ? scenarios.length : '';
                return value
            default:
                break;
        }
    }

    return (
        <FormControl>
            {testType === "generalSurvey" ? (
                <Select
                    disableUnderline
                    disabled={testCompleted || isViewer || disableSelect}
                    style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }}
                    name={coltype}
                    defaultValue=""
                    value={setSelectValue(segment, coltype)}
                    onChange={e => onChangeFunc(e, segment.testid, segment.segmentid)}
                    renderValue={(value) => value}>
                    {renderSelectOptionsSwitch(coltype, fpOptionsArr)}
                </Select>
            ) : (
                    <Select
                        disableUnderline
                        disabled={testCompleted || isViewer || disableSelect}
                        style={{ fontFamily: 'Gotham', fontSize: '13px', textAlign: 'center' }}
                        name={coltype}
                        defaultValue=""
                        value={setSelectValue(segment, coltype)}
                        onChange={e => handleChange(e, forwardRef)}
                        renderValue={(value) => value}>
                        {renderSelectOptionsSwitch(coltype, fpOptionsArr)}
                    </Select>
                )}

        </FormControl>
    )
}