import React from 'react';
import { Context } from '../../context/Context';
import Typography from "@material-ui/core/Typography";
import { LabelList } from '../../components/LabelList/LabelList';
import NavBarUtil from "../../components/Navbar/NavBarUtil";
import { Grid, withStyles } from "@material-ui/core";

const styles = () => ({
    root: {
        marginTop: '100px'
    },
    header: {
        fontFamily: "Gotham",
        marginTop: "30px"
    },
});

class ProjectsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = { projectId: null};
    }

    componentDidMount() {
        NavBarUtil.setNavButtonActive('#lBtn')

        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        this.setState({ projectId });
    }

    render() {
        const { projectId } = this.state;
        const { classes } = this.props;
        return (
            <Context.Consumer>
                {context => (
                    <div className={classes.root + ' checklists-view-container'}>
                        <div>
                            <Grid container spacing={3}>
                                <Grid item xs/>
                                <Grid item xs={8}>
                                    <Typography variant="h5" color="textPrimary" align="center" className={classes.header}>{`(Project Id ${projectId}) ${this.context.state.projectName}`}</Typography>
                                </Grid>
                                <Grid item xs/>
                            </Grid>

                        </div>
                        <div className="checklists-view-table-container">
                            <LabelList />
                        </div>
                        {/*
                        <div className="checklists-view-table-container">
                            <OrderForm />
                        </div>
                        */}
                    </div>
                )}
            </Context.Consumer>
        )
    }
}
ProjectsView.contextType = Context;

export default withStyles(styles, { withTheme: true })(ProjectsView);



