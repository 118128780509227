import React from "react";
import { Box, Typography } from "@material-ui/core";

const darkCell = {
  background: "rgb(0, 137, 150)",
  color: "white",
  padding: "5px",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: "smaller",
};

const lightCell = {
  background: "white",
  borderRight: "1px solid gray",
  borderBottom: "1px solid gray",
  color: "black",
  padding: "5px",
  textAlign: "center",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: "smaller",
};

const ScenariosWithSPs = ({ data }) => {
  const renderInnerTable = (tableData, key) => {
    return (
      <table
        key={key}
        style={{
          border: "1px solid gray",
          borderBottom: "none",
          borderRight: "none",
          width: "48%",
          borderSpacing: 0,
          margin: "16px 0",
        }}
      >
        <thead>
          <tr>
            <th style={darkCell} width="15%">
              SP Np
            </th>
            <th style={darkCell} width="15%">
              Start SN
            </th>
            <th style={darkCell} width="15%">
              End SN
            </th>
            <th style={darkCell} width="55%">
              Notes
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item) => (
            <tr key={item.id}>
              <td style={lightCell} width="15%">
                {item.number ?? "n/a"}
              </td>
              <td style={lightCell} width="15%">
                {item.samples[0]?.samplenumber}
              </td>
              <td style={lightCell} width="15%">
                {item.samples[item.samples.length - 1]?.samplenumber}
              </td>
              <td style={lightCell} width="55%"></td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderTableOrTables = (sc) => {
    if (Array.isArray(sc.SPs)) {
      if (sc.SPs.length === 0) {
        return null;
      }
      if (sc.SPs.length === 1) {
        return renderInnerTable(sc.SPs);
      } else {
        const halves = [
          sc.SPs.slice(0, Math.ceil(sc.SPs.length / 2)),
          sc.SPs.slice(Math.ceil(sc.SPs.length / 2)),
        ];
        return (
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            {renderInnerTable(halves[0], 1)}
            {renderInnerTable(halves[1], 2)}
          </Box>
        );
      }
    }
  };

  return (
    <div>
      {data?.map((sc, index) => {
        return (
          <div key={index}>
            <Typography>
              {sc.name} - Scenario Description: {sc.description}
            </Typography>
            <div style={{ marginLeft: "100px" }}>{renderTableOrTables(sc)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ScenariosWithSPs;
