import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const MuiAlertComponent = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

export const AlertTestCompleted = ({ flag, msg, closeFunc, updateSuccessful, workFlow }) => {
  //const classes = useStyles();
  const vertical = "top";
  const horizontal = "center";

  return (
    //<div className={classes.root}>
    <div>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={flag} autoHideDuration={6000}>
        {!workFlow ? (
          <MuiAlertComponent 
            style={!!updateSuccessful ? {backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", fontFamily: "Gotham"} : {backgroundColor: "#FF0000", color: "#ffffff", borderColor: "#82BC00", fontFamily: "Gotham"}} 
            onClose={closeFunc} 
            severity={!!updateSuccessful ? "success" : "error"}>
            {msg}
          </MuiAlertComponent>
        ) : (
          <MuiAlertComponent 
            style={{backgroundColor: "#008996", color: "#ffffff", borderColor: "#008996", fontFamily: "Gotham"}} 
            onClose={closeFunc} 
            severity="info">
            {msg}
          </MuiAlertComponent>
        )}
      </Snackbar>
    </div>
  );
}