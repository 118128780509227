import React from "react";

const darkCell = {
  background: "rgb(0, 137, 150)",
  color: "white",
  padding: "5px",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: "smaller",
};

const lightCell = {
  background: "white",
  borderRight: "1px solid gray",
  borderBottom: "1px solid gray",
  color: "black",
  padding: "5px",
  textAlign: "center",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: "smaller",
};

const TestScenarioList = ({ scenarios }) => {
  if (Array.isArray(scenarios) && scenarios.length > 0) {
    return (
      <table
        style={{
          border: "1px solid gray",
          borderBottom: "none",
          borderRight: "none",
          width: "99.5%",
          borderSpacing: 0,
        }}
      >
        <thead>
          <tr>
            <th style={darkCell} width="15%">
              Scenario
            </th>
            <th style={darkCell} width="35%">
              Description
            </th>
            <th style={darkCell} width="12%">
              Test Date
            </th>
            <th style={darkCell} width="12%">
              Start Date
            </th>
            <th style={darkCell} width="26%">
              Notes
            </th>
          </tr>
        </thead>

        <tbody>
          {scenarios.map((sc, index) => (
            <tr key={sc.scenarioId}>
              <td style={lightCell} width="15%">
                {index + 1} (ID: {sc.scenarioId})
              </td>
              <td style={lightCell} width="35%">
                {sc.scenarioDescription}
              </td>
              <td style={lightCell} width="12%"></td>
              <td style={lightCell} width="12%"></td>
              <td style={lightCell} width="26%"></td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else {
    return null;
  }
};

export default TestScenarioList;
