import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const useRowStyles = makeStyles({
    cell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        textAlign: 'center',
        border: 'none',
        paddingTop: '10px !important'
    },
    disabledColor: {
        color: 'rgba(0, 0, 0, 0.38)',
    }
});

const OpTableRow = ({ 
        viewOP, 
        onTagChange,
		tagsList,
        disabledActions,
        type,
        OPs,
    }) => {

    const classes = useRowStyles();

	const tagLotNumber = viewOP && viewOP.tagLotNumber && viewOP.tagLotNumber[0] ? viewOP.tagLotNumber[0] : {};
	const { lotNumber, expirationDate } = tagLotNumber;

    const getViewTagValue = () => {
        return (viewOP.tags && viewOP.tags[0] && viewOP.tags[0].tag) || '';
    };

    const handleTagSelect = (e) => {
        switch (type) {
            case 'single':
                onTagChange(e, [viewOP]);
                break;
            default:
                onTagChange(e);
                break;
        }
    };

    const getViewOpNumber = () => {
        if (type === 'single') {
            return viewOP.opnumber;
        }
        if (type === 'all' && Array.isArray(OPs)) {
            if (OPs.length === 1) {
                return OPs[0].opnumber;
            }
            const sortedOps = OPs.sort((a,b) => a.opnumber.localeCompare(b.opnumber));
            const lastOp = sortedOps[sortedOps.length - 1];
            
            return `${sortedOps[0].opnumber} - ${lastOp.opnumber}`;
        }
    
        return '';
    };

    if (!viewOP) return null;

    const textStyle = { 
        fontFamily: 'Gotham', 
        fontSize: '13px', 
        textAlign: 'center',
    };

    return (
        <>
            <TableRow className={classes.OProw}>
                <TableCell key='1' className={classes.cell}>{getViewOpNumber()}</TableCell>
                <TableCell key='2' className={classes.cell}>
                    <FormControl>
                        <Select
                            disableUnderline
                            style={textStyle}
                            name='tag'
                            disabled={disabledActions}
                            onChange={handleTagSelect}
                            value={getViewTagValue()}
                            renderValue={(value) => value}
                        >
                            {tagsList.map((tag, i) => (
                                <MenuItem key={`tag-key-${i}`} style={textStyle} value={tag || ''}>{tag}</MenuItem>
                            ))}
                            <MenuItem style={{ display: 'none' }} value={getViewTagValue()}/>
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell key='3' className={`${classes.cell} ${classes.disabledColor}`}>
                    {lotNumber || ''}
                </TableCell>
                <TableCell key='4' className={`${classes.cell} ${classes.disabledColor}`}>
                   {expirationDate || ''}
                </TableCell>
            </TableRow>
        </>
    );
};

export default OpTableRow;