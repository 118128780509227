import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ContextProvider } from './context/Context';
import axios from 'axios';
import { createBrowserHistory } from "history";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
require('./App.css');


export const history = createBrowserHistory();

axios.interceptors.request.use(config => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      config.headers["Authorization"] = `${token}`;
    }
    return config;
});

axios.interceptors.response.use(function (response) {
    return response;
    }, function (error) {

    console.log(error);
    // console.log('Axios Response interceptor error: ', JSON.stringify(error));
        if(error && error.message === 'Request failed with status code 403') {
            console.log('Authorization failed Error message: ', error.message);
            localStorage.clear();
            window.location.replace(window.location.origin + '/login');
        } else if (error && error.message === 'Request failed with status code 401') {
            console.log('Unauthorized Error message: ', error.message);
            // TODO: Let user know that they do not have permission for the selected feature
        } else {
            console.log('Axios Error message: ', error.message);
            // TODO: Handle response errors in the application
            return Promise.reject(error);
        }
    }
);

ReactDOM.render (
    
    <BrowserRouter>
        <ContextProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <App />
            </MuiPickersUtilsProvider>
        </ContextProvider>
    </BrowserRouter>, document.querySelector ('#root')
)