import React from 'react';
import Typography from '@material-ui/core/Typography'
import { Form } from '../../components/Form/Form';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import './ResetPasswordView.css';
import users from '../../api/users';

class ResetPasswordView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: '',
            matching: null,
            errorMsg: '',
        };
        this.resetPwArr = [
            {name : 'currentPassword' , label: 'Current Password'},
            {name : 'newPassword' , label: 'New Password'},
            {name : 'confirmPassword' , label: 'Confirm Password'}
        ]
    }

    componentDidMount() {
        document.getElementsByClassName('navbar-wrapper')[0].style.display = "none";
        document.getElementsByClassName('drawer-wrapper')[0].style.display = "none";
    }

    onSubmit = async (values) => {
        //Values is returned by react-hook-form handleSubmit()
        //  TODO: if response is not successful, send message to user
        this.setState({
            matching: true,
            errorMsg: '',
        });

        let data = {
            email: values.email,
            password: values.currentPassword,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword,
        };
        if(values.newPassword !== values.confirmPassword) {
            this.setState({ matching: false });
            return;
        }
        const response = await users.updateUserPassword(data);
        if (response && response.data) {
            if (response.data.success) {
                this.props.history.push('/login');
            } else {
                const errorMsg = response.data.error?.message || 'Internal error';
                this.setState({ errorMsg });
            }
        }
    }

    handleInputChange = event => {
        const value = event.target.value;
        const name = event.target.name;
        
        this.setState({
            [name]: value
        });
    }

    handleClick = () => {
        this.props.history.push('/login');
    }

    render() {
        const { matching, errorMsg } = this.state;
        return (
            <main className="resetPW-wrapper">
                <div className="resetPW-wrapper-header">
                    <Typography variant="h4" color="textPrimary" align="center" style={{fontFamily: "Gotham", marginTop: "30px"}}>Reset Password Form</Typography>
                </div>
                <div className="resetPW-wrapper-form">
                    <Form 
                        textFieldArr={this.resetPwArr}  
                        handleChangeFunc={this.handleInputChange}
                        handleSubmitFunc={this.onSubmit}
                        type={`resetPassword`}
                    />
                </div>
                <br />
                <div className="resetPW-wrapper-form">
                    <Link onClick={this.handleClick} variant="body2" style={{ color: '#008996', fontFamily: 'Gotham', cursor: 'pointer' }}>Return to login page</Link>
                </div>
                <div>
                    {matching === false && (<footer style={{ fontFamily: 'Gotham', color: '#FF0000', fontSize: '20px', textAlign: 'center' }}>{`Updated password does not match. Please try again`}</footer>)}
                </div>
                {errorMsg &&
                <div>
                    <footer style={{ fontFamily: 'Gotham', color: '#FF0000', fontSize: '20px', textAlign: 'center' }}>
                        {errorMsg}
                    </footer>
                </div>
                }
                
            </main>
        )
    }
}

export default ResetPasswordView;