import axios from 'axios';
import config from '../config.js';

const BASEURL = config.baseURL + '/results';
const results = {
    testApi : async() => {
        console.log(BASEURL + '/testapi');
        const response = await axios.post (BASEURL + '/testapi');
        return response;
    },
    getAugmentedData : async (projectId, verificationLikePart = false) => {
        let reqString = `/getaugmenteddata/${projectId}`;
        if (verificationLikePart) {
            reqString += '?verificationLikePart=1';
        }
        const response = await axios.get (BASEURL + reqString);
        return response;
    },
    getVerificationSummary: async (projectId) => {
        const response = await axios.get(BASEURL + '/verificationSummary/' + projectId);
        return response;
    },
    uploadResults : async(projectId, data) => {
        const response = await axios.post (BASEURL + '/uploadresults/' + projectId, data);
        return response;
    },
    getTestsList : async(projectId) => {
        const response = await axios.get (BASEURL + '/testslist/' + projectId);
        return response;
    },
    getHeatMap : async(projectId, data) => {
        const response = await axios.post (BASEURL + '/getheatmap/' + projectId, data);
        return response;
    },
    isResultsUploaded : async(projectId) => {
        const response = await axios.get (BASEURL + '/isresultsuploaded/' + projectId);
        return response;
    },
    getResultsFilesList : async(projectId) => {
        const response = await axios.get (BASEURL + '/resultsfileslist/' + projectId);
        return response;
    },
    updateHeatmapsData : async(projectId) => {
        const response = await axios.get (BASEURL + '/updateheatmapsdata/' + projectId);
        return response;
    },
    getPresignedURL: async(data) => {
        const response = await axios.get (BASEURL + '/getPresignedURL?projectId=' + data.projectId + '&filename=' + data.filename);
        return response;
    },
    deleteResultsFile: async(data) => {
        const response = await axios.get (BASEURL + '/deleteResultsFile?projectId=' + data.projectId + '&filename=' + data.filename);
        return response;
    },
    getTestsCoverage : async(projectId) => {
        const response = await axios.get (BASEURL + '/getTestsCoverage/' + projectId);
        return response;
    },
};


export default results;