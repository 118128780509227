import React, { Component } from 'react';
import { Context } from '../../context/Context';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { TagsChecklistTableHeader } from '../../components/ChecklistTableHeader/TagsChecklistTableHeader';
import TagsChecklistTable from '../../components/TagsChecklistTable/TagsChecklistTable';
import ReactToPrint from 'react-to-print';
import './TagsChecklistView.css';

export class TagsChecklistView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projectId: null, 
            totalNumSamples: null,
            projectName: null,
            address: null,
            testName: null
        };
    }
    
    qcChecklistContainerRef = React.createRef();

    async componentDidMount() {
        let checklistsNavButton = document.querySelector('#clBtn');
        if(checklistsNavButton) {
            checklistsNavButton.classList.add('selected');
        }
        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        this.setState({ projectId });
    };

    componentDidUpdate() {
        let checklistsNavButton = document.querySelector('#clBtn');
        if(checklistsNavButton) {
            checklistsNavButton.classList.add('selected');
        }
    }

    componentWillUnmount() {
        let checklistsNavButton = document.querySelector('#clBtn');
        if(checklistsNavButton) {
            checklistsNavButton.classList.remove('selected');
        }
    }

    render() {
        const { projectId } = this.state;
        return (
            <Context.Consumer>
                {context => (
                    <main className="tags-checklist-container">
                        <section className="tags-checklist-container-header-section">
                            <div>
                                <Typography variant="h5" color="textPrimary" align="center" style={{fontFamily: "Gotham", marginTop: "30px"}}>{`(Project Id ${projectId}) ${context.state.projectName}: ${context.state.testName} - Tags Checklist`}</Typography>
                            </div>
                            <div className="tags-checklist-container-header-section-btn">
                                <ReactToPrint
                                    trigger={() => {
                                        return (
                                            <div className="labels-flex-child">
                                                <Button size="small" variant="outlined" style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", marginLeft: "5px", fontFamily: "Gotham"}}>Print</Button>  
                                            </div>
                                        )                     
                                    }}
                                    content={() => this.qcChecklistContainerRef.current}
                                />
                            </div>
                        </section>
                        <main ref={this.qcChecklistContainerRef}>
                            <TagsChecklistTableHeader />
                            <section className="tags-checklist-materials-table">
                                <TagsChecklistTable />
                            </section>
                        </main>
                    </main>
                )}
            </Context.Consumer>
        );
    }
}