import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { Context } from '../../context/Context';

const useRowStyles = makeStyles({
    cell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        textAlign: 'left',
        borderRight: 'none',
        borderLeft: 'none',
    },
    headerCell: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'center',
        padding: '10px !important'
    },
    tableRow: {
        cursor: 'pointer',
    },
    cellContent: {
        fontFamily: 'Gotham',
        fontSize: '13px',
        textAlign: 'center',
        borderRight: 'none',
        borderLeft: 'none',
    }
});

export const NestedTableSegmentOP = ({ 
        ops,
        segOp, 
        index, 
        testCompleted,
        isViewer, 
        onKeyDownLotNumberExpirationDateUpdate, 
        handleTextFieldOnChange, 
        tagLotNumbers, 
        expirationDates 
    }) => {

    const classes = useRowStyles();
    const { state } = useContext(Context);
    let accessLevel = state.accessLevel;
    let disable = false;
    let lotNumOpId;

    //Function to set opid for each lotnum to create correct cell assignments
    const setTagLotNumberOpid = tagLotNumbers => {
        let opid;
        let tagLotNumbersOpids = Object.keys(tagLotNumbers);
        tagLotNumbersOpids.forEach(opId => {
            if(opId === segOp.opid.toString()) {
                opid = opId;
            }
        });
        return opid;
    }

    const setTag = segOp => {
        let tagVal;
        if(segOp.tags.length > 0) {
            tagVal = segOp.tags[0].tag;
        }
        return tagVal;
    }

    lotNumOpId = setTagLotNumberOpid(tagLotNumbers);
    
    if(accessLevel === 'Viewer') {
        disable = true;
    }
 
    return (
        <>
            <TableRow>
                <TableCell style={{ borderBottom: 'none' }} className={classes.cell}></TableCell>
                <TableCell style={{ borderBottom: 'none' }} className={classes.cellContent}>{segOp.opnumber}</TableCell>
                <TableCell style={{ borderBottom: 'none' }} className={classes.cellContent}>{setTag(segOp)}</TableCell>
                <TableCell style={{ borderBottom: 'none' }} className={classes.cell}>
                    <form onSubmit={e => onKeyDownLotNumberExpirationDateUpdate(e, segOp.opid, setTag(segOp), 'lotNumber', ops, index, segOp.segmentid)} noValidate autoComplete="off">
                        <div style={{ marginLeft: '50px' }}>
                            <TextField
                                type="text"
                                disabled={true}
                                inputProps={{
                                    style: { textAlign: 'center', fontFamily: 'Gotham', fontSize: '13px', marginTop: '10px' }
                                }}
                                InputProps={{ disableUnderline: true }}
                                value={Object.keys(tagLotNumbers).length === 0 ? '' : (tagLotNumbers[lotNumOpId] || '')}
                                onChange={e => handleTextFieldOnChange(e, 'lotNumber', index, segOp.opid)}
                            />
                        </div>
                    </form>
                </TableCell>
                <TableCell style={{ borderBottom: 'none' }} className={classes.cell}>
                    <form onSubmit={e => onKeyDownLotNumberExpirationDateUpdate(e, segOp.opid, setTag(segOp), 'expirationDate', ops, index, segOp.segmentid)} className={classes.root} noValidate autoComplete="off">
                        <div style={{ marginLeft: '50px' }}>
                            <TextField
                                type="text"
                                disabled={true}
                                inputProps={{
                                    style: { textAlign: 'center', fontFamily: 'Gotham', fontSize: '13px', marginTop: '10px' }
                                }}
                                InputProps={{ disableUnderline: true }}
                                value={Object.keys(expirationDates).length === 0 ? '' : (expirationDates[lotNumOpId] || '')}
                                onChange={e => handleTextFieldOnChange(e, 'expirationDate', index, segOp.opid)}
                            />
                        </div>
                    </form>
                </TableCell>   
            </TableRow>
        </>
    );
}