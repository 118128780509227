import React from 'react';
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = (theme) => ({
    columnHeader: {


        minHeight: '200px',
        //maxHeight: '20px',
        //border: '3px solid #73AD21',
        background: 'rgb(0, 137, 150)',//'lightgray',
        padding: '10px',
        //align: 'left',
        //transform: 'rotate(270deg)',
    },

    columnHeaderText: {
        //transform: 'rotate(270deg)', /* Equal to rotateZ(45deg) */
        //textAlign: 'left',
        //margin: '10px',
        //minWidth: '200px',
        //position: 'absolute',
        //right: '0px',
    },
    table: {
        border: '1px solid gray !important',//1px solid rgba(224, 224, 224, 1);
        //borderWidth: '1px !important',
        //borderSpacing: '2px !important',
        minWidth: 400,
    },
    cell: {
        padding: '4px !important',
        //margin: '10px',
        fontSize: 'smaller',
        border: '1px solid gray !important',
    },
    row: {
        //border: '1px solid #73AD21',
        padding: '10px',
    },
    idColumn: {
        width: '15px',
    }

})

/**
 * props
 *    id
 *    number
 *    name
 *    type
 *    overview
 *    isCompleted
 */
class OriginPointDetails extends React.Component {
    constructor(props) {
        super(props);

        //console.log(props);
        this.originPointsToRows = this.originPointsToRows.bind(this);
    }

    componentDidMount() {

    }

    originPointsToRows() {
        let items = [];

        //console.log(this.props.originPoints);

        this.props.originPoints.map((op) => {

            if (op.tags.length > 0) {
                op.tags.sort(function (a, b) {
                    if (a.scenarioSequenceNumber < b.scenarioSequenceNumber) {
                        return -1;
                    }
                    if (a.scenarioSequenceNumber > b.scenarioSequenceNumber) {
                        return 1;
                    }
                    return 0;
                }).map((tag) => {
                    items.push({
                        scenario: tag.scenarioSequenceNumber,
                        number: op.number,
                        name: op.name,
                        tag: tag.tag,
                        lot: tag.lotNumber,
                        expirationDate: tag.expirationDate,
                    });
                });

            }
        });

        let sortedItems = items.sort((a, b) => {
            if (a.scenario === b.scenario) {
                return a.number < b.number ? -1 : 1
            } else {
                return a.scenario < b.scenario ? -1 : 1
            }
        });

        //console.log(items);

        const { classes } = this.props;
        const elements = sortedItems.map((item) => {
            return (
                <TableRow key={item.id + "-" + item.senario} >
                    <TableCell className={classes.cell} align="right">
                        {item.scenario}
                    </TableCell>
                    <TableCell className={classes.cell} align="right">
                        {item.number}
                    </TableCell>
                    <TableCell className={classes.cell} align="left">
                        {item.name}
                    </TableCell>
                    <TableCell className={classes.cell} align="left">
                        {item.tag}
                    </TableCell>

                    <TableCell className={classes.cell} align="left">
                        {item.lot}
                    </TableCell>

                    <TableCell className={classes.cell} align="left">
                        {item.expirationDate}
                    </TableCell>
                    <TableCell className={classes.cell} align="left">

                    </TableCell>
                </TableRow>
            );

        });
        return elements;

    }
    render() {
        const { classes } = this.props;

        const opRows = this.originPointsToRows();
        return (
            <div class="OriginDetails">
                <TableContainer style={{ width: '99.5%' }}>

                    <div style={{ paddingTop: '10px', paddingBottom: '10px' }} >
                        <Typography variant='h6'>Origin Points</Typography>
                    </div>
                    <Table className={classes.table} size="small" aria-label="Origin Point data rows">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="right">Scenario</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="right">OP Number</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="left">OP Name</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="left">Tag</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="left">Tag Lot</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white' }} align="left">Tag Expiration Date</TableCell>
                                <TableCell className={classes.cell} style={{ background: 'rgb(0, 137, 150)', color: 'white', width: '35%' }} align="left">Notes</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {opRows}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        );
    }

}

export default withStyles(styles)(OriginPointDetails);