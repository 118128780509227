const NavBarUtil = {
    setNavButtonActive: (id) => {
        let navButton = document.querySelector(id);
        if ( navButton ) {
            navButton.classList.add('selected');
        }
    },
    setNavButtonInactive: (id) => {
        let navButton = document.querySelector(id);
        if ( navButton ) {
            navButton.classList.remove('selected');
        }
    }


}

export default NavBarUtil