import React, { Component } from 'react';
import { Context } from '../../context/Context';
import logo from '../../assets/SafeTraces_Logo.Horiz.png';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import QCChecklistTable from '../../components/QCChecklistTable/QCChecklistTable';
import ReactToPrint from 'react-to-print';
import samplepoints from '../../api/samplepoints';
import projects from '../../api/projects';
import tests from '../../api/tests';
import './QCChecklistView.css';

const styles = theme => ({
    cell: {
        fontFamily: 'Gotham',
        fontSize: '16px'
    }
});

class QCChecklistView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projectId: null, 
            totalNumSamples: null,
            projectName: null,
            address: null,
            testName: null
        };
    }
    
    qcChecklistContainerRef = React.createRef();

    async componentDidMount() {
        let oneCheckResponse;
        let checklistsNavButton = document.querySelector('#clBtn');
        if(checklistsNavButton) {
            checklistsNavButton.classList.add('selected');
        }

        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        let testId = JSON.parse(localStorage.getItem("testId")) ? JSON.parse(localStorage.getItem("testId")) : null;
        let testName = localStorage.getItem("testName") ? localStorage.getItem("testName") : null; 

        this.setState({
            projectId: projectId,
            testId: testId,
            testName: testName
        }, async () => {

            if(testId !== null) {
                oneCheckResponse = await tests.getOneTest(this.state.testId);
                this.setState({ 
                    projectName: oneCheckResponse.data.data.testname, 
                    address: oneCheckResponse.data.data.address,
                });
            } else {
                oneCheckResponse = await projects.getOneProject(this.state.projectId);
                this.setState({ 
                    projectName: oneCheckResponse.data.data.name, 
                    address: oneCheckResponse.data.data.address,
                });
            }
            const spResponse = await samplepoints.getAllSamples(this.state.testId, this.state.projectId);
            if(spResponse.data.data && spResponse.data.data.length > 0) {
                this.setState({ totalNumSamples: spResponse.data.data.length });
            }
        });
    };

    componentDidUpdate() {
        let checklistsNavButton = document.querySelector('#clBtn');
        if(checklistsNavButton) {
            checklistsNavButton.classList.add('selected');
        }
    }

    componentWillUnmount() {
        let checklistsNavButton = document.querySelector('#clBtn');
        if(checklistsNavButton) {
            checklistsNavButton.classList.remove('selected');
        }
    }

    render() {
        const { totalNumSamples, projectName, address, testName, projectId } = this.state;
        const { classes } = this.props;
        if(testName && testName !== undefined) {
            return (
                <Context.Consumer>
                    {context => (
                        <main style={{ marginTop: '100px', width: '100%' }} className="qc-checklist-container">
                            <section className="qc-checklist-container-header-section">
                                <div style={{ marginLeft: '45%', width: '95%' }}>
                                    <Typography variant="h5" color="textPrimary" align="center" style={{fontFamily: "Gotham", marginTop: "30px"}}>{`(Project Id ${projectId}) ${context.state.projectName}: ${context.state.testName} - QC Checklist`}</Typography>
                                </div>
                                <div className="qc-checklist-container-header-section-btn">
                                    <ReactToPrint
                                        trigger={() => {
                                            return (
                                                <div>
                                                    <Button size="small" variant="outlined" style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", marginLeft: "5px", fontFamily: "Gotham"}}>Print</Button>  
                                                </div>
                                            )                     
                                        }}
                                        content={() => this.qcChecklistContainerRef.current}
                                    />
                                </div>
                            </section>
                            <section style={{ marginLeft: '250px', width: '100%' }} ref={this.qcChecklistContainerRef} className="body-container">
                                <section style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ marginBottom: '20px' }}>
                                        <img src={logo} alt="logo" className="qc-checklist-header-logo" />
                                    </div>
                                    <div className="header"><b>{`QC Check List`}</b></div>
                                </section>
                                <TableContainer component={Paper}>
                                    <div>
                                        <Table aria-label="qc-table-header">
                                            <TableHead>
                                                <TableRow style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50vw', padding: '3px' }} className={classes.cell}>Prepared By:</TableCell>
                                                    <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50vw', padding: '3px' }} className={classes.cell}>Program: veriDART</TableCell>
                                                </TableRow>
                                                <TableRow style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50vw', padding: '3px' }} className={classes.cell}>Document Number:</TableCell>
                                                    <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50vw', padding: '3px' }} className={classes.cell}>Number of Pages:</TableCell>
                                                </TableRow >
                                                <TableRow style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50vw', padding: '3px' }} className={classes.cell}>Effective Date:</TableCell>
                                                    <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50vw', padding: '3px' }} className={classes.cell}>Version: 1</TableCell>
                                                </TableRow>
                                                <TableRow style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50vw', padding: '3px' }} className={classes.cell}>{`Project Id: ${projectId}`}</TableCell>
                                                    <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50vw', padding: '3px' }}  className={classes.cell}>{`Test Name: ${testName}`}</TableCell>
                                                </TableRow>
                                                <TableRow style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50vw', padding: '3px' }} className={classes.cell}>Facility Name:</TableCell>
                                                    <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50vw', padding: '3px' }} className={classes.cell}>Address:</TableCell>
                                                </TableRow>
                                                <TableRow style={{ display: 'flex', flexDirection: 'row' }} >
                                                    <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50vw', padding: '3px' }} className={classes.cell}>Date of Samples:</TableCell>
                                                    <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50vw', padding: '3px' }} className={classes.cell}>{`Total Number of Samples: ${totalNumSamples}`}</TableCell>
                                                </TableRow>
                                                <TableRow style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50vw', padding: '3px' }} className={classes.cell}>Send Copy with COC and Samples:</TableCell>
                                                    <TableCell style={{ border: '1px solid #F3F3F3', fontSize: '16px', width: '50vw', padding: '3px' }} className={classes.cell}>Completed by (Print):</TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </div>
                                </TableContainer>
                                <section>
                                    <QCChecklistTable />
                                </section>
                            </section>
                        </main>
                    )}
                </Context.Consumer>
            );
        } else {
            return null
        }
    }
}
export default withStyles(styles, { withTheme: true })(QCChecklistView);