import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import { withRouter } from "react-router";
import './Login.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#008996',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#008996',
    fontFamily: 'Gotham'
  },
  changePW: {
    color: '#008996',
    fontFamily: 'Gotham',
    cursor: 'pointer'
  }
}));

/*
  Validation: https://react-hook-form.com/get-started#Applyvalidation
  - react-hook-form: destructure errors obj from useForm()
  - register(obj) where you enter validation requirements aligning with the existing HTML standard for form validation.
  - pass errors.prop for textField via Material UI error prop
*/

const Login = ({ handleOnchange, onSubmit, auth, props }) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  const handleClick = e => {
    props.history.push('/resetpassword');
    console.log('props: ', props)
  }

  return (
    <>
      <Container className="login-wrapper" component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}></Avatar>
          <Typography component="h1" variant="h5" style={{ fontFamily: 'Gotham' }}>
            Sign in 
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              inputRef={register({ required: true, pattern: /$^|.+@.+..+/ })}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              style={{ fontFamily: 'Gotham' }}
              onChange={e => handleOnchange(e)}
              error={errors.email}
              helperText={errors.email && "Please enter valid email"}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              inputRef={register({ required: true })}
              name="password"
              label="Password"
              type="password"
              id="password"
              style={{ fontFamily: 'Gotham' }}
              onChange={e => handleOnchange(e)}
              error={errors.password}
              helperText={errors.email && "Password is required"}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link onClick={handleClick} variant="body2" className={classes.changePW}>
                  Change password
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        {auth === false && (<footer className="login-invalid-creds">{`Invalid credentials. Plesae try again`}</footer>)}
      </Container> 
    </>
  );
}

export default withRouter(Login);