import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

export default function BackdropFetch(props) {
  const { open } = props;
  return (
    <div>
      {/** Important: fix style in order to reuse */}
      <Backdrop
        style={{ zIndex: 1500, backgroundColor: "rgba(0, 0, 0, 0.1)" }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}