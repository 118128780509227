import React, { useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Context } from '../../context/Context';
import { useForm } from 'react-hook-form';
import { testTypesConfig, VERIFICATION_LIKE_TESTS } from '../../config';
import './Form.css';

export const Form = ({ textFieldArr, handleChangeFunc, handleSubmitFunc, companyNames, customerCompanies, type, typeNotSet, accessNotSet, noGST, isVerification, isFirstTest, selectedPartner, selectedCustomer, handleSelectChange }) => {
    const { register, handleSubmit, errors } = useForm();
    const { state } = useContext(Context);
    const [ accessLevels, setAccessLevels ] = useState([
        'Admin', 'Editor', 'Viewer'
    ]);
    const testTypes = testTypesConfig;
    const getSelectTestTypeItem = (value, key) => <MenuItem key={key} value={value}>{testTypes[value]}</MenuItem>;
    const getTestTypeOptions = () => {
        const types = [];
        if (isFirstTest) {
            types.push('generalSurvey', ...VERIFICATION_LIKE_TESTS);
        } else {
            if (isVerification) {
                types.push('verification');
            } else {
                types.push('generalSurvey', 'dilutionSmall', 'dilutionLarge', 'miniSurvey', 'recirculation');
            }
        }
        return types.map((tType, i) => getSelectTestTypeItem(tType, i));
    }

    /*
    WILL USE THIS LOGIC WHEN I CAN FIGURE OUT HOW TO SET DYNAMIC VALIDATION
    const removePartnerField = textFieldArr => {
        textFieldArr.splice(4, 1);
        return textFieldArr;
    }

    if (state.companyName !== 'Safetraces') {
        textFieldArr = removePartnerField(textFieldArr);
    } else {
        textFieldArr = textFieldArr;
    }
    */
        

    /*
    const [ newUserFormValidations, setNewUserForm ] = useState([
        {
            name: 'firstName', label: 'First Name', validation: { required: true }, errorMsg: 'Please enter First Name'

        },
        {
            name: 'lastName', label: 'Last Name', validation: { required: true }, errorMsg: 'Please enter Last Name'

        },
        {
            name: 'email', label: 'Email', validation: { required: true, pattern: /$^|.+@.+..+/ }, errorMsg: 'Please enter valid Email'

        },
        {
            name: 'password', label: 'Password', validation: { required: true, minLength: 8 }, errorMsg: 'Please enter Password. Minimum Length is 8 characters'

        },
        {
            name: 'accessLevel', label: 'Access Level', validation: { required: true }, errorMsg: 'Please enter valid Access Level'

        }
    ]);
    */
    

    /*
    return (
        <form onSubmit={e => handleSubmitFunc(e)} className='form'>
            {type !== 'newUser' ? (
                textFieldArr.map((textFieldObj, i) => (
                    <TextField
                        key={`text-field-${i}`}
                        name={textFieldObj.name}
                        label={textFieldObj.label}
                        inputRef={register({ required: true })}
                        onChange={e => handleChangeFunc(e)}
                    />
                ))
            ) : (
                textFieldArr.map((textFieldObj, i) => (
                    <TextField
                        key={`text-field-${i}`}
                        name={textFieldObj.name}
                        label={textFieldObj.label}
                        inputRef={register(newUserFormValidations[i].validation)}
                        error={newUserFormValidations[i].errorMsg}
                        helperText={newUserFormValidations[i].errorMsg}
                        onChange={handleSubmit(handleChangeFunc)}
                    />
                ))
            )}

            <Button label="Submit" type="submit">Submit</Button>
        </form>
    )
    */

    return (
        <>  
            {type === 'newProject' && (
                <form onSubmit={handleSubmit(handleSubmitFunc)} className='form' noValidate>
                    {/*
                    {textFieldArr.map((textFieldObj, i) => (
                        <TextField
                            key={`text-field-${i}`}
                            name={textFieldObj.name}
                            label={textFieldObj.label}
                            onChange={e => handleChangeFunc(e)}
                        />
                    ))}
                    */}
                    <TextField
                        required
                        inputRef={register({ required: true})}
                        id="name"
                        label="Project Name"
                        name="name"
                        autoComplete="name"
                        style={{ fontFamily: 'Gotham' }}
                        onChange={e => handleChangeFunc(e)}
                        error={errors.name}
                        helperText={errors.name && "Project Name required"}
                        autoFocus
                    />
                    <TextField
                        required
                        fullWidth
                        inputRef={register({ required: true })}
                        name="address"
                        label="Address"
                        id="address"
                        style={{ fontFamily: 'Gotham' }}
                        onChange={e => handleChangeFunc(e)}
                        error={errors.address}
                        helperText={errors.address && "Address is required"}
                    />
                    <TextField
                        required
                        fullWidth
                        inputRef={register({ required: true })}
                        name="description"
                        label="Description"
                        id="description"
                        style={{ fontFamily: 'Gotham' }}
                        onChange={e => handleChangeFunc(e)}
                        error={errors.description}
                        helperText={errors.description && "Description is required"}
                    />
                    {state.companyName === 'Safetraces' && (
                        <FormControl required={true}>
                            <InputLabel id="endCustomer">Customer</InputLabel>
                            <Select
                                labelId="endCustomer"
                                id="endCustomer"
                                name="endCustomer"
                                autoFocus
                                onChange={e => handleChangeFunc(e)}>
                                    {customerCompanies.map((companyName, i) => (
                                        <MenuItem key={`${i}-${companyName}`} value={companyName}>{companyName}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                    {state.companyName === 'Safetraces' && (
                        <FormControl required={false}>
                            <InputLabel id="channelPartner">Partner</InputLabel>
                            <Select
                                labelId="channelPartner"
                                id="channelPartner"
                                name="channelPartner"
                                onChange={e => register({ name: 'channelPartner', value: e.target.value})}>
                                    {companyNames.map((companyName, i) => (
                                        <MenuItem key={`${i}-${companyName}`} value={companyName}>{companyName}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                    <br />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", fontFamily: "Gotham"}}
                        >
                        Submit
                    </Button>
                </form>
            )} 
            {type === 'newUser' && (
                <form onSubmit={handleSubmit(handleSubmitFunc)} className='form' noValidate>
                    <TextField
                        required
                        inputRef={register({ required: true})}
                        id="firstName"
                        label="First Name"
                        name="firstName"
                        autoComplete="firstName"
                        style={{ fontFamily: 'Gotham' }}
                        onChange={e => handleChangeFunc(e)}
                        error={errors.firstName}
                        helperText={errors.firstName && "First Name required"}
                        autoFocus
                    />
                    <TextField
                        required
                        fullWidth
                        inputRef={register({ required: true })}
                        name="lastName"
                        label="Last Name"
                        id="lastName"
                        style={{ fontFamily: 'Gotham' }}
                        onChange={e => handleChangeFunc(e)}
                        error={errors.lastName}
                        helperText={errors.lastName && "Last Name is required"}
                    />
                    <TextField
                        required
                        fullWidth
                        inputRef={register({ required: true, pattern: /$^|.+@.+..+/ })}
                        name="email"
                        type="email"
                        label="Email"
                        id="email"
                        style={{ fontFamily: 'Gotham' }}
                        onChange={e => handleChangeFunc(e)}
                        error={errors.email}
                        helperText={errors.email && "Please enter a valid email"}
                    />
                    <TextField
                        required
                        fullWidth
                        inputRef={register({ required: true, minLength: 8 })}
                        name="password"
                        type="password"
                        label="Password"
                        id="password"
                        style={{ fontFamily: 'Gotham' }}
                        onChange={e => handleChangeFunc(e)}
                        error={errors.password}
                        helperText={errors.password && "Password is required. Minimum length is 8"}
                    />
                    {state.companyName === 'Safetraces' && (
                        <FormControl required={false}>
                            <InputLabel id="channelPartner">Partner</InputLabel>
                            <Select
                                labelId="channelPartner"
                                id="channelPartner"
                                name="channelPartner"
                                value={selectedPartner}
                                onChange={e => handleSelectChange(e, 'selectedPartner')}>
                                    {companyNames.map((companyName, i) => (
                                        <MenuItem key={`${i}-${companyName}`} value={companyName}>{companyName}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                    {state.companyName === 'Safetraces' && (
                        <FormControl required={false}>
                            <InputLabel id="customer">Customer</InputLabel>
                            <Select
                                labelId="customer"
                                id="customer"
                                name="customer"
                                value={selectedCustomer}
                                onChange={e => handleSelectChange(e, 'selectedCustomer')}>
                                    {customerCompanies.map((companyName, i) => (
                                        <MenuItem key={`${i}-${companyName}`} value={companyName}>{companyName}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                    <FormControl>
                        <InputLabel id="accessLevel">Access Level</InputLabel>
                        <Select
                            labelId="accessLevel"
                            id="accessLevel"
                            name="accessLevel"
                            onChange={e => register({ name: 'accessLevel', value: e.target.value})}>
                                {accessLevels.map((accessLevel, i) => (
                                    <MenuItem key={`${i}-${accessLevel}`} value={accessLevel}>{accessLevel}</MenuItem>
                                ))}
                        </Select>
                        {!!accessNotSet && (<p className="error">Access Level is required</p>)}
                    </FormControl>
                    <br />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", fontFamily: "Gotham"}}
                        >
                        Submit
                    </Button>
                </form>
            )}
            {type === 'newTest' && (
                <>
                    <form onSubmit={handleSubmit(handleSubmitFunc)} className='form' noValidate>
                        <TextField
                            required
                            inputRef={register({ required: true})}
                            id="name"
                            label="Name"
                            name="name"
                            autoComplete="name"
                            style={{ fontFamily: 'Gotham' }}
                            onChange={e => handleChangeFunc(e)}
                            error={errors.name}
                            helperText={errors.name && "Test Name required"}
                            autoFocus
                        />
                        <TextField
                            required
                            inputRef={register({ required: true})}
                            id="goal"
                            label="Goal"
                            name="goal"
                            autoComplete="goal"
                            style={{ fontFamily: 'Gotham' }}
                            onChange={e => handleChangeFunc(e)}
                            error={errors.goal}
                            helperText={errors.goal && "Goal required"}
                        />
                        <TextField
                            required
                            inputRef={register({ required: true})}
                            id="overview"
                            label="Overview"
                            name="overview"
                            autoComplete="overview"
                            style={{ fontFamily: 'Gotham' }}
                            onChange={e => handleChangeFunc(e)}
                            error={errors.overview}
                            helperText={errors.overview && "Overview required"}
                        />
                        <FormControl>
                          <InputLabel id="type">Type*</InputLabel>
                          <Select
                            labelId="type"
                            id="type"
                            name="type"
                            defaultValue=''
                            onChange={e => handleChangeFunc(e)}
                          >
                            {getTestTypeOptions()}
                          </Select>
                          {!!typeNotSet && (<p className="error">Type is required</p>)}
                        </FormControl>
                        <br />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", fontFamily: "Gotham"}}
                            >
                            Submit
                        </Button>
                    </form>
                    
                </>
            )}
            {type === 'resetPassword' && (
                <form onSubmit={handleSubmit(handleSubmitFunc)} className='form' noValidate>
                    <TextField
                        required
                        fullWidth
                        inputRef={register({ required: true })}
                        name="email"
                        label="Email"
                        id="email"
                        style={{ fontFamily: 'Gotham' }}
                        onChange={e => handleChangeFunc(e)}
                        error={errors.currentPassword}
                        helperText={errors.currentPassword && "Email is required"}
                    />
                    <TextField
                        required
                        fullWidth
                        inputRef={register({ required: true })}
                        name="currentPassword"
                        label="Current Password"
                        id="currentPassword"
                        style={{ fontFamily: 'Gotham' }}
                        onChange={e => handleChangeFunc(e)}
                        error={errors.currentPassword}
                        helperText={errors.currentPassword && "Current password is required"}
                    />
                    <TextField
                        required
                        fullWidth
                        inputRef={register({ required: true })}
                        name="newPassword"
                        label="New Password "
                        id="newPassword"
                        style={{ fontFamily: 'Gotham' }}
                        onChange={e => handleChangeFunc(e)}
                        error={errors.newPassword}
                        helperText={errors.newPassword && "New password is required"}
                    />
                    <TextField
                        required
                        fullWidth
                        inputRef={register({ required: true })}
                        name="confirmPassword"
                        label="Confirm Password"
                        id="confirmPassword"
                        style={{ fontFamily: 'Gotham' }}
                        onChange={e => handleChangeFunc(e)}
                        error={errors.confirmPassword}
                        helperText={errors.confirmPassword && "Please confirm password"}
                    />
                    <br />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", fontFamily: "Gotham"}}
                        >
                        Submit
                    </Button>
                </form>
            )}
        </>
    )
}





/*
NEED TO TRY TO MAKE DYNAMIC WITH ERROR HANDLING. THIS WAS CODE TRIED. NEEDS WORK

    <form onSubmit={handleSubmit(handleSubmitFunc)} className='form' noValidate>
        {newUserFormValidations.map((textFieldObj, i) => {
            
            const name = textFieldObj.name;
            console.log('textFieldObj: ', textFieldObj)

            console.log('textFieldObj.name: ', textFieldObj.name)
            console.log('textFieldObj.validation: ', textFieldObj.validation)
            console.log('textFieldObj.errorMsg: ', textFieldObj.errorMsg)

            return (
                <TextField
                    required
                    key={`text-field-${i}`}
                    name={textFieldObj.name}
                    label={textFieldObj.label}
                    inputRef={register(textFieldObj.validation)}
                    error={errors.name}
                    helperText={errors.name && 'work dam it'}
                    onChange={e => handleChangeFunc(e)}
                />

            )
        })}
        <Button label="Submit" type="submit">Submit</Button>
    </form>
*/