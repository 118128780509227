import React from 'react';
import "./Visualizer.css";

class FloorplanSelector extends React.Component {

    constructor(props) {
        super(props);
        this.makeOptionElement = this.makeOptionElement.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        //console.log("FloorplanSelector constructor");
        //console.log(this.props.currentSelection);
    }

    makeOptionElement(floorplanObject) {
        //console.log(this.props.currentSelection);
        if (floorplanObject !== undefined && floorplanObject !== null) {
            return (
                <label key={floorplanObject.floorplanid} className="control control--radio">{floorplanObject.filename}
                    <input type="radio" name="floorplan-selector" value={floorplanObject.floorplanid}
                        checked={(this.props.currentSelection !== undefined && this.props.currentSelection !== null && this.props.currentSelection.floorplanid === floorplanObject.floorplanid)} />
                    <div className="control__indicator" />
                </label>
            )
        }
    }

    onSelectionChange(e) {
        //console.log(e.target.value);
        if (this.props.onSelectionChange !== undefined && typeof this.props.onSelectionChange === 'function') {
            this.props.onSelectionChange(e);
        }
    }

    render() {

        let optionList = null;
        //console.log(this.props);
        if (this.props.floorPlans !== undefined && this.props.floorPlans !== null) {
            optionList = this.props.floorPlans.map((item) => {
                //console.log(item + ", " + first)  ;
                const result = this.makeOptionElement(item);
                return result;
            });
            //console.log(optionList);
        } else {
            optionList = null;
        }
        return (
            <div className="floorplan-selector">
                <h5>Floorplan</h5>
                <div className="control-group" onChange={this.onSelectionChange}>
                    {optionList}
                </div>
            </div>
        );
    }
}

export default FloorplanSelector;