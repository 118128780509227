import React from 'react';
import { Context } from '../../context/Context';
import Typography from "@material-ui/core/Typography";
import { LabelList } from '../../components/LabelList/LabelList';
import './LabelsView.css';
import NavBarUtil from "../../components/Navbar/NavBarUtil";

class LabelsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = { projectId: null };
    }
    
    componentDidMount() {
        //logic to handle highlighting navbar tab
        NavBarUtil.setNavButtonActive('#lBtn')
        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        this.setState({ projectId });
    }
    
    render() {
        const { projectId } = this.state;
        return (
            <Context.Consumer>
                {context => (
                    <div className="labelsview-container">
                            <div>
                                <Typography variant="h5" color="textPrimary" align="center" style={{fontFamily: "Gotham", marginTop: "30px"}}>{`(Project Id ${projectId}) ${context.state.projectName}: ${context.state.testName}`}</Typography>
                            </div>     
                            <div className="labelsview-table-container">
                                <LabelList />
                            </div>
                    </div>
                )}
            </Context.Consumer>
        )
    }
}
LabelsView.contextType = Context;
export default LabelsView;