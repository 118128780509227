function preparePlaceableData (items){
    const newList = items.map((item) => {
        const updateItem = {
            ...item,
            original: item,
            isDirty: false,
        }
        return updateItem;
    });
    return newList;

}

export default preparePlaceableData;