const PrintStyles = () => ({
    greenButton: {
        marginLeft: '6px',
        whiteSpace: 'nowrap',
        backgroundColor: '#82BC00',
        color: '#ffffff',
        borderColor: '#82BC00',
        fontFamily: "Gotham",
        marginTop: '0px',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(130, 188, 0, 0.7)',
        }
    },
    printParent: {
        marginLeft: '0px !important',
    }
});

export { PrintStyles };
