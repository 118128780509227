import React from 'react';
import EditField from './EditField.js'
import { ItemTypes } from './ItemTypes'
import { DragSource } from 'react-dnd'


//import ReactDOM from 'react-dom';

/**
 * Specifies the drag source contract.
 * Only `beginDrag` function is required.
 */
const placeableObjectSource = {
    canDrag(props, monitor) {
        //console.log("props.isEditable " + props.isEditable);
        return props.isEditable;
    },

    beginDrag(props) {
        // Return the data describing the dragged item

        //console.log("PlacableObjectSource beginDrag " + props.number + ", " + props.x + ", " + props.y + ", " + props.iconWidth + ", " + props.iconHeight);
        const item = {
            id: props.id,
            number: props.number,
            name: props.name,
            objectType: props.objectType,
            iconWidth: props.iconWidth,
            iconHeight: props.iconHeight,

        }
        return item
    },

    endDrag(props, monitor, component) {
        if (!monitor.didDrop()) {
            return
        }
        //console.log("PlacableObject Dropped ");
        // When dropped on a compatible target, do something
        const item = monitor.getItem();
        const dropResult = monitor.getDropResult()
        //update x,y here

        //console.log(item.number + " dropped " + dropResult.x + ", " + dropResult.y);

        const e = {
            target: {
                name: "xname",
                value: item.name,
            },
            id: item.id,
            objectType: item.objectType,
            x: dropResult.x,
            y: dropResult.y,
            iconWidth: item.iconWidth,
            iconHeight: item.iconHeight,
        };
        props.onChange(e);
        props.onChanged(e);
    }
}
/**
 * Specifies which props to inject into your component.
 */
function collect(connect, monitor) {
    return {
        // Call this function inside render()
        // to let React DnD handle the drag events:
        connectDragSource: connect.dragSource(),
        // You can ask the monitor about the current drag state:
        isDragging: monitor.isDragging(),
    }
}

class PlacableObject extends React.Component {
    /*
        id={rawOp.opid} 
        number={rawOp.opnumber}
        name={rawOp.opname}
        x={rawOp.opcoordx}
        y={rawOp.opcoordy}
        floorPlan={rawOp.floorplan}
        original={rawOp}
        isOnImageCanvas
     */
    constructor(props) {
        super(props);


        /*
        this.state = { 
            objectType: this.props.objectType, 
            id: this.props.id, 
            number: this.props.number, 
            name: this.props.name, 
            x: this.props.x, 
            y: this.props.y, 
            floorPlan: this.props.floorPlan, 
            original: this.props.original};
        */
        this.changeHandler = this.changeHandler.bind(this);
        this.changedHandler = this.changedHandler.bind(this);
        this.placableColor = this.placableColor.bind(this);


        //console.log("PlacableObject constructor");
        //console.log(props);

    }

    changeHandler(e) {
        //console.log("PlacableObject.js - changeHandler");       
        if (this.props.onChange !== undefined && typeof this.props.onChange === 'function') {
            //console.log(e.target.name + ", " + e.target.value);
            this.props.onChange(e);
        }
        return;
    }
    changedHandler(e) {
        //console.log("PlacableObject.js - changedHandler");       
        if (this.props.onChanged !== undefined && typeof this.props.onChanged === 'function') {
            this.props.onChanged(e);
        }
        return;
    }

    placableColor(objectType) {
        if (objectType === "OriginPoint") {
            return "saddlebrown";
        } else if (objectType === "SamplePoint") {
            return "blue";
        }
    }

    render() {
        const { connectDragSource, connectDropTarget, isDragging } = this.props;

        if (isDragging) {
            return null;
        }
        if (this.props.isOnImageCanvas && this.props.isOnImageCanvas === true) {

            if (isNaN(this.props.x) || isNaN(this.props.y) || this.props.x === null || this.props.y === null) {
                return null;
            }
            //console.log("PlaceableObject - isOnImageCanvas - " + this.props.iconHeight + ", " + this.props.iconHeight);

            const top = this.props.y + "px";
            const left = this.props.x + "px";
            const height = this.props.iconHeight + "px";
            const width = this.props.iconWidth + "px";
            const fontSize = this.props.iconWidth * 0.2;

            //console.log(this.props);
            return connectDragSource(

                <div className='object' style={{ left, top, width: width, height: height }} >

                    <svg height="100%" width="100%" preserveAspectRatio="xMidYMid meet">
                        <circle cx="50%" cy="50%" r="40%" stroke={this.placableColor(this.props.objectType)} strokeWidth="10%" fill="linen" />

                        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize={fontSize}>{this.props.number}</text>
                    </svg>
                </div>
            );


        } else {
            if (this.props.isEditable === true) {
                return connectDragSource(


                    <tr key={this.props.id} className={this.props.isDirty === true ? "dirty" : "not-dirty"}>
                        <td >{this.props.number}</td>
                        <td>
                            <EditField name="xname"
                                value={this.props.name}
                                onBlur={(e) => {
                                    //console.log("PlacableObject.js - " + this.props.id + ", " + this.props.number + ", " + this.props.name);
                                    e.id = this.props.id;
                                    e.objectType = this.props.objectType;
                                    e.name = this.props.name;
                                    e.x = this.props.x;
                                    e.y = this.props.y;
                                    e.floorPlan = this.props.floorPlan;
                                    e.original = this.props.original;
                                    this.changedHandler(e);

                                }}
                                onChange={(e) => {
                                    //console.log("PlacableObject.js - " + e.target.name + ": " + e.target.value);
                                    //this.setState({name: e.value});
                                    e.id = this.props.id;
                                    e.objectType = this.props.objectType;
                                    e.name = this.props.name;
                                    e.x = this.props.x;
                                    e.y = this.props.y;
                                    e.floorPlan = this.props.floorPlan;
                                    e.original = this.props.original;
                                    this.changeHandler(e);
                                }} />
                        </td>


                    </tr>

                );
            } else {
                return (
                    <tr key={this.props.id.toString()} className="not-dirty">
                        <td >{this.props.number}</td>
                        <td> <span className="field name">{this.props.name}</span></td>
                    </tr>
                );
            }

        }
    }
}

//export default PlacableObject;

export default DragSource(ItemTypes.PLACEABLE_OBJECT, placeableObjectSource, collect)(PlacableObject)


