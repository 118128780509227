import React from 'react';
import Typography from '@material-ui/core/Typography'
import { Form } from '../../components/Form/Form';
import { AlertNewTest } from '../../components/Alert/AlertNewTest'; 
import tests from '../../api/tests';
import { Context } from '../../context/Context';

class NewTestView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            overview: '',
            type: '',
            projectId: '',
            typeNotSet: null,
            noGST: null,
            isVerification: false,
            isFirst: null,
            alertMsg: 'Please complete all General Survey tests before creating any Dilution, Mini Survey or Recirculation tests'
        };
    }

    componentDidMount() {
        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        if (projectId !== null) {
            this.setState({ projectId }, async () => {
                let response = await tests.getAllTests(this.state.projectId);
                const testsData = response.data.data;
                if (Array.isArray(testsData)) {
                    if (!testsData.length) {
                        this.setState({ noGST: true, isFirst: true });
                    } else {
                        let noGST = true;
                        let isVerification = false;
                        for (let test of testsData) {
                            const { testtype } = test;
                            if (testtype === 'generalSurvey') {
                                noGST = false;
                                break;
                            }
                            if (testtype === 'verification') {
                                isVerification = true;
                            }
                        }
                        this.setState({ isFirst: false, noGST, isVerification });
                    }
                }
            });
        }
    }

    onSubmit = async (values) => {
        const data = {
            name: values.name,
            goal: values.goal,
            overview: values.overview,
            type: this.state.type,
            projectId: this.state.projectId,
        };
        if (!data.type) {
            this.setState({ typeNotSet: true });
            return;
        }

        const response = await tests.addTest(data);

        if (response && response.data && response.data.success) {
            const { projectId } = this.state;
            const { name, type } = data;
            
            const testId = response.data.data[0].testid;

            this.context.setTypeId(projectId, testId, type);
            this.context.setTypeName(name, 'Test');
            this.context.setOnProject();
            this.context.setNotOnProjectDetails();
            this.context.setTestType(type);

            if (type === 'generalSurvey') {
                this.props.history.push('/segments');
            } else {
                this.props.history.push('/scenarios');
            }
        } else {
            console.log('error with submission');
        }
    }

    handleInputChange = (event) => {
        if (!event || !event.target) return;

        const { value, name } = event.target;

        this.setState({
            [name]: value
        });
    }

    render() {
        const { noGST, isFirst, alertMsg, isVerification } = this.state;
        return (
            <main style={{ position: 'relative' }}>
                {noGST === false && !isFirst && <AlertNewTest msg={alertMsg} noGST={noGST} />}
                <div style={{ marginTop: '35px' }} className="new-user-wrapper">
                    <div className="new-user-wrapper-header">
                        <Typography variant="h4" color="textPrimary" align="center" style={{ fontFamily: "Gotham", marginTop: "30px" }}>
                            New Test Form
                        </Typography>
                    </div>
                    <div className="new-user-wrapper-form">
                        <Form
                            handleChangeFunc={this.handleInputChange}
                            handleSubmitFunc={this.onSubmit}
                            type={`newTest`}
                            noGST={noGST}
                            isVerification={isVerification}
                            isFirstTest={isFirst}
                            typeNotSet={this.state.typeNotSet}
                        />
                    </div>
                </div>
            </main>
        )
    }
}

NewTestView.contextType = Context;

export default NewTestView;