import React from 'react';
import logo from '../../assets/SafeTraces_Logo.Horiz.png';
import './ChecklistTableHeader.css';

export const LabChecklistTableHeader = props => {
    return (
        <section style={{ width: '100%' }} className="checklist-tags-header-summary">
            <div className="checklist-tags-header-summary-cell">
                <img src={logo} alt="logo" className="checklist-tags-header-logo" />
            </div>
            <div style={{ position: 'relative', top: '10px' }}>
                <div className="checklist-header-item1">{`Laboratory Check List`}</div>
            </div>
        </section> 
    )
}

