import React from '../../../node_modules/react';
import floorPlans from '../../api/floorPlans';
import DeleteFPCellRenderer from '../../components/DeleteFPCellRenderer/DeleteFPCellRenderer';
import { AgGridReact } from '../../../node_modules/ag-grid-react';
import '../../../node_modules/ag-grid-community/dist/styles/ag-grid.css';
import '../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css';
import './FloorPlanList.css';

class FloorPlanList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [{
                headerName: "File Name", 
                field: "filename",
                editable: false, 
                onCellClicked: this.valueChanged.bind(this), 
                width: 300
            }, {
                headerName: "Delete File",
                editable: false, 
                field: "floorplanid",
                cellRenderer: 'deleteFPCellRenderer',
                width: 300
            }],
            defaultColDef: {
                editable: true,
                sortable: true,
                flex: 1,
                minWidth: 100,
                filter: true,
                resizable: true,
            },
            frameworkComponents: {
                deleteFPCellRenderer: DeleteFPCellRenderer
            }
        }
    }

    async valueChanged(event) {
        let colId = event.column.colId;
        if(colId === 'filename') {
            this.props.onFileCellClicked(event);
        }
    }

    render() {
        return (
            <div
            className="ag-theme-alpine"
            style={{
            height: '500px',
            width: '350px',
            textAlign: "center",
            marginTop: '20px' }}>
                <AgGridReact
                    pagination={true}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.props.floorplans}
                    rowSelection="single"
                    testCompleted={this.props.testCompleted}
                    accessLevel={this.props.accessLevel}
                    frameworkComponents={this.state.frameworkComponents}
                    deleteFloorPlans={this.props.deleteFloorPlans}
                >
                </AgGridReact>
            </div>
        );
    }
}

export default FloorPlanList;