import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import utils from '../../utils/utils';
import { OpLabelTags } from '../OpLabelTags/OpLabelTags';
import { SpLabelSns } from '../SpLabelSns/SpLabelSns';
import './Label.css';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        margin: 10
    },
    media: {
        height: 66,
        width: 120,
        marginTop: 10
    },
    label: {
        position: "absolute",
        top: 120,
        left: 200
    },
    warning: {
        color: "#FF0000",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: '15px'
    },
    topSectionDescription: {
        paddingRight: 20,
        fontWeight: "bold",
        fontSize: 20,
        align: "right"
    }
});

export const Label = ({ warning, surveydate, projectName, number, name, tag, qrcode, sampleNumber, spNumber, sampleType, samplePoint, plates, label, projectId }) => {
    const classes = useStyles();
    let opid = false;

    const opType = label => {
        let { opid } = label;
        if(opid !== undefined) {
            return true;
        } else {
            return false;
        }
    }

    const trimScLabelPrefix = plates => {
        return plates.map(plate => {
            if(plate !== null) {
                if(plate.includes('Tag-')) {
                    plate = plate.slice(4);
                    return plate;
                } else {
                    return plate;
                }
            }
        });
    }

    opid = opType(label);

    if(plates !== undefined) {
        plates = trimScLabelPrefix(plates);
    }
    
    return (
        <Card style={plates ? {height: '1in', width: '.75in', margin: '0.9px 0.5px 0.5px 0.5px', paddingTop: '6px 0 2px 0', border: 'none'} : {height: '2in', width: '3in', margin: '5px 5px 5px 5px', padding: '5px 5px 5px 5px', border: 'none'}} variant="outlined">
            {!plates ? (
                <CardContent>
                    <section className="label-top-section-content">
                        <div className="logo-wrapper">
                            <img src="/static/media/SafeTraces_Logo.Horiz.96beedfd.png" alt="logo" className="horizontal-logo" />
                        </div>
                        <div className="label-top-section-description">
                            <Typography className={classes.topSectionDescription} variant="body2" color="textPrimary" align="center">
                                {number}
                            </Typography>
                        </div>
                    </section>
                    <div className="horizontal-bar"></div>
                    <section className="label-top-section-content-op-sp">
                        <div style={{ width: '190px' }}>
                            <Typography variant="body2" color="textPrimary" align="left">
                                {`Test: ${label.testNumber}`}
                            </Typography>
                            <Typography variant="body2" color="textPrimary" align="left">
                                Scenario:
                            </Typography>
                            {opid ? (
                                <OpLabelTags tags={label} />
                            ) : (
                                <SpLabelSns sns={label.samples} />
                            )}
                        </div>
                        <div style={{ height: '10%', width: '70px', position: 'relative' }}>
                            <Typography className={classes.warning} variant="body2" align="center">
                                {warning}
                            </Typography>
                        </div>
                    </section>
                    <div className="horizontal-bar"></div>
                    <section className="label-bottom-section-content-op-sp">
                        <div style={{width: '70%'}}>
                            <Typography variant="body2" color="textPrimary" align="center" noWrap>
                                {`${projectId}`}: {projectName}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body2" color="textPrimary" align="center" noWrap>
                                {surveydate}
                            </Typography>
                        </div>
                    </section>
                </CardContent>
            ) : (
                <CardContent>
                    <section className="label-section-content-sc">
                        <div className="label-section-content-sc-values">
                            <div style={{ color: 'rgb(0, 137, 150)' }} className="label-section-content-sc-sn">
                                {sampleNumber}
                            </div>
                            <div className="label-section-content-sc-values-styling">
                                {spNumber}
                            </div>
                            <div className="label-section-content-sc-values-styling">
                                {sampleType}
                            </div>
                            {/**
                            <div style={{ marginRight: '30px' }} className={plates.length > 3 ? "label-plates-reduced-font" : "label-plates"}>
                                {plates.map((plate, i) => (
                                    <div style={{ marginRight: '5px' }} key={`plate-key-${i}`}>{`${plate}`}</div>
                                ))}
                            </div>
                             */}
                            <div className="horizontal-bar"></div>
                            <div style={{ marginBottom: '0px', paddingBottom: '0px', color: 'rgb(0, 137, 150)' }} className="label-section-content-sc-values-styling">
                                {`${projectId}`}-{!!projectName ? utils.truncate(projectName, 15) : projectName}
                            </div>
                            <div className="horizontal-bar"></div>
                            <div style={{ marginBottom: '5px' }} className="label-section-content-sc-values-styling">
                                {surveydate}
                            </div>
                        </div>
                    </section>
                </CardContent>
            )}
        </Card>
    );
};
