import axios  from 'axios';
import config from '../config.js';

const BASEURL = config.baseURL + '/tags';

const tags = {
    /**
     * Receive amount of tags related to project
     *
     * @param projectId
     * @returns {Promise<AxiosResponse<any>>}
     */
    getTagsCount: async (projectId) => {
        /* WARNING: The structure of the data returned by this method has been changed as of March 2023.
         * New structure looks like this:
         * {
         *    'Tag-A1': { 'Sprayer-1': 4, 'Sprayer-2': 7 },
         *    'Tag-A2': { 'Sprayer-1': 1, 'Sprayer-2': 2 },
         *  }
        */
        if (projectId !== null) {
            return await axios.get(BASEURL + '/count?projectId=' + projectId, {});
        }
    },

    /**
     * Receive list of all tags available in system
     *
     * @param platekind
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAllTags: async (platekind = null) => {
        if (platekind !== null) {
            return await axios.get(BASEURL + '?platekind=' + platekind, {});
        } else {
            return await axios.get(BASEURL, {});
        }
    },

    /**
     * Receive list of all tags and tagproperties available in system to be downloaded as an excel sheet
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
     getAllTagProperties: async () => {
        return await axios.get(BASEURL + '/properties', {});
    },

    /**
     * Create new tag
     *
     * @param plateKind
     * @param tag
     * @param manufactureDate
     * @param expirationDate
     * @param tagSlope
     * @param tagIntercept
     * @param tagNotes
     * @param tagLotNumber
     * @param manufacturedBy
     * @returns {Promise<AxiosResponse<any>>}
     */
    addOneTag: async (
        plateKind,
        tag,
        manufactureDate,
        expirationDate,
        tagSlope,
        tagIntercept,
        tagNotes = '',
        tagLotNumber,
        manufacturedBy,
    ) => {
        const data = {
            plateKind:       plateKind,
            tag:             tag,
            manufactureDate: manufactureDate,
            expirationDate:  expirationDate,
            tagSlope:        tagSlope,
            tagIntercept:    tagIntercept,
            tagNotes:        tagNotes,
            tagLotNumber:    tagLotNumber,
            manufacturedBy:  manufacturedBy
        };
        return await axios.post(BASEURL, data, {});
    },

    /**
     * Update tag's details by ID
     *
     * @param plateKind
     * @param tag
     * @param manufactureDate
     * @param expirationDate
     * @param tagSlope
     * @param tagIntercept
     * @param tagNotes
     * @param tagLotNumber
     * @param manufacturedBy
     * @param tagId
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateOneTag: async (
        plateKind,
        tag,
        manufactureDate,
        expirationDate,
        tagSlope,
        tagIntercept,
        tagNotes = '',
        tagLotNumber,
        manufacturedBy,
        tagId
    ) => {
        const data = {
            plateKind:       plateKind,
            tag:             tag,
            manufactureDate: manufactureDate,
            expirationDate:  expirationDate,
            tagSlope:        tagSlope,
            tagIntercept:    tagIntercept,
            tagNotes:        tagNotes,
            tagLotNumber:    tagLotNumber,
            manufacturedBy:  manufacturedBy,
        };
        return await axios.put(BASEURL + '/' + tagId, data, {});
    },

    /**
     * Remove tag by ID
     *
     * @param tagId
     * @returns {Promise<AxiosResponse<any>>}
     */
    removeOneTag: async (tagId) => {
        return await axios.delete(BASEURL + '/' + tagId, {});
    },


    /**
     * Create a new tag's baseline
     *
     * @param tagId
     * @param qcDate
     * @param baseline
     * @returns {Promise<AxiosResponse<any>>}
     */
    addTagBaseline: async (tagId, qcDate, baseline) => {
        const data = { tagQCDate: qcDate, tagBaseline: baseline };
        return await axios.post(BASEURL + '/' + tagId + '/baseline', data, {});
    },

    /**
     * Update exists tag's baseline
     *
     * @param tagId
     * @param tagBaselineId
     * @param qcdate
     * @param baseline
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateTagBaseline: async (tagId, tagBaselineId, qcdate, baseline) => {
        const data = { qcdate: qcdate, baseline: baseline };
        return await axios.put(BASEURL + '/' + tagId + '/baseline/' + tagBaselineId, data, {});
    },

    /**
     * Remove tag's baseline
     *
     * @param tagId
     * @param tagBaselineId
     * @returns {Promise<AxiosResponse<any>>}
     */
    removeTagBaseline: async (tagId, tagBaselineId) => {
        return await axios.delete(BASEURL + '/' + tagId + '/baseline/' + tagBaselineId, {});
    },

};

export default tags;
