import React, {useEffect, useRef, useState, useContext} from 'react';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import './ProjectDetails.css';
import TextField from "@material-ui/core/TextField";
import {useForm} from "react-hook-form";
import projects from "../../api/projects";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Context } from '../../context/Context';
import { AlertGeneral } from '../../components/Alert/AlertGeneral';
import { GeneralConfirmationDialog } from '../../components/ConfirmationDialog/GeneralConfirmationDialog';

export const ProjectDataEdit = ({ projectId, projectData, partnersList, handleProjectEdit, fetchUpdatedProject, customerCompanies }) => {
    const [ name, setName ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ endCustomer, setEndCustomer ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ partners, setPartners ] = useState('');
    const { register, errors } = useForm();
    const { state } = useContext(Context);
    const [ alertOpen, setAlertOpen ] = useState(false);
    const [ alertMsg, setAlertMsg ] = useState('');
    const [ shouldConfirmStatusChange, setShouldConfirmStatusChange ] = useState(false);

    const firstUpdate = useRef(true);
    useEffect(() => {
        if (!firstUpdate.current || !projectData) {
            return;
        }
        setName(projectData.name);
        setAddress(projectData.address);
        setEndCustomer(projectData.endCustomer);
        setDescription(projectData.description);
        setPartners(projectData.partners);

        firstUpdate.current = false;
    });

    const handleEditProjectClick = projId => {
        let projectId = JSON.stringify(projId);
        localStorage.setItem("projectId", projectId);
        handleProjectEdit();
    }

    const handleSubmit = (event) => {
        const projectEditData = {
            projectName: name,
            address: address,
            partners: partners,
            endCustomer: endCustomer,
            users: projectData.users,
            totalSegments: projectData.totalSegments,
            description: description,
        }

        projects.updateOneProject(projectId, projectEditData).then(()=> {
            fetchUpdatedProject();
            handleProjectEdit();
        })

        event.preventDefault();
    }

    const handleChange = (event) => {
        const value = event.target.value;
        const field = event.target.name;

        switch(field) {
            case 'name':
                setName(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'endCustomer':
                setEndCustomer(value);
                break;
            case 'description':
                setDescription(value);
                break;
            case 'partners':
                setPartners(value);
                break;
            default:
                break
        }
    }

    if (!projectData) {
        return null;
    }

    const handleStatusChange = async () => {
        let errMsg;
        try {
            const result = await projects.updateStatus(projectId, 'exec_ready');
            if (result?.data?.success) {
                await fetchUpdatedProject();
                setShouldConfirmStatusChange(false);
            } else {
                if (result?.data?.error?.message) {
                    errMsg = result.data.error.message;
                    throw new Error();
                }
            }

        } catch (err) {
            console.log(err, 'err')
            setShouldConfirmStatusChange(false);  
            setAlertMsg(errMsg || 'Something went wrong');
            setAlertOpen(true);
        }
    }

    return (
        <div>
            <form onSubmit={e => handleSubmit(e)} noValidate>
            {!!alertOpen && (<AlertGeneral open={alertOpen} alertMsg={alertMsg} closeFunc={() => setAlertOpen(false)} severity='error' />)}
                <div className="grid-wrapper">
                    <div>
                        <Typography variant="h5" color="textPrimary">
                            {`Project (Id ${projectId})`}
                            <Button
                                size="small"
                                variant="outlined"
                                className="add-edit-button"
                                onClick={() => handleEditProjectClick(projectId)}
                            >
                                Back to project details
                            </Button>
                        </Typography>
                    </div>
                </div>
                {projectData.status === 'in_progress' && <>
                    <div>
                        <Typography variant="h5" color="textPrimary" style={{marginTop: 0}}>
                            <Button
                                disabled={state.accessLevel !== 'Admin'}
                                variant="outlined"
                                onClick={() => setShouldConfirmStatusChange(true)}
                                style={{color: '#008996'}}
                                size="small"
                            > 
                                Set ready for execution
                            </Button>
                        </Typography>
                    </div>
                    <div style={{margin: "16px 0px 8px 0px"}}>
                        <Typography style={{margin: 0}} variant='h5' color="textPrimary">
                            Details
                        </Typography>
                    </div>
                </>}
                <div className="grid-wrapper-info">
                    <div>
                        <TextField
                            required
                            inputRef={register({ required: true})}
                            id="name"
                            label="Project Name"
                            name="name"
                            autoComplete="name"
                            style={{ fontFamily: 'Gotham', width: '100%' }}
                            onChange={e => handleChange(e)}
                            error={errors.name}
                            helperText={errors.name && "Project name is required"}
                            value={name}
                            autoFocus
                        />
                    </div>
                    <div>
                        {state.companyName === 'Safetraces' && (
                            <FormControl required={true}>
                                <InputLabel id="endCustomer">Customer</InputLabel>
                                <Select
                                    labelId="endCustomer"
                                    id="endCustomer"
                                    name="endCustomer"
                                    value={endCustomer}
                                    autoFocus
                                    onChange={e => handleChange(e)}>
                                        {customerCompanies.map((companyName, i) => (
                                            <MenuItem key={`${i}-${companyName}`} value={companyName}>{companyName}</MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                    </div>
                    <div>
                        <TextField
                            required
                            inputRef={register({ required: true})}
                            id="address"
                            label="Address"
                            name="address"
                            autoComplete="address"
                            style={{ fontFamily: 'Gotham', width: '100%' }}
                            onChange={e => handleChange(e)}
                            error={errors.address}
                            helperText={errors.address && "Address is required"}
                            value={address}
                        />
                    </div>
                    <div>
                    {state.companyName === 'Safetraces' && (
                        <FormControl required={false} style={{ fontFamily: 'Gotham', width: '100%' }}>
                            <InputLabel id="partners">Partner</InputLabel>
                            <Select
                                labelId="partners"
                                id="partners"
                                name="partners"
                                onChange={e => handleChange(e)}
                                value={partners}
                            >
                                {partnersList.map((companyName, i) => (
                                    <MenuItem key={`${i}-${companyName}`} value={companyName}>{companyName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    </div>
                    <div>
                        <TextField
                            required
                            inputRef={register({ required: true})}
                            id="description"
                            label="Description"
                            name="description"
                            autoComplete="description"
                            style={{ fontFamily: 'Gotham', width: '100%' }}
                            onChange={e => handleChange(e)}
                            error={errors.description}
                            helperText={errors.description && "Description is required"}
                            value={description}
                        />
                    </div>
                </div>
                <div className="grid-wrapper">
                    <div>
                        <Typography variant="h5" color="textPrimary">
                            <Button
                                size="small"
                                variant="outlined"
                                className="add-edit-button"
                                type="submit"
                                style={{marginLeft: '10px'}}
                            >
                                Save
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                className="add-edit-button"
                                onClick={() => handleEditProjectClick(projectId)}
                            >
                                Cancel
                            </Button>
                        </Typography>
                    </div>
                </div>
            </form>
            {projectData.status === 'in_progress' && (
                <GeneralConfirmationDialog
                    open={shouldConfirmStatusChange}
                    title={"Are you sure you want to change the project status to 'ready for execution'?"}
                    onOk={handleStatusChange}
                    onCancel={() => setShouldConfirmStatusChange(false)}
                    cancelBtnText={"Cancel"}
                    okBtnText={"Change status"}
                />
            )}
        </div>
    );
};