import React, { useEffect, useRef, useState } from 'react';
import tagsApi                                from '../../api/tags';
import usersApi                               from '../../api/users';
import { Context }                            from '../../context/Context';
import Typography                             from '@material-ui/core/Typography';
import { TagList }                            from "../../components/TagList/TagList";
import Tooltip                                from "@material-ui/core/Tooltip";
import IconButton                             from "@material-ui/core/IconButton";
import AddBoxIcon                             from "@material-ui/icons/AddBox";
import Modal                                  from "@material-ui/core/Modal";
import Backdrop                               from "@material-ui/core/Backdrop";
import Fade                                   from "@material-ui/core/Fade";
import TextField                              from "@material-ui/core/TextField";
import Button                                 from "@material-ui/core/Button";
import { makeStyles }                         from "@material-ui/core/styles";
import InputLabel                             from "@material-ui/core/InputLabel";
import Select                                 from "@material-ui/core/Select";
import MenuItem                               from "@material-ui/core/MenuItem";
import FormControl                            from "@material-ui/core/FormControl";
import { DatePicker }                         from "@material-ui/pickers";
import { useForm }                            from "react-hook-form";
import './TagsView.css';
import { format, parseISO } from "date-fns";
import { CSVLink } from 'react-csv';
import { AlertGeneral } from '../../components/Alert/AlertGeneral';

const useRowStyles = makeStyles((theme) => ({
    cell:        {
        fontFamily:  'Gotham',
        fontSize:    '13px',
        textAlign:   'center',
        borderRight: 'none',
        borderLeft:  'none',
        height:      '40px',
    },
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell:  {
        fontFamily: 'Gotham',
        fontSize:   '13px',
        fontWeight: '700',
        textAlign:  'center',
        padding:    '10px !important'
    },
    tableRow:    {
        cursor: 'pointer',
    },
    modal:       {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center'
    },
    paper:       {
        backgroundColor: theme.palette.background.paper,
        boxShadow:       theme.shadows[5],
        padding:         theme.spacing(7, 5, 7),
        textAlign:       'center',
        fontFamily:      'Gotham',
        minWidth:        '350px'
    },
}));

export const TagsView = () => {

    const classes              = useRowStyles();
    const { register, errors } = useForm();

    const [ tags, setTags ]   = useState([]);
    const [ users, setUsers ] = useState([]);
    const [ tagProperties, setTagProperties ] = useState([]);

    const [ tagAddModal, setTagAddModal ] = useState(false);

    const [ tagName, setTagName ]                       = useState(null);
    const [ tagPlateKind, setTagPlateKind ]             = useState(null);
    const [ tagSlope, setTagSlope ]                     = useState(null);
    const [ tagIntercept, setTagIntercept ]             = useState(null);
    const [ tagLotNumber, setTagLotNumber ]             = useState(null);
    const [ tagManufactureDate, setTagManufactureDate ] = useState(null);
    const [ tagExpirationDate, setTagExpirationDate ]   = useState(null);
    const [ tagManufacturedBy, setTagManufacturedBy ]   = useState(null);
    const [ tagNotes, setTagNotes ]                     = useState(null);

    const [ alertOpen, setAlertOpen ]                   = useState(false);
    const [ alertMsg, setAlertMsg ]                     = useState('');

    const tagsList = [
        'Tag-A1', 'Tag-A2', 'Tag-A3', 'Tag-A4', 'Tag-A5', 'Tag-A6', 'Tag-A7', 'Tag-A8',
        'Tag-B1', 'Tag-B2', 'Tag-B3', 'Tag-B4', 'Tag-B5', 'Tag-B6', 'Tag-B7', 'Tag-B8',
        'Tag-C1', 'Tag-C2', 'Tag-C3', 'Tag-C4', 'Tag-C5', 'Tag-C6', 'Tag-C7', 'Tag-C8',
    ];

    const plateKindList = [ 'A', 'B', 'C' ];

    /**
     * Open modal for adding new tag
     */
    const handleTagAddModal = () => {
        setTagAddModal(!tagAddModal);
    }

    /**
     * Editing details about tag
     *
     * @param field
     * @param value
     */
    const handleChange = (field, value) => {
        switch (field) {
            case "tag":
                setTagName(value.target.value);
                const plateKind = value.target.value.split('-')[1][0];
                if (plateKindList.includes(plateKind)) {
                    setTagPlateKind(plateKind);
                }
                break;
            case "platekind":
                setTagPlateKind(value.target.value);
                break;
            case "tagslope":
                setTagSlope(value.target.value);
                break;
            case "tagintercept":
                setTagIntercept(value.target.value);
                break;
            case "taglotnumber":
                setTagLotNumber(value.target.value);
                break;
            case "manufacturedate":

                if (value === null) {
                    setTagManufactureDate(null);
                } else {
                    setTagManufactureDate(format(value, 'yyyy-MM-dd'));
                }

                break;
            case "expirationdate":

                if (value === null) {
                    setTagExpirationDate(null);
                } else {
                    setTagExpirationDate(format(value, 'yyyy-MM-dd'));
                }

                break;
            case "manufacturedby":
                setTagManufacturedBy(value.target.value);
                break;
            case "tagnotes":
                setTagNotes(value.target.value);
                break;
        }
    }

    /**
     * Create new tag
     *
     * @returns {Promise<void>}
     */
    const handleTagAddSubmit = async () => {
        const response = await tagsApi.addOneTag(
            tagPlateKind,
            tagName,
            tagManufactureDate,
            tagExpirationDate,
            tagSlope,
            tagIntercept,
            tagNotes,
            tagLotNumber,
            tagManufacturedBy,
        );

        if (response && response.data) {
            const { success } = response.data;
            if (success) {
                // Refresh list of all tags
                await fetchTags();
                // Refresh list of all tag properties to be downloaded
                await fetchTagProperties();

                // Set defaults to null
                setTagName(null);
                setTagPlateKind(null);
                setTagSlope(null);
                setTagIntercept(null);
                setTagLotNumber(null);
                setTagManufactureDate(null);
                setTagExpirationDate(null);
                setTagManufacturedBy(null);
                setTagNotes(null);
                // Close Tag Add modal
                setTagAddModal(false);
            } else {
                let msgAlert = 'Something went wrong';
                const { error } = response.data;
                if (error && error.message && typeof error.message === 'string') {
                    msgAlert = error.message;
                }
                handleAlert(true, msgAlert);
            }
        }
    }

    /**
     * Get list of all tags
     *
     * @returns {Promise<void>}
     */
    const fetchTags = async () => {
        const response = await tagsApi.getAllTags();
        setTags(response.data.data);
    }

    /**
     * Get list of all users
     *
     * @returns {Promise<void>}
     */
    const fetchUsers = async () => {
        const response = await usersApi.getUsers();
        setUsers(response.data.data);
    }

    /**
     * Get list of all tags
     *
     * @returns {Promise<void>}
     */
     const fetchTagProperties = async () => {
        const response = await tagsApi.getAllTagProperties();
        setTagProperties(response.data.data);
    }

    const firstUpdate = useRef(true);
    useEffect(() => {
        if (!firstUpdate.current) {
            return;
        }
        fetchUsers();
        fetchTags();
        fetchTagProperties();
        firstUpdate.current = false;
    });

    const handleAlert = (open, message = '') => {
        setAlertOpen(open);
        if (open) {
            setAlertMsg(message);
        }
    }

    return (
        <Context.Consumer>
            {context => (context.state.accessLevel !== 'Admin' && context.state.roleName !== 'Safetracer') ? null : (
                <>
                    <div className="tags-view-container">
                        <Typography variant="h4" color="textPrimary" align="center" style={{ fontFamily: "Gotham", marginTop: "30px" }}>{`Tags`}</Typography>
                        <div className="tags-view-body">
                            <div className="tags-view-btn-container">
                                <div style={{ marginTop: '20px'}}>
                                    <CSVLink
                                        data={tagProperties}
                                        style={{margin: '0 10px 0 10px', textDecoration: "none"}}
                                        filename={`Tag-Lot-Properties-` + new Date().toISOString().slice(0, 10) + `.csv`}>
                                        <Button size="small" variant="outlined" style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", fontFamily: "Gotham"}} >Download</Button>
                                    </CSVLink>
                                </div>
                                <Tooltip title="Add Tag" placement="top" aria-label="add-tag">
                                    <IconButton style={{ color: '#82BC00' }} onClick={handleTagAddModal} aria-label="add-tag-button">
                                        <AddBoxIcon style={{ height: '45px', width: '45px' }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <TagList users={users} tags={tags} fetchTags={fetchTags} fetchTagProperties={fetchTagProperties}/>
                        </div>
                    </div>

                    {/* Modal Tag START */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={tagAddModal}
                        onClose={handleTagAddModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}>
                        <div>
                            <Fade in={tagAddModal}>
                                <div className={classes.paper}>
                                    <div className="grid-wrapper">
                                        <div>
                                            <h2 id="transition-modal-title">
                                                Create new tag
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="grid-wrapper">
                                        <div>
                                            <FormControl required={false} style={{ fontFamily: 'Gotham', width: '100%' }}>
                                                <InputLabel id="tagNames">Name</InputLabel>
                                                    <Select
                                                        labelId="Name"
                                                        id="Name"
                                                        name="tag-name"
                                                        onChange={(newValue) => handleChange("tag", newValue)}
                                                        value={tagName ?? ''}
                                                        autoFocus
                                                    >
                                                        {tagsList.map((tagName, i) => (
                                                            <MenuItem key={`${i}-${tagName}`} value={tagName}>{tagName}</MenuItem>
                                                        ))}
                                                    </Select>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormControl required={false} style={{ fontFamily: 'Gotham', width: '100%' }}>
                                                <InputLabel id="plateKinds">Platekind</InputLabel>
                                                    <Select
                                                        labelId="Platekind"
                                                        id="Platekind"
                                                        name="tag-platekind"
                                                        disabled={Boolean(tagPlateKind)}
                                                        onChange={(newValue) => handleChange("platekind", newValue)}
                                                        value={tagPlateKind ?? ''}
                                                    >
                                                        {plateKindList.map((tagPlatekind, i) => (
                                                            <MenuItem key={`${i}-${tagPlatekind}`} value={tagPlatekind}>{tagPlatekind}</MenuItem>
                                                        ))}
                                                    </Select>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth={true}
                                                label="Slope"
                                                name="tag-slope"
                                                autoComplete="tag-slope"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("tagslope", newValue)}
                                                value={tagSlope ?? ''}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth={true}
                                                label="Intercept"
                                                name="tag-intercept"
                                                autoComplete="tag-intercept"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("tagintercept", newValue)}
                                                value={tagIntercept ?? ''}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth={true}
                                                label="Lot Number"
                                                name="tag-lotnumber"
                                                autoComplete="tag-lotnumber"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("taglotnumber", newValue)}
                                                value={tagLotNumber ?? ''}
                                            />
                                        </div>
                                        <div>
                                            <DatePicker
                                                fullWidth={true}
                                                inputRef={register({ required: true })}
                                                label="Manufacture Date (Bulk saliva)"
                                                name="tag-manufacturedate"
                                                autoComplete="tag-manufacturedate"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("manufacturedate", newValue)}
                                                error={errors.manufacturedate}
                                                format="yyyy-MM-dd"
                                                mask="____-__-__"
                                                value={tagManufactureDate !== null ? parseISO(tagManufactureDate) : null}
                                                clearable
                                            />
                                        </div>
                                        <div>
                                            <DatePicker
                                                fullWidth={true}
                                                inputRef={register({ required: true })}
                                                label="Expiration Date (Bulk saliva)"
                                                name="tag-expirationdate"
                                                autoComplete="tag-expirationdate"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("expirationdate", newValue)}
                                                error={errors.manufacturedate}
                                                format="yyyy-MM-dd"
                                                mask="____-__-__"
                                                value={tagExpirationDate !== null ? parseISO(tagExpirationDate) : null}
                                                clearable
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth={true}
                                                label="Manufactured By"
                                                name="tag-manufacturedby"
                                                autoComplete="tag-manufacturedby"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("manufacturedby", newValue)}
                                                value={tagManufacturedBy ?? ''}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth={true}
                                                label="Notes"
                                                name="tag-notes"
                                                autoComplete="tag-notes"
                                                style={{ fontFamily: 'Gotham' }}
                                                onChange={(newValue) => handleChange("tagnotes", newValue)}
                                                value={tagNotes ?? ''}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid-wrapper">
                                        <div>
                                            <Button
                                                onClick={handleTagAddSubmit}
                                                disabled={!(tagPlateKind && tagName && tagManufactureDate && tagSlope && tagIntercept && tagLotNumber)}
                                                size="small"
                                                className="modal-button"
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                onClick={handleTagAddModal}
                                                size="small"
                                                className="modal-button"
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </Modal>
                    {/* Modal Tag END */}
                    <AlertGeneral
                        open={alertOpen}
                        alertMsg={alertMsg}
                        closeFunc={() => handleAlert(false)}
                        severity='error'
                    />
                </>
            )}
        </Context.Consumer>
    );
}
