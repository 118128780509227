import axios from 'axios';
import config from '../config.js';
const BASEURL = config.baseURL + '/common';

const common = {
  getSprayers : async() => {
      const response = await axios.get (BASEURL + '/sprayers', {});
      return response; 
  }
};


export default common;