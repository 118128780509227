import React, { Component } from 'react';
import { Context } from '../../context/Context';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SamplesTable from '../../components/SamplesTable/SamplesTable';
import samplepoints from '../../api/samplepoints';
import { SamplesChecklistTableHeader } from '../../components/ChecklistTableHeader/SamplesChecklistTableHeader';
import { CSVLink } from 'react-csv';
import './SamplesView.css';

export class SamplesView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projectId: null, 
            totalNumSamples: null,
            projectName: null,
            address: null,
            testName: null,
            csvData: []
        };
    }

    formatCsvData = () => {
        let data = [['Airflow L/min', 'SP Total', 'Sampling Events', 'Samples', 'Sampling Duration', 'Sampling Times']];

        let x = ['-'];
        x.push(this.state.sampleDetails['SPTotal']);
        x.push(this.state.sampleDetails['intervalCount']);
        x.push(this.state.sampleDetails['samplesTotal']);
        x.push(this.state.sampleDetails['intervalDuration']);
        let samplingTimes = '';
        if (this.state.sampleDetails.samplingTimes) {
            samplingTimes = 'T1 = ' + this.state.sampleDetails.samplingTimes['T1'] + ' T2 = ' + this.state.sampleDetails.samplingTimes['T2']+ ' T3 = ' + this.state.sampleDetails.samplingTimes['T3']+ ' T4 = ' + this.state.sampleDetails.samplingTimes['T4'];
        }
        x.push(samplingTimes);
        data.push(x);
        data.push(['ScenarioID', 'Scenario Name', 'SP Number', 'SP Name', 'sample Type', 'T1', 'T2', 'T3', 'T4']);

        this.state.sampleDetails.samples.forEach(sample => {
            data.push([ sample.scenarioId?sample.scenarioId:'-', 
                        sample.scenarioName?sample.scenarioName:'-', 
                        sample.SPNumber?sample.SPNumber:'-', 
                        sample.SPName?sample.SPName:'-',
                        sample.sampleType?sample.sampleType:'-', 
                        sample.samplesAtInterval && sample.samplesAtInterval['T1']?sample.samplesAtInterval['T1']:'-', 
                        sample.samplesAtInterval && sample.samplesAtInterval['T2']?sample.samplesAtInterval['T2']:'-',
                        sample.samplesAtInterval && sample.samplesAtInterval['T3']?sample.samplesAtInterval['T3']:'-',
                        sample.samplesAtInterval && sample.samplesAtInterval['T4']?sample.samplesAtInterval['T4']:'-'
                    ]);
        });

        this.setState({ csvData : data });
    }

    async componentDidMount() {
        let checklistsNavButton = document.querySelector('#sBtn');
        if(checklistsNavButton) {
            checklistsNavButton.classList.add('selected');
        }
        let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
        let testId = JSON.parse(localStorage.getItem("testId")) ? JSON.parse(localStorage.getItem("testId")) : null;
        let testType = JSON.parse(localStorage.getItem("testType")) ? JSON.parse(localStorage.getItem("testType")) : null;
        let testName = localStorage.getItem("testName") ? localStorage.getItem("testName") : null; 

        this.setState({
            testId: testId,
            testType: testType,
            testName: testName,
            projectId
        }, async () => {
            let response = await samplepoints.getDetails(testId);
            if(response.data.success) {
                this.setState({ 
                    sampleDetails: response.data.data
                }, () => {
                    this.formatCsvData();
                });
            }
        });
    };

    componentDidUpdate() {
        let checklistsNavButton = document.querySelector('#sBtn');
        if(checklistsNavButton) {
            checklistsNavButton.classList.add('selected');
        }
    }

    componentWillUnmount() {
        let checklistsNavButton = document.querySelector('#sBtn');
        if(checklistsNavButton) {
            checklistsNavButton.classList.remove('selected');
        }
    }

    render() {
        const { testName, sampleDetails, csvData, projectId } = this.state;
        if(sampleDetails !== undefined && csvData !== undefined) {
            return (
                <Context.Consumer>
                    {context => (
                        <main className="samples-checklist-container">
                            <section className="samples-checklist-container-header-section">
                                <Typography variant="h5" color="textPrimary" align="center" style={{fontFamily: "Gotham", marginTop: "30px", marginLeft: "100px"}}>{`(Project Id ${projectId}) ${context.state.projectName}: ${context.state.testName} - View Samples`}</Typography>
                            </section> 
                            <main style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    width: '75%',
                                }}>
                                <section style={{ display: 'flex', flexDirection: 'row'  }}>
                                    <SamplesChecklistTableHeader />
                                    <section style={{ position: 'relative', top: '30px' }}>
                                        <CSVLink 
                                            data={csvData}
                                            filename={`${context.state.projectName}-${projectId}-${context.state.testName}-samples.csv`} 
                                            style={{margin: '0 10px 0 10px', textDecoration: "none"}}>
                                                <Button size="small" variant="outlined" style={{backgroundColor: "#82BC00", color: "#ffffff", borderColor: "#82BC00", fontFamily: "Gotham"}} >Download</Button>
                                        </CSVLink>
                                    </section>
                                </section>
                                <section style={{ width: '100%' }} className="samples-checklist-materials-table">
                                    <SamplesTable testType={this.state.testType}/>
                                </section>
                            </main>
                        </main>
                    )}
                </Context.Consumer>
            );
        } else {
            return null;
        }
        
    }
}