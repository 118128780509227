import axios from 'axios';
import config from '../config.js';
import { saveAs } from 'file-saver';
import JSZip from "jszip";

const BASEURL = config.baseURL + '/sampleplans';

const sampleplans = {
  getSamplePlan : async(segmentId) => {
    const response = await axios.get (BASEURL + '/' + segmentId, {});
    return response;
  },
  updateSamplePlan : async(projectId) => {
    const response = await axios.post (BASEURL + '/update/' + projectId, {});
    return response;
  },
  getSamplePlanForProject : async(testId, projectId) => {
    if(testId !== null) {
      const response = await axios.get (BASEURL + '?testId=' + testId, {});
      return response;
    } else {
      const response = await axios.get (BASEURL + '?projectId=' + projectId, {});
      return response;
    }
  },
  createSamplePlan : async(segmentId) => {
    const response = await axios.post (BASEURL + '/create/' + segmentId, {});
    return response;
  },
  getLabels : async(testId, projectId) => {
    if(testId !== null) {
      const response = await axios.get (BASEURL + '/labels?testId=' + testId , {});
      return response;
    } else {
      const response = await axios.get (BASEURL + '/labels?projectId=' + projectId , {});
      return response;
    }
  },
  getSamplesForLab : async(testId) => {
    const response = await axios.get (BASEURL + '/lab?testId=' + testId , {});
    return response;
  },
  getZipUrl: (projectId, projectName)=> {
    axios({
      url: BASEURL + '/zip/' + projectId,
      method: 'GET',
    }).then((response) => {
      const zip = new JSZip();
      for ( const [ key, value ] of Object.entries(response.data) ) {
        const plateNumber = key.slice(0, -4); // remove file format from key
        zip.file(`PID-${projectId}-${projectName}-${plateNumber}-Long.csv`, typeof value === "object" ? JSON.stringify(value) : value);
      }
      zip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, `PID-${projectId}-${projectName}-Long.zip`);
      });
    });
  }
};

export default sampleplans;