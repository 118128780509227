import React from "react";

import tests from "../../api/tests";
import segments from "../../api/segments";
import scenarios from "../../api/scenarios";
import testPlan from "../../api/testPlan";
import floorPlans from "../../api/floorPlans";
import originpoints from "../../api/originpoints";
import samplepoints from "../../api/samplepoints";
import sampleplans from "../../api/sampleplans";
import common from "../../api/common";

import ScenariosList from "../../components/ScenariosList/ScenariosList.js";
import MiniSurveyTest from "../../components/MiniSurveyTest/MiniSurveyTest.js";
import {Alert} from "../../components/Alert/Alert";
import {AlertLotNumber} from "../../components/Alert/AlertLotNumber";
import DataLoadingDialog from "../../components/DataLoadingDialog/DataLoadingDialog";
import VolumeBasedTest from "../../components/VolumeBasedTest/VolumeBasedTest";
import { areaOpSpCountConfig } from '../../components/VolumeBasedTest/config';
import {Context} from "../../context/Context";
import { VERIFICATION_LIKE_TESTS } from '../../config';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from "@material-ui/core/styles";
import Select, {components} from "react-select";
import { TestAreaUpdateConfirmation } from '../../components/VolumeBasedTest/TestAreaUpdateConfirmation';

import "./Scenarios.css";

const styles = (theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(0),
            position: "relative",
            top: "15px",
        },
    },
});

const {ValueContainer, Placeholder} = components;

const CustomValueContainer = ({children, ...props}) => {
    return (
      <ValueContainer {...props}>
          <Placeholder {...props} isFocused={props.isFocused}>
              {props.selectProps.placeholder}
          </Placeholder>
          {React.Children.map(children, child =>
            child && child.type !== Placeholder ? child : null
          )}
      </ValueContainer>
    );
};

class Scenarios extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectId: null,
            testId: null,
            floorPlans: [],
            totalSegments: 0,
            segments: [],
            selectedSampleRate: null,
            sampleRate: '',
            sampleRates: [{value: '16 sq.in', label: '16 sq.in'}],
            sprayer: {label: 'Bucko', value: 1},
            sprayerOptions: [],
            scenarios: [],
            disableScenarios: false,
            open: false,
            displayOP: false,
            displaySP: false,
            displaySampleRate: false,
            ops: [],
            updateSuccessful: false,
            surveyDate: null,
            comments: '',
            arrayLenMeasure: [],
            scenarioDescription: {},
            tagLotNumbers: {},
            expirationDates: {},
            launchLotAlert: false,
            lotNumAlertMsg: '',
            segmentid: JSON.parse(localStorage.getItem('segmentid')) ? JSON.parse(localStorage.getItem('segmentid')) : null,
            allScenariosHasTags: false,
            testCompleted: false,
            isUpdating: false,
            projectFromProjectPortal: false,
            alertMsg: '',
            shouldConfirmOpResetOnBuildingDataUpdate: false,
            afterBuildingDataUpdateConfirmation: null,
        };

        setTimeout(() => {
            const testType = JSON.parse(localStorage.getItem("testType")) ? JSON.parse(localStorage.getItem("testType")) : null;

            this.projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
            this.testType = testType;
            this.sprayerFixedByTestType = ~VERIFICATION_LIKE_TESTS.indexOf(testType);
        }, 0);

        this.handleOpenVisualizerClick = this.handleOpenVisualizerClick.bind(this);
    }

    formatSurveyDate = surveyDate => {
        if (surveyDate === null) {
            return new Date().toISOString().slice(0, 10);
        } else {
            return new Date(surveyDate).toISOString().slice(0, 10);
        }
    }

    isSuccessResponse = (res) => res && res.data && res.data.success;

    getResponseData = (res) => {
        let data = null;
        try {
            data = res.data.data;
        } finally {
            return data;
        }
    };

    isNotEmptyArray = (data) => !!(Array.isArray(data) && data.length);

    componentDidMount() {
        //logic to handle highlighting navbar tab
        const segmentsNavButton = document.querySelector('#sBtn')
        if (segmentsNavButton) {
            segmentsNavButton.classList.add('selected');
        }

        let surveyDate;
        let segResponse;

        setTimeout(() => {
            const projectId = JSON.parse(localStorage.getItem('projectId')) || null;
            let testId = JSON.parse(localStorage.getItem('testId')) || null;
            const projectFromProjectPortal = JSON.parse(localStorage.getItem('projectFromProjectPortal'));

            this.setState({
                projectId,
                testId,
                projectFromProjectPortal,
            }, async () => {
                const sprayerRes = await common.getSprayers();

                if (this.isSuccessResponse(sprayerRes)) {
                    const sprayerData = this.getResponseData(sprayerRes);

                    const sprayerOptions = sprayerData.map((sprayer) => {
                        const {sprayername, sprayerid} = sprayer;
                        return {
                            label: sprayername,
                            value: sprayerid
                        };
                    });
                    this.setState({sprayerOptions});
                }

                if (testId) {
                    const oneCheckResponse = await tests.getOneTest(testId);

                    if (this.isSuccessResponse(oneCheckResponse)) {
                        const {
                            sprayername,
                            sprayerid,
                            testexecuted,
                            buildingdata
                        } = this.getResponseData(oneCheckResponse);

                        const sprayer = {
                            label: sprayername,
                            value: sprayerid
                        };

                        this.setState({
                            sprayer,
                            testCompleted: testexecuted,
                            testBuildingData: buildingdata
                        });
                    }
                }

                const segmentData = {
                    projectId,
                    testId,
                    totalSegments: 1
                };

                segResponse = await segments.getSegments(testId, projectId);

                if (this.isSuccessResponse(segResponse)) {
                    //If segment already exists
                    const segmentsData = this.getResponseData(segResponse);
                    const isSegments = this.isNotEmptyArray(segmentsData);

                    if (isSegments) {
                        //Format Date
                        const segment = segmentsData[0];
                        const {segmentid, comments} = segment;

                        surveyDate = segment.surveydate ? this.formatSurveyDate(segment.surveydate) : null;

                        this.setState({
                            segments: segmentsData,
                            surveyDate: surveyDate,
                            comments,
                        }, async () => {
                            //Need to do this to sustain persistance (segmentid) upon page refresh
                            localStorage.setItem('segmentid', segmentid);

                            const scenariosResponse = await scenarios.getScenarios(segmentid);

                            if (this.isSuccessResponse(scenariosResponse)) {
                                const scenariosData = this.getResponseData(scenariosResponse);
                                const isScenarios = this.isNotEmptyArray(scenariosData);

                                this.setState({
                                    displaySampleRate: isScenarios,
                                    scenarios: isScenarios ? scenariosData : [],
                                });

                                if (isScenarios) {
                                    // Get all origin points for test segment
                                    originpoints.getAllOriginpoints(testId, projectId)
                                      .then(async (OpResponse) => {
                                          const opData = this.getResponseData(OpResponse);
                                          const isOp = this.isNotEmptyArray(opData);

                                          if (isOp) {
                                              this.setState({
                                                  displayOP: true,
                                                  ops: opData,
                                              });

                                              this.setTagLotNumbersAndExpirationDates(OpResponse);

                                              //IF LOTNUM ARRAY DOESN'T EXIST GENERATE LENGTH OF OBJ WITH INDEXES
                                              const {taglotnumbers} = opData[0];

                                              if (!taglotnumbers) {
                                                  const scenariosRes = await scenarios.getScenarios(segmentid);
                                                  const scenariosData = this.getResponseData(scenariosRes);

                                                  if (this.isSuccessResponse(scenariosRes)) {
                                                      scenariosData.forEach((_, i) => {
                                                          this.setState(prevState => ({
                                                              scenarios: scenariosData,
                                                              tagLotNumbers: {...prevState.tagLotNumbers, [i]: null},
                                                              expirationDates: {
                                                                  ...prevState.expirationDates,
                                                                  [i]: null
                                                              },
                                                          }));
                                                      })
                                                  }
                                              }
                                          } else {
                                              console.log('api error: ', OpResponse.data.error);
                                          }
                                      });
                                }
                            }

                            // Get all sample points for test segment
                            samplepoints.getAllSamplepoints(testId, projectId)
                              .then(spResponse => {
                                  const spData = this.getResponseData(spResponse);
                                  const isSp = this.isNotEmptyArray(spData);

                                  if (!spData) {
                                      console.log('api error: ', spResponse.data.error);
                                  }

                                  if (isSp) {
                                      const {airSamplerRate} = spData[0];

                                      this.setState({
                                          displaySP: true,
                                          sampleRate: airSamplerRate || '',
                                      });
                                  }
                              });

                            // Get all scenarios for test segment
                            const scenariosRes = await scenarios.getScenarios(segmentid);

                            const scenariosData = this.getResponseData(scenariosRes);
                            const hasScenarios = this.isNotEmptyArray(scenariosData);

                            if (hasScenarios) {
                                scenariosData.forEach((scenario, i) => {
                                    this.setState(prevState => ({
                                        scenarios: scenariosData,
                                        scenarioDescription: {
                                            ...prevState.scenarioDescription,
                                            [i]: scenario.scenariodescription
                                        }
                                    }));
                                })
                            }
                        });
                    } else {
                        try {
                            //  1. Create new segment for the test
                            const segCreate = await segments.createSegments(segmentData);
                            if (this.isSuccessResponse(segCreate)) {
                                //  2. Get newly created segments
                                segResponse = await segments.getSegments(testId, projectId);

                                if (this.isSuccessResponse(segResponse)) {
                                    //Format Date
                                    const segmentsData = this.getResponseData(segResponse);
                                    const {segmentid} = segmentsData[0];

                                    surveyDate = segmentsData[0].surveydate ? this.formatSurveyDate(segmentsData[0].surveydate) : null;

                                    this.setState({
                                        segments: segmentsData,
                                        surveyDate: surveyDate
                                    }, () => {
                                        //Need to do this to sustain persistance (segmentid) upon page refresh
                                        localStorage.setItem('segmentid', segmentid);
                                    });

                                    const createOp = await originpoints.createOriginpoints({testId});

                                    if (this.isSuccessResponse(createOp)) {
                                        this.setState({
                                            displayOP: true,
                                        }, () => {
                                            //Setting surveyDate on for new test Segment creation
                                            const testPlanToUpdate = {
                                                testId,
                                                segmentId: segmentid,
                                                surveyDate: surveyDate,
                                            };

                                            testPlan.updateTestPlan(testPlanToUpdate).then(async () => {
                                                await this.updateSamplePlan();
                                            });
                                        });
                                    } else {
                                        console.log('Error creating OP');
                                    }

                                    if (!!~['miniSurvey', ...VERIFICATION_LIKE_TESTS].indexOf(this.testType)) {
                                        await this.handleScenariosSelect({}, segmentid, 1);
                                    }
                                } else {
                                    console.log('error');
                                }
                            }
                        } catch (err) {
                            console.log(err, 'err');
                        }
                    }

                    floorPlans.getFloorPlans(projectId).then(result => {
                        const data = this.getResponseData(result);
                        if (data) {
                            this.setState({
                                floorPlans: data
                            });
                        }
                    });

                } else {
                    this.setState({
                        displaySampleRate: false
                    });
                }
            });
        }, 0);
    };

    async componentDidUpdate(prevProps, prevState) {
        this.isAllScenariosHasTags();
        if (prevState.tagLotNumber !== this.state.tagLotNumber) {
            originpoints.getAllOriginpoints(this.state.testId, this.state.projectId).then(result => {
                this.setTagLotNumbersAndExpirationDates(result);
            });
        }
    }

    setTagLotNumbersAndExpirationDates = async (result) => {
        this.setState({
            tagLotNumbers: {},
            expirationDates: {},
        });

        const resData = this.getResponseData(result);
        const OpObj = (this.isNotEmptyArray(resData) && resData[0]) || {};

        const {tagLotNumber, tags} = OpObj;

        if (!tagLotNumber) return;

        if (tagLotNumber.length > 0) {
            this.state.scenarios.forEach((scenarioObj, scenarioIndex) => {
                tags.forEach((tagObj) => {
                    let scenarioId = parseInt(tagObj.scenarioId);
                    //If scenarioId for row matches the scenarioId in tags obj then assign to this row
                    if (scenarioId === scenarioObj.scenarioid) {
                        tagLotNumber.forEach((tagLotObj) => {
                            //If tagLotObj tag matches tagObj tag then assign that particular lotNumber and exDate to that index
                            if (tagLotObj.tag === tagObj.tag) {
                                this.setState({
                                    tagLotNumbers: {...this.state.tagLotNumbers, [scenarioIndex]: tagLotObj.lotNumber},
                                    expirationDates: {
                                        ...this.state.expirationDates,
                                        [scenarioIndex]: tagLotObj.expirationDate
                                    },
                                });
                            }
                        });
                    }
                });
            })
        } else {
            //IF LOTNUM ARRAY EMPTY GENERATE LENGTH OF OBJ WITH INDEXES
            let scenariosRes = await scenarios.getScenarios(this.state.segments[0].segmentid);
            if (scenariosRes.data.success) {
                scenariosRes.data.data.forEach((scenario, i) => {
                    this.setState(prevState => ({
                        scenarios: scenariosRes.data.data,
                        tagLotNumbers: {...prevState.tagLotNumbers, [i]: null},
                        expirationDates: {...prevState.expirationDates, [i]: null},
                    }));
                })
            }
        }
    }

    handleSelectSprayer = selectedOption => {
        this.setState({sprayer: selectedOption, isUpdating: true}, async () => {
            tests.updateOneTest(this.state.testId, {sprayerId: this.state.sprayer.value}).then(result => {
                if (result.data.success) {
                    console.log('result: ', result);
                }
                this.setState({isUpdating: false});
            }).catch(err => this.stopUpdateAndAlertError(err.message));
        });
    }

    handleSampleRateChange = e => {
        e.preventDefault();
        let sampleRate = this.state.sampleRate;
        sampleRate = sampleRate.toString();
        if (sampleRate.length <= 0) {
            console.log('Invalid Sample Rate!');
            this.setState({
                launchLotAlert: true,
                lotNumAlertMsg: 'Please provide a valid Air sampler rate'
            })
        } else {
            this.setState({selectedSampleRate: this.state.sampleRate}, async () => {
                tests.setSampleRate({testId: this.state.testId, sampleRate: sampleRate}).then(async result => {
                    if (result.data.success) {
                        this.setState({
                            launchLotAlert: true,
                            lotNumAlertMsg: 'Air sample rate successfully updated'
                        });
                    }
                });
            });
        }
    }

    handleTagsSelect = async (rowVals, ops, scenario, value) => {
        const opsUpdated = [];

        ops.forEach((op) => {
            if (this.testType === 'recirculation') {
                this.state.scenarios.filter(eachScenario => eachScenario.scenarioid === scenario.scenarioid).forEach((eachScenario) => {
                    rowVals = {"OPId": op.opid, "scenarioId": eachScenario.scenarioid.toString(), "tag": value};
                    opsUpdated.push(originpoints.setOpTag(rowVals));
                })
            } else {
                rowVals = {"OPId": op.opid, "scenarioId": scenario.scenarioid.toString(), "tag": value};
                opsUpdated.push(originpoints.setOpTag(rowVals));
            }
        })

        Promise.all(opsUpdated).then(() => {
            originpoints.getAllOriginpoints(this.state.testId, this.state.projectId).then(async result => {
                if (result.data.data.length > 0) {
                    this.setState({ops: result.data.data, allScenariosHasTags: false});
                    await this.setTagLotNumbersAndExpirationDates(result);
                    await this.updateSamplePlan();
                } else {
                    console.log('api error: ', result.data.error)
                }
            });
        })
    }

    handleScenariosSelect = async (rowValues, segmentId, value) => {
        const {testId, projectId} = this.state;

        rowValues = {testId, segmentId, totalScenarios: value};

        //API calls for creating/getting scenarios
        const scenariosResponse = await scenarios.createScenarios(rowValues);

        const isSuccessCreate = this.isSuccessResponse(scenariosResponse);
        const scenariosData = this.getResponseData(scenariosResponse);

        if (isSuccessCreate) {
            this.setState({
                scenarios: scenariosData,
                disableScenarios: true,
                scenarioDescription: {},
                allScenariosHasTags: false,
                //tagLotNumbers: {},
                //expirationDates: {}
            }, async () => {
                const scenariosRes = await scenarios.getScenarios(this.state.segments[0].segmentid);

                if (this.isSuccessResponse(scenariosRes)) {
                    const data = this.getResponseData(scenariosRes);
                    this.setState({
                        scenarios: data,
                    });
                    //rmonakhov: unless the below line is needed for anything
                    //except displaying the Air Sampler Rate input, I suggest we remove it
                    //window.location.reload();
                }
                /*
                If we don't hit this endpoint when user initially creates scenarios for a new  project,
                then ops array is empty upon initial render of NestedTableOP component, thus app breaks
                */
                originpoints.getAllOriginpoints(testId, projectId).then(result => {
                    if (result.data.data.length > 0) {
                        this.setState({
                            displayOP: true,
                            ops: result.data.data
                        });
                    } else {
                        console.log('api error: ', result.data.error)
                    }
                });

                samplepoints.getAllSamplepoints(testId, projectId).then(result => {
                    const spData = this.getResponseData(result);
                    const hasSp = this.isNotEmptyArray(spData);

                    if (!spData) {
                        console.log('Error getting SP: ', result.data.error);
                        return;
                    }
                    if (!hasSp) {
                        samplepoints.createSamplepoints({testId})
                          .then(async (response) => {
                              if (this.isSuccessResponse(response)) {
                                  this.setState({
                                      displaySP: true,
                                      displaySampleRate: true
                                  });
                                  await this.updateSamplePlan();
                              } else {
                                  console.log('Error creating SP');
                              }
                          });
                    }

                    this.setState({
                        displaySP: true,
                        displaySampleRate: true
                    });
                });
            });
        }
        this.setState({
            isUpdating: false
        });
    }

    handleScenarioDescriptionTextUpdate = async (scenarioId, value) => {
        const updateErrorMsg = `Couldn't update scenario's description`;
        const getErrorMsg = `Couldn't get updated scenarios' data. Description successfully updated.`
        try {
            // 1. Update specific scenario description
            const scenarioDescrRes = await scenarios.updateScenarios(scenarioId, {
                scenarioDescription: value
            });

            const updateSuccess = this.isSuccessResponse(scenarioDescrRes);
            if (!updateSuccess) throw new Error(updateErrorMsg);

            // 2. Get all scenarios so we can update state scenarioDescription obj and pass down as prop to textFields
            const scenariosRes = await scenarios.getScenarios(this.state.segments[0].segmentid);
            const getSuccess = this.isSuccessResponse(scenariosRes);
            if (!getSuccess) {
                throw new Error(getErrorMsg);
            }

            const newScenariosData = scenariosRes.data.data;
            const newScenariosDesc = {};
            for (let i = 0; i < newScenariosData.length; i++) {
                newScenariosDesc[i] = newScenariosData[i].scenariodescription || '';
            }
            this.setState({
                scenarios: newScenariosData,
                scenarioDescription: newScenariosDesc,
                launchLotAlert: true,
                lotNumAlertMsg: 'Description successfully updated',
            })
        } catch (err) {
            console.log('handleScenarioDescriptionTextUpdate error', err);
            if (!!~[updateErrorMsg, getErrorMsg].indexOf(err.message)) {
                this.stopUpdateAndAlertError(err.message);
            }
        }
    }

    handleSelectOP = async (numOP, numSPVerTest = false, continuousUpdate = false) => {
        const segment = this.state.segments ? this.state.segments[0] : {};
        let {
            floorplan,
            comments,
            surveydate,
            sp
        } = segment;

        const {projectId, testId} = this.state;

        const floorPlan = floorplan || null;
        const surveyDate = this.formatSurveyDate(surveydate || null);
        comments = comments || null;

        const data = {
            projectId,
            testId,
            totalSegments: 1,
            op: numOP
        };
        if (!!~VERIFICATION_LIKE_TESTS.indexOf(this.testType)) {
            data.intervalcount = segment.intervalcount || '';
            if (numSPVerTest) {
                data.sp = numSPVerTest;
            } else if (sp) {
                data.sp = sp;
            }
        }

        return segments.createSegments(data).then(async () => {
            const response = await segments.getSegments(testId, projectId);

            const segData = this.getResponseData(response);
            const isAnySegment = this.isNotEmptyArray(segData);

            if (!isAnySegment) {
                console.log('Error creating Segments');
                throw new Error('Error creating Segments');
            }

            const segmentId = segData[0].segmentid;

            this.setState({
                segments: segData,
                segmentId
            });

            //Need to do this to sustain persistance (segmentid) upon page refresh
            localStorage.setItem('segmentid', segmentId);

            await testPlan.updateTestPlan({
                segmentId,
                testId,
                surveyDate,
                comments
            });

            const updateResponse = await segments.updateSegment(segmentId, {testId, floorPlan, comments});
            const segUpdateSuccess = this.isSuccessResponse(updateResponse);

            if (segUpdateSuccess) {
                const segResponse = await segments.getSegments(testId, projectId);

                const isSuccess = this.isSuccessResponse(segResponse);
                if (!isSuccess) {
                    console.log('Error updating segments');
                    throw new Error('Error updating segments');
                }

                const segData = this.getResponseData(segResponse);
                this.setState({
                    segments: segData
                });
            }
            return originpoints.createOriginpoints({testId}).then(async (response) => {
                const isSuccess = this.isSuccessResponse(response);
                if (!isSuccess) {
                    console.log('Error creating OP');
                    throw new Error('Error creating OP');
                }

                if (this.state.scenarios.length > 0) {
                    await this.handleScenariosSelect({}, segmentId, this.state.scenarios.length)
                      .then(() => {
                          this.setState({
                              isUpdating: continuousUpdate
                          });
                      }).catch((err) => {
                          this.stopUpdateAndAlertError(err.message);
                          return false;
                      });
                } else {
                    this.setState({
                        isUpdating: continuousUpdate
                    });
                }
                return true;
            });
        }).catch((err) => {
            this.stopUpdateAndAlertError(err.message);
            return false;
        });
    }

    updateSamplePlan = async () => {
        try {
            await sampleplans.updateSamplePlan(this.projectId);
        } catch (e) {
            console.error(e);
        }
    }

    handleSelectChange = async (e, ref, type, segment, ops, scenario) => {
        this.setState({
            isUpdating: true
        });

        let shouldUpdateSamples = false;

        const {testId} = this.state;
        const segmentId = JSON.parse(localStorage.getItem('segmentid'));
        const rowValues = {
            testId
        };

        let value = e.target.value;

        if (type === 'scenarios') {
            await this.handleScenariosSelect(rowValues, segmentId, value);
            return;
        }

        if (!!~['originPointsRecirculation', 'originPointsVerification'].indexOf(type)) {
            value = parseInt(value, 10);
            return this.handleSelectOP(value);
        }

        //Setting payload for either fp or intervalDuration
        if (type === 'floorPlans') {
            rowValues.floorPlan = value;
        }

        if (type === 'intervalDuration') {
            value = parseFloat(value);
            rowValues.intervalDuration = value;
            shouldUpdateSamples = true;
        }

        if (type === 'samplePointsDilution' || type === 'samplePointsRecirculation' || type === 'samplePointsVerification') {
            value = parseInt(value);
            rowValues.sp = value;
            shouldUpdateSamples = true;
        }

        if (type === 'intervalCountVerification') {
            rowValues.intervalCount = parseInt(value, 10);
            shouldUpdateSamples = true;
        }

        //API calls for updating/getting segments
        const response = await segments.updateSegment(segmentId, rowValues);

        const segmentsUpdated = this.isSuccessResponse(response);
        if (segmentsUpdated) {
            const segResponse = await segments.getSegments(testId, this.projectId);

            const isSuccess = this.isSuccessResponse(segResponse);
            const segData = this.getResponseData(segResponse);

            if (isSuccess) {
                this.setState({
                      segments: segData
                  }, async () => {
                      if ([
                          'floorPlans',
                          'intervalDuration',
                          'originPointsRecirculation',
                          'intervalCountVerification',
                          'samplePointsDilution',
                          'samplePointsRecirculation',
                          'samplePointsVerification'
                      ].includes(type)) {
                          this.setState({
                              isUpdating: false
                          });
                      }
                  }
                );
            } else {
                console.log('error getting segments after update');
                this.stopUpdateAndAlertError('Error');
            }
        }

        if (type === 'tag') {
            shouldUpdateSamples = true;
            return await this.handleTagsSelect(rowValues, ops, scenario, value)
              .then(() => {
                  this.setState({
                      isUpdating: false
                  });
                  return true;
              }).catch(err => {
                  this.stopUpdateAndAlertError(err.message)
                  return false;
              });
        }

        if (shouldUpdateSamples) {

            await this.updateSamplePlan();
        }

    }

    stopUpdateAndAlertError = (message = '') => {
        this.setState({
            isUpdating: false,
            open: true,
            updateSuccessful: false,
            workFlow: false,
            alertMsg: message || 'Something went wrong'
        });
    };

    handleTextFieldChange = (event, i, type) => {
        const value = event.target.value;
        if (type === 'surveyDate') {
            this.setState({surveyDate: value});
        }
        if (type === 'comments') {
            this.setState({comments: value});
        }

        if (type === 'scenarioDescription') {
            //Descriptions obj to handle multiple textField states
            this.setState(prevState => ({
                scenarioDescription: {...prevState.scenarioDescription, [i]: value}
            }));
        }
    }

    handleSurveyDateUpdate = async (date) => {
        this.setState({
            surveyDate: date
        });
        await this.updateSurveyDate(date);
    }

    handleTagLotNumberChange = (event, i, type) => {
        const value = event.target.value;
        if (type === 'lotNumber') {
            this.setState(prevState => ({
                tagLotNumbers: {...prevState.tagLotNumbers, [i]: value}
            }));
        }
        if (type === 'expirationDate') {
            this.setState(prevState => ({
                expirationDates: {...prevState.expirationDates, [i]: value}
            }));
        }
    }

    onKeyDownLotNumberExpirationDateUpdate = async (e, opId, tag, type, ops, index) => {
        e.preventDefault();
        let requestBody = {};
        let value = e.target.children[0].children[0].children[0].children[0].value;
        if (type === 'lotNumber') {
            if (value !== '') {
                const opsUpdated = [];
                ops.forEach((op, i) => {
                    requestBody = {"OPId": op.opid, "tag": tag, "tagLotNumber": value.toString()};
                    console.log("requestBody: ", requestBody);
                    opsUpdated.push(originpoints.setTagLotNumber(requestBody));
                })
                Promise.all(opsUpdated).then(response => {
                    const firstResponse = response.shift();
                    if (firstResponse.data && firstResponse.data.success) {
                        this.setState({
                            launchLotAlert: true,
                            lotNumAlertMsg: 'Lot number successfully updated'
                        });

                        originpoints.getAllOriginpoints(this.state.testId, this.state.projectId).then(result => {
                            if (result.data.data.length > 0) {
                                this.setState({
                                    ops: result.data.data
                                });
                                if (result.data.data[0].tagLotNumber) {
                                    if (result.data.data[0].tagLotNumber.length > 0) {
                                        this.state.scenarios.forEach((scenarioObj, scenarioIndex) => {
                                            result.data.data[0].tags.forEach((tagObj, i) => {
                                                let scenarioId = parseInt(tagObj.scenarioId);
                                                if (scenarioId === scenarioObj.scenarioid) {
                                                    result.data.data[0].tagLotNumber.forEach((tagLotObj, tagLotObjIndex) => {
                                                        if (tagLotObj.tag === tagObj.tag) {

                                                            //Logic to handle preventing undefined being auto assigned to corresponding expirationDate cell
                                                            let exDate = '';
                                                            if (tagLotObj.expirationDate === 'undefined') {
                                                                exDate = '';
                                                            } else {
                                                                exDate = tagLotObj.expirationDate;
                                                            }

                                                            this.setState(prevState => ({
                                                                tagLotNumbers: {
                                                                    ...prevState.tagLotNumbers,
                                                                    [scenarioIndex]: tagLotObj.lotNumber
                                                                },
                                                                expirationDates: {
                                                                    ...prevState.expirationDates,
                                                                    [scenarioIndex]: exDate
                                                                },
                                                            }));
                                                        }
                                                    });
                                                }
                                            });
                                        })
                                    }
                                }
                            }
                        });
                    } else {
                        this.setState({
                            launchLotAlert: true,
                            lotNumAlertMsg: 'Please assign a plate kind and provide a valid lot number'
                        }, () => {
                            setTimeout(() => {
                                this.setState({launchLotAlert: false});
                            }, 1000);
                        });
                        this.state.tagLotNumbers[index] = '';
                    }
                });
            } else {
                this.setState({
                    launchLotAlert: true,
                    lotNumAlertMsg: 'Please assign a plate kind and provide a valid lot number'
                }, () => {
                    setTimeout(() => {
                        this.setState({launchLotAlert: false});
                    }, 1000);
                });
                this.state.tagLotNumbers[index] = '';
            }
        }
        if (type === 'expirationDate') {
            const opsUpdated = [];
            for (const i in ops) {
                if (ops[i].taglotnumbers === null) {
                    this.setState({
                        launchLotAlert: true,
                        lotNumAlertMsg: 'Please assign tag lot number first before submitting expiration date'
                    }, () => {
                        setTimeout(() => {
                            this.setState({launchLotAlert: false});
                        }, 1000);
                    });
                    this.state.tagLotNumbers[ops[i].opid] = '';
                    return;
                }
            }
            ops.forEach((op) => {
                requestBody = {
                    "OPId": op.opid,
                    "tag": tag,
                    "tagLotNumber": op.tagLotNumber[0].lotNumber,
                    expirationDate: value.toString()
                };
                console.log("requestBody: ", requestBody);
                opsUpdated.push(originpoints.setTagLotNumber(requestBody));
            })
            Promise.all(opsUpdated).then(response => {
                let firstResponse = response.shift();
                if (firstResponse.data.success) {
                    this.setState({
                        launchLotAlert: true,
                        lotNumAlertMsg: 'Expiration date successfully updated'
                    });

                    originpoints.getAllOriginpoints(this.state.testId, this.state.projectId).then(result => {
                        if (result.data.data.length > 0) {
                            this.setState({
                                ops: result.data.data
                            });
                            if (result.data.data[0].tagLotNumber) {
                                if (result.data.data[0].tagLotNumber.length > 0) {
                                    this.state.scenarios.forEach((scenarioObj, scenarioIndex) => {
                                        result.data.data[0].tags.forEach((tagObj, i) => {
                                            let scenarioId = parseInt(tagObj.scenarioId);
                                            if (scenarioId === scenarioObj.scenarioid) {
                                                result.data.data[0].tagLotNumber.forEach((tagLotObj) => {
                                                    if (tagLotObj.tag === tagObj.tag) {
                                                        this.setState(prevState => ({
                                                            tagLotNumbers: {
                                                                ...prevState.tagLotNumbers,
                                                                [scenarioIndex]: tagLotObj.lotNumber
                                                            },
                                                            expirationDates: {
                                                                ...prevState.expirationDates,
                                                                [scenarioIndex]: tagLotObj.expirationDate
                                                            },
                                                        }));
                                                    }
                                                });
                                            }
                                        });
                                    })
                                }
                            }
                        }
                    });
                }
            });
        }
    }

    onKeyDownTextUpdate = async (e, type, scenarioId) => {
        e.preventDefault();
        const segmentId = JSON.parse(localStorage.getItem('segmentid'));
        const testId = this.state.segments[0].testid;
        let alertMsg = '';
        let value = e.target.children[0].children[0].children[0].children[0].value;

        let rowVals = {};

        if (type === 'surveyDate') {
            alertMsg = 'Survey data successfully updated';
            rowVals = {"testId": testId, "segmentId": segmentId, "surveyDate": value}
        }
        if (type === 'comments') {
            alertMsg = 'Comments successfully updated';
            rowVals = {"testId": testId, "comments": value}
        }

        await this.doTestUpdate(type, rowVals, alertMsg, segmentId, testId);

        // Handle scenarioDescription
        if (type === 'scenarioDescription') {
            await this.handleScenarioDescriptionTextUpdate(scenarioId, value);
        }
    }

    doTestUpdate = async (type, rowValues, alertMsg, segmentId, testId) => {
        let response = await segments.updateSegment(segmentId, rowValues);
        //This is how we update survey date (segments microservice)
        await testPlan.updateTestPlan(rowValues).then(async () => {
            await this.updateSamplePlan();
        });

        if (response.data.success) {
            if (type === 'surveyDate' || type === 'comments') {
                this.setState({
                    launchLotAlert: true,
                    lotNumAlertMsg: alertMsg
                });
            }
            let segResponse = await segments.getSegments(testId, this.projectId);
            if (segResponse.data.success) {
                //surveyDate and comments are passed as props to textFields for dynamic content rendering
                this.setState({
                    segments: segResponse.data.data,
                    surveyDate: segResponse.data.data[0].surveydate ? this.formatSurveyDate(segResponse.data.data[0].surveydate) : null,
                    comments: segResponse.data.data[0].comments
                });
            } else {
                console.log('error')
            }
        }

    }

    updateSurveyDate = async (value) => {
        const segmentId = JSON.parse(localStorage.getItem('segmentid'));
        const testId = this.state.segments[0].testid;
        const alertMsg = 'Survey data successfully updated';
        const rowValues = {"testId": testId, "segmentId": segmentId, "surveyDate": value}

        await this.doTestUpdate('surveyDate', rowValues, alertMsg, segmentId, testId);
    }

    handleClosingAlert = () => {
        this.setState({
            launchLotAlert: false,
            lotNumAlertMsg: ''
        });
    }

    handleOpenVisualizerClick() {
        this.props.history.push('/visualizer');
    }

    handleDisplayOPClick = () => this.props.history.push('/originpoints');

    handleDisplaySPClick = () => this.props.history.push('/samplepoints');

    handleViewSamplesClick = () => this.props.history.push('/viewsamples');

    handleViewPlanForProjectClick = () => this.props.history.push('/testplandilution');

    handleClosingAlertDefineSegs = () => {
        this.setState({
            open: false,
            alertMsg: '',
            updateSuccessful: false
        });
    }

    isAllScenariosHasTags() {
        // Get IDs of all scenarios
        let scenariosIds = this.state.scenarios.map(function (d) {
            return d['scenarioid'];
        });
        if (scenariosIds === undefined) {
            return false;
        }

        // Get list of all tags connected to scenarios
        let opsTags = this.state.ops.map(function (d) {
            return d['tags'];
        })[0];
        if (opsTags === undefined) {
            return false;
        }

        // Compare counts, it should be equal
        for (let i = 0; i < scenariosIds.length; i++) {
            let scenarioId = scenariosIds[i];
            let tag = opsTags.find(x => x.scenarioId === scenarioId.toString());
            if (tag === undefined) {
                return false;
            }
        }
        // set state property, so that sample plan can be created (if not already created)
        if (this.state.allScenariosHasTags === false) this.setState({allScenariosHasTags: true});
        return true;
    }

    updateTestBuildingData = async (values) => {
        if (!values || !Object.keys(values)) return;
        for (let value of Object.values(values)) {
            const floatValue = parseFloat(value);
            if (floatValue <= 0) return;
        }

        try {
            const { segments, testBuildingData } = this.state;
            const testType = this.testType;

            let currSP;
            let currOP;
            if (segments && segments[0] && Object.keys(segments[0])) {
                const { sp, op } = segments[0];
                currSP = sp || 0;
                currOP = op || 0;
            }
            
            const squarePerOp = areaOpSpCountConfig[testType].op_square || null;
            const squarePerSp = areaOpSpCountConfig[testType].sp_square || null;
            if (!squarePerOp || !squarePerSp) {
                throw new Error('No config for OP, SP square ft relation');
            }

            const OP_amount = Math.ceil(values.sq_ft / squarePerOp);
            const SP_amount = Math.ceil(values.sq_ft / squarePerSp);
            
            const alreadyHasBuildingData = !!(Object.keys(testBuildingData) && Object.keys(testBuildingData).length);
            const shouldUpdateOpSp = ((currSP && currSP !== SP_amount) || (currOP && currOP !== OP_amount));

            if (shouldUpdateOpSp) {
                if (alreadyHasBuildingData) {
                    this.setState({
                        shouldConfirmOpResetOnBuildingDataUpdate: true,
                        afterBuildingDataUpdateConfirmation: (shouldUpdateSP) => this.doUpdateBuildingData(values, OP_amount, SP_amount, shouldUpdateSP),
                    });
                } else {
                    return await this.doUpdateBuildingData(values, OP_amount, SP_amount);
                }
            } else {
                return await this.doUpdateBuildingData(values);
            }
        } catch (err) {
            console.log('updateTestBuildingData error: ', err);
        }
    };

    doUpdateBuildingData = async (buildingData, OPamount = 0, SPamount = 0, shouldUpdateSP = true) => {
        this.setState({
            isUpdating: true
        });

        let success = false;
        let alertMsg = `Couldn't update building data`;
        const shouldUpdateOp = shouldUpdateSP && (OPamount || SPamount);

        try {
            const { testId } = this.state;
            const body = {
                buildingData,
            };

            const opUpdate = shouldUpdateOp && await this.handleSelectOP(OPamount, SPamount, true);

            if (opUpdate || !shouldUpdateOp) {
                const response = await tests.updateOneTest(testId, body);

                success = response && response.data && response.data.success;
                if (success) {
                    alertMsg = 'Building data succesfully updated';
                }
            }
        } catch (err) {
            console.log('doUpdateBuildingData error: ', err);
        } finally {
            const oldBuildingData = this.state.testBuildingData;

            this.setState({
                isUpdating: false,
                open: true,
                alertMsg,
                updateSuccessful: success,
                shouldConfirmOpResetOnBuildingDataUpdate: false,
                afterBuildingDataUpdateConfirmation: null,
                testBuildingData: success ? buildingData : oldBuildingData,
            });
        }

        return success;
    };

    getSecondaryBtn = (text, onClick) => {
        const buttonProps = {
            size: 'small',
            variant: 'outlined',
            style: {
                backgroundColor: "#82BC00",
                color: "#ffffff",
                borderColor: "#82BC00",
                marginLeft: "5px",
                marginTop: "58px",
                fontFamily: "Gotham"
            }
        };

        if (typeof onClick === 'function') {
            buttonProps.onClick = onClick;
        }

        return <Button {...buttonProps}>{text}</Button>;
    };

    render() {
        const {
            testCompleted,
            floorPlans,
            testId,
            projectId,
            segments,
            scenarios,
            ops,
            disableScenarios,
            surveyDate,
            comments,
            scenarioDescription,
            launchLotAlert,
            lotNumAlertMsg,
            tagLotNumbers,
            expirationDates,
            displaySampleRate,
            isUpdating,
            projectFromProjectPortal,
            updateSuccessful,
            alertMsg,
            open,
            displayOP,
            displaySP,
            shouldConfirmOpResetOnBuildingDataUpdate,
            afterBuildingDataUpdateConfirmation,
        } = this.state;

        const {classes} = this.props;
        return (
          <Context.Consumer>
              {context => (
                <>
                    {isUpdating === true && (
                      <DataLoadingDialog
                        title='The test is being updated'
                        message='Updating test data, this will take a few moments.'
                        onCancel={() => {
                            this.setState({isUpdating: false});
                        }}
                      />
                    )}
                    <TestAreaUpdateConfirmation
                        open={shouldConfirmOpResetOnBuildingDataUpdate}
                        onPrimaryAction={() => afterBuildingDataUpdateConfirmation(true)}
                        onSecondaryAction={() => afterBuildingDataUpdateConfirmation(false)}
                        onCancel={() => this.setState({ 
                            shouldConfirmOpResetOnBuildingDataUpdate: false,
                            afterBuildingDataUpdateConfirmation: false,
                        })}
                    />
                    <div className="segments-container">
                        <div>
                            <Typography variant="h5" color="textPrimary" align="center"
                                        style={{fontFamily: "Gotham", marginTop: "30px"}}>
                                {`(Project Id ${projectId}) ${context.state.projectName}: ${context.state.testName}`}
                            </Typography>
                        </div>
                        <div className="scenarios-btn-container-dilution">
                            {(this.context.state.accessLevel !== 'Viewer') && (
                              <div
                                style={{width: '150px', fontFamily: "Gotham", marginTop: '60px', marginRight: '20px'}}>
                                  <Select
                                    value={this.state.sprayer}
                                    onChange={this.handleSelectSprayer}
                                    options={this.state.sprayerOptions}
                                    isDisabled={this.state.testCompleted || this.context.state.accessLevel === 'Viewer' ||  this.sprayerFixedByTestType}
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    placeholder="Sprayer"
                                    label="Sprayer"
                                    styles={{
                                        container: (provided, state) => ({
                                            ...provided,
                                            // width: '40%',
                                            marginTop: 20
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            overflow: "visible"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top: state.hasValue || state.selectProps.inputValue ? -15 : "50%",
                                            transition: "top 0.1s, font-size 0.1s",
                                            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                                        })
                                    }}>
                                  </Select>
                              </div>
                            )}

                            {(displaySampleRate && this.context.state.accessLevel !== 'Viewer') && !this.sprayerFixedByTestType && (
                              <div style={{width: '150px', fontFamily: "Gotham", marginTop: '60px'}}>
                                  <div className='text-field-label-scenarios'>Air Sample Rate (L/min)*</div>
                                  <form onSubmit={e => this.handleSampleRateChange(e)} className={classes.root}
                                        noValidate autoComplete="off">
                                      <div style={{
                                          width: '150px',
                                          fontFamily: "Gotham",
                                          display: 'flex',
                                          flexDirection: 'row'
                                      }}>
                                          <TextField
                                            type="text"
                                            name='sampleRate'
                                            inputProps={{min: 0, style: {textAlign: 'center'}}}
                                            defaultValue={this.state.sampleRate}
                                            value={this.state.sampleRate}
                                            onChange={e => {
                                                let {value} = e.target;
                                                this.setState({sampleRate: value});
                                            }}
                                            disabled={testCompleted || this.context.state.accessLevel === 'Viewer' || this.state.selectedSPCollectionMethod === null || this.sampleType === 'Swab'}
                                          />
                                          <Tooltip title="Submit Air Sample Rate" placement="top"
                                                   aria-label="air-sample-rate">
                                              <IconButton
                                                type="submit"
                                                disabled={testCompleted || this.context.state.accessLevel === 'Viewer' || this.state.selectedSPCollectionMethod === null || this.sampleType === 'Swab'}
                                              >
                                                  <CloudUploadIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>
                                              </IconButton>
                                          </Tooltip>
                                      </div>
                                  </form>
                              </div>
                            )}
                            <div style={{marginTop: '35px'}} className="segments-buttons segments-btn-container-child">
                                {displaySP && displayOP && this.getSecondaryBtn('Floor plans', this.handleOpenVisualizerClick)}
                                {displayOP && this.getSecondaryBtn('Origin points', this.handleDisplayOPClick)}
                                {displaySP && this.getSecondaryBtn('Sample points', this.handleDisplaySPClick)}
                                {displaySP && displayOP && !!~['dilution', 'miniSurvey', 'recirculation', ...VERIFICATION_LIKE_TESTS].indexOf(this.testType) &&
                                  this.getSecondaryBtn('Samples', this.handleViewSamplesClick)}
                            </div>
                        </div>
                        <div className="segments-table-container">
                            {this.testType === 'miniSurvey' && (
                              <MiniSurveyTest
                                testCompleted={testCompleted}
                                floorPlans={floorPlans}
                                testId={testId}
                                projectId={projectId}
                                disableScenarios={disableScenarios}
                                surveyDate={surveyDate}
                                comments={comments}
                                scenarioDescription={scenarioDescription}
                                onChangeFunc={this.handleSelectChange}
                                onTextChangeFunc={this.handleTextFieldChange}
                                onSurveyDateUpdateFunc={this.handleSurveyDateUpdate}
                                onKeyDownTextUpdate={this.onKeyDownTextUpdate}
                                handleTagLotNumberChange={this.handleTagLotNumberChange}
                                onKeyDownLotNumberExpirationDateUpdate={this.onKeyDownLotNumberExpirationDateUpdate}
                                tagLotNumbers={tagLotNumbers}
                                expirationDates={expirationDates}
                                segment={segments[0]}
                                ops={ops}
                                scenario={scenarios[0]}/>
                            )}
                            {!!~['dilution', 'recirculation'].indexOf(this.testType) && (
                              <ScenariosList
                                testCompleted={testCompleted}
                                floorPlans={floorPlans}
                                testId={testId}
                                projectId={projectId}
                                projectFromProjectPortal={projectFromProjectPortal}
                                disableScenarios={disableScenarios}
                                surveyDate={surveyDate}
                                comments={comments}
                                scenarioDescription={scenarioDescription}
                                onChangeFunc={this.handleSelectChange}
                                onTextChangeFunc={this.handleTextFieldChange}
                                onSurveyDateUpdateFunc={this.handleSurveyDateUpdate}
                                onKeyDownTextUpdate={this.onKeyDownTextUpdate}
                                handleTagLotNumberChange={this.handleTagLotNumberChange}
                                onKeyDownLotNumberExpirationDateUpdate={this.onKeyDownLotNumberExpirationDateUpdate}
                                tagLotNumbers={tagLotNumbers}
                                expirationDates={expirationDates}
                                segments={segments}
                                ops={ops}
                                scenarios={scenarios}
                                testType={this.testType}/>
                            )}
                            {!!~VERIFICATION_LIKE_TESTS.indexOf(this.testType) && (
                              <VolumeBasedTest
                                testType={this.testType}
                                segment={segments[0]}
                                ops={ops || []}
                                scenarios={scenarios}
                                testCompleted={testCompleted}
                                floorPlans={floorPlans}
                                testId={testId}
                                projectId={projectId}
                                surveyDate={surveyDate}
                                comments={comments}
                                onChangeFunc={this.handleSelectChange}
                                onTextChangeFunc={this.handleTextFieldChange}
                                onSurveyDateUpdateFunc={this.handleSurveyDateUpdate}
                                onKeyDownTextUpdate={this.onKeyDownTextUpdate}
                                updateTestBuildingData={this.updateTestBuildingData}
                                handleScenarioDescriptionUpdate={this.handleScenarioDescriptionTextUpdate}
                              />
                            )}
                        </div>
                    </div>
                    {!!open && (<Alert flag={open} msg={alertMsg} closeFunc={this.handleClosingAlertDefineSegs}
                                       updateSuccessful={updateSuccessful}/>)}
                    {!!launchLotAlert && (
                      <AlertLotNumber launchLotAlert={launchLotAlert} lotNumAlertMsg={lotNumAlertMsg}
                                      handleClosingAlert={this.handleClosingAlert}/>)}
                </>
              )}
          </Context.Consumer>
        )
    }
}

Scenarios.contextType = Context;

export default withStyles(styles, {withTheme: true})(Scenarios);
