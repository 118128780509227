import axios from 'axios';
import config from '../config.js';

const LOGINURL = config.baseURL + '/auth/login';
const LOGOUTURL = config.baseURL + '/auth/logout';
const COMPANIESURL = config.baseURL + '/auth/companies';
const PARTNERSURL = config.baseURL + '/auth/partners';
const CUSTOMERSURL = config.baseURL + '/auth/customers';

const authentication  = {
  login : async(data) => {
    const response = await axios.post (LOGINURL, data);
    return response;
  },
  logout: async() => {
      const response = await axios.post (LOGOUTURL, {});
      return response;
  },
  getCompanies: async() => {
    const response = await axios.get (COMPANIESURL, {});
      return response;
  },
  getPartners: async() => {
    const response = await axios.get (PARTNERSURL, {});
      return response;
  },
  getAllCustomers: async() => {
    const response = await axios.get (CUSTOMERSURL, {});
      return response;
  }
};

export default authentication;