import React, {useContext, useEffect, useRef, useState} from 'react';
import { useForm }                            from "react-hook-form";
import Paper                                  from "@material-ui/core/Paper";
import Table                                  from "@material-ui/core/Table";
import TableHead                              from "@material-ui/core/TableHead";
import TableBody                              from "@material-ui/core/TableBody";
import TableRow                               from "@material-ui/core/TableRow";
import TableCell                              from "@material-ui/core/TableCell";
import Button                                 from "@material-ui/core/Button";
import TableContainer                         from "@material-ui/core/TableContainer";
import { makeStyles }                         from "@material-ui/core/styles";
import Modal                                  from "@material-ui/core/Modal";
import Backdrop                               from "@material-ui/core/Backdrop";
import Fade                                   from "@material-ui/core/Fade";
import TextField                              from "@material-ui/core/TextField";
import { DatePicker }                         from "@material-ui/pickers";
import { AlertTestCompleted }                 from "../../components/Alert/AlertTestCompleted";
import originpoints                           from "../../api/originpoints";
import './ProjectDetails.css';
import {Context} from "../../context/Context";
import { parseISO } from "date-fns";

const useRowStyles = makeStyles((theme) => ({
    paper:       {
        backgroundColor: theme.palette.background.paper,
        boxShadow:       theme.shadows[5],
        padding:         theme.spacing(7, 5, 7),
        textAlign:       'center',
        fontFamily:      "Gotham"
    },
    tableHeader: {
        backgroundColor: '#F3F3F3'
    },
    headerCell:  {
        fontFamily: 'Gotham',
        fontSize:   '13px',
        fontWeight: '700',
        textAlign:  'left',
        padding:    '10px !important'
    },
    bodyCell:    {
        textAlign: 'left',
        padding:   '10px !important'
    },
    modal:       {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center'
    },
}));

export const ProjectTagsTable = ({projectId, projectTags, filterTags, fetchUpdatedProject}) => {
    const {state}                                     = useContext(Context);
    const classes                                     = useRowStyles();
    const {register, errors}                          = useForm();
    const [projectTagsCurrent, setProjectTagsCurrent] = useState({});
    const [projectTagsParsed, setProjectTagsParsed]   = useState({});
    const [openTagModal, setOpenTagModal]             = useState(null);
    const [tagNumber, setTagNumber]                   = useState('');
    const [tagObject, setTagObject]                   = useState({});
    const [tagLotNumber, setTagLotNumber]             = useState(null);
    const [expirationDate, setExpirationDate]         = useState(null);
    const [launchLotAlert, setLaunchLotAlert]         = useState(false);
    const [lotNumAlertMsg, setLotNumAlertMsg]         = useState('');

    const getParsedTags = (projectTags) => {
        let tagsTmp = {};
        projectTags.forEach((obj, index) => {
            const {opid, tags, tagLotNumber} = obj;

            tags.forEach(function (tag) {

                let tagName = tag.tag;

                if (!tagName.startsWith(filterTags)) {
                    return;
                }

                if (tagsTmp[tagName] === undefined || null) {
                    tagsTmp[tagName] = [];
                }

                let item = {opid: opid, tagLotNumber: null, expirationDate: null};

                if (tagLotNumber !== undefined) {
                    tagLotNumber.forEach(function (tagLot) {
                        if (tagName === tagLot.tag) {
                            if (tagLot.lotNumber !== "null") {
                                item.tagLotNumber = tagLot.lotNumber;
                            }
                            if (tagLot.expirationDate !== "null") {
                                item.expirationDate = tagLot.expirationDate;
                            }
                        }
                    });
                }

                tagsTmp[tagName].push(item);
            });
        });

        // Order object by key names
        return Object.keys(tagsTmp).sort().reduce((obj, key) => {
            obj[key] = tagsTmp[key];
            return obj;
        }, {});
    }

    const handleModalOpen  = (tag, tagObj) => {
        setTagNumber(tag);
        setTagObject(tagObj);
        setTagLotNumber(tagObj[0].tagLotNumber ?? null);
        setExpirationDate(tagObj[0].expirationDate ?? null);
        setOpenTagModal(tag);
    }
    const handleModalClose = () => {
        setOpenTagModal(null);
    }
    const handleChange     = (field, value) => {
        switch (field) {
            case 'tagLotNumber':
                setTagLotNumber(value.target.value ?? null);
                break;
            case 'expirationDate':

                if (value === null) {
                    setExpirationDate(null);
                } else {
                    let d     = new Date(value),
                        month = '' + (d.getMonth() + 1),
                        day   = '' + d.getDate(),
                        year  = d.getFullYear();

                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;

                    setExpirationDate([year, month, day].join('-'));
                }

                break;
            default:
                break;
        }
    }
    /**
     * Update details for tag with same name in all segments
     */
    const handleSubmit     = async () => {
        Promise
            .all(
                tagObject.map(function (tagObjData) {
                    return originpoints.setTagLotNumber({
                        OPId:           tagObjData.opid,
                        tag:            tagNumber,
                        tagLotNumber:   tagLotNumber ?? null,
                        expirationDate: expirationDate ?? null,
                    });
                })
            )
            .then(function (response) {
                if (response[0].data.error !== undefined && response[0].data.error.message !== undefined) {
                    handleOpenAlert(response[0].data.error.message);
                }
                firstUpdate.current = true;
                fetchUpdatedProject();
                handleModalClose();
            });
    }

    const handleOpenAlert = (message) => {
        setLaunchLotAlert(true);
        setLotNumAlertMsg(message);
        setTimeout(() => {
            setLaunchLotAlert(false);
            setLotNumAlertMsg('');
        }, 2000);
    }

    const handleClosingAlert = () => {
        this.setState({
            open:             false,
            alertMsg:         '',
            updateSuccessful: false
        });
    }

    const projectTagsParsedTmp = getParsedTags(projectTags);
    const firstUpdate          = useRef(true);
    useEffect(() => {
        // Reject if:
        // - it's not a first request
        // - or if an array with parsed tags is empty
        // - or if a new array with tags is the same as a previous one
        if (!firstUpdate.current || Object.keys(projectTagsParsedTmp).length === 0 || projectTags === projectTagsCurrent) {
            return;
        }
        setProjectTagsCurrent(projectTags);
        setProjectTagsParsed(projectTagsParsedTmp);
        firstUpdate.current = false;
    });

    // Return nothing if there is no tags
    if (Object.keys(projectTagsParsed).length === 0) {
        return ('');
    }

    return (
        <div style={{width: "100%", marginRight: "15px"}}>
            <TableContainer style={{marginTop: '15px', boxShadow: 'none'}} component={Paper}>
                <Table aria-label="project-list-table">
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell className={classes.headerCell}>Tag</TableCell>
                            <TableCell className={classes.headerCell}>Lot Number*</TableCell>
                            <TableCell className={classes.headerCell}>Exp. Date*</TableCell>
                        </TableRow>
                    </TableHead>
                    {Object.keys(projectTagsParsed).map((tagName, index) => (
                        <TableBody key={`key-${index}`}>
                            <TableRow hover={true} onClick={() => {state.accessLevel !== 'Viewer' && handleModalOpen(tagName, projectTagsParsed[tagName])}}>
                                <TableCell className={classes.bodyCell + " table-cell" + (state.accessLevel !== 'Viewer' ? "-pointer" : "")}>
                                    {tagName}
                                </TableCell>
                                <TableCell className={classes.bodyCell + " table-cell" + (state.accessLevel !== 'Viewer' ? "-pointer" : "")}>
                                    {projectTagsParsed[tagName][0].tagLotNumber}
                                </TableCell>
                                <TableCell className={classes.bodyCell + " table-cell" + (state.accessLevel !== 'Viewer' ? "-pointer" : "")}>
                                    {projectTagsParsed[tagName][0].expirationDate}
                                </TableCell>
                            </TableRow>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className={classes.modal}
                                open={openTagModal === tagName}
                                onClose={() => handleModalClose()}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}>
                                <div>
                                    <Fade in={openTagModal === tagName}>
                                        <div className={classes.paper}>
                                            <div className="grid-wrapper">
                                                <div>
                                                    <h2 id="transition-modal-title">
                                                        Update "{tagNumber}"
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="grid-wrapper-info">
                                                <div>
                                                    <TextField
                                                        fullWidth={true}
                                                        inputRef={register({required: true})}
                                                        id={`tagLotNumber-${index}`}
                                                        label="Tag lot number"
                                                        name="tagLotNumber"
                                                        autoComplete="tagLotNumber"
                                                        style={{fontFamily: 'Gotham'}}
                                                        onChange={(newValue) => handleChange("tagLotNumber", newValue)}
                                                        error={errors.tagLotNumber}
                                                        value={tagLotNumber}
                                                        autoFocus
                                                    />
                                                </div>
                                                <div>
                                                    <DatePicker
                                                        fullWidth={true}
                                                        inputRef={register({required: true})}
                                                        id={`expirationDate-${index}`}
                                                        label="Expiration date"
                                                        name="expirationDate"
                                                        autoComplete="expirationDate"
                                                        style={{fontFamily: 'Gotham'}}
                                                        onChange={(newValue) => handleChange("expirationDate", newValue)}
                                                        error={errors.expirationDate}
                                                        format="yyyy-MM-dd"
                                                        mask="____-__-__"
                                                        value={parseISO(expirationDate) ?? null}
                                                        clearable
                                                    />
                                                </div>
                                            </div>
                                            <div className="grid-wrapper">
                                                <div>
                                                    <Button
                                                        onClick={e => handleSubmit(e)}
                                                        size="small"
                                                        className="modal-button"
                                                    >
                                                        Save
                                                    </Button>
                                                    <Button
                                                        onClick={() => handleModalClose()}
                                                        size="small"
                                                        className="modal-button"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </Modal>
                        </TableBody>
                    ))}
                </Table>
                {!!launchLotAlert && (<AlertTestCompleted flag={launchLotAlert} msg={lotNumAlertMsg} closeFunc={handleClosingAlert} />)}
            </TableContainer>
        </div>
    );
};
