import React, { useEffect, useRef, useState } from 'react';

import originpoints from "../../api/originpoints";
import Labels from "../../components/Labels/Labels";
import samplepoints from "../../api/samplepoints";
import sampleplans from "../../api/sampleplans";

export default function PrintAll(props) {
    const [ OPLabels, setOPLabels ] = useState([]);
    const [ SPLabels, setSPLabels ] = useState([]);
    const [ SCLabels, setSCLabels ] = useState([]);

    const isFirstRender = useRef(true);
    useEffect(() => {
        if ( isFirstRender.current ) {
            isFirstRender.current = false;

            let projectId = JSON.parse(localStorage.getItem("projectId")) ? JSON.parse(localStorage.getItem("projectId")) : null;
            getOPLabels(projectId).then(r => {
            });
            getSPLabels(projectId).then(r => {
            });
            getSCLabels(projectId).then(r => {
            });
        }
    })

    const getOPLabels = async (projectId) => {
        const response = await originpoints.getLabels(null, projectId);
        if ( typeof response !== 'undefined' && response.data.success ) {
            let labels = response.data.data;
            if ( Object.keys(labels).length !== 0 ) {
                labels.forEach(label => {
                    label.number = label.opnumber;
                    label.name   = label.opname;
                });
                setOPLabels(labels);
            }
        }
    }

    const getSPLabels = async (projectId) => {
        const response = await samplepoints.getLabels(null, projectId);
        if ( typeof response !== 'undefined' && response.data.success ) {
            let labels = response.data.data;
            if ( Object.keys(labels).length !== 0 ) {
                labels.forEach(label => {
                    label.number = label.spnumber;
                    label.name   = label.spname;
                });
                setSPLabels(labels);
            }
        }
    }

    const getSCLabels = async (projectId) => {
        const response = await sampleplans.getLabels(null, projectId);
        if ( typeof response !== 'undefined' && response.data.success ) {
            let labels = response.data.data;
            if ( Object.keys(labels).length !== 0 ) {
                labels.forEach(label => {
                    label.number = label.samplePoint;
                });
                setSCLabels(labels);
            }
        }
    }

    return (
        <div>
            {props.type === 'OPLabels' && (
                <div className="item-container">
                    <Labels
                        labels={OPLabels} perPage={999} noPagination={true}/>
                </div>
            )}
            {props.type === 'SPLabels' && (
                <div className="item-container">
                    <Labels
                        labels={SPLabels} perPage={999} noPagination={true}/>
                </div>
            )}
            {props.type === 'SCLabels' && (
                <div className="item-container">
                    <Labels
                        labels={SCLabels} perPage={999} noPagination={true}/>
                </div>
            )}
        </div>
    )
}